import React, {useEffect, useState} from "react";
import {Checkbox} from "../Checkbox/Checkbox";
import pureFolder from "../../../dist/icons/pureFolder.svg";
import {Typography} from "../Typography/Typography";
import conversionBytes from "../../../utils/conversionBytes";
import {colors} from "../../../assets/colors/colors";
import {toast} from "react-toastify";

import './style.scss'
import {ButtonV2} from "../ButtonV2";
import {BTN_LABELS} from "../../../constants";
import {COLORS_BTN, VARIATIONS_BTN} from "../ButtonV2/constants";

const configError = {
    autoClose: 3000
};

export const FileBlock = ({size, checked, file, select, edit, save, cancel, preview }) => {
    const [valueEdit, setValueEdit] = useState(file.name);

    useEffect(() => {
        setValueEdit(file.name);
    }, [edit]);

    const handleClickSave = () => {
        if (valueEdit.length < 2) {
            toast("File Name min length is 2", configError);
            return;
        }

        save(valueEdit);
    }

    const handleClickCancel = () => {
        setValueEdit("");
        cancel();
    }

    return (
        <a className="file-attachment" href={preview} target='_blank'>
            {select && !edit && <Checkbox checked={!!checked} onClick={select} />}
            <div className="file-attachment_file">
                <img src={pureFolder} alt="circle" />
                <span className="file-attachment_file_format">{file.format}</span>
            </div>

            <div className='file-attachment_container'>
                {edit ? (
                    <input
                        maxLength={200}
                        className="file-attachment_edit"
                        value={valueEdit}
                        onChange={e => {
                            setValueEdit(e.target.value);
                        }}
                    />
                ) : (
                    <Typography
                        capitalize
                        text={file.name}
                        variant="p"
                        className="file-attachment_name"
                    />
                )}
                {size && <Typography
                    text={conversionBytes(size)}
                    variant="h5"
                    color={colors.greyMiddle}
                    className="file-attachment_size"
                />}
            </div>
            {edit && (
                <div className='file-attachment_actions'>
                    <ButtonV2 color={COLORS_BTN.PRIMARY} variant={VARIATIONS_BTN.TEXT} uppercase
                              // className="file-attachment_save"
                              onClick={handleClickSave}>{BTN_LABELS.SAVE}</ButtonV2>
                    <ButtonV2 color={COLORS_BTN.PRIMARY} variant={VARIATIONS_BTN.TEXT} uppercase
                              // className="file-attachment_cancel"
                              onClick={handleClickCancel}>{BTN_LABELS.CANCEL}</ButtonV2>
                </div>
            )}
        </a>
    );
};
