import React from "react";
import "./style.scss";
import cn from "classnames";
import { colors } from "../../../assets/colors/colors";

export const Radiobutton = props => (
  <label
    className={cn(
      "container-radio-button",
      props.checked && "active",
      props.className,
      {
        "container-radio-button_error": props.error
      },
      {
        "container-radio-button_disabled": props.disable
      }
    )}
  >
    {props.label}
    <input
      disabled={props.disable}
      onChange={props.onClick}
      type="radio"
      name="radio"
      checked={props.checked}
    />
    <span className="container-radio-button_checkmark">
      {props.checked ? (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 5C7.24 5 5 7.24 5 10C5 12.76 7.24 15 10 15C12.76 15 15 12.76 15 10C15 7.24 12.76 5 10 5ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18Z"
            fill={colors.navy}
          />
        </svg>
      ) : (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18Z"
            fill={props.error ? colors.redError : "#5F6368"}
          />
        </svg>
      )}
    </span>
  </label>
);
