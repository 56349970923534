import React from "react";

export const TestPerformed = ({items}) => {
    return {
        type: 'label',
        data: 'TESTS PERFORMED',
        extraType: 'tests_performed',
        required: true,
        isDelete: false,
        canDeleteBlock: true,
        items: items
    }
}
