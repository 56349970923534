import React, { Component } from "react";
import "./style.scss";
import PersonalInfoForm from "../PersonalInfoForm";
import ButtonGroup from "../../Common/ButtonGroup/ButtonGroup";
import { Typography } from "../../Common/Typography/Typography";
import { Button } from "../../Common/Button/Button";
import EmploymentApplication from "../EmploymentApplication/EmploymentApplication";
import { DOCTOR } from "../../../constants/constants";
import {
  BUTTONSDOCTOR,
  routersDoctor,
  TYPOGRAPHYNAME
} from "../../../constants/Doctors";
import history from "../../../helpers/history";
import { lengthObj } from "../../../utils/common";
import { routesCommon } from "../../../constants";

class PersonalInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      statePage: BUTTONSDOCTOR[0],
      data: {}
    };

    this.funcSave = () => {};
    this.func = () => {};
    this.funcCancel = () => {};
  }

  saveElements = () => this.func();

  componentDidMount() {
    this.props.getDoctorData().then((resp)=>{
      if(resp.uuid){
        this.props.saveSignature(null, resp.uuid)
      }
    })
  }

  cancelAction = () => this.funcCancel();

  changePage = title => {
    if (title !== this.state.statePage) {
      let switchTab = true;
      if (title === BUTTONSDOCTOR[1]) switchTab = this.funcSave(true);
      if (switchTab) {
        this.setState({
          statePage: title
        });
      }
    }
  };

  setPersonalInfo = data => {
    this.setState({ data: data });
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.location.state) {
      this.props.getDoctorData().then(() => {
        this.setState({
          statePage: nextProps.location.state.step
        });
      });
      history.replace(`${routesCommon.doctor}${routersDoctor.personal_info}`);
    }
  }

  render() {
    const doctorData = lengthObj(this.state.data)
      ? this.state.data
      : this.props.doctorData;

    return (
      <div className="doctor-page-container">
        <div className="doctor-page-personal-info">
          <ButtonGroup
            page={this.state.statePage}
            buttonsTab={BUTTONSDOCTOR}
            changePage={this.changePage}
          />
          <Typography
            capitalize
            dangerouslySetInnerHTML={
              TYPOGRAPHYNAME[this.state.statePage === BUTTONSDOCTOR[0] ? 0 : 1]
            }
            variant="h3"
            className="doctor-page-personal-info_title"
          />

          {this.state.statePage === BUTTONSDOCTOR[0] && (
            <PersonalInfoForm
              setPersonalInfo={this.setPersonalInfo}
              updatePersonalInfo={this.props.updatePersonalInfoDoctorFlow}
              updateDoctor={this.props.updateDoctor}
              path={this.props.path}
              flag={DOCTOR}
              doctorData={doctorData}
              saveElements={(func, funcCancel) => {
                this.func = func;
                this.funcSave = func;
                this.funcCancel = funcCancel;
              }}
            />
          )}

          {this.state.statePage === BUTTONSDOCTOR[1] && (
            <EmploymentApplication
                listOfDocumentsFor={doctorData?.employeeStatus}
                signature={this.props.signature}
              saveDataDoctor={this.funcSave}
              updateUploadedFiles={this.props.updateUploadedFiles}
              doctorData={this.props.doctorData}
              flag={DOCTOR}
              saveSignature={this.props.saveSignature}
              saveElements={(func, funcCancel) => {
                this.func = func;
                this.funcCancel = funcCancel;
              }}
            />
          )}

          <div className="doctor-page-personal-info_title-btn">
            <Button
              text="Cancel"
              size="middle"
              variant="default"
              onClick={this.cancelAction}
            />
            <Button
              text="Save"
              size="middle"
              variant="primary"
              onClick={this.saveElements}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default PersonalInfo;
