import React from "react";
import cn from "classnames";
import { Typography } from "../../Common/Typography/Typography";
import { Checkbox } from "../../Common/Checkbox/Checkbox";

export const DataItem = ({ onClick, select, text, image,disableChoose }) => (
  <div
      aria-disabled={disableChoose}
    className={cn("step-symptoms-list_wrapper_data-item", {
      "step-symptoms-list_wrapper_data-item_selected": select
    })}
    onClick={onClick}
  >
    <div className="step-symptoms-list_wrapper_data-item_wrapper">
      <img src={image} />
      <Typography
        variant="p"
        text={text}
        className="step-symptoms-list_wrapper_data-item_wrapper_name"
      />
    </div>
    <Checkbox
      className="step-symptoms-list_wrapper_data-item_checkbox"
      onClick={onClick}
      checked={select}
    />
  </div>
);
