import React from "react";
import { Typography } from "../../Common/Typography/Typography";
import cn from "classnames";
import { AdditionalServices } from "../../../compoenentsAdditional/AdditionalServices";

export const ViewServices = ({ className, selectedServices, isAdmin }) => {
  return (
    <div className={cn("view-services", className)}>
      {isAdmin && (
        <Typography
          text="Additional Services Applied:"
          color="grey"
        />
      )}
      <AdditionalServices noLabel services={selectedServices}/>
      {/*{!!selectedServices.length && (*/}
      {/*  <>*/}
      {/*    <Typography*/}
      {/*      text="Additional Services"*/}
      {/*      className="view-services_text"*/}
      {/*    />*/}
      {/*    {selectedServices.map((item, index) => {*/}
      {/*      const color =*/}
      {/*        colorStatus[*/}
      {/*          item.payment_status.toLowerCase().replace(/\s/g, "_")*/}
      {/*        ];*/}
      {/*      const colorPrice =*/}
      {/*        item.payment_status !== PENDING ? colors.navy : color;*/}

      {/*      const colorName =*/}
      {/*        item.payment_status !== PENDING ? colors.black : color;*/}

      {/*      return (*/}
      {/*        <div key={index} className="view-services_container">*/}
      {/*          <Typography*/}
      {/*            text={item.name}*/}
      {/*            className="view-services_name"*/}
      {/*            color={colorName}*/}
      {/*          />*/}
      {/*          <Typography*/}
      {/*            className="view-services_price"*/}
      {/*            text={`$${item.price.slice(0, -3)}`}*/}
      {/*            color={colorPrice}*/}
      {/*          />*/}
      {/*          <Typography*/}
      {/*            text={item.payment_status}*/}
      {/*            className="view-services_status"*/}
      {/*            color={color}*/}
      {/*          />*/}
      {/*        </div>*/}
      {/*      );*/}
      {/*    })}*/}
      {/*  </>*/}
      {/*)}*/}
    </div>
  );
};
