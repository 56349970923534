import React from "react";
import {Typography} from "../../components/Common/Typography/Typography";
import cn from 'classnames';
import "./style.scss";
import doctorAvatar from "../../dist/images/patientAvatar.svg";
import {SIZES} from "./constants";
import {colors} from "../../assets/colors/colors";

export const DoctorInfo = ({size, doctor}) => {

  const text = doctor ? `Meet Dr. ${doctor}` : 'Doctor is not assigned yet'
    return (
        <div className={cn('doctor-info', {
            'doctor-info_small': size === SIZES.SMALL,
            'doctor-info_medium': size === SIZES.MEDIUM,
            'doctor-info_big': size === SIZES.BIG,
        })}>
          <Typography color={colors.grey} variant="p" text="Doctor"  className='doctor-info_header'/>
            <div className='doctor-info_body'>
                <img src={doctorAvatar} alt="avatar"/>
                <Typography
                    text={text}
                />
            </div>
        </div>
    );
};
