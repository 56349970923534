import React, {Component} from "react";
import {PanelFilters} from "./PanelFilters/PanelFilters";
import {DropDown} from "../Common/DropDown/DropDown";
import {Checkbox} from "../Common/Checkbox/Checkbox";
import {CustomTable} from "../Table/Table";
import {colors} from "../../assets/colors/colors";
import {Typography} from "../Common/Typography/Typography";
import {getLockFilters} from "../../store/actions";
import {getFilters} from "../../store/emr/actions";
import moment from "moment";
import {formatViewDate, formatAppointmentDate} from "../../utils/date";
import {infoCloudSearch, placeholders, routesCommon, visitNoteStatuses} from "../../constants";
import "../../styles/EMRPage.scss";
import history from "../../helpers/history";
import {routersDoctor} from "../../constants/Doctors";
import {convertPhoneValue, lengthObj} from "../../utils/common";
import {RequestStatus} from "../Common/RequestStatus/RequestStatus";

const defaultStyles = {
    fontSize: 14,
    letterSpacing: "0.25px",
    textTransform: "capitalize",
    color: colors.tuna,
    fontWeight: 500,
    marginRight: 0,
    height: 29
    // borderBottomStyle: 'solid',
    // borderBottomWidth: 1,
    // borderBottomColor: 'rgba(151,151,151, 0.4)'
};

const defaultStylesCell = {
    height: 44,
    display: 'flex',
    alignItems: 'center'
}

const columnsAdmin = [
    {
        headerClassName: "table-header-cell",
        label: "patient",
        dataKey: "patient_name",
        headerStyle: {width: "12.5%", ...defaultStyles},
        style: {width: "12.5%", ...defaultStylesCell}
    },
    {
        headerClassName: "table-header-cell",
        label: "phone number",
        dataKey: "patient_phone_number",
        headerStyle: {width: "12.5%", ...defaultStyles},
        style: {width: "12.5%", ...defaultStylesCell},
        customCell: ({rowData}) => {
            const phone = convertPhoneValue(rowData.patient_phone_number, true);
            return <span>{phone}</span>;
        }
    },
    {
        headerClassName: "table-header-cell",
        label: "birth date",
        dataKey: "patient_date_of_birth",
        headerStyle: {width: "12.5%", ...defaultStyles},
        style: {width: "12.5%", ...defaultStylesCell}
    },
    {
        headerClassName: "table-header-cell",
        label: "specialty",
        dataKey: "doctor_speciality",
        headerStyle: {width: "12.5%", ...defaultStyles},
        style: {width: "12.5%", ...defaultStylesCell}
    },
    {
        headerClassName: "table-header-cell",
        label: "location",
        dataKey: "region",
        headerStyle: {width: "12.5%", ...defaultStyles},
        style: {width: "12.5%", ...defaultStylesCell}
    },
    {
        headerClassName: "table-header-cell",
        label: "physician",
        dataKey: "doctor_name",
        headerStyle: {width: "12.5%", ...defaultStyles},
        style: {width: "12.5%", ...defaultStylesCell}
    },
    {
        headerClassName: "table-header-cell",
        label: "last visit",
        dataKey: "application_time",
        headerStyle: {width: "12.5%", ...defaultStyles},
        style: {width: "12.5%", ...defaultStylesCell}
    },
    {
        headerClassName: "table-header-cell",
        label: "notes",
        dataKey: "notes",
        headerStyle: {width: "12.5%", ...defaultStyles},
        customCell: ({rowData}) => {
            const status = visitNoteStatuses[rowData.status.toLowerCase()]
            return <RequestStatus
                status={status?.name}
                className="custom-input-container_error"
            />;
        },
        style: {width: "12.5%", ...defaultStylesCell}
    }
];


const checkboxValue = [
    {label: "Select all", value: "all"},
    {label: "Complete", value: 'Request completed,Declined'},
    {label: "Incomplete", value: 'Pending, Assigned,In progress,Active,Visit completed'},
];


class TablePageEMR extends Component {
    initialState = {
        regions: [],
        specialities: [],
        doctors: [],
        patients: [],
        checkbox: []
    };

    constructor(props) {
        super(props);

        this.state = {
            checkbox: [],
            dateTo: moment().toDate(),
            dateFrom: moment()
                .startOf("month")
                .toDate(),
            searchValue: "",

            choosedFilters: {
                ...this.initialState
            },

            applyFilters: {
                ...this.initialState
            },
            valueFilters: {
                regions: [],
                specialities: [],
                doctors: [],
                patients: []
            },
            lastModified: {}
        };
    }

    updateSearchValue = (field, value) => {
        this.props.getTableData({
            limit: 100,
            offset: 0,
            application_time_before: formatAppointmentDate(this.state.dateTo),
            application_time_after: formatAppointmentDate(this.state.dateFrom),
            ...this.getUuidFilters(this.state.applyFilters),
            search: value
        });

        this.setState({
            [field]: value
        });
    };

    selectFilters = async (field, value) => {

        let obj = {
            ...this.state.choosedFilters,
            [field]: value
        };

        this.setState({
            choosedFilters: {...obj},
        });
    };

    deleteAppliedFilters = async (field) => {

        let filterQuery = {
            application_time_before: formatAppointmentDate(this.state.dateTo),
            application_time_after: formatAppointmentDate(this.state.dateFrom)
        };

        let resp = await getFilters(filterQuery)
        this.setState(
            {
                choosedFilters: {
                    ...this.initialState
                },
                applyFilters: {
                    ...this.initialState
                },
                valueFilters: resp
            },
            () => {
                this.sendLockFilters()
                this.updateTableData();
            }
        );
    };

    upDateCheckbox = field => {

        let filtersNew = null
        if (this.state.choosedFilters.checkbox.find(i => i.label === field)) {
            if (field === 'Select all') filtersNew = []
            else filtersNew = this.state.choosedFilters.checkbox.filter(
                i => i.label !== field && i.label !== 'Select all'
            )
            this.setState({
                choosedFilters: {
                    ...this.state.choosedFilters,
                    checkbox: filtersNew
                }
            }, () => {
                this.sendFilters('checkbox')
            });
        } else {
            if (field === 'Select all' || this.state.choosedFilters.checkbox.length === 1) filtersNew = checkboxValue
            else filtersNew = [
                ...this.state.choosedFilters.checkbox,
                checkboxValue.find(i => i.label === field)
            ]
            this.setState({
                choosedFilters: {
                    ...this.state.choosedFilters,
                    checkbox: filtersNew
                }
            }, () => {
                this.sendFilters('checkbox')
            });
        }
    };

    getMergedFilters = (lock = {}, filters) => {
        let filtersApply = {
            ...this.initialState
        };
        for (let key in lock) {
            filtersApply[key] = filters[key]
                ? lock[key].filter(i => filters[key].find(v => v.value === i.value))
                : lock[key];
        }
        return filtersApply;
    };

    getUuidFilters = filtersObject => {
        let filters = {};
        for (let key in filtersObject) {
            if (key === 'checkbox') filters.statuses = filtersObject[key].map(i => i.value);
            else filters[key] = filtersObject[key].map(i => i.value);
        }
        return filters;
    };

    updateTableData = () => {
        let filterQuery = {
            limit: 100,
            offset: 0,
            application_time_before: formatAppointmentDate(this.state.dateTo),
            application_time_after: formatAppointmentDate(this.state.dateFrom),
            ...this.getUuidFilters(this.state.choosedFilters),
            search: this.state.searchValue
        };
        this.props.getTableData(filterQuery);
    };

    applyFilterAction = () => {
        this.updateTableData();
        this.setState({
            applyFilters: {
                ...this.state.choosedFilters
            }
        }, () => {
            this.sendLockFilters()
        });
    };

    proceedFilters = async (lockFilter, isGetTable) => {
        let filterQuery = {
            application_time_before: formatAppointmentDate(
                (lockFilter && lockFilter.dateTo) || this.state.dateTo
            ),
            application_time_after: formatAppointmentDate(
                (lockFilter && lockFilter.dateFrom) || this.state.dateFrom
            ),
            ...lockFilter?.filters
        };
        let filters = await getFilters(filterQuery);
        let mergedFilters = this.getMergedFilters(
            lockFilter && lockFilter.applyFilters ? lockFilter.applyFilters : null,
            filters
        );
        isGetTable && this.props.getTableData({
            limit: 100,
            offset: 0,
            search: this.state.searchValue,
            ...filterQuery,
            ...this.getUuidFilters(mergedFilters)
        });
        return {
            filters: filters,
            mergedFilters: mergedFilters
        };
    };

    sendLockFilters = () => {
        getLockFilters("emr", {
            state: {
                applyFilters: this.state.choosedFilters,
                dateTo: this.state.dateTo,
                dateFrom: this.state.dateFrom,
                lastModified: this.state.lastModified
            }
        });
    }

    async componentDidMount() {

        let lockFilter = await getLockFilters("emr");
        lockFilter = lockFilter.state || {};
        if (lengthObj(lockFilter.lastModified)) {
            lockFilter.filters = {
                [lockFilter.lastModified.field]: lockFilter.lastModified.data.value
            }
        }

        let proceededFilters = await this.proceedFilters(lockFilter, true);

        let mutadedChoosedFilters = this.getMergedFilters(proceededFilters.filters, proceededFilters.mergedFilters)

        let mutatedValueFilters = lengthObj(lockFilter.lastModified) ? ({
            ...proceededFilters.filters,
            [lockFilter.lastModified.field]: lockFilter.lastModified.data.options

        }) : proceededFilters.filters
        this.setState({
            applyFilters: {...proceededFilters.mergedFilters, checkbox: lockFilter?.applyFilters?.checkbox || []},
            choosedFilters: {...mutadedChoosedFilters, checkbox: lockFilter?.applyFilters?.checkbox || []},
            valueFilters: mutatedValueFilters,
            dateTo: lockFilter && lockFilter.dateTo ? new Date(lockFilter.dateTo) : this.state.dateTo,
            dateFrom: lockFilter && lockFilter.dateFrom ? new Date(lockFilter.dateFrom) : this.state.dateFrom,
        });
    }

    componentWillUnmount() {
        this.sendLockFilters()
    }

    onChange = async (field, value) => {
        let lockFilters = {
            applyFilters: {...this.state.applyFilters},
            dateTo: this.state.dateTo + "",
            dateFrom: this.state.dateFrom + "",
            [field]: value + ""
        };

        if (lengthObj(this.state.lastModified)) {
            lockFilters.filters = {
                [this.state.lastModified.field]: this.state.lastModified.data.value
            }
        }
        let proceededFilters = await this.proceedFilters(lockFilters, true);
        let mutatedFilters = this.getMergedFilters(proceededFilters.filters, this.state.choosedFilters)
        let mutatedValueFilters = (lengthObj(this.state.lastModified) && !!proceededFilters.filters.length) ? ({
            ...proceededFilters.filters,
            [this.state.lastModified.field]: this.state.lastModified.data.options
        }) : proceededFilters.filters

        this.setState({
            dateTo: moment(lockFilters.dateTo).toDate(),
            dateFrom: moment(lockFilters.dateFrom).toDate(),
            applyFilters: proceededFilters.mergedFilters,
            choosedFilters: mutatedFilters,
            valueFilters: mutatedValueFilters
        }, () => {
            this.sendLockFilters()
        });
    };

    loadTableData = () => {
        !!this.props.tableData.next &&
        this.props.getTableData(
            {
                limit: 100,
                offset: this.props.tableData.results.length,
                application_time_before: formatAppointmentDate(this.state.dateTo),
                application_time_after: formatAppointmentDate(this.state.dateFrom),
                ...this.getUuidFilters(this.state.choosedFilters),
                search: this.state.searchValue
            },
            {results: this.props.tableData.results}
        );
    };

    showFiltersValue = () => {
        const obj = this.state.applyFilters;
        let filtersValue = "";
        let countFilters = -1;
        for (let key in obj) {
            if (obj[key].length) {
                if (countFilters === -1) {
                    const lengthMoreThen2 = obj[key].length > 1
                    filtersValue = (lengthMoreThen2 ? [obj[key][0]] : obj[key]).map(i => i.label).join(", ");
                    filtersValue = lengthMoreThen2 ? filtersValue + ' ...' : filtersValue
                }
                countFilters = countFilters + 1;
            }
        }
        if (filtersValue !== "" && !!countFilters)
            filtersValue = `${filtersValue}, +${countFilters}`;
        return filtersValue;
    };

    sendFilters = async (field) => {
        let fieldValue = this.state.choosedFilters[field].map(i => i.value)
        let data = {
            application_time_before: formatAppointmentDate(this.state.dateTo),
            application_time_after: formatAppointmentDate(this.state.dateFrom),
            filters: {
                [field]: fieldValue
            }
        };

        let resp = await this.proceedFilters(data);

        let mutatedChoosedFilters = this.getMergedFilters(resp.filters, this.state.choosedFilters);
        let mutatedValueFilters = {
            ...resp.filters,
            [field]: this.state.valueFilters[field]
        };

        this.setState({
            choosedFilters: {...mutatedChoosedFilters, checkbox: this.state.choosedFilters.checkbox},
            valueFilters: mutatedValueFilters,
            lastModified: {
                field: field,
                data: {
                    value: fieldValue,
                    options: this.state.valueFilters[field]
                }
            }
        });
    };

    render() {
        const {
            regions,
            specialities,
            doctors,
            patients,
            checkbox
        } = this.state.choosedFilters;

        const {
            regions: regionsV,
            specialities: specialitiesV,
            doctors: doctorsV,
            patients: patientsV
        } = this.state.valueFilters;
        const filtersValue = this.showFiltersValue();
        const data = this.props.tableData.results.map(item => ({
            ...item,
            application_time: formatViewDate(item.application_time)
        }));

        return (
            <div className="emr-page-container">
                <PanelFilters
                    dateFrom={this.state.dateFrom}
                    dateTo={this.state.dateTo}
                    showDatePickerRange
                    className="emr-page-container_panel-filters"
                    value={this.state.searchValue}
                    filtersValue={filtersValue}
                    applyFilters={this.state.applyFilters}
                    applyFilterAction={this.applyFilterAction}
                    deleteAppliedFilters={this.deleteAppliedFilters}
                    onChange={this.updateSearchValue}
                    onChangeDate={this.onChange}
                    infoCloud={infoCloudSearch}
                >
                    <DropDown
                        multi
                        value={regions}
                        field="regions"
                        handlerSelect={this.selectFilters}
                        label={placeholders.location}
                        options={regionsV}
                        action={this.sendFilters}
                    />
                    <DropDown
                        multi
                        value={specialities}
                        field="specialities"
                        handlerSelect={this.selectFilters}
                        label={placeholders.speciality}
                        options={specialitiesV}
                        action={this.sendFilters}
                    />
                    <DropDown
                        multi
                        value={doctors}
                        field="doctors"
                        handlerSelect={this.selectFilters}
                        label={placeholders.physician}
                        options={doctorsV}
                        action={this.sendFilters}
                    />
                    <DropDown
                        multi
                        value={patients}
                        field="patients"
                        handlerSelect={this.selectFilters}
                        label={placeholders.patient}
                        options={patientsV}
                        action={this.sendFilters}
                    />
                    <div className="emr-page-container_checkbox">
                        <Typography
                            className="notes-label"
                            color={colors.blackMuted}
                            text="Note Type:"
                            variant="p"
                        />
                        {checkboxValue.map((item, index) => {
                            return (
                                <Checkbox
                                    key={index}
                                    className="emr-page-container_checkbox_item"
                                    checked={!!checkbox.find(i => i.label === item.label)}
                                    field={item.label}
                                    onClick={this.upDateCheckbox}
                                    label={item.label}
                                />
                            );
                        })}
                    </div>
                </PanelFilters>
                <CustomTable
                    onRowClick={({rowData}) => {
                        history.push(
                            `${this.props.urlNavigationToEMR}${routersDoctor.info_request}/${rowData.service_request_uuid}`
                        );
                    }}
                    loadTableData={this.loadTableData}
                    heightTable="calc(100vh - 190px)"
                    columnsHeader={columnsAdmin}
                    data={data}
                    rowClassName="emr-page-container_table_row"
                    className="emr-page-container_table"
                />
            </div>
        );
    }
}

export default TablePageEMR;
