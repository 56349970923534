import moment from "moment";
import { momentAbbreviation } from "../constants";


export const getDateFromBEFormat = date => moment(date, 'YYYY-MM-DD').toDate()

export const formatAppointmentDate = date => moment(date).format("YYYY-MM-DD");
export const formatViewDate = date => moment(date).format("MM/DD/YYYY");

export const getStartOfDayWithPrevDay = (date) => moment(date || {}).startOf('day')
    .subtract(1, "days")
    .format("YYYY-MM-DD") + "T00:00";

export const getStartOfDayWithNextDay = (date) => moment(date || {}).endOf('day')
    .add(1, "days")
    .format("YYYY-MM-DD") + "T00:00";

export const getStartOfWeek = date =>
  moment(date || {})
    .startOf("week")
    .format("YYYY-MM-DD") + "T00:00";
export const getEndOfWeek = date =>
  moment(date || {})
    .endOf("week")
    .format("YYYY-MM-DD") + "T23:59";

export const getStartOfWeekWithPrevDay = date =>
    moment(getStartOfWeek(date) || {})
        .subtract(1, "days")
        .format("YYYY-MM-DD") + "T00:00";
export const getEndOfWeekWithNextDay = date =>
    moment(getEndOfWeek(date) || {})
        .add(1, "days")
        .format("YYYY-MM-DD") + "T23:59";

export const getDateFromISO = date =>
  new Date(
    +date.substr(0, 4),
    +date.substr(5, 2) - 1,
    +date.substr(8, 2),
    +date.substr(11, 2),
    +date.substr(14, 2),
    +date.substr(17, 2)
  );
export const changeFormatForRequest = date => moment(date).format("YYYY-MM-DD");

export const dateFormatCalendarRequests = (date) => moment(date).format("YYYY-MM-DD") + "T00:00"
export const changeDateWithFormat = (date, convertToFE) => {
  if (date)
    return moment(date, convertToFE ? "YYYY-MM-DD" : "MM/DD/YYYY").format(
      convertToFE ? "MM/DD/YYYY" : "YYYY-MM-DD"
    );
  else return null;
};

export const addFromCurrentInISO = (initialTime, ms) =>
  moment()
    .add(initialTime, ms)
    .format("YYYY-MM-DDTHH:mm:ss.SSSZ");

export const converTimeToMilleseconds = value =>
  value[0] * 60 * 60 * 1000 + value[1] * 60 * 1000;

export const getTimeInFormat12 = time => moment(time).format("h:mm a");
export const getTimeInFormat24 = time => moment(time).format("HH:mm");
export const addFromCurrentTime = (time, value) =>
  moment()
    .add(time, value)
    .format("HH:mm");

export const timeDelta = (data, value) =>
  moment.duration(moment(data).diff(moment())).as(value);

export const durationTimeLaterRequest = (start, end) => ({
  timeOfStart: moment(start).format("h:mm"),
  timeOfEnd: moment(end).format("h:mm"),
  periodOfStart: moment(start).format('A'),
  periodOfEnd: moment(end).format('A'),
  month: moment(start).format("MMM"),
  day: moment(start).format("D")
});

export const convertToISO = () => moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ");

export const dateMoreThenNowPlus90Min = date =>
  moment(addFromCurrentInISO(90, momentAbbreviation.minutes)) < moment(date);

export const dateLesThenNowPlus90Min = date =>
  moment(addFromCurrentInISO(90, momentAbbreviation.minutes)) > moment(date);

export const covertTime = initialTime =>
  moment()
    .startOf("day")
    .minutes(initialTime, "m")
    .format("HH:mm");

export const getFullYearsUser = date => moment().diff(date, "years");

export const isToday = date =>
  moment()
    .startOf("day")
    .add(1, "day") > moment(date);

export const setTimeZoneForDate = (date) => moment(moment.utc(date).format()).format()


export const setTimezoneEvents = (data) => data.map(i => ({
  ...i, application_can_start_at: setTimeZoneForDate(i.application_can_start_at),
  application_time: setTimeZoneForDate(i.application_time)
}))


export const durationInSec = ({start,end}) => {
  return +moment(end).diff(moment(start), 'seconds')
}

export const addMilliseconds = (time, ms) => {
  return moment(time).add(ms, 'ms');
};
