import { Typography } from "../../Common/Typography/Typography";
import { colors } from "../../../assets/colors/colors";
import { Radiobutton } from "../../Common/RadioButton/Radiobutton";
import { Input } from "../../Common/Input/Input";
import { PopupContainer } from "../../Common/Popup/PopupContainer";
import React, { useEffect, useState } from "react";
import {
  buttonsText,
  choice,
  regexsWithMessage,
  routesCommon
} from "../../../constants";
import history from "../../../helpers/history";

export const Popup6Month = ({ open, close, emailUser, contactWithUs }) => {
  const [radioNotify, setRadioNotify] = useState(true);
  const [email, setEmail] = useState(emailUser);

  useEffect(() => setEmail(emailUser), [emailUser]);

  const updateState = (field, value) => setEmail(value);
  const handleRadioButton = () => setRadioNotify(!radioNotify);
  const notifyAction = () => {
    // request to be with ${email}
  };

  const disabledButtonPrimary =
    radioNotify && !regexsWithMessage.email.regex.test(email);

  const actionPrimaryButton = radioNotify ? notifyAction : close;
  return (
    <PopupContainer
      open={open}
      close={close}
      extraTitle="Infants Under 6 Months of Age Yet"
      title="Sorry, the Doctors Do Not Treat"
      textDefault={buttonsText.contact}
      // textPrimary={radioNotify ? buttonsText.notify : buttonsText.submit}
      textPrimary={""}
      disabledButtonPrimary={disabledButtonPrimary}
      actionDefaultButton={contactWithUs}
      actionPrimaryButton={actionPrimaryButton}
    >
      <div className="patient-info-container_verification-6-month">
        <Typography
          color={colors.grey}
          variant="p"
          className=""
          text="Would you like to be notified when the Doctors start seeing children under 6 months?"
        />
        {choice.map((i, index) => (
          <Radiobutton
            label={i}
            key={index}
            onClick={handleRadioButton}
            checked={index === 0 ? radioNotify : !radioNotify}
          />
        ))}
        <Input
          placeholder="Email"
          value={email}
          className="patient-info-container_verification-6-month_email"
          field="email"
          onChange={updateState}
        />
        <div>
          <Typography
            color={colors.grey}
            variant="p"
            className="patient-info-container_verification-6-month_address-top"
            text="In Case of Emergency,"
          />
          <Typography
            variant="link"
            className="patient-info-container_verification-6-month_address-top"
            text=" CALL 9-1-1."
          />
        </div>
        <Typography
          color={colors.grey}
          variant="p"
          className="patient-info-container_verification-6-month_address-bottom"
          text="Otherwise, Please Contact Your Pediatrician."
        />
      </div>
    </PopupContainer>
  );
};
