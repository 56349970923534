import React, {useCallback, useEffect, useState} from "react";
import {addNewCard, patientProfileTabs} from "../../../../constants/Patient";
import {Payment} from "../Payment";
import {AddCard} from "../AddCard";

export const ContainerPayment = ({activeTab,updateTitle = () =>{}, isAddNewCard, controlAddNewCard, ...props}) => {
    return <>
        {!isAddNewCard && (
            <Payment
                {...props}
                controlAddNewCard={controlAddNewCard}
            />
        )}
        {isAddNewCard && (
            <AddCard
                {...props}
                controlAddNewCard={controlAddNewCard}
            />
        )}
        </>
}
