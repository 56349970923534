import React from "react";
import { Button } from "@material-ui/core";
import { VARIATIONS_BTN } from "./constants";
import './style.scss'
import cn from 'classnames'

export const ButtonV2 = ({
  variant = VARIATIONS_BTN.TEXT,
  children,
  color,
  className,
  onClick,
  uppercase,
  capitalize,
  startIcon,
  endIcon,
  disabled
}) => {
  return(
    <Button
      startIcon={startIcon}
      onClick={onClick}
      className={cn('btn-component', className,
        { 'btn-component_uppercase': uppercase, 'btn-component_capitalize': capitalize }
      )}
      color={color}
      variant={variant}
      endIcon={endIcon}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};
