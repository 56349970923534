import {fetchWrapper} from "../../helpers/helpers";
import {
    SET_PATIENT_DATA,
    SET_PATIENT_EVENTS,
    SET_PATIENT_CARD,
    SET_DEFAULTVALUE_PATIENT,
    SET_FILES_OF_REQUEST,
    UPDATE_REQYEST_STATE_NOTE
} from "./actionTypes";
import {converterDataField} from "../../utils/common";
import moment from "moment";
import {VISIT_COMPLETED} from "../../constants";
import {getDetailsEvent} from "../doctor/actions";
import conversionBytes from "../../utils/conversionBytes";
import {getDataRequest} from "../request/actions";

const setPatientData = payload => ({type: SET_PATIENT_DATA, payload});
const setEventsPatient = payload => ({type: SET_PATIENT_EVENTS, payload});
const setPatientCards = payload => ({type: SET_PATIENT_CARD, payload});
const clearPatient = payload => ({type: SET_DEFAULTVALUE_PATIENT, payload});
const setFilesOfRequest = payload => ({type: SET_FILES_OF_REQUEST, payload});
export const updateRequestOnList = payload => ({type: UPDATE_REQYEST_STATE_NOTE, payload});

const getPatientData = body => dispatch =>
    fetchWrapper({
        method: body ? "PUT" : "GET",
        url: `users/${window.localStorage.getItem("user_uuid")}/`,
        body: body
    })
        .then(resp => {
            if (resp.uuid) {
                dispatch(setPatientData(converterDataField({
                    ...resp,
                    suite: resp.home_address?.apartment,
                    home_address: resp.home_address && {address: resp.home_address.address_line, latLng: null}
                }, true)));
                return resp;
            } else {
                throw resp
            }
        })


const getCardsPatient = () => dispatch =>
    fetchWrapper({url: "payments/list/"}).then(resp => {
        dispatch(setPatientCards(resp));
    });
const addNewCard = body => dispatch =>
    fetchWrapper({url: "payments/add/", method: "POST", body}).then(resp => {
        if (resp.card_number) dispatch(getCardsPatient());
        return resp;
    });
const removeCard = uuid => (dispatch, getStore) =>
    fetchWrapper({url: `payments/delete/${uuid}/`, method: "DELETE"}).then(
        resp => {
            dispatch(getCardsPatient());
            return resp
        }
    );

const approveDeclineServices = (body, id) => dispatch =>
    fetchWrapper({
        url: `service-requests/additional-services/${(body.payment_profile_id || body.card_info) ? 'approve/' : 'decline/'}`,
        method: "POST",
        body
    })
        .then(resp => {
            if (resp.assignments_ids) dispatch(getDataRequest({uuid:id, noClearStore: true}))
            else throw 'error'
        });

const clearDataPatient = () => dispatch => dispatch(clearPatient())
const changeDefaultCard = (body) => dispatch =>
    fetchWrapper({
        url: `payments/update-default-profile/`,
        method: "POST",
        body: body
    })
        .then(resp => {
            if (!resp.payment_profile_id) throw 'error'
        });


export {
    getPatientData,
    getCardsPatient,
    addNewCard,
    removeCard,
    approveDeclineServices,
    changeDefaultCard,
    clearDataPatient,
};
