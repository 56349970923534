import React, {useEffect, useState} from "react";
import patientAvatar from "../../../dist/images/patientAvatar.svg";
import {Typography} from "../../Common/Typography/Typography";
import cn from "classnames";
import {PENDING, DECLINED, routesCommon} from "../../../constants";
import {colors} from "../../../assets/colors/colors";
import {BtnChat} from "../../Chats/btn";
import history from "../../../helpers/history";
import {routersPatient} from "../../../constants/Patient";
import {Chat} from "../../Chats";
import {hideButton, openChatsFromRedirect, showChats} from "../../../utils/common";


export const HeaderPatientRequest = ({data, openChat}) => {
    const showOnTheWay = data.doctor_should_arrive_at || data.urgency_type === 'now';
    const nameDoctor =
        data.doctor_data &&
        (data.doctor_data.initials || data.doctor_data.last_name);

    let titleStatus = `Your Doctor, Dr. ${nameDoctor}, is on the way to you!`
    if (data?.doctor_5_mins_left_should_arrive_at && nameDoctor) titleStatus = `Dr. ${nameDoctor} Will Be At Your Door in About Five Minutes!`
    if (data?.doctor_arrived_at && nameDoctor) titleStatus = `Dr. ${nameDoctor} Has ARRIVED! Feel Better Soon!`
    if (data?.finished_at && nameDoctor || !showOnTheWay) titleStatus = `Your Doctor is Dr. ${nameDoctor}`

    return data.status.toLowerCase() !== DECLINED && data.status.toLowerCase() !== PENDING && nameDoctor ? (
        <>
            <div className="component-card-request-details_doctor">
                <div className={cn("component-card-request-details_doctor_img")}>
                    <img src={patientAvatar} alt="avatar"/>
                    <Typography
                        text={`Meet Dr. ${nameDoctor}`}
                        className="side-bar__avatar-patient"
                    />
                </div>
                <div className="component-card-request-details_doctor_text">
                    <Typography
                        color={colors.greyShuttle}
                        text={titleStatus}
                        className="doctor-present__header"
                        variant="span"
                    />
                    <Typography
                        text={`Dr. ${nameDoctor} is a local, trusted Physician with years of experience caring for Patients like you.`}
                        className="doctor-present__info"
                        variant="span"
                    />
                </div>
                <div className='component-card-request-details_doctor_chat'>
                    {!!data && hideButton(data) && <BtnChat request={data} chatIsAvailable={showChats(data)} onClick={openChat}/>}
                </div>
            </div>
            <div className="separator" />
        </>
    ) : (
        ""
    );
};
