import React, {useState} from 'react'
import {Typography} from "../../../Common/Typography/Typography";
import {colors} from "../../../../assets/colors/colors";
import {requestDetailsControls} from "../../../../constants/Doctors";
import ButtonGroup from "../../../Common/ButtonGroup/ButtonGroup";
import {formatViewDate, getDateFromBEFormat} from "../../../../utils/date";
import {ArrowDecrease, ArrowIncrease} from "../../../Common/Icons";
import cn from "classnames";
import {VeradigmButton} from "../../Templates/Components/VeradigmButton";


const buttons = ["Active", "Inactive", "All"];

export const Medications = ({options = [], showVeradigm, dataRequest}) => {
    const [showAll, setShowAll] = useState(false)
    const [activeStatus, setActiveStatus] = useState(buttons[0])

    let viewOptions = options
    if (activeStatus === 'Active') viewOptions = options.filter(i => i.status === 'active')
    if (activeStatus === 'Inactive') viewOptions = options.filter(i => i.status !== 'active')
    return <div className='medicastion-view'>
        <Typography variant="p" text='Medications' color={colors.greyMiddle}/>
        <ButtonGroup
            page={activeStatus}
            buttonsTab={buttons}
            changePage={setActiveStatus}
        />
        {viewOptions.slice(0, showAll ? options.length - 1 : 5).map((i) => {
            const isActive = i.status === 'active'
            return <div className='medicastion-view_item'>
                <div className='medicastion-view_item_header'>
                <span className='medicastion-view_item_header_container'>
                    <span className={cn('medicastion-view_item_point', {"medicastion-view_item_point_active": isActive})}/>
                    <span className='medicastion-view_item_name'
                    >{i.name}</span>
                </span>
                    <span className='medicastion-view_item_doze'>{i.doze}</span>
                </div>
                {!isActive &&
                <div className='medicastion-view_item_header_date'>Created: {formatViewDate(i.created)}</div>}
            </div>
        })}
        <div className='medicastion-view_footer'>{viewOptions.length > 5 &&
        <button
            className={cn('no-default-style-button medicastion-view_button', {'medicastion-view_button_active': showAll})}
            onClick={() => {
                setShowAll(!showAll)
            }}>
            <ArrowIncrease/><span className='medicastion-view_button_txt'>{showAll ? 'HIDE' : 'SHOW MORE'}</span>
        </button>} {showVeradigm && <VeradigmButton viewButtonAsText dataRequest={dataRequest} title='Go To Veradigm'/>}</div>


    </div>
}
