import React from 'react'


export const RenderRadioSingleBLocks = (options, withFirstComma = false) => {
    let arr = []

    options.forEach((i,index) => {
        if(index === 0 && !withFirstComma){
            arr.push({
                type: "radio-single",
                data: [{checked: false, data: i}]
            })
        }else{
            arr.push({
                type: "text",
                data: ',',
                editable: true,
                source: null
            },)
            arr.push({
                type: "radio-single",
                data: [{checked: false, data: i}]
            })
        }
    })

    return arr
}