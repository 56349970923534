import React, { Component } from "react";
import cn from "classnames";

export const BirdSteps = props => (
  <div
    onClick={props.onClick}
    className={cn(props.className, "icon-component")}
    style={props.style}
  >
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.9999 10.6L1.7999 6.4L0.399902 7.8L5.9999 13.4L17.9999 1.4L16.5999 0L5.9999 10.6Z"
        fill="white"
      />
    </svg>
  </div>
);
