import React from "react";
import cn from "classnames";
import { Typography } from "../../../Common/Typography/Typography";
import "./style.scss";
import { InfoWithMessage } from "../../../Common/infoWithMessage/infoWithMessage";

export const Card = ({ children, label, className, ...rest }) => {
  const infoCondition = ["Patient's Satisfaction"];
  const patientCondition = infoCondition.find(i => i === label);
  return (
    <div className={cn("dashboard-card", className)}>
      {!!label && (
        <div className="dashboard-card__container">
          <Typography variant="h2" text={label} className="card-title" />
          {patientCondition && (
            <InfoWithMessage label="Based on Patient's scores" />
          )}
        </div>
      )}
      <div>{children}</div>
    </div>
  );
};
