import React, { Component } from "react";
import "./style.scss";
import ButtonGroup from "../../Common/ButtonGroup/ButtonGroup";
import {addNewCard, patientProfileTabs, routersPatient} from "../../../constants/Patient";
import { Profile } from "./Profile";
import { Payment } from "./Payment";
import { AddCard } from "./AddCard";
import {ContainerPayment} from "./ContainerPayment";
import history from "../../../helpers/history";
import {routesCommon} from "../../../constants";

class PersonalInfoPatient extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: patientProfileTabs[0],
      isAddNewCard: false,
    };
  }
  controlAddNewCard = value => {
    this.setState({isAddNewCard: value})
  }
  changePage = value => {
    this.setState({ activeTab: value });
  };

  componentDidMount() {
    this.props.updateDataUser();
    this.props.getCardsPatient();
  }

  simpleUpdateState = (field, value) => {
    this.setState({ [field]: value });
  };

  closeList = () => history.push(`${routesCommon.patient}${routersPatient.schedule}`)

  render() {
    return (
      <div className="personal-info-patient">
        <div className="personal-info-patient_controls">
            <ButtonGroup id='btn-group'
              page={this.state.activeTab}
              buttonsTab={patientProfileTabs}
              changePage={this.changePage}
            />
        </div>
        {patientProfileTabs[0] === this.state.activeTab && (
          <Profile
            user={this.props.patient}
            updateDataUser={this.props.updateDataUser}
          />
        )}
        {patientProfileTabs[1] === this.state.activeTab && <ContainerPayment
            {...this.props}
            closeList={this.closeList}
            listCards={this.props.cards}
            controlAddNewCard={this.controlAddNewCard}
            isAddNewCard={this.state.isAddNewCard}
        />}
      </div>
    );
  }
}

export default PersonalInfoPatient;
