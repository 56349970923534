import React, {useEffect, useRef} from 'react'
import {HeaderNotes} from "../HeaderNotes/HeaderNotes";
import {FooterNotes} from "../FooterNotes/FooterNotes";
import {v1 as uuidv1} from "uuid";
import './style.scss'
import moment from "moment";

export const PdfGenerator = ({name = 'Test', content = 'test', uuid, date, dataRequest, signature, data}) => {
    const container = useRef()
    const dateTime = moment(date).format('MM/DD/YYYY hh:mm a')
    const uuidParagraph = uuidv1()
    let txtArr = content.split('\n').map(i => i.split('.')).reduce((acc, current) => [...acc, ...current, uuidParagraph], []).filter(i => i)
    txtArr = txtArr.slice(0, txtArr.length - 1)


    const fullArr = (start, end) => {
        return Array(end - start + 1).fill().map((_, idx) => start + idx)
    }
    const checkIntersection = (arr1 = [], arr2 = []) => {
        const array1 = fullArr(arr1[0], arr1[1])
        const array2 = fullArr(arr2[0], arr2[1])
        return (array1.filter(value => array2.includes(value)).length > 1)
    };
    useEffect(() => {
        const paddingPage = 40
        const a4Height = 842
        let page = 1
        let nodeList = Array.from(container.current.childNodes)

        nodeList.forEach((i, index, array) => {
            const outSpaceTop = page * a4Height - paddingPage
            const outSpaceBottom = page * a4Height + paddingPage
            const isIntersection = checkIntersection([outSpaceTop, outSpaceBottom], [i.offsetTop, i.offsetTop + i.offsetHeight])
            if(isIntersection){
                let elSpacer = document.createElement("div");
                const prevEl = array[index - 1]
                elSpacer.style.height = outSpaceBottom - (prevEl.offsetTop + prevEl.offsetHeight)  + 'px'
                container.current.insertBefore(elSpacer, array[index])
                page = page + 1
            }
        })
    }, [])
    return (
        <div className='followup_note' id={uuid} ref={container}>
             <HeaderNotes contextComponent={{title: `Follow Up`}} date={dateTime} dataRequest={dataRequest}/>
                    <div className='line'/>
                    <div className='followup_note_name'>{name}</div>
                    {txtArr.map(i => {
                        if (i === uuidParagraph) return <space className='main-context_br'></space>
                        return <txt>{i}.</txt>
                    })}
                    <FooterNotes dataRequest={{doctor_data: {...data.createdBy.user}}}
                                 signature={data.createdBy?.signature}/>
        </div>
    )
}
