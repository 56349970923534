import React from "react";
import "./style.scss";
 export const LoaderPage = () => {
  return (
    <div className="loader-page-all-requests">
      <div className="spinner-box">
        <div className="circle-border">
          <div className="circle-core">
          </div>
        </div>
      </div>
    </div>
  );
};
