import React from "react";
import cn from "classnames";
import "./style.scss";

export const InfoCloud = ({className, label, dangerouslySetInnerHTML, children, top}) => {
    return (
            <div className="card-container_info-cloud">
                {(dangerouslySetInnerHTML || label) && <div className={cn("card-info__message_info-cloud", { "info-cloud__top" : top}, className)}>
                    {dangerouslySetInnerHTML ? (
                        <span
                            dangerouslySetInnerHTML={{__html: dangerouslySetInnerHTML}}
                        />
                    ) : (
                        <span>{label}</span>
                    )}
                </div>}
                {children}
            </div>
    );
};
