const SET_TABLE_DATA_DOCTOR = "SET_TABLE_DATA_DOCTOR";
const SET_LOCATION_DATA = "SET_LOCATION_DATA";
const SET_SPECIALITY_DATA = "SET_SPECIALITY_DATA";
const SET_CALENDAR_EVENTS = "SET_CALENDAR_EVENTS";
const SET_UPLOAD_APPLICATIONS_DOCTOR_FLOW =
  "SET_UPLOAD_APPLICATIONS_DOCTOR_FLOW";
const SET_PERSONAL_INFO_DOCTOR_FLOW = "SET_PERSONAL_INFO_DOCTOR_FLOW";
const SET_DETAILS_EVENT = "SET_DETAILS_EVENT";
const UPDATE_REQYEST_STATE_NOTE_DOCTOR = "UPDATE_REQYEST_STATE_NOTE_DOCTOR";
const SET_STATUS_ARRIVE = "SET_STATUS_ARRIVE";
const SET_DASHBOARD_DOCTOR_DATA = "SET_DASHBOARD_DOCTOR_DATA";
const CLEAR_ALL_DATA_DOCTOR = "CLEAR_ALL_DATA_DOCTOR";
const SET_PCP_DOCTORS = "SET_PCP_DOCTORS";
const SET_TYPES_DOCTOR = "SET_TYPES_DOCTOR";
const SET_FOLLOW_UPS = "SET_FOLLOW_UPS";
const SET_ATTACHMENTS = "SET_ATTACHMENTS";
const SET_TYPES_DEGREE = "SET_TYPES_DEGREE";
const SET_LIST_REQUEST_PATIENT = "SET_LIST_REQUEST_PATIENT";
const SET_ADDITIONAL_INFO = "SET_ADDITIONAL_INFO";
const SET_SIGNATURE = "SET_SIGNATURE";
const SET_PMH_INITIAL_INFO = "SET_PMH_INITIAL_INFO";
const SET_ATTACHMENT_PMH = "SET_ATTACHMENT_PMH";
const SET_TEMPLATES = "SET_TEMPLATES";
const SET_DATA_TEMPLATE = "SET_DATA_TEMPLATE";
const SET_DATA_EXCUSE = "SET_DATA_EXCUSE";
const SET_DATA_COVID = "SET_DATA_COVID";
const SET_FSH_OPTIONS = "SET_FSH_OPTIONS";
const SET_CPT_CODE = "SET_CPT_CODE";
const SET_DB_DATA = "SET_DB_DATA";
const UPDATE_STSTUS_NOTES = "UPDATE_STSTUS_NOTES";
const SET_DISCHARGE_DATA = "SET_DISCHARGE_DATA";
const SET_ICD_LIST = "SET_ICD_LIST";
const SET_RECEIPT_DATA = "SET_RECEIPT_DATA";
const SET_DEFAULT_VALUE_VISIT_NOTES = "SET_DEFAULT_VALUE_VISIT_NOTES";
const SET_DEFAULT_VALUE_REQUEST = "SET_DEFAULT_VALUE_REQUEST";
const CLEAR_DATA_AFTER_DELETE_VISIT_NOTE = "CLEAR_DATA_AFTER_DELETE_VISIT_NOTE";

export {
  SET_TABLE_DATA_DOCTOR,
  SET_LOCATION_DATA,
  SET_SPECIALITY_DATA,
  SET_CALENDAR_EVENTS,
  SET_UPLOAD_APPLICATIONS_DOCTOR_FLOW,
  SET_PERSONAL_INFO_DOCTOR_FLOW,
  SET_DETAILS_EVENT,
  SET_STATUS_ARRIVE,
  SET_DASHBOARD_DOCTOR_DATA,
  CLEAR_ALL_DATA_DOCTOR,
  SET_PCP_DOCTORS,
  SET_TYPES_DOCTOR,
  SET_FOLLOW_UPS,
  SET_ATTACHMENTS,
  SET_TYPES_DEGREE,
  SET_LIST_REQUEST_PATIENT,
  SET_ADDITIONAL_INFO,
  SET_PMH_INITIAL_INFO,
  SET_ATTACHMENT_PMH,
  SET_TEMPLATES,
  SET_DATA_TEMPLATE,
  SET_DATA_EXCUSE,
  SET_FSH_OPTIONS,
  SET_CPT_CODE,
  SET_DB_DATA,
  SET_DISCHARGE_DATA,
  SET_ICD_LIST,
  UPDATE_REQYEST_STATE_NOTE_DOCTOR,
  SET_RECEIPT_DATA,
  SET_DEFAULT_VALUE_VISIT_NOTES,
  SET_DEFAULT_VALUE_REQUEST,
  UPDATE_STSTUS_NOTES,
  CLEAR_DATA_AFTER_DELETE_VISIT_NOTE,
  SET_SIGNATURE,
  SET_DATA_COVID
};
