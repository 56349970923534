import React, { useRef } from "react";
import { Clock } from "../../Common/Icons";
import { Typography } from "../../Common/Typography/Typography";
import { Input } from '../../Common/Input/Input';
import cn from "classnames";

// import "./OrganizersList.style.scss";

export const SideBarTime = ({ classNames, inputHour, inputMin, onChangeHour, onChangeMin }) => {
  const ref = useRef();
  return (
    <div className={cn(classNames)}>
      {/*<Clock />*/}
      <div ref={ref} className="side-bar__clock-input">
        <Input
          onChange={(value, field) => {
            if(field.length >= 2) {
              ref.current && ref.current.children[1].children[0].focus();
              onChangeMin(field.slice(2));
            }
            onChangeHour(field);
          }}
          className="side-bar__clock-input_item"
          placeholder="Hour"
          value={inputHour.slice(0, 2)}
          field="hour"
          type="number"
          maxLength="3"
        />
        <Input
          onChange={(value, field) => {
            if(inputMin.length > field.length && field.length === 0) {
              ref.current && ref.current.children[0].children[0].focus();
            }
            onChangeMin(field);
          }}
          className="side-bar__clock-input_item"
          placeholder="Min"
          value={inputMin}
          field="min"
          type="number"
          maxLength="2"
          onKeyDown={e => {
            if(e.key === 'Backspace' && inputMin.length === 0 && e.target.value.length === 0) {
              ref.current && ref.current.children[0].children[0].focus();
            }
          }}
        />
      </div>
      <Typography
        text="Try To Meet In 60 Mins"
        variant="span"
        className="side-bar__clock-time"
      />
    </div>
  );
};
