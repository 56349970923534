import React, { useEffect } from 'react';
import { useCustomState } from "../../../utils/customHooks";
import { SideBarRequest } from "../../Sidebar/Sidebar";
import { ContainerComponentsRequest } from "../ContainerComponentRequest";
import { momentAbbreviation, requestStatuses } from "../../../constants";
import {
  addFromCurrentInISO,
  dateLesThenNowPlus90Min,
  dateMoreThenNowPlus90Min,
  timeDelta
} from "../../../utils/date";
import moment from "moment";
import {
  getAdditionalServices,
  sendServiceRequest
} from "../../../store/doctor/actions";
import { SidebarWrapper } from "../../Sidebar/SidebarWrapper";
import { MultipleVisitsList } from "../../SideBarRequest/CompoenentsRequestAdmin/MultipleVisitsList";
import { USER_ROLES, DOCTOR_STEPS, REQUEST_STATUS_INTO_PAGES } from "../../Sidebar/constants";
import { showChats } from "../../../utils/common";
import { changeRequestDetails, updateRequestData } from "../../../store/request/actions";
import { useDispatch } from "react-redux";
import { STARTED, COMPLETED } from "../../../store/request/constants";
import { converTimeToMilleseconds } from "../../../utils/date";
import { setDisplayChat } from "../../../store/stable/slice";
import { updateRequestsStatuses } from "../../../store/requests/slice";

const initial = {
  activePage: DOCTOR_STEPS.Confirm,
  initialTime: null,
  inputHour: '',
  inputMin: '',
  comment: '',
  services: [],
  checkboxServices: [],
  selectedServices: [],
  group: null,
  showPopUp: false,
  // openChat: false,
  isTimerEditorOpen: false,
  timeChanged: false,
  patientForExamination: null
};

export const DoctorSidebar = ({
  data,
  open,
  userData,
  doctorCurrent,
  onClose,
                                isFromEmr,
  setStatusAndArrive,
  updateStatusEvent,
  isChatOpen,
  viewDetailsAppointmentOrShift,
  multipleRequestList,
  isMultiple,
  isInMultiple,
  simpleUpdate,
  isAdditional,
  additionalFromUuid,
  setNeedRefresh
}) => {
  const {
    state,
    onChangeTime,
    clearTimeInput,
    moveToPage,
    changeState
  } = useCustomState(initial);
  const dispatch = useDispatch();

  useEffect(() => () => dispatch(setDisplayChat(false)),[]);

  useEffect(() => {
    defineStepFromStatus();
  }, [data?.data.status, data?.data.uuid]);

  useEffect(() => {
    let time = 0;
    if(!!data?.data.doctor_should_arrive_at) {
      time = timeDelta(data?.data.doctor_should_arrive_at, momentAbbreviation.milliseconds);
    }
    if(!!data?.data.doctor_5_mins_left_should_arrive_at) {
      time = timeDelta(data?.data.doctor_5_mins_left_should_arrive_at, momentAbbreviation.milliseconds);
    }
    setNewTime(time);
    startTimer();
    changeState({ initialTime: time });
  }, [data?.data.doctor_should_arrive_at, data?.data.doctor_5_mins_left_should_arrive_at]);

  useEffect(() => {
    const containerPopup = document.querySelector('.container-sidebar--open');
    if(state.showPopUp) {
      if(containerPopup) {
        containerPopup.classList.add("container-sidebar--open_decline");
      } else {
        containerPopup.classList.remove("container-sidebar--open_decline");
      }
    }
  }, [state.showPopUp]);

  // useEffect(() => {
  //   if(isChatOpen && data?.data.uuid) {
  //     changeState({ openChat: true });
  //   }
  // },[data?.data.uuid, isChatOpen]);

  const defineStepFromStatus = () => {
    if(data?.data.uuid) {
      if(!data?.data.is_group) {
        changeState({ patientForExamination: data?.data.patients[0]?.uuid });
      } else {
        changeState({ group: data?.data.uuid });
      }
      if(data?.data.doctor_data?.uuid !== userData?.uuid) {
        moveToPage(DOCTOR_STEPS.ORDER_DETAILS);
      } else {
        if(data?.data.status?.toLowerCase() === requestStatuses.assigned) {
          if(dateMoreThenNowPlus90Min(data?.data.application_can_start_at)) {
            moveToPage(DOCTOR_STEPS.CONFIRM);
          } else if(moment(data?.data.application_can_start_at) &&
            !data?.data.doctor_should_arrive_at &&
            dateLesThenNowPlus90Min(data?.data.application_can_start_at)
          ) {
            moveToPage(DOCTOR_STEPS.NEXT);
          } else if(!!data?.data.doctor_should_arrive_at && !data?.data.doctor_5_mins_left_should_arrive_at) {
            const timeValue = timeDelta(data?.data.doctor_should_arrive_at, momentAbbreviation.milliseconds);
            changeState({ initialTime: timeValue });
            moveToPage(DOCTOR_STEPS.MINS_LEFT);
          } else if(!!data?.data.doctor_5_mins_left_should_arrive_at) {
            const timeValue = timeDelta(data?.data.doctor_5_mins_left_should_arrive_at, momentAbbreviation.milliseconds);
            changeState({ initialTime: timeValue });
            startTimer(timeValue);
            startTimer();
            moveToPage(DOCTOR_STEPS.HERE);
          }
        } else {
          if(state.activePage === DOCTOR_STEPS.ADD_SERVICES) {
            moveToPage(DOCTOR_STEPS.TRIGGER_SERVICES);
          } else {
            moveToPage(REQUEST_STATUS_INTO_PAGES[data?.data.status?.toUpperCase()]);
          }
        }
      }
      clearTimeInput();
    }
  };

  useEffect(() => {
    if(isAdditional && additionalFromUuid) {
      if(data?.data.is_group) {
        moveToPage(DOCTOR_STEPS.EXAMINATION);
      } else {
        moveToPage(DOCTOR_STEPS.TRIGGER_SERVICES);
      }
      changeState({ patientForExamination: additionalFromUuid });
    }
  }, [isAdditional, additionalFromUuid]);

  const handleChangeStep = () => {
    if(state.activePage === DOCTOR_STEPS.TRIGGER_SERVICES || state.activePage === DOCTOR_STEPS.PATIENTS_LIST) {
      changeState({ showPopUp: true });
    } else if(state.activePage === DOCTOR_STEPS.MINS_LEFT) {
      setNewTime(300000);
      changeState({ initialTime: 300000 });
      startTimer();
      dispatch(updateRequestData(data?.data.uuid, { doctor_5_mins_left_should_arrive_at: true })).then(resp => {
        thenHandler(resp);
      });
    } else if(state.activePage === DOCTOR_STEPS.NEXT) {
      const time = converTimeToMilleseconds([state.inputHour, state.inputMin]);
      onChangeTime(time, data?.data).then(resp => thenHandler(resp));
    } else if(state.activePage === DOCTOR_STEPS.HERE) {
      dispatch(updateRequestData(data?.data.uuid, { confirm_arrival: true })).then(resp => {
        if(resp.data?.uuid) {
          dispatch(updateRequestsStatuses({ status: resp.data.status, uuid: resp.data.uuid }));
        }
        thenHandler(resp);
      });
    } else if(state.activePage === DOCTOR_STEPS.ADD_SERVICES) {
      sendServiceRequest(state.patientForExamination, state.checkboxServices).then(resp => {
        if(!!resp) {
          const servicesForCurrent = data?.data.patients.find(i => i.uuid === state.patientForExamination)?.additional_services;
          if(data?.data.is_group) {
            moveToPage(DOCTOR_STEPS.EXAMINATION);
          } else {
            moveToPage(DOCTOR_STEPS.TRIGGER_SERVICES);
          }
          simpleUpdate('isAdditional', false);
          simpleUpdate('additionalFromUuid', '');
          const services = state.checkboxServices.map(item => ({
            ...item,
            payment_status: resp.find(i => i.additional_service === item.uuid).payment_status
          }));
          changeState({checkboxServices: []});
          dispatch(changeRequestDetails('additional_services', state.patientForExamination, servicesForCurrent.concat(services)));
        }
      });
    } else if(state.activePage === DOCTOR_STEPS.CONFIRM) {
      dispatch(updateRequestData(data?.data.uuid, { confirm_assignment: true })).then(resp => {
        if(resp.data?.uuid) {
          dispatch(updateRequestsStatuses({ status: resp.data.status, uuid: resp.data.uuid }));
        }
        thenHandler(resp);
      });
    } else if(state.activePage === DOCTOR_STEPS.EXAMINATION) {
      dispatch(updateRequestData(state.patientForExamination, { examination_status: COMPLETED })).then(resp => {
        if(resp.data.uuid) {
          moveToPage(DOCTOR_STEPS.PATIENTS_LIST);
        }
      });
    }
  };

  const thenHandler = resp => {
    if(resp.data?.uuid) {
      if(resp.data.status?.toLowerCase() === requestStatuses.assigned && data?.data.status === requestStatuses.pending) {
        updateStatusEvent();
      }
      setStatusAndArrive(resp.data.status?.toLowerCase(), resp.data.doctor_should_arrive_at);
      if(moment(addFromCurrentInISO(90, momentAbbreviation.minutes)) > moment(data?.data.application_can_start_at)) {
        moveToPage(state.activePage + 1);
      }
    }
  };

  const startExamination = uuid => {
    dispatch(updateRequestData(uuid, { examination_status: STARTED })).then(resp => {
      if(resp.data.uuid) {
        changeState({ patientForExamination: uuid });
        moveToPage(DOCTOR_STEPS.EXAMINATION);
      }
    });
  };

  const closeSideBar = () => {
    onClose();
    dispatch(setDisplayChat(false))
    // changeState({ openChat: false });
  };

  const openServices = () => {
    moveToPage(DOCTOR_STEPS.ADD_SERVICES);
    getAdditionalServices().then(resp => {
      changeState({ services: resp });
    });
  };

  const completeRequest = () => {
    dispatch(updateRequestData(data?.data.uuid, { complete_visit: true })).then(resp => {
      if(resp.data?.uuid) {
        dispatch(updateRequestsStatuses({ status: resp.data.status, uuid: resp.data.uuid }));
        changeState({ showPopUp: false });
        moveToPage(DOCTOR_STEPS.ORDER_DETAILS);
      }
    });
  };

  const handlerBack = () => {
    if(state.activePage === DOCTOR_STEPS.ADD_SERVICES) {
      if(data?.data.is_group) {
        moveToPage(DOCTOR_STEPS.EXAMINATION);
      } else {
        moveToPage(DOCTOR_STEPS.TRIGGER_SERVICES);
      }
    } else if(isInMultiple) {
      if(data?.data.is_group) {
        if(state.activePage === DOCTOR_STEPS.PATIENTS_LIST) {
          defineStepFromStatus();
        } else if(state.activePage === DOCTOR_STEPS.EXAMINATION) {
          moveToPage(DOCTOR_STEPS.PATIENTS_LIST);
        } else {
          simpleUpdate('isInMultiple', false);
        }
      } else {
        simpleUpdate('isInMultiple', false);
      }
    } else if(state.activePage === DOCTOR_STEPS.PATIENTS_LIST) {
      defineStepFromStatus();
    } else if(state.activePage === DOCTOR_STEPS.EXAMINATION) {
      moveToPage(DOCTOR_STEPS.PATIENTS_LIST);
    }
  };

  let startTimer = () => {};
  let setNewTime = () => {};

  const hidePrimary = data?.data.status?.toLowerCase() === requestStatuses.assigned && dateMoreThenNowPlus90Min(data?.data.application_can_start_at) ||
    state.activePage === DOCTOR_STEPS.ORDER_DETAILS ||
    state.activePage === DOCTOR_STEPS.PATIENTS_LIST && data?.data.status.toLowerCase() !== requestStatuses.inProgress;
  const hideArrow = !(isInMultiple ||
    state.activePage === DOCTOR_STEPS.ADD_SERVICES ||
    state.activePage === DOCTOR_STEPS.PATIENTS_LIST ||
    state.activePage === DOCTOR_STEPS.EXAMINATION
  );
  const hideSecondary = state.activePage === DOCTOR_STEPS.ADD_SERVICES ||
    state.activePage === DOCTOR_STEPS.PATIENTS_LIST ||
    state.activePage === DOCTOR_STEPS.EXAMINATION ||
    state.activePage === DOCTOR_STEPS.CONFIRM ||
    !data?.data && !showChats(data?.data);
  
  return(
    <SideBarRequest
      open={open}
    >
      <SidebarWrapper
        activePage={state.activePage}
        onClose={onClose}
        isFromEmr={isFromEmr}
        handlerBack={hideArrow ? null : handlerBack}
        data={data?.data}
        actionPrimary={handleChangeStep}
        open={open}
        hidePrimary={hidePrimary}
        isInMultiple={isInMultiple}
        isMultiple={isMultiple}
        simpleUpdate={simpleUpdate}
        multipleRequestList={multipleRequestList}
        disabledPrimary={state.activePage === DOCTOR_STEPS.ADD_SERVICES && !state.checkboxServices}
        userRole={USER_ROLES.DOCTOR}
        hideSecondary={hideSecondary}
        actionSecondary={() => {
          dispatch(setDisplayChat(true))
          // changeState({ openChat: true })
        }}
        hideAdditional
        increaseHeader={state.activePage === DOCTOR_STEPS.NEXT}
      >
        {(!isMultiple || isMultiple && isInMultiple) && (
          <ContainerComponentsRequest
            {...state}
            openChat={isChatOpen}
            userData={userData}
            data={data?.data}
            isFromEmr={isFromEmr}
            doctorCurrent={doctorCurrent}
            changeState={changeState}
            onChangeTime={onChangeTime}
            completeRequest={completeRequest}
            openServices={openServices}
            closeSideBar={closeSideBar}
            startExamination={startExamination}
            startTimer={func => (startTimer = func)}
            setNewTime={func => (setNewTime = func)}
            patientForExamination={state.patientForExamination}
            setNeedRefresh={setNeedRefresh}
          />
        )}
        {isMultiple && !isInMultiple && (
          <MultipleVisitsList
            data={multipleRequestList}
            viewDetailsAppointmentOrShift={viewDetailsAppointmentOrShift}
            detailsAppointment={data?.data}
            isMultiple={isMultiple}
            doctorLocation={doctorCurrent.location?.name}
            action={viewDetailsAppointmentOrShift}
            user={doctorCurrent}
            isDoctor
          />
        )}
      </SidebarWrapper>
    </SideBarRequest>
  );
};
