import createReducer from "../createReducer.js";
import {
  SET_NOTIFICATIONS,
  SET_TWILIO_CLIENT,
  SET_USER_DATA,
  SET_MESSAGES,
  UPDATE_NOTIFICATIONS,
  SET_REQUEST_TYPES
} from "./actionTypes";

const initialState = {
  user: {},
  twilioClient: null,
  notifications: null,
  messages: [],
  requestTypes: []
};

function setUserData(state, { payload }) {
  return { ...state, user: payload };
}

function setClientTwilio(state, { payload }) {
  return { ...state, twilioClient: payload };
}

function setNotification(state, { payload }) {
  return { ...state, notifications: payload };
}

function updateNotification(state, { payload }) {
  if(payload.allRead){
    return { ...state, notifications: state.notifications.map(i => ({...i, is_read: true}))};
  }else{
    return { ...state, notifications: state.notifications.map(j => j.payload.service_request_uuid === payload.uuid ? {...j, is_read: true} : j)};
  }

}

function setMessages(state, { payload }) {
  if(payload.clear) return { ...state, messages: [] };
  return { ...state, messages: [...state.messages, ...payload] };
}

function setRequestTypes(state, { payload }) {
  return { ...state, requestTypes: payload };
}

const commonReducer = createReducer(initialState, {
  [SET_USER_DATA]: setUserData,
  [SET_TWILIO_CLIENT]: setClientTwilio,
  [SET_NOTIFICATIONS]: setNotification,
  [SET_MESSAGES]: setMessages,
  [UPDATE_NOTIFICATIONS]: updateNotification,
  [SET_REQUEST_TYPES]: setRequestTypes
});

export default commonReducer;
