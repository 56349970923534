import { CATCH_ERROR, CLEAR_ERROR } from "./error.actionTypes";

const throwErrorToStore = data => ({ type: CATCH_ERROR, data });
const clearError = data => ({ type: CLEAR_ERROR, data });

function catchError(data) {
  return dispatch => {
    dispatch(throwErrorToStore(data));
  };
}

export { catchError, clearError };
