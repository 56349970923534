import React from 'react'
import {Button} from "../../components/Common/Button/Button";
import {fetchWrapper} from "../../helpers/helpers";
import {templates} from "../../components/DoctorFlow/Templates/baseTermplates";

export const ManageTemplates = () => {
    const updateTemplates = () => {
        fetchWrapper({
            url: `electronic-medical-record/common-emr-templates/`
        }).then((resp) => {
            const newTemplates = templates.filter(i => !resp.find(j => j.name === i.name)).map(i => fetchWrapper({
                method: 'POST',
                url: `electronic-medical-record/common-emr-templates-management/`,
                body: i
            }))
            return Promise.all(resp.map(i => {
                return fetchWrapper({
                    method: 'PUT',
                    url: `electronic-medical-record/common-emr-templates-management/${i.uuid}/`,
                    body: templates.find(j => j.name === i.name)
                })
            }), ...newTemplates)
        })
    }


    return <div>

        <Button
            text="Update templates"
            size="large"
            variant="primary"
            onClick={updateTemplates}
        />
    </div>
}
