import React from 'react'
import {GroupBlockReviewOfSystem} from "./GroupBlockReviewOfSystems";

export const ReviewOfSystem = () => {
    return {
        type: "label",
        data: "REVIEW OF SYSTEMS:",
        items: [
            GroupBlockReviewOfSystem({title: 'General:', radio: 'fever'}),
            GroupBlockReviewOfSystem({title: 'HENT:', radio: 'congestion'}),
            GroupBlockReviewOfSystem({title: 'Respiratory:', radio: 'cough'}),
            GroupBlockReviewOfSystem({title: 'Cardiovascular:', radio: 'chest pain'}),
            GroupBlockReviewOfSystem({title: 'GI:', radio: 'abdominal pain'}),
            GroupBlockReviewOfSystem({title: 'GU:', radio: 'urinary complaints'}),
            GroupBlockReviewOfSystem({title: 'Musculoskeletal:', radio: 'other aches or pains'}),
            GroupBlockReviewOfSystem({title: 'Endocrine:', radio: 'generalized weakness'}),
            GroupBlockReviewOfSystem({title: 'Neurological:', radio: 'localized weakness'}),
            GroupBlockReviewOfSystem({title: 'Psychiatric:', radio: 'emotional stress'}),
        ]
    }
}
