import {Text} from "./Components/Text";
import {Label} from "./Components/Label";
import {RadioBlock} from "./Components/RadioBlock";
import {RadioSingle} from "./Components/RadioSingle";
import {Checkbox} from "./Components/Checkbox/Checkbox";
import {VitalSigns} from "./Components/VitalSIgns";
import {PMHBlock} from "./Components/PMHBlock";
import {modesTemplate, placeholdersPMH} from "../../../constants/Doctors";
import {Treatment} from "./Components/Treatment";
import {Input} from "./Components/Input";
import {CustomWomanBlock} from "./Components/CustomWomanBlock";
import {InputStaticText} from "./Components/InputStaticText";
import React from "react";
import {BlockCheckboxCondition} from "./Components/BlockCheckboxCondition";
import {DropDown} from "./Components/DropDown/DropDown";
import _ from "lodash";
import moment from "moment";
import {VeradigmButton} from "./Components/VeradigmButton";
import {checkIsVitalRequired} from './constants';
import {TextArea} from "./Components/TextArea";
import {GroupBlock} from "./Components/GroupBlock";

const previewExceptions = ({data, DbData}) => {
    let empty = []
    data.data.forEach((i, index) => !i && empty.push(index))
    let dataFiltered = data.data.filter(i => i)
    let textDataFiltered = data.textData.filter((i, idx) => !empty.includes(idx))
    const useTextAreaData = data.hasOwnProperty('textAreaData')
    if(data.type === 'diagnoses' || data.type === 'pastMedicalHistory') {
        dataFiltered = dataFiltered.map(item => {
            const code = new RegExp(item.code, 'g');
            return {...item, label: item.label.replace(code, '').replace(/\(\)/g, '')};
        });
    }
    const dataForPreview = dataFiltered.map((i, idx) => {
        const textMed = idx === 0 && useTextAreaData
        return (textMed && data.textAreaData ? `${data.textAreaData?.replace(/\n/g, '</br>')}</br>` : '') + i.label + (i.code ? ` ${i.code}` : '') + (i.quantity ? `: ${i.quantity}` : '') + (textDataFiltered[idx].data ? ` (${textDataFiltered[idx].data})` : '')
    }).join("</br>")
    return dataForPreview ? dataForPreview : (useTextAreaData && data.textAreaData ? data.textAreaData.replace(/\n/g, '</br>') : '')
}

export const Element = ({
                            parentData,
                            type,
                            data,
                            updateState,
                            error,
                            DbData,
                            disabled,
                            mode,
                            dataRequest,
    actions
                        }) => {
    const props = {
        error: error,
        updateState: updateState,
        DbData: DbData,
        data: data,
        dataRequest: dataRequest,
        disabled: disabled,
        parentData: parentData,
        actions: actions
    }
    switch (type) {
        case "text":
            return textModes[mode](props)
        case "textarea":
            return textAreaModes[mode](props)
        case "veradigm":
            return veradigmModes[mode](props)
        case "label":
            return labelModes[mode](props)
        case "radio-block":
            return radioBlockModes[mode](props)
        case "radio-single":
            return radioSingleModes[mode](props)
        case "checkbox":
            return checkboxModes[mode](props)
        case "medicationAllergies":
            return medicationAllergiesModes[mode](props)
        case "pastMedicalHistory":
            return pastMedicalHistoryModes[mode](props)
        case "diagnoses":
            return diagnosesModes[mode](props)
        case "medicationsPrescribed":
            return medicationsPrescribedModes[mode](props)
        case "familySocialHistory":
            return familySocialHistoryModes[mode](props)
        case "medications":
            return medicationsModes[mode](props)
        // case 'treatment':
        //     return treatmentModes[mode](props)
        case "input":
            return inputModes[mode](props)
        case "input-static-text":
            return inputStaticTextModes[mode](props)
        case "selector":
            return selectorModes[mode](props)
        case "blockCheckboxCondition":
            return blockCheckboxConditionModes[mode](props)
        case "group-block":
            return GroupBlockModes[mode](props)
        default:
            return defaultModes[mode](props)


        // case 'custom-woman-block':
        //     return customWomanBlockModes[mode](props)
        //     return (
        //         <CustomWomanBlock {...props}/>
        //     )
        // case "vitalSigns":
        //     return vitalSignsModes[mode](props)
        //     return (
        //         <VitalSigns {...props}/>
        //     );
    }
}

const textAreaModes = {
    render: (props) => <TextArea {...props}/>,
    preview: ({data, DbData}) => {
        if(data.isJson)return data.data
        return <div>{data.data}</div>
    },
    check: ({data}) => ({error: !data.data})
}

const GroupBlockModes  = {
    render: (props) => <GroupBlock {...props}/>,
    preview: ({data, DbData}) => {
        if(data.isJson)return data.data
        return <div>{data.data}</div>
    },
    check: ({data}) => ({error: !data.data})
}

const textModes = {
    render: (props) => <Text {...props}/>,
    preview: ({data, DbData}) => _.get(DbData, data.source, data.data),
    check: () => ({error: false})
}
const veradigmModes = {
    render: (props) => <VeradigmButton {...props}/>,
    preview: ({data, DbData}) => '',
    check: () => ({error: false})
}

const labelModes = {
    render: (props) => <Label {...props}/>,
    preview: ({data, DbData}) => _.get(DbData, data.source, data.data),
    check: () => ({error: false})
}
const radioBlockModes = {
    render: (props) => <RadioBlock {...props}/>,
    preview: ({data}) => data.data.find(i => i.checked)?.data || '',
    check: ({data}) => ({error: !data.data.filter(i => i.checked).length})
}
const radioSingleModes = {
    render: (props) => <RadioSingle {...props} />,
    preview: ({data}) => {
        const node = data.data[0]
        return node.checked ? node.data : `NO ${node.data}`
    },
    check: () => ({error: false})
}
const checkboxModes = {
    render: (props) => <Checkbox {...props}/>,
    preview: ({data}) => data.data
        .filter(i => i.checked)
        .map(i => i.data + (i.inputText ? `( ${i.inputText.data} )` : i.datepicker ? `( ${moment(i.datepicker.data).format('MM/DD/YYYY')} )` : ''))
        .join(", "),
    check: ({data}) => {
        const firstChecking = !data.data.filter(i => i.checked).length
        let extraChecking = false
        data.data.forEach(i => {
            if (i.checked && !extraChecking) {
                const datePickerCheck = i.hasOwnProperty('datepicker') && !i.datepicker.data
                const inputCheck = i.hasOwnProperty('inputText') && !i.inputText?.data
                if (datePickerCheck || inputCheck) extraChecking = true
            }
        })
        return ({error: firstChecking || extraChecking})
    }
}
const medicationAllergiesModes = {
    render: (props) => <PMHBlock {...props} label={placeholdersPMH.allergies}/>,
    preview: previewExceptions,
    check: ({data}) => !data.data.filter(i => i).length
}
const pastMedicalHistoryModes = {
    render: (props) => <PMHBlock {...props} label={placeholdersPMH.pmh}/>,
    preview: previewExceptions,
    check: () => ({error: false})
}
const diagnosesModes = {
    render: (props) => <PMHBlock {...props} label='Diagnosis'/>,
    preview: previewExceptions,
    check: ({data}) => ({
        error: !data.data.filter(i => i).length,
        data: {
            diagnoses: data.data.map((i, index) => ({
                name: i?.value,
                code: i?.code,
                comment: data.textData[index].data
            })).filter(i => i.name)
        }
    })
}
const medicationsPrescribedModes = {
    render: (props) => <PMHBlock {...props} label='Medications prescribed'/>,
    preview: previewExceptions,
    // check: ({data})=> ({error: !data.data.filter(i => i).length,
    //         data: {medications: data.data.map((i, index) => ({name: i?.value, comment: data.textData[index].data})).filter(i => i)}})
    check: ({data}) => ({error: false})
}
const familySocialHistoryModes = {
    render: (props) => <PMHBlock {...props} label={placeholdersPMH.familySocial}/>,
    preview: previewExceptions,
    check: () => ({error: false})
}
const medicationsModes = {
    render: (props) => <PMHBlock {...props} label={placeholdersPMH.medications}/>,
    preview: previewExceptions,
    check: () => ({error: false})
}
const treatmentModes = {
    render: (props) => <Treatment {...props}/>,
    preview: (props) => props.data.data.map(i => <span><Element {...props}
                                                                type={i.type}
                                                                mode={modesTemplate.preview}
                                                                data={i}/></span>),
    check: (props) => {
        let inputsCount = props.data.data.filter(i => i.type === 'input').length
        let treatmentData = props.data.data.map(i => Element({
            ...props,
            type: i.type,
            mode: modesTemplate.check,
            data: i
        }).error).filter(i => i)
        return {
            error: (props.data.data.length - inputsCount) !== treatmentData.length,
            data: {
                treatment: props.data.data.map(i => Element({
                    ...props,
                    type: i.type,
                    mode: modesTemplate.preview,
                    data: i
                })).join(' ')
            }
        }
    }
}
const inputModes = {
    render: (props) => <Input {...props}/>,
    preview: ({data, DbData}) => data.data || (data.placeholder && `(${data.placeholder})`) || '',
    check: ({data}) => ({error: !!data.placeholder && !data.data})
}

const inputStaticTextModes = {
    render: (props) => <InputStaticText {...props}/>,
    preview: ({data, DbData}) => {
        const isEmpty = !data.data.filter(i => i.data).length
        if (isEmpty) return ''
        let text = data.label + ' '
        data.data.forEach((j, index) =>
            text += (j.data.split('').pop() === '.' ? j.data.slice(0, -1) : j.data) + (data.deviders[index] ? ' ' + data.deviders[index] : '') + ' ')
        if(data.isVitalSigns) text = text.replace(/\*/g, '')
        return text
    },
    check: ({data}) => ({error: !data.noMandatory && !!data.data.find(i => !i.data) || checkIsVitalRequired(data)})
}
const selectorModes = {
    render: (props) => <DropDown {...props}/>,
    preview: ({data, DbData}) => (data.multi ? data.data : [data.data]).filter(i => i).map(i => i.label).filter(i => i).join(", "),
    check: ({data}) => ({error: data.multi ? !data.data.filter(i => i).length : !data.data})
}
const blockCheckboxConditionModes = {
    render: (props) => <BlockCheckboxCondition {...props}/>,
    preview: (props) => {
        const item = (i, isVitalSigns) => Element({
            ...props,
            type: i.type,
            mode: modesTemplate.preview,
            data: {...i, isVitalSigns}
        })

        if (!props.data.data.checkbox[0].checked && props.data.extraType !== 'vitalSigns') return ''
        else if (props.data.extraType === 'vitalSigns') {
            const checkboxData = props.data.data.checkbox[0].checked ? ' ' + props.data.data.checkbox[0].data : ''
            return `${checkboxData ? checkboxData + '; ' : ''}` + props.data.data.hiddenBlock.map(i => item(i, true)).filter(i => i).join(", ")
        } else {
            const checkboxData = props.data.data.checkbox[0].data
            return checkboxData + props.data.data.hiddenBlock.map(i => {
                const label = i.type === 'selector' ? i.label + ': ' : ''
                return label ? label + ' ' + item(i) : item(i)
            }).filter(i => i).join(' ')
        }

    },


    check: (props) => {
        let data = null
        if (props.data.extraType === 'vitalSigns') {
            data = {vital_signs: {}}
            props.data.data.hiddenBlock.forEach(i => {
                const value = i.data.map(j => j.data).filter(i => i).join('/')
                data.vital_signs[i.nameBE] = value
            })
        } else if (props.data.extraType === 'treatment') {
            data = {
                treatment: props.data.data.hiddenBlock.map(i => Element({
                    ...props,
                    type: i.type,
                    mode: modesTemplate.preview,
                    data: i
                })).join(' ')
            }
        } else if (props.data.extraType === 'assessmentContent') {
            data = {
                assessments_plan: props.data.data.hiddenBlock.map(i => Element({
                    ...props,
                    type: i.type,
                    mode: modesTemplate.preview,
                    data: i
                })).join(' ')
            }
        }
        let error = checkFirstStep(props) && !!props.data.data.hiddenBlock.map(i => {
            return Element({
                ...props,
                type: i.type,
                mode: modesTemplate.check,
                data: i.type === 'input' ? {...i, placeholder: 'placeholderForTest'} : i
            }).error
        }).filter(i => i).length
        return {
            error: error,
            data: data
        }
    }
}

const defaultModes = {
    render: (props) => <div style={{height: '20px', width: '100%', background: 'red'}}/>,
    preview: ({data, DbData}) => <div style={{height: '20px', width: '100%', background: 'red'}}/>,
    check: () => ({error: false})
}


const checkFirstStep = (props) => {
    if (props.data.extraType === 'vitalSigns') return !props.data.data.checkbox[0].checked
    else return props.data.data.checkbox[0].checked
}



