import createReducer from "../createReducer.js";
import {
  SET_PATIENT_CARD,
  SET_PATIENT_DATA,
  SET_PATIENT_EVENTS,
  SET_DEFAULTVALUE_PATIENT,
  SET_FILES_OF_REQUEST,
  UPDATE_REQYEST_STATE_NOTE
} from "./actionTypes";

const initialState = {
  patient: {},
  events: { future: [], past: [] },
  cards: [],
  filesRequest: []
};

function setPatient(state, { payload }) {
  return { ...state, patient: payload };
}


function updateRequestOnList(state, { payload }) {
  const func = (i) => i.uuid === payload.uuid ? ({...i, service_request_chat: {...i.service_request_chat, has_unread_messages: payload.mess}}) : i
  const funcAll = (i) => ({...i, service_request_chat: {...i.service_request_chat, has_unread_messages: true}})
  if(payload.allRead){
    return { ...state, events: {future: state?.events?.future?.map(funcAll), past: state?.events?.past?.map(funcAll)} };
  }else{
    return { ...state, events: {future: state?.events?.future?.map(func), past: state?.events?.past?.map(func)} };
  }
}

function setEventsPatients(state, { payload }) {
  return { ...state, events: payload };
}
function setCards(state, { payload }) {
  return { ...state, cards: payload };
}
function setFiles(state, { payload }) {
  return { ...state, filesRequest: payload };
}
function setDefaultData(state, { payload }) {
  return { patient: {},
    events: { future: [], past: [] },
    cards: []
  };
}

const patientReducer = createReducer(initialState, {
  [SET_PATIENT_DATA]: setPatient,
  [SET_PATIENT_EVENTS]: setEventsPatients,
  [SET_PATIENT_CARD]: setCards,
  [SET_DEFAULTVALUE_PATIENT]: setDefaultData,
  [SET_FILES_OF_REQUEST]: setFiles,
  [UPDATE_REQYEST_STATE_NOTE]: updateRequestOnList
});

export default patientReducer;
