import * as components from "./components";
import {PatientData} from "./components/PatientData";

export const template = {
    name: 'Sty',
    body: {
        body: [
            {
                type: "label",
                data: "PRESENTING PROBLEM:",
                items: [
                    {
                        type: "text",
                        data: 'Eye infection',
                        editable: true,
                        source: null
                    },
                ]
            },
            {
                type: "label",
                data: "HISTORY OF THE PRESENT ILLNESS:",
                required: true,
                items: [
                    ...PatientData(),
                    {
                        type: "text",
                        data: 'who reports history of',
                        editable: true,
                        source: null
                    },
                    {
                        type: "radio-block",
                        data: [{checked: false, data: "mild"},
                            {checked: false, data: "moderate"}]
                    },
                    {
                        type: "text",
                        data: 'swelling and pain of',
                        editable: true,
                        source: null
                    },
                    {
                        type: "radio-block",
                        data: [{checked: false, data: "left"},
                            {checked: false, data: "right upper"},
                            {checked: false, data: "lower"}]
                    },

                    {
                        type: "text",
                        data: 'eyelid since',
                        editable: true,
                        source: null
                    },
                    {
                        type: "input",
                        placeholder: "mechanism/time",
                        data: "",
                    },

                    {
                        type: "text",
                        data: '. Otherwise:',
                        editable: true,
                        source: null
                    },
                    {
                        type: "checkbox",
                        data: [
                            {checked: false, data: "visual changes"},
                            {checked: false, data: "discharge"},
                            {checked: false, data: "injury"},
                            {checked: false, data: "recent \"fever blister\""}
                        ]
                    },
                    {
                        type: "text",
                        data: '.',
                        editable: true,
                        source: null
                    }
                ]
            },
            components.ReviewOfSystem(),
            components.PMH(),
            components.GYM(),
            components.FamSocHistory(),
            components.Medications(),
            components.Allergies(),
            components.PhysicianExamination({
                items: [
                    components.GeneralizedAppearance(),
                    components.VitalSigns(),
                    {
                        type: "label",
                        data: "ENMT:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            ...components.RenderRadioSingleBLocks(['facial vesicles']),
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    {
                        type: "label",
                        data: "Visual Acuities:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: 'input-static-text',
                                label: 'O.S.: 20/',
                                data: [{data: '', mask: '99', min: 0, max: 99}],
                                deviders: [],
                            },
                            {
                                type: 'input-static-text',
                                label: 'O.D.: 20/',
                                data: [{data: '', mask: '99', min: 0, max: 99}],
                                deviders: [],
                            },
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    {
                        type: "label",
                        data: "Lids & Lashes:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            ...components.RenderRadioSingleBLocks(['mild swelling']),
                            {
                                type: "text",
                                data: ', erythema and tenderness of',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-block",
                                data: [
                                    {checked: false, data: "left"},
                                    {checked: false, data: "right upper"},
                                    {checked: false, data: "lower"},
                                ]
                            },
                            {
                                type: "text",
                                data: 'lid with \"pointing\" to lid margin. Lacrimal duct:',
                                editable: true,
                                source: null
                            },
                            ...components.RenderRadioSingleBLocks(['discharge']),
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    {
                        type: "label",
                        data: "Pupils:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'PERRL.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    {
                        type: "label",
                        data: "EOM\'s:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Intact.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    {
                        type: "label",
                        data: "Lid eversion:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            ...components.RenderRadioSingleBLocks(['foreign body']),
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    {
                        type: "label",
                        data: "Conjunctivae:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            ...components.RenderRadioSingleBLocks(
                                ['injection']),
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    {
                        type: "label",
                        data: "Cornea:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Clear.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    {
                        type: "label",
                        data: "Anterior Chamber:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            ...components.RenderRadioSingleBLocks(['hyphema']),
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                ]
            }),
            components.AssessmentPlan([ {
                type: 'blockCheckboxCondition',
                extraType: 'assessmentContent',
                data: {
                    checkbox: [{checked: true, data: '', isHide: true}],
                    hiddenBlock: [
                        {
                            type: "text",
                            data: 'Follow up consultation to be arranged by',
                            editable: true,
                            source: null
                        },
                        {
                            type: "radio-block",
                            data: [{checked: true, data: "patient"},
                                {checked: false, data: "caretaker"},
                                {checked: false, data: "me"},
                            ]

                        },
                        {
                            type: "text",
                            data: 'with',
                            editable: true,
                            source: null
                        },
                        {
                            type: "radio-block",
                            data: [{checked: true, data: "PCP"},
                                {checked: false, data: "ophthalmologist"}]
                        },{
                            type: "text",
                            data: 'in',
                            editable: true,
                            source: null
                        },

                        {
                            type: 'input-static-text',
                            label: '',
                            data: [{data: '', mask: '999', min: 0, max: 365}],
                            deviders: []
                        },
                        {
                            type: "text",
                            data: 'days for further evaluation.',
                            editable: true,
                            source: null
                        },
                    ]
                }
            }]),
        ]
    }
}
