import React, {useMemo} from "react";
import {Typography} from "../../components/Common/Typography/Typography";
import {colors} from "../../assets/colors/colors";
import './style.scss'

export const PriceBlock = ({request,timestampRequest, userIsClient}) => {
    const countRequest = userIsClient ? request?.patients_number : 1
    const sum = useMemo(() => {
        let sum = 0
        if (!!request) {
            request.patients.forEach((i) => i.additional_services.forEach(j => {
                if (j.payment_status === 'paid') sum += +j.price
            }))
            return sum
        }
        return sum
    }, [timestampRequest])

    return <div className='price-block'>
        <div className='price-block_type'>
            <div>
                <Typography
                    color={colors.navy}
                    variant="title"
                    text="Type: "
                />
                <Typography
                    color={colors.grey}
                    variant="title"
                    text={request?.service_type?.viewLabel}
                />
            </div>
            {countRequest > 1 && <Typography
                color={colors.navy}
                variant="title"
                text={`${countRequest}x`}
            />}
            <Typography
                color={colors.navy}
                variant="title"
                text={`$${request?.visit_price}`}
            />
        </div>
        {sum > 0 && <div className='price-block_services'>
            <Typography
                color={colors.navy}
                variant="title"
                text="Additional services:"
            />
            <Typography
                color={colors.navy}
                variant="title"
                text={`+$${sum}`}
            />
        </div>}
        {!!request.promo_codes?.length && userIsClient && <div className='price-block_gift'>
            <Typography
                color={colors.navy}
                variant="title"
                dangerouslySetInnerHTML={`Gift card code : <grey>${request.promo_codes.map(i => i.title).join(',')}</grey>`}
            />
            <Typography
                color={colors.navy}
                variant="title"
                text={`-$${Math.floor(request.promo_codes[0].price)}`}
            />
        </div>}
        <div className='price-block_price'>
            <Typography
                color={colors.navy}
                variant="title"
                text="Price:"
            />
            <Typography
                color={colors.navy}
                variant="h2_5"
                text={`$${request?.total_visit_price}`}
            />
        </div>
    </div>
}
