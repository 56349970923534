import { createSelector } from "reselect";
import { getDateFromISO } from "../../utils/date";

export const events = store => store.doctor.calendar.events;

export const selectorEvents = createSelector([events], items =>
  items.map(i => ({
    id: i.uuid,
    title: i.service_request_status,
    data: i,
    start: getDateFromISO(i.application_can_start_at),
    end: getDateFromISO(i.application_time)
  }))
);
