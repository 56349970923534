import React from "react";
import { Typography } from "../Typography/Typography";
import cn from "classnames";
import "./style.scss";
import {colors} from "../../../assets/colors/colors";

export const Delta = ({ delta, extraText }) => (
  <div className={cn("delta", { delta_zero: delta === 0 })}>
    <div
      className={cn("image", {
        positive: delta >= 0,
        negative: delta < 0
      })}
    />
    <Typography variant="h5" className="mgr" text="on" />{" "}
    <Typography
      variant="h5"
      className="delta-value"
      text={`${delta}%`}
      color={delta < 0 ? colors.redError : colors.elfName}
    />
    {extraText && (
      <>
        {" "}
        <Typography variant="h5" className="mgr" text={extraText} />
      </>
    )}
  </div>
);
