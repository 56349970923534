import React, { useEffect } from 'react';
import './style.scss';
import moment from "moment";
import { HeaderNotes } from "../Emr/componentsPDF/HeaderNotes/HeaderNotes";
import { FooterNotes } from "../Emr/componentsPDF/FooterNotes/FooterNotes";
import { TablePatient } from "../Emr/componentsPDF/TablePatient/TablePatient";
import { Element } from "../Templates/chooserElements";
import { modesTemplate } from "../../../constants/Doctors";
import cn from 'classnames';
import { changeDateWithFormat } from "../../../utils/date";
import { formatReceipt } from '../../../helpers/helpers';
import { assessmentsPlanKeys } from "../Templates/constants";

const DischargeContext = (dataRequest, {targetRequest, ...props}) => {
    let vitalSigns = null
    const funcVitalSignsSearch = i => {
        if(i.data === 'Vital Signs:') vitalSigns = i
        else if(i.parent && !i.isDelete) i.items.forEach(funcVitalSignsSearch)
    }
    props.templateData.body.forEach(funcVitalSignsSearch);
    let assessmentsPlan
    try {
        assessmentsPlan = JSON.parse(props.dischargeData?.assessments_plan || {})
    }catch (e) {
        assessmentsPlan = props.dischargeData?.assessments_plan
    }
    const doctorInitial = dataRequest?.doctor_data?.first_name + ' ' + dataRequest?.doctor_data?.last_name;
    const degree = dataRequest?.doctor_data?.title;
    const isOld = typeof assessmentsPlan === 'string'
    return (
        <>
            <TablePatient dataRequest={dataRequest} targetRequest={targetRequest} dateService={moment(dataRequest.application_can_start_at).format('MM/DD/YYYY')}/>
                <div className='discharge-context__item'>
                    Physician Providing Services: {`${doctorInitial}${degree ? `, ${degree}` : ''}`}
                </div>
                <div className='discharge-context__item'>
                    <b>Reason(s) for House Call Visit: </b>
                    <span>{props?.targetRequest?.symptoms?.map(i => i.name).join(', ')}</span>
                    <br/>
                    {props?.targetRequest?.comment}
                </div>
            {vitalSigns && <div className='discharge-context__item vital_signs'>
                <div className='vital_signs_title'>Vital Signs:</div>
                <br/>
                {vitalSigns?.items?.map(i => {
                    const el = Element({
                        data: i,
                        mode: modesTemplate.preview,
                        type: i.type
                    });
                    return el
                })}
            </div>}
            {props.dischargeData?.tests_performed && <div className='discharge-context__item'>
                <b>TESTS PERFORMED </b><br/><br/> <div dangerouslySetInnerHTML={{__html: props.dischargeData?.tests_performed}}/>
            </div>
            }
            {props.dischargeData?.treatment && <div className='discharge-context__item'>
                <b>Treatment </b> <span>{props.dischargeData?.treatment.content}</span>
            </div>
            }
            <div className='discharge-context__item'>
                    <span style={{fontWeight: 'bold'}}>Assessment and Patient Plan:</span>
                </div>
            {isOld && <>
                {props.dischargeData.diagnoses.map(item => {
                    return (
                        <div className='discharge-context__item'>
                            <div>
                                {item.code} {item.name}
                                <br/>
                                {item.comment}
                            </div>
                        </div>
                    )
                })}
                {
                    props.dischargeData.medications.map(item => {
                        return (
                            <div className='discharge-context__item'>
                                {item.name}
                                <br/>
                                {item.comment}
                            </div>
                        )
                    })
                }
            </>}
            {isOld ? <div className='discharge-context__item'>
                {assessmentsPlan}
            </div> : <>
                <div className='discharge-context__item' dangerouslySetInnerHTML={{__html: assessmentsPlan[assessmentsPlanKeys.txtFirstPart]?.replace(/\n/g, '</br>')}}/>
                <div className='discharge-context__item' dangerouslySetInnerHTML={{__html: assessmentsPlan[assessmentsPlanKeys.textAreaAssessmentPlan]?.replace(/\n/g, '</br>')}}/>
                <div className='discharge-context__item' dangerouslySetInnerHTML={{__html: assessmentsPlan[assessmentsPlanKeys.diagnosis]?.replace(/\n/g, '</br>')}}/>
                <div className='discharge-context__item' dangerouslySetInnerHTML={{__html: assessmentsPlan[assessmentsPlanKeys.medications]?.replace(/\n/g, '</br>')}}/>
                <div className='discharge-context__item' dangerouslySetInnerHTML={{__html: assessmentsPlan[assessmentsPlanKeys.assessments_plan]?.replace(/\n/g, '</br>')}}/>
            </>}
        </>
    )
};

const VisitNoteContext = (dataRequests, {dbDataTemplate, templateData, targetRequest}) => {
    const exceptions = {
        diagnoses: {type: 'diagnoses', className: 'diagnose-emr-preview'},
        medicationAllergies: {type: 'medicationAllergies', className: ''},
        pastMedicalHistory:{type: 'pastMedicalHistory', className: ''},
        medicationsPrescribed:{type: 'medicationsPrescribed', className: 'medication-pres-emr-preview'},
        familySocialHistory:{type: 'familySocialHistory', className: ''},
        medications:{type: 'medications', className: ''},
        blockCheckboxCondition:{type: 'blockCheckboxCondition', className: ''}
    }
    const processBlockItems = (items) => {
        return items.reduce((acc, cur)=> ([...acc, ...(cur.type === 'group-block' ? cur.items : [cur])]),[])
    }

    const renderBlock = i => {
        const processedItems = processBlockItems(i.items)
        let items = processedItems.map(j => Element({
            data: j,
            mode: modesTemplate.preview,
            type: j.type,
            DbData: dbDataTemplate
        }))
        if (processedItems.length && (!i.parent && !items.filter(i => i).length) || i.isDelete) return
        if(i.parent){
            return <>
                <div className='visit-note-pdf-preview' data-index={i}>
                {i.data && <h4>{i.data}</h4>}
                </div>
                {processedItems.map(renderBlock)}
                </>
        }
        return <div className='visit-note-pdf-preview' data-index={i}>
            {i.data && <h4>{i.data}</h4>}
            {processedItems.map((j, ind) =>
                {
                    const elem = Element({data: j,
                        mode:modesTemplate.preview,
                        type: j.type,
                        DbData:dbDataTemplate})
                    if(exceptions[j.type]){
                         return <div key={ind} className={exceptions[j.type].className} dangerouslySetInnerHTML={{__html: elem}}/>
                        }
                        else {
                            return <span key={ind}>{elem}</span>
                        }
                }
            )}
        </div>
    }
    return <>
        <TablePatient dataRequest={dataRequests} targetRequest={targetRequest} dateService={moment(dataRequests?.application_can_start_at).format('MM/DD/YYYY')}/>
        <div className='line bold'/>
        {templateData.body.map(renderBlock)}
    </>
}

const ReceiptContext = (dataRequest, props) => {
    const sum = props.receiptData.receipt_elements.reduce((acc, elem) => acc + Number(elem.cpt ? elem.price : 0), 0)
    return (
        <>
            <TablePatient dataRequest={dataRequest} targetRequest={props.targetRequest} dateService={moment(dataRequest?.application_can_start_at).format('MM/DD/YYYY')}/>
            <div className='table-service-wrapper'>
            <table style={{width: '100%'}} className='table-service'>
                <tr>
                    <th style={{width: '15%'}}>CPT Codes</th>
                    <th>Service Description</th>
                    <th>ICD 10 codes</th>
                    <th style={{width: '15%'}}>Charges</th>
                </tr>
                {formatReceipt(props.receiptData.receipt_elements).map((el, ind, arr) => {
                    const string = `${el.diagnosis?.code || ''} ${el.diagnosis?.name || ''} ${el.additional_service_request_assigment?.name || ''}`.split(' ')
                    return (
                        <>
                            <tr>
                                <td style={{width: '15%'}}>{el.cpt?.code}</td>
                                <td>{el.cpt?.name}</td>
                                <td> <div className='table-td-str-tags'>{string.map(i => <span className='table-td-str-tags-span'>{i}</span>)}</div></td>
                                <td style={{width: '15%'}}>{`$${el.cpt ? el.price : '0.00'}`}</td>
                            </tr>
                            {!!arr[ind]?.diagnosis && !arr[ind + 1]?.diagnosis && arr.length !== ind + 1 &&
                            <tr>
                                <td colSpan={4}>Additional services</td>
                            </tr>}
                        </>
                    )
                })
                }
                <tr>
                    <td style={{width: '15%'}}>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td style={{width: '15%'}}>{`$${sum}.00`}</td>
                </tr>
            </table>
            </div>
            <div className='receipt-context__sum'>This invoice was paid in full by credit card in the amount
                of {`$${sum}.00`}</div>
        </>
    )
}

const CovidContext = (dataRequest, props) => {
    const covid = props.covid?.result
    return (
        <>
            <TablePatient dataRequest={dataRequest} targetRequest={props.targetRequest} dateService={moment(dataRequest?.application_can_start_at).format('MM/DD/YYYY')}/>
            <div className='table-service-wrapper'>
            <table style={{width: '100%'}} className='table-service'>
                <tr>
                    <th>&nbsp;</th>
                    <th>Test Result</th>
                    <th>Reference</th>
                </tr>
                <tr>
                    <td>COVID-19 Rapid Test</td>
                    <td>{covid}</td>
                    <td>Negative</td>
                </tr>
            </table>
                <div className='covid-pdf-txt'>
                    Covid 19 Rapid antigen test was performed using <strong>BD Veritor Plus system</strong>, which provides rapid detection of SARS-CoV-2 using chromatographic immunoassay for the direct and qualitative detection of SARS-CoV-2 antigens in nasal swabs from patients.
                </div>
            </div>
        </>
    )
}

const ExcuseContext = (dataRequest, props) => {
    const application_can_start_at = dataRequest?.data?.application_can_start_at
    const {first_name, last_name} = props.targetRequest

    return (
        <>
            <p>To Whom it May Concern:</p>
            <div>
                {first_name} {last_name} was evaluated by a physician through Uplin's Doctor House Calls
                Service on {moment(application_can_start_at).format('MM/DD/YYYY hh:mm a')} and may return to work/school
                on {changeDateWithFormat(props?.excuse?.desired_date, true) || ''}.
            </div>
            <div className='excuse'>
                Please excuse {first_name} for {props.excuse?.days_number || ''} day(s).
            </div>
        </>
    )
}
const contextName = {
    discharge: (...data) => ({title: 'DISCHARGE SUMMARY', context: DischargeContext(...data)}),
    receipt: (...data) => {
        return ({title: data[0]?.service_type === 'covid' ?
                'Receipt for COVID-19 Test Visit' :
                'RECEIPT FOR THE VISIT', context: ReceiptContext(...data)})
    },
    excuse: (...data) => ({title: 'EXCUSE SUMMARY', context: ExcuseContext(...data)}),
    note: (...data) => ({title: 'VISIT NOTE', context: VisitNoteContext(...data)}),
    covid: (...data) => ({title: 'COVID-19 Test Results', context: CovidContext(...data)})
}

export const GenerationPDFnotes = ({notesView, dataRequest, callBackAfterRender, signature, ...props}) => {
    useEffect(()=>{
        const paddingPage = 40
        const a4Height = 842
        const headerNode = document.querySelector(`.templates-wrapper_header`)
        const footerNode = document.querySelector(`.templates-footer`)
        notesView.filter(i => (i !== 'receipt' || i !== 'excuse') && !!i).forEach((i) => {
            let fillingPage = 0
            const currentPdf = document.querySelector(`#pdf-content-${i}`)
            let nodeList = [headerNode, ...Array.from(currentPdf.childNodes), footerNode]
            nodeList.forEach((i, index, array) => {
                let oldPage = fillingPage + i.offsetHeight < a4Height - paddingPage
                if(oldPage){
                    fillingPage += i.offsetHeight
                }else{
                    let elSpacer = document.createElement("div");
                    elSpacer.style.height = a4Height - fillingPage + 'px'
                    if(array.length - 1 === index){
                        currentPdf.appendChild(elSpacer)
                    }else {
                        currentPdf.insertBefore(elSpacer, array[index])
                        fillingPage = i.offsetHeight
                    }
                }
            })
        })
        callBackAfterRender && callBackAfterRender()
    })
    return (
        <div className="optional-templates-container_preview-block">
            {notesView.filter(i => i).map(i => {
                const contextComponent = contextName[i](dataRequest, props)
                let date = ''
                let className = ''
                if (i === 'discharge') {
                    className = 'discharge-context'
                    date = moment(dataRequest?.data?.application_can_start_at).format('MM/DD/YYYY')
                } else if (i === 'receipt'){
                    className = 'receipt-context'
                    date = moment(props.receiptData.updated_at || props.receiptData.created_at).format('MM/DD/YYYY hh:mm a')
                } else if (i === 'covid'){
                    className = 'covid-context'
                    date = props.covid ? moment(props.covid.updated_at || props.covid.created_at).format('MM/DD/YYYY hh:mm a') : ''
                } else if(i === 'note') {
                    className = 'visit-note';
                }
                return <div className="templates-wrapper" id={`pdf-prewie-${i}`}>
                    <div className='templates-wrapper_header'>
                        <HeaderNotes contextComponent={contextComponent} date={date} dataRequest={dataRequest}/>
                    <div className='line bold'/>
                    </div>
                    <div className={cn("main-context", className)} id={`pdf-content-${i}`}>
                        {contextComponent.context}
                    </div>
                    <FooterNotes dataRequest={dataRequest} signature={signature}/>
                </div>
            })}
        </div>
    )
}


