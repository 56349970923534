import createReducer from "../createReducer.js";
import {
  SET_DOCTOR_DATA,
  SET_TABLE_DATA_ADMIN,
  SET_UPLOAD_APPLICATIONS_DOCTORS_ADMIN,
  SET_PERSONAL_INFO_ADMIN,
  CLEAR_DOCTOR_DATA,
  CLEAR_PHYSICIAN,
  SET_APPOINTMENTS_SELECTED_DOCTORS,
  SET_ARCHIVED_FILES,
  SET_FILTERS_SHIFTS,
  SET_ACTIVE_PHYSICIAN,
  SET_DASHBOARD_DATA_ADMIN,
  SET_ADMIN_DATA,
  SET_DOCTOR_ASSIGN,
  UPDATE_STATUS_INVITE_SENT
} from "./actionTypes";

const initialState = {
  tableData: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  admin: {},
  doctor: {},
  events: [],
  appointmentsSelectedDoctors: [],
  filtersShifts: {},
  physicianActive: [],
  archivedFiles: [],
  doctorForAssign: [],

  dashboard: {
    summary: {},
    orderResponceTime: {},
    notesChar: {},
    satisfaction: {}
  }
};

function setDashboardData(state, { payload }) {
  return { ...state, dashboard: payload };
}

function clearTableData(state) {
  return { ...state, tableData: initialState.tableData };
}
function setTableData(state, { payload }) {
  return { ...state, tableData: payload };
}
function setDoctorData(state, { payload }) {
  return { ...state, doctor: payload };
}

function setPersonalInfo(state, { payload }) {
  return {
    ...state,
    doctor: {
      ...payload,
      upload_applications: state.doctor.upload_applications
        ? state.doctor.upload_applications
        : []
    }
  };
}

function setUploadFiles(state, { payload }) {
  return {
    ...state,
    doctor: {
      ...state.doctor,
      uploadApplications: payload.main,
      ...payload.other
    }
  };
}

function setEmptyDoctorData(state) {
  return { ...state, doctor: {} };
}

function setAppointmentsSelectedDoctors(state, { payload }) {
  return { ...state, appointmentsSelectedDoctors: payload };
}

function setFiltersShifts(state, { payload }) {
  return { ...state, filtersShifts: payload };
}
function setActivePhysicianNoFilters(state, { payload }) {
  return { ...state, physicianActive: payload };
}

function setArchivedDocuments(state, { payload }) {
  return {
    ...state,
    archivedFiles: payload
  };
}
function setAdminData(state, { payload }) {
  return {
    ...state,
    admin: payload
  };
}
function setDoctorsAssign(state, { payload }) {
  return {
    ...state,
    doctorForAssign: payload
  };
}

function updateStatusToInviteSent(state, { payload }) {
  return {
    ...state,
    tableData: {
      ...state.tableData,
      results: state.tableData.results.map(i =>
        i.uuid === payload ? { ...i, validation_status: "Invite Sent" } : i
      )
    }
  };
}

const adminReducer = createReducer(initialState, {
  [SET_TABLE_DATA_ADMIN]: setTableData,
  [SET_DOCTOR_DATA]: setDoctorData,
  [SET_UPLOAD_APPLICATIONS_DOCTORS_ADMIN]: setUploadFiles,
  [SET_PERSONAL_INFO_ADMIN]: setPersonalInfo,
  [CLEAR_DOCTOR_DATA]: setEmptyDoctorData,
  [CLEAR_PHYSICIAN]: clearTableData,
  [SET_APPOINTMENTS_SELECTED_DOCTORS]: setAppointmentsSelectedDoctors,
  [SET_FILTERS_SHIFTS]: setFiltersShifts,
  [SET_ACTIVE_PHYSICIAN]: setActivePhysicianNoFilters,
  [SET_ARCHIVED_FILES]: setArchivedDocuments,
  [SET_DASHBOARD_DATA_ADMIN]: setDashboardData,
  [SET_ADMIN_DATA]: setAdminData,
  [SET_DOCTOR_ASSIGN]: setDoctorsAssign,
  [UPDATE_STATUS_INVITE_SENT]: updateStatusToInviteSent
});

export default adminReducer;
