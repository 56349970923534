import React from "react";
import fileDownload from "js-file-download";
import { Button } from "../../Common/Button/Button";
import { downloadPatientsReport } from "../../../store/admin/actions";
import './style.scss';

export const PatientsReport = () => {
  const download = () => {
    downloadPatientsReport().then(resp => {
      resp.size && fileDownload(resp, `Uplin_Patients.xls`);
    });
  };

  return(
    <div className='patients-report'>
      <Button
        text='download'
        size="middle"
        variant="primary"
        capitalize
        onClick={download}
      />
    </div>
  );
};
