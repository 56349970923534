import React from "react";
import "./style.scss";
import {Typography} from "../../Common/Typography/Typography";
import {colors} from "../../../assets/colors/colors";
import {TimeOfRequest} from "../../Common/TimeOfRequest/TimeOfRequest";
import {dateToDisplay} from "../../../helpers/helpers";
import {toCapitalizeChart1} from "../../../utils/common";

export const Card = ({
                         user,
                         requester,
                         applicationDate,
                         timeVisiting,
                         urgencyType
                     }) => {
    const author = `${user?.firstName} ${user?.lastName}`;
    const phoneAuthor = user?.phone;

    return (
        <div className="component-card-request">
            {applicationDate && timeVisiting && (
                <div className="component-card-request__info">
                    <div className="component-card-request__type">
                        {urgencyType && <Typography
                            color={colors.navy}
                            variant="title"
                            className="side-bar__request-indicator"
                            text={`${urgencyType} request`}
                        />}
                    </div>
                    <TimeOfRequest
                        applicationDate={applicationDate}
                        timeVisiting={timeVisiting}
                    />
                    <div className='component-card-request-details_services_separator'/>
                </div>
            )}
            <div className='component-card-request_wrapper'>
                {user && <div className="component-card-request_author-block">
                    <Typography
                        color={colors.greyMiddle}
                        variant="title"
                        className="component-card-request_author-block_title"
                        text={requester ? "Organizer:" : "Requested by:"}
                    />
                    <div className="component-card-request_author-block_container">
                        <Typography
                            variant="title"
                            className="component-card-request_author-block_container_author"
                            text={author}
                        />
                    </div>
                    {(typeof user?.isMember === 'boolean' || typeof user?.is_member === 'boolean') && <Typography
                        color={colors.core70}
                        text={(user?.is_member || user?.isMember) ? 'Member' : 'Non-member'}
                        variant="p"
                    />}
                    {user?.sex && <Typography
                        color={colors.core70}
                        text={`Gender: ${toCapitalizeChart1(user?.sex)}`}
                        variant="p"
                    />}
                    <Typography
                        color={colors.navy}
                        variant="p"
                        className="component-card-request_author-block_phone"
                        text={phoneAuthor}
                    />
                </div>}

                {requester && <div className="component-card-request_author-block">
                    <Typography
                        color={colors.greyMiddle}
                        variant="title"
                        className="component-card-request_author-block_title"
                        text="Booked by:"
                    />
                    <div className="component-card-request_author-block_container">
                        <Typography
                            variant="title"
                            className="component-card-request_author-block_container_author"
                            text={`${requester?.firstName} ${requester?.lastName}`}
                        />
                    </div>
                    <Typography
                        color={colors.greyMiddle}
                        variant="p"
                        text="Admin"
                    />
                    <div className='component-card-request_author-block_date'>
                        <Typography
                            color={colors.greyMiddle}
                            variant="p"
                            text="Date of the request:"
                        />
                        <Typography
                            variant="p"
                            text={dateToDisplay()}
                        />
                    </div>
                </div>}
            </div>
            <div className="component-card-request_price-block">
                <div className="component-card-request_price-block_left"/>
                <div className="component-card-request_price-block_right"/>
                <div className="component-card-request_price-block_container-price"/>
            </div>
        </div>
    );
};
