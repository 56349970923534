import React from 'react';
import {
  SideBarClock,
  Separator,
  Map,
  RequestInfo,
  RequestedBy,
  ApproximateEta
} from '../../SideBarRequest/components';
import { GroupCard } from "../../Common/GroupCard/GroupCard";
import { PatientSidebar } from "../../Common/PatientSidebar";

export const Next = props => {
  return(
    <>
        <ApproximateEta showWhenEdit {...props} />
        {!props.isFromEmr && <><SideBarClock {...props} /> <Separator /></>}
      <RequestInfo {...props} />
      <Separator />
      <RequestedBy {...props} />
      {props.data?.is_group ? (
        <GroupCard
          onClick={props.openPatientList}
          data={props.data}
        />
      ) : (
        <PatientSidebar
          {...props}
          detailsRequest={props.data}
          data={props.data?.patients[0]}
          hideNote
          hideSignature
          hideExamination
          hideAddButton
        />
      )}
      <Map {...props} comment={props.data?.location.comment} isShowSuite />
    </>
  );
};
