export const GeneralizedAppearance = () => {
    return {
        type: "label",
        data: "Generalized Appearance:",
        canDelete: true,
        items: [
            {
                type: "input",
                placeholder: "",
                data: "",
            },
            {
                type: "checkbox",
                data: [
                    {checked: false, data: "alert"},
                    {checked: false, data: "oriented and in no distress"},
                ]
            },
        ]
    }
}