import * as components from "./components";
import {PatientData} from "./components/PatientData";
import {GroupBlockReviewOfSystem} from "./components/GroupBlockReviewOfSystems";

export const template = {
    name: 'Sinusitis',
    body: {
        body: [
            {
                type: "label",
                data: "PRESENTING PROBLEM:",
                items: [
                    {
                        type: "text",
                        data: 'Facial pain',
                        editable: true,
                        source: null
                    },
                ]
            },
            {
                type: "label",
                data: "HISTORY OF THE PRESENT ILLNESS:",
                required: true,
                items: [
                    ...PatientData(),
                    {
                        type: "text",
                        data: 'who reports',
                        editable: true,
                        source: null
                    },
                    {
                        type: "input",
                        placeholder: "duration",
                        data: "",
                    },
                    {
                        type: "text",
                        data: 'history of',
                        editable: true,
                        source: null
                    },
                    {
                        type: "radio-block",
                        data: [{checked: true, data: "mild"},
                            {checked: false, data: "moderate"},
                            {checked: false, data: "severe"},
                        ]
                    },
                    {
                        type: "text",
                        data: 'facial',
                        editable: true,
                        source: null
                    },
                    {
                        type: "radio-block",
                        data: [{checked: true, data: "pain"},
                            {checked: false, data: "pressure"},
                        ]
                    },
                    {
                        type: "text",
                        data: 'accompanied by upper respiratory symptoms including nasal congestion',
                        editable: true,
                        source: null
                    },
                    {
                        type: "radio-block",
                        data: [{checked: false, data: "and"},
                            {checked: false, data: "or"},
                        ]
                    },
                    {
                        type: "text",
                        data: 'rhinorrhea. Describes pain as dull, constant and aggravated by bending. Otherwise:',
                        editable: true,
                        source: null
                    },
                    {
                        type: "checkbox",
                        data: [
                            {checked: false, data: "cough"},
                            {checked: false, data: "fever"},
                            {checked: false, data: "headache"},
                            {checked: false, data: "stiff neck"},
                            {checked: false, data: "photophobia"},
                            {checked: false, data: "visual symptoms"},
                            {checked: false, data: "prior trauma"},
                            {checked: false, data: "recent dental infection"},
                            {checked: false, data: "tooth pain"},
                            {checked: false, data: "ear pain,"},
                            {checked: false, data: "sore throat"},

                        ]
                    },
                    {
                        type: "text",
                        data: '.',
                        editable: true,
                        source: null
                    }
                ]
            },
            {
                type: "label",
                data: "REVIEW OF SYSTEMS:",
                items: [
                    GroupBlockReviewOfSystem({title: 'General:', radio: 'fever'}),
                    GroupBlockReviewOfSystem({title: 'Skin:', radio: 'rash'}),
                    GroupBlockReviewOfSystem({title: 'HENT:', radio: 'congestion'}),
                    GroupBlockReviewOfSystem({title: 'Respiratory:', radio: 'cough'}),
                    GroupBlockReviewOfSystem({title: 'Cardiovascular:', radio: 'chest pain'}),
                    GroupBlockReviewOfSystem({title: 'GI:', radio: 'abdominal pain'}),
                    GroupBlockReviewOfSystem({title: 'GU:', radio: 'urinary complaints'}),
                    GroupBlockReviewOfSystem({title: 'Musculoskeletal:', radio: 'other aches or pains'}),
                    GroupBlockReviewOfSystem({title: 'Endocrine:', radio: 'generalized weakness'}),
                    GroupBlockReviewOfSystem({title: 'Neurological:', radio: 'localized weakness'}),
                    GroupBlockReviewOfSystem({title: 'Psychiatric:', radio: 'emotional stress'}),
                ]
            },
            components.PMH(),
            components.GYM(),
            components.FamSocHistory(),
            components.Medications(),
            components.Allergies(),
            components.PhysicianExamination({
                items: [
                    components.GeneralizedAppearance(),
                    components.VitalSigns(),
                    {
                        type: "label",
                        data: "Skin:",
canDelete: true,
                        required: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Warm, dry;',
                                editable: true,
                                source: null
                            },
                            ...components.RenderRadioSingleBLocks(
                                ['cyanosis']),
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    {
                        type: "label",
                        data: "Head:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            ...components.RenderRadioSingleBLocks(['scalp swelling', 'scalp tenderness']),
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    components.Eyes([
                        {
                            type: "text",
                            data: '. PERRL, EOMI;',
                            editable: true,
                            source: null
                        },
                        ...components.RenderRadioSingleBLocks(['proptosis', 'orbital erythema or swelling'])
                    ]),
                    {
                        type: "label",
                        data: "ENMT:",
canDelete: true,
                        items: [

                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'TMs:',
                                editable: true,
                                source: null
                            },
                            ...components.RenderRadioSingleBLocks(
                                ['erythema', 'nasal discharge', 'blood']),
                            {
                                type: "text",
                                data: 'Mild tenderness of',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-block",
                                data: [
                                    {checked: false, data: "frontal"},
                                    {checked: false, data: "malar"},
                                ]
                            },
                            {
                                type: "text",
                                data: 'area noted with no swelling;',
                                editable: true,
                                source: null
                            },
                            ...components.RenderRadioSingleBLocks(
                                ['erythema',
                                ]),
                            {
                                type: "text",
                                data: '. Pharynx:',
                                editable: true,
                                source: null
                            },
                            ...components.RenderRadioSingleBLocks(
                                ['erythema',
                                    'exudate'
                                ]),
                            {
                                type: "text",
                                data: '. Airway patent:',
                                editable: true,
                                source: null
                            },
                            ...components.RenderRadioSingleBLocks(
                                ['stridor', 'hoarseness']),
                            {
                                type: "text",
                                data: '. Mucous membranes moist.',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    components.Neck(components.RenderRadioSingleBLocks(['meningismus'], true)),
                    components.ChestRespiratory(),
                    {
                        type: "label",
                        data: "HEART:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'RRR w/o murmur',
                                editable: true,
                                source: null
                            }
                        ]
                    },

                    {
                        type: "label",
                        data: "EXT:",
                        canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            ...components.RenderRadioSingleBLocks(['cyanosis']),
                            {
                                type: "text",
                                data: 'or edema.',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "label",
                        data: "Neuro/Psych:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Mental status as above; CNs intact as above;',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "focal findings"}]
                            },
                            {
                                type: "text",
                                data: '.', editable: true,
                                source: null
                            },
                        ]
                    },
                ]
            }),
            components.TestPerformed({items: [
                    {
                        type: 'blockCheckboxCondition',
                        data: {
                            checkbox: [{checked: false, data: 'None'}],
                            hiddenBlock: []
                        }
                    },
                    {
                        type: 'blockCheckboxCondition',
                        data: {
                            checkbox: [{checked: false, data: 'Covid 19 test -'}],
                            hiddenBlock: [
                                {
                                    type: "radio-block",
                                    data: [{checked: true, data: "Negative"},
                                        {checked: false, data: "Positive"}]
                                },
                            ]
                        }
                    },
                    {
                        type: 'blockCheckboxCondition',
                        data: {
                            checkbox: [{checked: false, data: 'Strep test:'}],
                            hiddenBlock: [
                                {
                                    type: "radio-block",
                                    data: [{checked: true, data: "Negative"},
                                        {checked: false, data: "Positive"}]
                                },
                            ]
                        }
                    },
                    {
                        type: 'blockCheckboxCondition',
                        data: {
                            checkbox: [{checked: false, data: 'Influenza A and B test:'}],
                            hiddenBlock: [
                                {
                                    type: "radio-block",
                                    data: [{checked: true, data: "Negative"},
                                        {checked: false, data: "Positive for ___"}]
                                },
                            ]
                        }
                    },
                    {
                        type: 'blockCheckboxCondition',
                        data: {
                            checkbox: [{checked: false, data: ':'}],
                            hiddenBlock: [
                                {
                                    type: "input",
                                    placeholder: "",
                                    data: "",
                                },
                            ]
                        }
                    },
                ]}),
            components.AssessmentPlan([ {
                type: 'blockCheckboxCondition',
                extraType: 'assessmentContent',
                data: {
                    checkbox: [{checked: true, data: '', isHide: true}],
                    hiddenBlock: [
                        {
                            type: "text",
                            data: 'Follow up in',
                            editable: true,
                            source: null
                        },
                        {
                            type: 'input-static-text',
                            label: '',
                            data: [{data: '', mask: '999', min: 0, max: 365}],
                            deviders: []
                        },
                        {
                            type: "text",
                            data: 'days for further evaluation.',
                            editable: true,
                            source: null
                        },
                    ]
                }
            }]),
        ]
    }
}
