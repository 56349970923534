import React, {useEffect, useState} from "react";
import { colors } from "../../../assets/colors/colors";
import { Typography } from "../../Common/Typography/Typography";
import CreditCard from "../../../dist/images/CreditCard.svg";
import { Button } from "../../Common/Button/Button";
import { buttonsInnerText, routesCommon } from "../../../constants";
import cn from "classnames";
import { addNewCard, routersPatient } from "../../../constants/Patient";
import { Trash } from "../../Common/Icons/trash";
import { Radiobutton } from "../../Common/RadioButton/Radiobutton";
import visa from "../../../dist/icons/visa.svg";
import mastercard from "../../../dist/icons/mastercard.svg";
import american from "../../../dist/icons/american.svg";
import indicator from "../../../dist/icons/indicatorCircle.svg";
import indicatorPassive from "../../../dist/icons/indicatorCirclePassive.svg";
import history from "../../../helpers/history";
import {COLORS_BTN, VARIATIONS_BTN} from "../../Common/ButtonV2/constants";
import {ButtonV2} from "../../Common/ButtonV2";

export const Payment = ({controlAddNewCard, removeCard,hideCancelBtnList,closeList, ...props}) => {
    const [defaultCard, setDefaultCard] = useState(null)

    useEffect(()=>{
        setDefaultCard(props.listCards.find(i => i.is_default))
    },[props.listCards.length])

    const changeDefaultCard = (item) => {
        if(!props.changeDefaultCard) return
        props.changeDefaultCard({payment_profile_id: item.payment_profile_id})
            .then(()=>{
                setDefaultCard(item)
            })
    }

  const addCreditCard = () => controlAddNewCard(true);
  return (
    <div className="personal-info-patient_payment">
      {props.listCards.length ? (
        props.listCards.map((i, index) => (
          <div className="personal-info-patient_payment_item" key={index} >
            <div>
              <img className='personal-info-patient_payment_item_radio' onClick={(()=> changeDefaultCard(i))}
                   src={defaultCard?.payment_profile_id === i.payment_profile_id ? indicator : indicatorPassive} />
              <img
                src={
                  i.card_type === "Visa"
                    ? visa
                    : i.card_type === "AmericanExpress"
                    ? american
                    : mastercard
                }
              />
              <div className="personal-info-patient_payment_item_info">
                <Typography
                  text={i.card_number.replace(/\X/g, "*")}
                  variant="p"
                  color={colors.black}
                />
                <Typography
                  text={i.expiry_date
                    .substr(2)
                    .split("-")
                    .reverse()
                    .join("/")}
                  variant="p"
                  color={colors.grey}
                />
              </div>
            </div>
              {!!removeCard && <Trash
              onClick={() => removeCard(i.payment_profile_id)}
            />}
          </div>
        ))
      ) : (
        <div className="personal-info-patient_payment_empty">
          <img src={CreditCard} />
          <Typography text="You Have Not Added Any" variant="h1_75" />
          <Typography text="Credit Cards Yet" variant="h1_75" />
        </div>
      )}
      <div
        className={cn("personal-info-patient_payment_footer", {
          "personal-info-patient_payment_footer_central": !props.listCards
            .length
        })}
      >
          {closeList && <Button
          text={buttonsInnerText.cancel}
          size="middle"
          variant="default"
          capitalize
          onClick={closeList}
        />}
          <ButtonV2 onClick={addCreditCard} variant={VARIATIONS_BTN.CONTAINED}
                    color={COLORS_BTN.PRIMARY}>{buttonsInnerText.add_credit_card}</ButtonV2>
          {props.btnItems}
      </div>
    </div>
  );
};
