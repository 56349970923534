import { Typography } from "../../Common/Typography/Typography";
import { colors } from "../../../assets/colors/colors";
import { Radiobutton } from "../../Common/RadioButton/Radiobutton";
import { Input } from "../../Common/Input/Input";
import { PopupContainer } from "../../Common/Popup/PopupContainer";
import React, { useEffect, useState } from "react";
import {
  buttonsText,
  choice,
  regexsWithMessage,
  routesCommon,
  contactUsUrl
} from "../../../constants";
import history from "../../../helpers/history";
import { routersLanding } from "../../../constants/Landing";
import {fetchWrapper} from "../../../helpers/helpers";

export const PopupZipcode = ({ open, close, emailUser, zip}) => {
  const [radioNotify, setRadioNotify] = useState(true);
  const [email, setEmail] = useState(emailUser);

  useEffect(() => setEmail(emailUser), [emailUser]);

  const updateState = (field, value) => setEmail(value);
  const handleRadioButton = () => setRadioNotify(!radioNotify);
  const notifyAction = () => {
    // request to be with ${email}
    fetchWrapper({
      url: 'patients/zip-code-not-found-email/',
      method: 'POST',
      body: {email: email, zip: zip}
    }).then(() => {
      setEmail(emailUser)
      close()
    })
  };
  const contactWithUs = () =>
      window.open(contactUsUrl);

  const disabledButtonPrimary =
    radioNotify && !regexsWithMessage.email.regex.test(email);

  return (
    <PopupContainer
      open={open}
      close={close}
      title="Sorry, The Doctors Do Not Service"
      extraTitle='This Zip Code Quite Yet'
      // textDefault='Cancel'
      // actionDefaultButton={close}
         hideCancel
      className='zip-code-popup'
      actionPrimaryButton={notifyAction}
      textPrimary={radioNotify ? buttonsText.notify : ""}
      // textPrimary={""}
      disabledButtonPrimary={disabledButtonPrimary}
    >
      <div className="patient-info-container_verification-6-month">
        <Typography
          color={colors.grey}
          variant="p"
          className="patient-info-container_verification-6-month_text"
          text="Currently, the Doctors cover most of Palm Beach County, FL."
        />
        <Typography
          color={colors.grey}
          variant="p"
          className="patient-info-container_verification-6-month_text"
          text="If you would like for us to send an update when the Doctors do cover your Zip Code, please send us your email address below."
        />
        <Typography
            color={colors.grey}
            variant="p"
            className=""
            text="Thank you!"
        />
        {/*{choice.map((i, index) => (*/}
        {/*  <Radiobutton*/}
        {/*    label={i}*/}
        {/*    key={index}*/}
        {/*    onClick={handleRadioButton}*/}
        {/*    checked={index === 0 ? radioNotify : !radioNotify}*/}
        {/*  />*/}
        {/*))}*/}
        <Input
          placeholder="Email"
          value={email}
          className="patient-info-container_verification-6-month_email"
          field="email"
          onChange={updateState}
        />
        {/*<Typography*/}
        {/*  color={colors.grey}*/}
        {/*  variant="p"*/}
        {/*  className="patient-info-container_verification-6-month_address-top"*/}
        {/*  text="The Physicians cover Manhattan from Wall Street"*/}
        {/*/>*/}
        {/*<Typography*/}
        {/*  color={colors.grey}*/}
        {/*  variant="p"*/}
        {/*  className="patient-info-container_verification-6-month_address-bottom"*/}
        {/*  text="to 96th Street"*/}
        {/*/>*/}
      </div>
    </PopupContainer>
  );
};
