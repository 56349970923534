import React, { Component } from "react";
import cn from "classnames";
import {colors} from "../../../assets/colors/colors";

export const View = props => (
  <div
    onClick={props.onClick}
    className={cn(props.className, "icon-component")}
    style={props.style}
  >
    <svg
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 0.339355C6 0.339355 1.73 3.38725 0 7.68959C1.73 11.9919 6 15.0398 11 15.0398C16 15.0398 20.27 11.9919 22 7.68959C20.27 3.38725 16 0.339355 11 0.339355ZM11 12.5897C8.24 12.5897 6 10.3945 6 7.68959C6 4.9847 8.24 2.78943 11 2.78943C13.76 2.78943 16 4.9847 16 7.68959C16 10.3945 13.76 12.5897 11 12.5897ZM11 4.7495C9.34 4.7495 8 6.06274 8 7.68959C8 9.31644 9.34 10.6297 11 10.6297C12.66 10.6297 14 9.31644 14 7.68959C14 6.06274 12.66 4.7495 11 4.7495Z"
        fill={colors.navy}
      />
    </svg>
  </div>
);
