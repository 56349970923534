import React from "react";
import "./GoogleMarker.scss";

export const GoogleMarker = ({ color, name, id }) => {
  return (
    <div
      className="marker"
      style={{ backgroundColor: color, cursor: "pointer" }}
      title={name}
    >
      <div className="custom-marker-on-map" />
    </div>
  );
};
