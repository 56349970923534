import React from "react";
import cn from "classnames";

export const DropdownArrow = props => (
  <div
    className={cn(props.className, "icon-component")}
  >
    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.41 8.02541e-07L6 4.58L10.59 0L12 1.41L6 7.41L0 1.41L1.41 8.02541e-07Z" fill="#5F6368"/>
    </svg>
  </div>
);
