import React, {useEffect, useMemo, useState} from "react";
import "../../styles/EmrReuqest.scss";
import { DataRequests } from "../../components/DoctorFlow/Emr/DataRequests";
import { PatientData } from "../../components/DoctorFlow/Emr/PatientData";
import { routesCommon } from "../../constants";
import Templates from "../../components/DoctorFlow/Templates";
import { Route, Switch, Redirect } from "react-router-dom";
import { routersDoctor } from "../../constants/Doctors";
import history from "../../helpers/history";
import { syncPmhFromMedicationVeradigm } from "../../store/doctor/actions";
import { useDispatch, useSelector } from "react-redux";
import { requestDetailsSelector } from "../../store/request/selectors";
import { getDataRequest } from "../../store/request/actions";
import { getSingleFromGroupRequest } from "../../store/request/utils";
import {clearRequestDetails} from "../../store/request/slice";

export const EMRDetailsRequest = (props)=> {
  const [isCurrentDoctor, setIsCurrentDoctor] = useState(null);
  const [patientUuid, setPatientUuid] = useState(null);
  const request = useSelector(requestDetailsSelector);
  const dispatch = useDispatch();

  const targetRequest = useMemo(()=>{
    return getSingleFromGroupRequest({request: request, uuid: props.id})
  },[request?.timestamp]);

  const updateData =() => {
    if (document.visibilityState === 'visible') {
      const patientUuid = getSingleFromGroupRequest({request, uuid: props.id})?.patient_uuid

      if (props.role === 'doctor') syncPmhFromMedicationVeradigm(props.id, true).then((resp) => {
        props.getUpdatePMHinfo(patientUuid, null, true)
      })
    }
  }

  useEffect(()=>{
    if(props.role === 'doctor'){
      props.getCPTcode()
      props.getICDList()
    }
    props.getTypesDoctor();
    props.getTypesDegree();
    props.getListTemplatesCommon();
    props.getFamilySocialHistoryOptions()


    return ()=>{
      props.clearRequestData()
      dispatch(clearRequestDetails())
    }
  }, []);

  useEffect(()=>{
    if(!!request) {
      props.saveSignature(null, request.data.doctor_data.uuid, true)
      setPatientUuid(getSingleFromGroupRequest({request, uuid: props.id})?.patient_uuid);
      setIsCurrentDoctor(props.doctorData.uuid === request.data.doctor_data.uuid)
    }
  },[request?.timestamp]);

  useEffect(() => {
    dispatch(getDataRequest({uuid: props.id, noClearStore: true}));
    if(patientUuid && props.id) {
      props.getDataTemplateOfRequest(props.id, patientUuid);
      props.getAttachmentsPMH(patientUuid)
      props.getLastRequestsOfPatient(patientUuid);
      props.getPCPDoctor(patientUuid);
      props.getDbDataTemplate(props.id)
      props.getFollowUps(props.id);
      props.getAttachments(props.id);
      props.getExcuse(props.id);
      props.getCovidDoc(props.id);
      props.getAdditionalInfo(props.id);
    }
  }, [patientUuid, props.id]);

  useEffect(()=>{
    if(!!request){
      setPatientUuid(getSingleFromGroupRequest({request, uuid: props.id})?.patient_uuid);
    }

    document.addEventListener("visibilitychange", updateData);
    return () => {
      document.removeEventListener('visibilitychange', updateData)
    }
  },[!!request]);

  const changeShowingRequest = data => {
    history.push(`${props.basePart}${routersDoctor.info_request}/${data.service_request_uuid}`)
  };
  
  const redirectToTemplate = (template) => {
    history.push(`${routesCommon.doctor}${routersDoctor.request}${routersDoctor.template}/${props.id}`, {templateId: template.value || props.templateData.uuid})
    }
  const redirectToInfoRequest = () => {
    history.push(`${routesCommon.doctor}${routersDoctor.request}${routersDoctor.info_request}/${props.id}`)
  }

    return (
      <div className="container-emr-request">
        <Switch>
          <Route
              path={`${props.baseUrl}${routersDoctor.request}${routersDoctor.info_request}/${props.id}`}
              render={({ match }) => (<div className='container-emr-request_info'><DataRequests
                    id={props.id}
                    updateAdditionalInfo={props.updateAdditionalInfo}
                    additionalInfo={props.additionalInfo}
                    doctorData={props.doctorData}
                    deleteAdditionalInfo={props.deleteAdditionalInfo}
                    listLastRequests={props.listLastRequests}
                    dataRequest={request}
                    role={props.role}
                    changeShowingRequest={changeShowingRequest}
                />
                  <PatientData {...props}  dataRequest={request} redirectToTemplate={redirectToTemplate} isCurrentDoctor={isCurrentDoctor}/></div>)
              }
          />
          <Route
              path={`${props.baseUrl}${routersDoctor.request}${routersDoctor.template}/:id`}
              render={(params) => {
                return <Templates
                    pathRedirect={`${routesCommon.doctor}${routersDoctor.request}${routersDoctor.info_request}/${props.id}`}
                    idRequest={props.id}
                    templateId={params?.location?.state?.templateId}
                    getUpdatePMHinfo={props.getUpdatePMHinfo}
                    redirectToInfoRequest={redirectToInfoRequest}
                    {...props}
                    targetRequest={targetRequest}
                    dataRequest={request}
                />
              }}
          />
          <Redirect path={`${props.baseUrl}${routersDoctor.request}${routersDoctor.info_request}/${props.id}`} />
        </Switch>
      </div>
    );
}
