import {fetchV2} from "../../helpers/helpers";
import {initialDatesRequests} from "./constants";
import {setRequestsData, addPartData} from "./slice";

export const getRequestsData = (body = initialDatesRequests) => dispatch => {
    return dispatch(fetchV2({
        url: "service-requests/calendar/events/",
        body: body
    })).then(resp => {
        if(body.modeLoading)dispatch(addPartData({data: resp, mode: body.modeLoading}))
        else dispatch(setRequestsData(resp))
    });
}
