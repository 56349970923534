import React from "react";
import { Typography } from "../../Common/Typography/Typography";
import { Cross } from "../../Common/Icons";
import "./style.scss";
import { colors } from "../../../assets/colors/colors";

export const Selected = ({ value, field, deleteAction }) => {
  const deleteEvent = () => {
    deleteAction(field);
  };
  return (
    <div className="component-filter-value">
      <Typography variant="p" text={value} color={colors.navy} />
      <Cross className="component-filter-value_img" onClick={deleteEvent} />
    </div>
  );
};
