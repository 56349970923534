import React from "react";
import { Plus } from "../../Common/Icons";
import { Typography } from "../../Common/Typography/Typography";
import cn from "classnames";

export const AddAdditionalServices = ({ classNames, openServices }) => {
  return (
    <div className={cn("add-services", classNames)} onClick={openServices}>
      <Plus />
      <Typography
        text="add additional services"
        uppercase
        variant="span"
        className="add-services_text"
      />
    </div>
  );
};
