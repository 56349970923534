import React from "react";
import { Typography } from "../../Common/Typography/Typography";
import { SideBarTime } from "./SideBarTime";

export const SideBarClock = ({ inputHour, inputMin, onChangeHour, onChangeMin }) => {
  return (
    <>
      <Typography
        text="Time"
        color="#717579"
        variant="span"
        className="side-bar__clock-typography"
      />
      <SideBarTime
        classNames="side-bar__clock"
        inputHour={inputHour}
        inputMin={inputMin}
        onChangeHour={onChangeHour}
        onChangeMin={onChangeMin}
      />
    </>
  );
};
