import {changeDateWithFormat} from "../../utils/date";
import {convertPhoneValue} from "../../utils/common";

export const getSingleFromGroupRequest = ({request, uuid}) => {
    return request?.data?.patients?.find(i => i.uuid === uuid)
}


export const mapPatients = ({request, requestsTypes}) => {
    const allSymptoms = requestsTypes?.reduce((acc, curr)=> [...acc, ...curr.symptoms], [])
    const detailsType = requestsTypes.find(i => i.name === request.service_type.replace(/_/g,'-'))
    const allPatients = request.patients.map(i => {
        const item = {
            ...i, ...i.patient_info,
            uuid: i.uuid,
            patient_uuid: i.patient_info.uuid,
            date_of_birth: changeDateWithFormat(i.patient_info.date_of_birth, true),
            phone_number: convertPhoneValue(i.patient_info.phone_number, true),
            symptoms: i.symptoms.map(j => allSymptoms.find(k => k.uuid === j)),
            status_request: i.status
        }
        delete item.patient_info
        return item
    }).sort((a, b) => a.status.localeCompare(b.status))
    return {
        ...request,
        service_type: detailsType,
        client_user_info: {
            ...request.client_user_info,
            date_of_birth: changeDateWithFormat(request.client_user_info.date_of_birth, true),
            phone_number: convertPhoneValue(request.client_user_info.phone_number, true)
        },
        allPatients: allPatients,
        patients: allPatients.filter(i => !i.is_deleted)
    }
}
