import React, {useEffect, useMemo, useState} from "react";
import "./style.scss";
import { UserData } from "../../../../../compoenentsAdditional/UserData";
import { Typography } from "../../../../Common/Typography/Typography";
import { colors } from "../../../../../assets/colors/colors";
import { getFullYearsUser } from "../../../../../utils/date";
import { Map } from "../../../../../compoenentsAdditional/Map";
import { Edit, Plus } from "../../../../Common/Icons";
import { SymptomsNotTreat } from "../../../../CreateReqest/StepSymptoms/SymptomsNotTreat";
import { PopupContainer } from "../../../../Common/Popup/PopupContainer";
import { placeholdersPCPDoctor } from "../../../../../constants/Doctors";
import { Input } from "../../../../Common/Input/Input";
import { Textarea } from "../../../../Common/TextArea/Textarea";
import {buttonsInnerText, visitNoteStatuses} from "../../../../../constants";
import {Trash} from "../../../../Common/Icons/trash";
import {getSingleFromGroupRequest} from "../../../../../store/request/utils";

export const DetailsOfRequest = ({ dataRequest, id, info, sendInfo, doctorData, deleteAdditionalInfo, role}) => {
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [popup, setPopup] = useState(false);
  const [errorAdditionalInfo, setErrorAdditionalInfo] = useState("");
  const [confirmation, setConfirmation] = useState(false)

  const updateAdditionalInfo = e => {
    setErrorAdditionalInfo("");
    setAdditionalInfo(e.target.value);
  };
  const closePopup = () => {
    setPopup(false);
    setErrorAdditionalInfo("");
  };

  const user = useMemo(()=>{
      return getSingleFromGroupRequest({request: dataRequest, uuid: id})
  },[!!dataRequest]);

  const deleteInfo = () => {
    deleteAdditionalInfo(confirmation, user.uuid).then(()=>{
      setConfirmation(false)
    })
  }

  const sendAdditionalInfo = () => {
    let error = "";
    if (!additionalInfo.length) error = "This field is required";
    else if (additionalInfo.length < 2)
      error = "Additional info min length is 2";

    if (error) {
      setErrorAdditionalInfo(error);
      return;
    }
    sendInfo(additionalInfo, info.find(i => i.uuid === popup), user.uuid).then(resp => {
      if (resp.uuid) {
        closePopup();
      }
    });
  };

  if(!user) {
    return <></>
  }

  return (
    <div className="details-of-request">
      <UserData user={user} isEmr />
      <ComponentTitleValue
        text="Phone Number"
        value={user?.phone_number}
        className="details-of-request_age"
        isEmail
      />
      <ComponentTitleValue
        text="DOB"
        value={user?.date_of_birth}
        className="details-of-request_age"
      />
      <ComponentTitleValue
        text="age"
        value={getFullYearsUser(user?.date_of_birth)}
        className="details-of-request_age"
      />
      <ComponentTitleValue
        text="gender"
        value={user?.sex}
        className="details-of-request_sex"
      />
      <Map data={dataRequest?.data} hideMap isShowSuite />
      <ComponentTitleValue
        text="email"
        value={user?.email}
        className="details-of-request_email"
        isEmail
      />
      {info.map(i => <div className="details-of-request_info">
          <Typography
              capitalize
              text="Additional information:"
              variant="p"
              color={colors.grey}
          />
          <Typography text={i.text} variant="p" color={colors.black} />
        {doctorData.uuid === i.doctor && <><Edit onClick={()=>{
          setPopup(i.uuid);
          setAdditionalInfo(i.text)

        }
        } />
        <Trash onClick={()=> setConfirmation(i.uuid)} /></>}
      </div>)}
        {role === 'doctor' && <button
          className="no-default-style-button details-of-request_additional"
          onClick={()=>{
            setPopup(true);
            setAdditionalInfo('')
          }}
        >
          <Plus />
          <Typography
            variant="h4"
            uppercase
            text="add additional information"
            color={colors.navy}
          />
        </button>}
      <PopupContainer
        textPrimary={buttonsInnerText.save}
        open={popup}
        close={closePopup}
        uppercaseButton
        actionDefaultButton={closePopup}
        title={`${typeof popup === 'string' ? 'Edit' : 'Add'} Additional Information`}
        actionPrimaryButton={sendAdditionalInfo}
        className="additional-information-text"
      >
        <Textarea
          textError={errorAdditionalInfo}
          error={errorAdditionalInfo}
          maxLength={200}
          placeholder="Additional information"
          value={additionalInfo}
          onChange={updateAdditionalInfo}
        />
      </PopupContainer>
      <PopupContainer
          close={()=>{
            setConfirmation(false)
          }}
          open={confirmation}
          textPrimary={buttonsInnerText.confirm}
          actionDefaultButton={()=>{
            setConfirmation(false)
          }}
          actionPrimaryButton={deleteInfo}
          uppercaseButton
          className='confirmation-delete-add-info'
          title="Are you sure you want to remove additional information?"
      />
    </div>
  );
};

const ComponentTitleValue = ({ text, value, isEmail }) => {
  return(
    <div className="container-title-value">
      <Typography capitalize text={`${text}: `} variant="p" color={colors.grey} />
      <Typography capitalize={!isEmail} text={value} variant="title" color={isEmail ? colors.navy : colors.black} />
    </div>
)};
