import {routesCommon} from "../../constants";
import {COLORS_BTN} from "../../components/Common/ButtonV2/constants";

export const requestsTypesFields = {
    common: {
        viewLabel: () => 'Sick Visit',
    },
    covid: {
        viewLabel: () => 'Ancillary Services',
    },
    pcr_covid: {
        viewLabel: () => 'Rapid RT-PCR Test',
    },
    urgent_pcr_covid: {
        viewLabel: () => 'Urgent RT-PCR Test',
    },
    rapid_antibody_test: {
        viewLabel: () => 'Rapid Antibody Test',
    },
    wellness_visit: {
        viewLabel: (isAdmin) => isAdmin ? 'Comprehensive' : 'Check-up/Wellness Visit',
    },
    std_visit: {
        viewLabel: () => 'STD Visit',
    },
    auxiliary_visit: {
        viewLabel: () => 'Auxiliary visit',
    }
}

export const NAVIGATION_TYPES_REQUEST = {
    COMMON: 'common',
    COVID: 'covid',
    PCR_COVID: 'pcr-covid',
    URGENT_PCR_COVID: 'urgent-pcr-covid',
    RAPID_ANTIBODY_TEST: 'rapid-antibody-test',
    WELLNESS_VISIT: 'wellness-visit',
    STD_VISIT: 'std-visit',
    AUXILIARY_VISIT: 'auxiliary_visit',
}


export const requestsTypesFieldsPatient = {
    common: {
        urlNav: `${routesCommon.create_request}/${NAVIGATION_TYPES_REQUEST.COMMON}`,
        color: COLORS_BTN.PRIMARY
    },
    covid: {
        urlNav: `${routesCommon.create_request}/${NAVIGATION_TYPES_REQUEST.COVID}`,
        color: COLORS_BTN.DEFAULT

    },
    'pcr-covid': {
        urlNav: `${routesCommon.create_request}/${NAVIGATION_TYPES_REQUEST.PCR_COVID}`,
    },
    //NEW
    'urgent-pcr-covid': {
        urlNav: `${routesCommon.create_request}/${NAVIGATION_TYPES_REQUEST.URGENT_PCR_COVID}`,
        color: COLORS_BTN.PRIMARY
    },
    'rapid-antibody-test': {
        urlNav: `${routesCommon.create_request}/${NAVIGATION_TYPES_REQUEST.RAPID_ANTIBODY_TEST}`,
        color: COLORS_BTN.PRIMARY
    },
    'wellness-visit': {
        urlNav: `${routesCommon.create_request}/${NAVIGATION_TYPES_REQUEST.WELLNESS_VISIT}`,
        color: COLORS_BTN.PRIMARY
    },
    'std-visit': {
        urlNav: `${routesCommon.create_request}/${NAVIGATION_TYPES_REQUEST.STD_VISIT}`,
        color: COLORS_BTN.PRIMARY
    },
    'auxiliary-visit': {
        urlNav: `${routesCommon.create_request}/${NAVIGATION_TYPES_REQUEST.AUXILIARY_VISIT}`,
        color: COLORS_BTN.PRIMARY
    }
}
