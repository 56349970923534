export const routesRequest = {
  symptoms: "/symptoms",
  time: "/time",
  login: "/login",
  user_info: "/user-info",
  patient_info: "/patient-info",
  address: "/address",
  review: "/review",
  patients: "/patients",
  review_cards: '/cards'
};

export const sex = ["male", "female"];
export const choice = ["Yes", "No"];
export const placeholdersUserInfo = {
  lastName: "Last Name",
  firstName: "First Name",
  birthday: "Date of Birth",
  phone: "Cell Phone #",
  email: "Email",
  sex: "Gender",
  homeAddress: 'Home Address',
  suite: 'Suite'
};

export const placeholdersPatients = {
  lastName: "Last Name",
  firstName: "First Name",
  birthday: "Date of Birth",
  phone: "Cell Phone #",
  email: "Email",
  sex: "Gender",
  relationship: "What's Your Relationship To The Patient?"
};

export const placeholdersAddress = {
  zipcode: "Zip Code",
  address: "Address",
  apartment: "Suite",
  floor: "Floor",
  comment: 'Additional Info'
};

export const placeholdersMultipleVisit = {
  assignTo: 'Assign To',
  type: 'Visit Type',
  date: 'Date',
  timeStart: 'Start',
  timeEnd: 'End'
};

export const buttonsText = {
  cancel: "Cancel",
  next: "Next",
  previous: "Previous",
  contact: "Contact Us",
  notify: "Yes,Notify me",
  submit: "Submit",
  newPatient: "Add New Patient"
};

export const otherSymptomsText = "Please Describe How You’re Feeling";
export const extraSymptomsText =
  "Any Other Medical Information For the Doctor?";
