export const colors = {
  core70: '#5E6878',
  core40: '#BAC0C9',
  primary20: '#E6ECF9',
  greyDisable: "rgba(0,0,0,0.4)",
  blackMuted: "rgba(0, 0, 0, 0.87)",
  grey: "#717579",
  navy: "#1e427b",
  lightBlue: "#F3F6FC",
  white: "#FFFFFF",
  lightGrey: "#DDE0E4",
  lightGreyMuted: "rgba(238, 238, 238, 0.4)",
  black: "#090A0B",
  darkMuted: "rgba(0, 0, 0, 0.6)",
  orangeMuted: "rgba(255, 0, 0, 0.6)",
  greyDark: "#70757A",
  greyShuttle: "#5F6368",
  blackRussian: "#202124",
  redError: "#B00020",
  greyMiddle: "#777777",
  greyDeep: "#515151",
  tuna: "#494A4B",
  blueIce: "#DCECF9",
  navyBlue: "#357DBD",
  steel: "#757575",
  sea: "#2758A4",
  denim: "#0C1A31",
  blueAliceLight: "#E6ECF9",
  blueAlice: "#E6ECF9",
  green: "#7CC44F",
  greyStatus: "#979797",
  redDisabled: "#DE2121",
  yellow: "#E6E92A",
  blueTropaz: "#296193",
  elfName: "#166438",
  offGreen: "#CDF4DE",
  pattensBlue: "#E8F1F8",
  apricot: "#FBEBC5",
  peach: '#D3970D',
  lightRed: "#FDC4C4",
  redAlarm: "#CE1212"

};
