import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    data: null,
    isLoaded: false,
}

const requestPatientsSlice = createSlice({
    name: 'requestPatientsSlice',
    initialState,
    reducers: {
        setPatientsData: (state, {payload}) => {
            state.data = payload
            state.isLoaded = true;
        },
        clearPatientsData: (state) => {
            state.data = null;
            state.isLoaded = false;
        },
    }
})

export const {setPatientsData, clearPatientsData} = requestPatientsSlice.actions
export const requestPatientsReducer = requestPatientsSlice.reducer
