import * as components from "./components";
import {PatientData} from "./components/PatientData";

export const template = {
    name: 'Sunburn',
    body: {
        body: [
            {
                type: "label",
                data: "PRESENTING PROBLEM:",
                items: [
                    {
                        type: "text",
                        data: 'Sunburn',
                        editable: true,
                        source: null
                    },
                ]
            },
            {
                type: "label",
                data: "HISTORY OF THE PRESENT ILLNESS:",
                required: true,
                items: [
                    ...PatientData(),
                    {
                        type: "text",
                        data: 'who reports pain in sun-exposed areas of body after prolonged exposure',
                        editable: true,
                        source: null
                    },
                    {
                        type: "input",
                        placeholder: "duration",
                        data: "",
                    },
                    {
                        type: "text",
                        data: '. Otherwise:',
                        editable: true,
                        source: null
                    },
                    {
                        type: "checkbox",
                        data: [
                            {checked: false, data: "fever"},
                            {checked: false, data: "vomiting"},
                            {checked: false, data: "altered mental status"},
                        ]
                    },
                    {
                        type: "text",
                        data: '.',
                        editable: true,
                        source: null
                    },
                ]
            },
            components.TetanusStatus(),
            components.ReviewOfSystem(),
            components.PMH(),
            components.GYM(),
            components.FamSocHistory(),
            components.Medications(),
            components.Allergies(),
            components.PhysicianExamination({
                items: [
                    {
                        type: "label",
                        data: "Generalized Appearance:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Alert, cooperative, in',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-block",
                                data: [{checked: false, data: "no"},
                                    {checked: false, data: "minimal"},
                                    {checked: false, data: "moderate"},
                                    {checked: false, data: "severe"}
                                ]
                            },
                            {
                                type: "text",
                                data: 'distress.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    components.VitalSigns(),
                    {
                        type: "label",
                        data: "Skin:",
canDelete: true,
                        required: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Diffuse erythema of',
                                editable: true,
                                source: null
                            },
                            {
                                type: "input",
                                placeholder: "location",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'with',
                                editable: true,
                                source: null
                            },{
                                type: "radio-block",
                                data: [
                                    {checked: false, data: "no"},
                                    {checked: false, data: "ruptured"},
                                ]
                            },
                            {
                                type: "text",
                                data: 'vesicles',
                                editable: true,
                                source: null
                            },
                            ...components.RenderRadioSingleBLocks(
                                ['cellulitis']),
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    {
                        type: "label",
                        data: "Head:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            ...components.RenderRadioSingleBLocks(['scalp swelling', 'tenderness']),
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    components.Eyes(components.RenderRadioSingleBLocks(['scleral icterus'], true)),
                    {
                        type: "label",
                        data: "ENMT:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Pharynx:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "erythema"}]
                            },
                            {
                                type: "text",
                                data: '; airway patent:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "stridor"}]
                            },
                            {
                                type: "text",
                                data: '; mucous membranes moist.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    {
                        type: "label",
                        data: "LYMPHATIC:",
                        canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            ...components.RenderRadioSingleBLocks(['lymphangitis']),
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    components.ChestRespiratory(),
                    components.HeartCardiovascular(),
                    {
                        type: "label",
                        data: "Abdomen/GI:", canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Soft;',
                                editable: true,
                                source: null
                            },
                            ...components.RenderRadioSingleBLocks(
                                ['tenderness', 'guarding',
                                    'rebound', 'palpable masses', 'CVA tenderness']),
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    components.Extremities(),
                    {
                        type: "label",
                        data: "Neuro/Psych:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Mental status as above;',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "focal findings"}]
                            },
                            {
                                type: "text",
                                data: '.', editable: true,
                                source: null
                            },
                        ]
                    },
                ]
            }),
            {
                type: 'label',
                data: 'TREATMENT',
                required: true,
                items: [
                    {
                        type: 'blockCheckboxCondition',
                        extraType: 'treatment',
                        data: {
                            checkbox: [{checked: true, data: '', isHide: true}],
                            hiddenBlock: [
                                {
                                    type: "text",
                                    data: 'Tetanus prophylaxis',
                                    editable: true,
                                    source: null
                                },
                                {
                                    type: "radio-block",
                                    data: [{checked: false, data: "was"}, {checked: false, data: "was not"}]
                                },
                                {
                                    type: "text",
                                    data: 'administered.',
                                    editable: true,
                                    source: null
                                },
                                {
                                    type: "input",
                                    placeholder: "",
                                    data: "",
                                }
                            ]
                        }
                    },
                ]
            },
            components.AssessmentPlan([ {
                type: 'blockCheckboxCondition',
                extraType: 'assessmentContent',
                data: {
                    checkbox: [{checked: true, data: '', isHide: true}],
                    hiddenBlock: [
                        {
                            type: "text",
                            data: 'Follow up with',
                            editable: true,
                            source: null
                        },
                        {
                            type: "radio-block",
                            data: [{checked: true, data: "PCP"},
                                {checked: false, data: "dermatologist"}]
                        },{
                            type: "text",
                            data: 'or return for fever, vomiting or other new symptoms.',
                            editable: true,
                            source: null
                        }
                    ]
                }
            }]),
        ]
    }
}
