import React, {useMemo} from "react";
import cn from 'classnames';
import "./style.scss";
import {arrayFromNumber} from "../../utils/common";
import {COLORS_BTN} from "../../components/Common/ButtonV2/constants";
import {ButtonV2} from "../../components/Common/ButtonV2";
import {BTN_LABELS} from "../../constants";
import patientAvatar from "../../dist/images/accountAvatar.svg";
import {patientHideInfoOtherPatient} from "../../components/Common/Tooltip/constants";
import {Error} from "@material-ui/icons";
import {Tooltip} from "../../components/Common/Tooltip";

export const CountPatient = ({activeMode, number, onClick, maxView = 3, showNote}) => {
    const arr = useMemo(()=>{
        return arrayFromNumber(number)
    }, [number])
    const numberMore = +number - maxView

    if(!number) return <></>
    return (
       <div className={cn('count-patients', {'count-patients_active': activeMode, 'count-patients_with-note': showNote})}>
           {activeMode && <div className='count-patients_header'>
               Patients
           </div>}
           <div className='count-patients_body'>
               <div className='count-patients_body_patients'>
                   {arr.slice(0, maxView).map(i => <img src={patientAvatar}/>)}
                   {numberMore > 0 &&  (showNote ? <Tooltip title={patientHideInfoOtherPatient}>
                       <span>+ {numberMore} more</span></Tooltip> : <span>+ {numberMore} more</span>)}
               </div>
               {activeMode && <ButtonV2 onClick={onClick} uppercase color={COLORS_BTN.PRIMARY}>{BTN_LABELS.SHOW_ALL}</ButtonV2>}
           </div>
       </div>
    );
};
