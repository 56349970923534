const test = [
    {
        type: "label",
        data: "HISTORY OF THE PRESENT ILLNESS:",
        required: true,
        items: []
    },
    {
        type: "text",
        data: null,
        editable: false,
        source: "patient.first_name"
    },
    {
        type: "text",
        data: 'y.o.',
        editable: true,
        source: null
    },
    {
        type: "radio-single",
        data: [{checked: false, data: "mild"}]
    },
    {
        type: "radio-block",
        data: [{checked: false, data: "mild"}]
    },
    {
        type: "checkbox",
        data: [
            {checked: false, data: "alert"},
            {checked: false, data: "oriented and in no distress"},
        ]
    },
    {
        type: "selector",
        data: {value: 'negative', label: 'negative'},
        multi: false,
        label: 'Leukocyte esterase',
        options: [
            {value: 'negative', label: 'negative'},
            {value: 'trace', label: 'trace'},
            {value: '+', label: '+'}
        ],
    },
    {
        type: "input",
        placeholder: "Location (arm / leg)",
        data: "",
    },
    {
        type: 'input-static-text',
        label: 'BP:*',
        data: [
            {data: '', mask: '999', min: 50, max: 250},
            {data: '', mask: '999', min: 20, max: 150}],
        deviders: ["/"],
        nameBE: 'blood_pressure'
    },
    {
        type: 'pastMedicalHistory',
        textData: [{placeholder: '', data: ''}],
    },
    {
        type: 'familySocialHistory',
        textData: [{placeholder: '', data: ''}]
    },
    {
        type: 'medications',
        textData: [{placeholder: '', data: ''}]
    },
    {
        type: 'medicationAllergies',
        textData: [{placeholder: '', data: ''}]
    },
    {
        type: 'diagnoses',
        textData: [{placeholder: '', data: ''}]
    },
    {
        type: 'medicationsPrescribed',
        textData: [{placeholder: '', data: ''}]
    },
    {
        type: 'blockCheckboxCondition',
        extraType: 'vitalSigns', //not required
        data: {
            notUseDisable: true, //not required
            checkbox: [{checked: false, data: 'For woman', isHide: false}],
            hiddenBlock: [
                {
                    type: "checkbox",
                    data: [
                        {checked: false, data: "LMP", datepicker: {data: null}},
                        {checked: false, data: "NO period"},
                        {checked: false, data: "Contraception", inputText: {data: ''}}
                    ]
                },
            ]
        }
    },
]

export const configPMHExtra = {
    diagnoses: {
        urlOptions: 'electronic-medical-record/icd-10-list/',
        proceedOptions: (options) => options.map(i => ({value: i.name, code: i.code, label: i.name + ` (${i.code})`})),
        sendToBe: (i) => ({name: i.label, code: i.code}),
    },
    medicationsPrescribed: {
        urlOptions: null,
        sendToBe: (i) => i.label,
        proceedOptions: (options) => options.map(i => ({value: i.name, code: i.code, label: i.name}))

    },
}

export const configPMH = {
    pastMedicalHistory: {
        textAreaData: '',
        fieldDataTextArea: 'comment',
        placeholderTextArea: 'Additional Information',
        urlOptions: 'electronic-medical-record/icd-10-list/',
        textAddButton: 'PAST MEDICAL HISTORY',
        sendToBe: (i) => ({name: i.label, code: i.code}),
        proceedOptions: (options) => options.map(i => ({value: i.name, code: i.code, label: i.name + ` (${i.code})`}))
    },
    familySocialHistory: {
        creatable: true,
        urlOptions: 'electronic-medical-record/past-medical-history/family-social-history-choices/',
        textAddButton: 'FAMILY AND SOCIAL HISTORY',
        sendToBe: (i) => i.label,
        proceedOptions: (options) => options.map(i => ({value: i.name, code: i.code, label: i.name}))

    },
    medications: {
        urlOptions: null,
        textAreaData: '',
        fieldDataTextArea: 'appointedMedications',
        placeholderTextArea: 'Medication',
        textAddButton: 'MEDICATIONS',
        sendToBe: (i) => i.label,
        proceedOptions: (options) => options.map(i => ({value: i.name, code: i.code, label: i.name}))

    },
    medicationAllergies: {
        urlOptions: null,
        textAreaData: '',
        fieldDataTextArea: 'appointedAllergies',
        placeholderTextArea: 'Allergies',
        textAddButton: 'ALLERGIES',
        sendToBe: (i) => i.label,
        proceedOptions: (options) => options.map(i => ({value: i.name, code: i.code, label: i.name}))

    },
}

export const updateArrJson = ({newData, idxChild, idxParent, jsonTree}) => {
    return jsonTree.map((i, idx) => idx === idxParent ? {
        ...i,
        items: i.items.map((y, ydx) => ydx === idxChild ? newData : y).filter(i => i)
    } : i).filter(i => i.items?.length)
}

export const checkIsVitalRequired = data => {
    const dataLength = data.data.length;
    const emptyLength = data.data.filter(i => !i.data).length;
    const filledLength = data.data.filter(i => i.data).length;
    return data.noMandatory && emptyLength !== dataLength && filledLength !== dataLength;
}



export const assessmentsPlanKeys = {
    medications: 'medications',
    diagnosis: 'diagnosis',
    textAreaAssessmentPlan: 'txtAssessmentPlan',
    txtFirstPart: 'txtFirstPart',
    assessments_plan: 'assessments_plan'
}
