import React, {useState} from "react";
import './style.scss'
import {Typography} from "../../Common/Typography/Typography";
import {colors} from "../../../assets/colors/colors";
import {RangeDatePicker} from "../../Common/RangeDatePicker/RangeDatePicker";
import moment from "moment";
import {buttonsInnerText} from "../../../constants";
import {Button} from "../../Common/Button/Button";
import fileDownload from "js-file-download";
import {downloadFileGift} from "../../../store/admin/actions";

export const GiftCards = () => {
    const [dateTo, setDateTo] = useState(moment().toDate())
    const [dateFrom, setDateFrom] = useState(moment().add(-30, 'day').toDate())

    const onChangeDate = (field, value) => {
        if(field === 'dateTo'){
            setDateTo(value)
        }else{
            setDateFrom(value)
        }
    }

    const download = () => {
        downloadFileGift({
            date_of_usage_before: moment(dateTo).format('YYYY-MM-DD'),
            date_of_usage_after: moment(dateFrom).format('YYYY-MM-DD')
        }).then((resp)=>{
            resp.size && fileDownload(resp, `Used_codes_${moment().format('YYYY-MM-DD, HH:mm')}.xls`)
        })

    }
    return <div className='gift-cards-container'>
        <Typography
            capitalize
            variant='p'
            color={colors.grey}
        text='select period'/>
        <RangeDatePicker
            onChange={onChangeDate}
            dateTo={dateTo}
            dateFrom={dateFrom}
            maxDateTo={moment().toDate()}
        maxDateFrom={dateTo}
        />
        <Button
            text='download statistic'
            size="middle"
            variant="primary"
            capitalize
            onClick={download}
        />
    </div>
}
