import { createSlice } from '@reduxjs/toolkit'
import {MODES_ADDITIONAL_LOADING} from "./constants";
import moment from "moment";
import _ from 'lodash'

const initialState = {
    data: null,
    countRequestInLine: 3,
}

const requestsSlice = createSlice({
    name: 'requestsSlice',
    initialState,
    reducers: {
        setRequestsData: (state, {payload}) => {
            state.data = payload
        },
        addPartData: (state, {payload: {mode, data}}) => {
            if (mode === MODES_ADDITIONAL_LOADING.TOP) state.data = {
                timestamp: data.timestamp,
                data: _.uniqBy(data.data.concat(state.data?.data || []), 'uuid')
            }
            else state.data = {timestamp: data.timestamp, data: _.uniqBy(state.data.data.concat(data.data), 'uuid')}
        },
        updateStatusMessRequest: (state, {payload, ...g}, t) => {
            const func = (i) => i.uuid === payload.uuid ? ({
                ...i,
                service_request_chat: {...i.service_request_chat, has_unread_messages: payload.mess}
            }) : i
            const funcAll = (i) => ({
                ...i,
                service_request_chat: {...i.service_request_chat, has_unread_messages: false}
            })
            let data = state.data?.data
            if(payload.allRead) data = data?.map(funcAll)
            else data = data?.map(func)
            state.data = {timestamp: moment().format(), data: data}
        },
        updateRequestsStatuses: (state, { payload }) => {
            const func = i => i.uuid === payload.uuid ? ({ ...i, service_request_status: payload.status, status: 'accepted' }) : i;
            const data = state.data?.data.map(func);
            state.data = { timestamp: moment().format(), data: data };
        },
        clearRequestsData: (state, {payload}) => {
            state.data = null
        },
        updateCountRequestInList: (state, {payload}) => {
            state.countRequestInLine = payload
        }
    }
})

export const {setRequestsData, addPartData, clearRequestsData, updateStatusMessRequest, updateRequestsStatuses, updateCountRequestInList} = requestsSlice.actions
export const requestsReducer = requestsSlice.reducer
