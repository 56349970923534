import React, { useMemo, useState } from "react";
import "./style.scss";
import { Typography } from "../../../../../Common/Typography/Typography";
import { colors } from "../../../../../../assets/colors/colors";
import cn from "classnames";
import { AdditionalServices } from "../../../../../../compoenentsAdditional/AdditionalServices";
import { EMRSidebar } from "../../../EMRSidebar/EMRSidebar";
import { getSingleFromGroupRequest } from "../../../../../../store/request/utils";
import { SignatureContainer } from "../../../../../SideBarRequest/components";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { IconButton } from "../../../../../Common/IconButton";

export const RequestDetailsOther = props => {
    const [open, setOpen] = useState(false);
    const [needRefresh, setNeedRefresh] = useState(false);

    const targetRequest = useMemo(()=>{
        return getSingleFromGroupRequest({request: props.dataRequest, uuid: props.id})
    },[props.dataRequest?.timestamp]);

    const comment = targetRequest?.doctor_comment;
    const commentPatient = targetRequest?.comment;
    const additionalComment = props.dataRequest?.data?.location?.comment;
    const canAdd = props.role !== 'admin' && props.dataRequest?.data?.doctor_data.uuid === props.doctorData?.uuid;

    return (
        <>
            <div
                className={cn("request-details-other", {
                    "request-details-other_open": open
                })}
            >
                <div className="request-details-other_header">
                    <Typography
                        variant="p"
                        text="Request details"
                        color={colors.greyMiddle}
                    />
                    <IconButton className="request-details-other_header_icon" onClick={() => setOpen(!open)}>
                        {open ? <ExpandLess/> : <ExpandMore/>}
                    </IconButton>
                </div>
                <div className="request-details-other_body">
                    <div className="request-details-other_body_symptoms">
                        {targetRequest?.symptoms.map((i, index) => (
                            <Typography
                                key={index}
                                color={colors.black}
                                background={colors.pattensBlue}
                                paddingSide={15}
                                variant="title"
                                text={i.name}
                            />
                        ))}
                    </div>
                    <div className="separator"/>
                    <AdditionalServices
                        noLabel
                        services={targetRequest?.additional_services}
                    />
                    {commentPatient && (
                        <div className="request-details-other_body_comment">
                            <Typography
                                variant="p"
                                text="Patient’s note"
                                color={colors.greyMiddle}
                            />
                            <comment>{commentPatient}</comment>
                        </div>
                    )}
                    {comment && (
                        <div className="request-details-other_body_comment">
                            <Typography
                                variant="p"
                                text="Doctor’s note"
                                color={colors.greyMiddle}
                            />
                            <comment>{comment}</comment>
                        </div>
                    )}
                    {additionalComment && (
                        <div className="request-details-other_body_comment">
                            <Typography
                                variant="p"
                                text="Additional Comment"
                                color={colors.greyMiddle}
                            />
                            <comment>{additionalComment}</comment>
                        </div>
                    )}
                    <EMRSidebar
                        data={props?.dataRequest}
                        setStatusAndArrive={props.setStatusAndArrive}
                        doctorData={props.doctorData}
                        isFromEmr
                        setNeedRefresh={setNeedRefresh}
                    />
                </div>
            </div>
            <SignatureContainer
                canAdd={canAdd}
                data={props.dataRequest?.data.patients.find(i => i.uuid === props.id)}
                detailsRequest={props.dataRequest?.data}
                needRefresh={needRefresh}
            />
        </>
    );
};
