import React from "react";
import Popup from "reactjs-popup";
import "./style.scss";
import { Button } from "../Button/Button";
import { Cross } from "../Icons";
import { Typography } from "../Typography/Typography";
import { buttonsInnerText } from "../../../constants";
import cn from "classnames";
import {CustomSaveButton} from "../CustomSaveButton";
import {toast} from "react-toastify";
import {ButtonV2} from "../ButtonV2";
import {COLORS_BTN, VARIATIONS_BTN} from "../ButtonV2/constants";
import {IconButton} from "../IconButton";
{
  /*<PopupContainer open={}*/
}
{
  /*                close={}*/
}
{
  /*                actionDefaultButton={}*/
}
{
  /*                actionPrimaryButton={}*/
}
{
  /*                extraTitle={}*/
}
{
  /*                title={}*/
}
{
  /*                textDefault={}*/
}
{
  /*                textPrimary={}>*/
}

{
  /*</PopupContainer>*/
}
export const PopupContainer = ({
  close,
  open,
  title,
  extraTitle,
  children,
  hideCancel,
  capitalize,
  textDefault = buttonsInnerText.cancel,
  textPrimary = buttonsInnerText.save,
  actionDefaultButton,
  actionPrimaryButton,
  disabledButtonDefault,
  disabledButtonPrimary,
  uppercaseButton,
  className,
  customSaveButton,
    footer
}) => {
  const textButtonDefault = textDefault;
  const textButtonPrimary = textPrimary;

  const clickDefaultButton = () => {
    actionDefaultButton();
  };

  const clickPrimaryButton = () => {
    actionPrimaryButton();
  };

  const onClose = () => {
    close();
  };

  return (
    <Popup
      open={open}
      onClose={onClose}
      closeOnDocumentClick={false}
      className={cn("popup-component-common", className)}
      overlayStyle={{
        zIndex: 1010
      }}
      contentStyle={{
        padding: 30,
        width: 500,
        borderRadius: 4,
        background: "#FFFFFF",
      }}
    >
      <div className="popup-component-common-content_header">
        <div className="popup-component-common-content_header_wrapper-titles">
          <Typography
            variant="h3"
            text={title}
            className="app-container-request_header-panel_wrapper-titles_title"
          />
          <Typography
            variant="h3"
            text={extraTitle}
            className="app-container-request_header-panel_wrapper-titles_title"
          />
        </div>
      </div>
      <IconButton className='popup-component-common-content_close' onClick={onClose}/>
      <div className="popup-component-common-content_body">{children}</div>
      <div className="popup-component-common-content_footer">
        {footer ? footer : <> {textButtonDefault && !hideCancel && (
            <ButtonV2 capitalize={capitalize}
                      disabled={disabledButtonDefault}
                      uppercase={uppercaseButton}
                      onClick={clickDefaultButton}
                      variant={VARIATIONS_BTN.CONTAINED}>{textButtonDefault}</ButtonV2>
        )}
        {textButtonPrimary && (customSaveButton ? (
            <CustomSaveButton onClick={actionPrimaryButton}/>
        ) : (
            <ButtonV2 capitalize={capitalize}
                      disabled={disabledButtonPrimary}
                      uppercase={uppercaseButton}
                      color={COLORS_BTN.PRIMARY}
                      onClick={clickPrimaryButton}
                      variant={VARIATIONS_BTN.CONTAINED}>{textButtonPrimary}</ButtonV2>
        ))}</>}
      </div>
    </Popup>
  );
};
