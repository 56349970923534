import React, {useState} from 'react'
import _ from "lodash";
import {Element} from "../../chooserElements";
import {modesTemplate} from "../../../../../constants/Doctors";

export const Treatment = ({data, updateState, error, DbData}) => {
    const [config, setConfig] = useState(data)
    const updateTreatment = (value, index) => {
        setConfig({...config, data: config.data.map((i, idx) => idx === index ? value : i)})
    }
    return <span onBlur={()=> updateState(config)}>
        {config.data.map((i, index) => <Elements type={i.type}
                                               data={i}
                                                 mode={modesTemplate.render}
                                               index={index}
                                               DbData={DbData}
                                               updateState={(value) => updateTreatment(value, index)}
                                               error={error}/>
                                               )}
    </span>
}


const Elements = React.memo(
    ({...props}) => <Element {...props}/>,
    (prev, next) => _.isEqual(prev.data, next.data) && prev.error === next.error
)
