import React, { useState } from "react";
import { Typography } from "../../Common/Typography/Typography";
import Timer from "react-compound-timer";
import cn from "classnames";
import { ASSIGNED } from "../../../constants";
import moment from "moment";
import { durationTimeLaterRequest } from "../../../utils/date";
import { formatValueTime } from "../../../utils/common";
import { Edit } from 'components/Common/Icons'

export const ApproximateEta = ({
  setNewTime,
  initialTime,
  className,
  startTimer,
  data,
  editable,
  openTimerEditor,
  showWhenEdit,
  isTimerEditorOpen,
                                 isFromEmr
}) => {
  const [openTimer, setOpenTimer] = useState(true);
  const [timeZero, setTimeZero] = useState(false);

  const timeLaterRequest =
    data &&
    durationTimeLaterRequest(
      data.application_can_start_at,
      data.application_time
    );

  const dataLaterRequest =
    data &&
    moment(data.application_can_start_at)
      .format("ll")
      .slice(0, 6);

  const timeLateCondition =
    !openTimer &&
    (data.status === ASSIGNED || data.status === "pending") &&
    moment(data.application_can_start_at) > moment() && !timeZero;

  return (
      <div className="approximate-eta-container">
          {(!isTimerEditorOpen || showWhenEdit) && !!initialTime && (
              <div className={cn("approximate-eta", className)}>
                  <Typography text="Approximate ETA" variant="span" />
                  <Timer
                      checkpoints={[
                          {
                              time: 0,
                              callback: () => {
                                  setTimeZero(true)
                                  setOpenTimer(false)
                              }
                          }
                      ]}
                      initialTime={initialTime}
                      direction="backward"
                      startImmediately={true}
                      timeToUpdate={10}
                  >
                      {({ start, setTime }) => {
                          setNewTime(setTime);
                          startTimer(start);
                          return (
                              <div className="approximate-eta_container">
                                  <span className="approximate-eta_time">
                                    <Timer.Hours formatValue={formatValueTime}/>
                                    <Typography text="H" variant="p"  className="approximate-eta_time__unit">H</Typography>
                                    <Timer.Minutes formatValue={formatValueTime}/>
                                    <Typography text="min" variant="p"  className="approximate-eta_time__unit">min</Typography>
                                    <Timer.Seconds formatValue={formatValueTime}/>
                                    <Typography text="sec" variant="p" className="approximate-eta_time__unit">sec</Typography>
                                  </span>
                              </div>
                          );
                      }}
                  </Timer>
                  {editable && !isFromEmr && (
                      <div className="approximate-eta__edit-button">
                          <Edit onClick={openTimerEditor} />
                      </div>
                  )}
              </div>
          )}
          {timeLateCondition && (
              <div className={cn("approximate-eta", className)}>
                  <Typography text={dataLaterRequest} variant="span" />
                  <div className="approximate-eta_container-extra">
            <span className="approximate-eta_time">
              {`${timeLaterRequest.timeOfStart} - ${timeLaterRequest.timeOfEnd}`}
            </span>
                      <Typography text={timeLaterRequest.periodOfEnd.toLowerCase()} variant="span" />
                  </div>
              </div>
          )}
      </div>
  )
};
