import React, {useEffect, useRef, useState} from "react";
import Popup from "reactjs-popup";
import "./style.scss";
import {Button} from "../../../../../Common/Button/Button";
import {Cross} from "../../../../../Common/Icons";
import {Typography} from "../../../../../Common/Typography/Typography";
import {buttonsInnerText, PLACEHOLDERS} from "../../../../../../constants";
import cn from "classnames";
import {Input} from "../../../../../Common/Input/Input";
import {Textarea} from "../../../../../Common/TextArea/Textarea";

export const CreatorEditorFollowUp = ({
                                          close,
                                          open,
                                          saveFollowUp,
                                          disabledButtonDefault,
                                          disabledButtonPrimary,
                                          data,
                                          mode
                                      }) => {
    const [name, setName] = useState("");
    const [text, setText] = useState("");
    const [nameError, setNameError] = useState("");
    const [textError, setTextError] = useState("");


    useEffect(() => {
        if (open && data) {
            setName(data.name);
            setText(data.content);
        } else {
            setName("");
            setText("");
        }
    }, [open]);

  const clickDefaultButton = () => onClose();

  const clickPrimaryButton = () => {
    let errorName = "";
    let errorText = "";
    if (!name) errorName = "Please fill in all the required fields.";
    if (!text) errorText = "Please fill in all the required fields.";
    if (name.length < 2) errorName = "Follow up Name min length is 2";
    if (text.length < 2) errorText = "Follow up min length is 2";

        if (errorName || errorText) {
            setNameError(errorName);
            setTextError(errorText);
        } else {
            saveFollowUp(name, text);
        }
    };

  const onClose = () => {
    close();
  };

    return (

            <Popup
                open={open}
                onClose={() => {
                }}
                closeOnDocumentClick={false}
                className="follow-up"
                overlayStyle={{
                    zIndex: 1000,
                    top: -64
                }}
                contentStyle={{
                    width: "100%",
                    height: "100%",
                    background: "#FFFFFF"
                }}
            >
                <div className="follow-up-content_header">
                    <Typography
                        variant="h2"
                        text="Follow Up"
                        className="app-container-request_header-panel_wrapper-titles_title"
                    />
                    <Cross
                        onClick={onClose}
                        className="follow-up-content_header_close-icon"
                    />
                </div>
                <div className="follow-up-content_body">
                    <Input
                        maxLength={200}
                        placeholder="Follow up name"
                        value={name}
                        error={nameError}
                        onChange={(field, value) => {
                            setNameError("");
                            setName(value);
                        }}
                    />
                    <Textarea
                        placeholder="Follow up"
                        value={text}
                        maxLength={50000}
                        textError={textError}
                        error={textError}
                        onChange={e => {
                            setTextError("");
                            setText(e.target.value);
                        }}
                    />
                </div>
                <div className="follow-up-content_footer">
                    <Button
                        disabled={disabledButtonDefault}
                        text={buttonsInnerText.cancel}
                        size="middle"
                        variant="default"
                        capitalize
                        onClick={clickDefaultButton}
                    />
                    <Button
                        disabled={disabledButtonPrimary}
                        text={buttonsInnerText.save}
                        size="middle"
                        variant="primary"
                        capitalize
                        onClick={clickPrimaryButton}
                    />
                </div>
            </Popup>
    );
};
