export const VARIATIONS_BTN = {
    CONTAINED: 'contained',
    OUTLINED: 'outlined',
    TEXT: 'text'
}

export const COLORS_BTN = {
    DEFAULT: 'default',
    PRIMARY: 'primary',
    SECONDARY: 'secondary'
}

