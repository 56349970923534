import React from 'react';
import {Typography} from '../Common/Typography/Typography';
import {colors} from '../../assets/colors/colors';
import './style.scss';
import _ from 'lodash';
import {Separator} from "../SideBarRequest/components";

export const PriceSummary = ({data}) => {
    const countRequest = data?.allPatients?.length
    const promo = data.promo_codes;
    const type = data.service_type?.viewLabel;
    const giftCard = promo && promo[0] || {};
    const patientsAmount = countRequest;
    const services = _.flatten(data.patients.map(i => i.additional_services).filter(i => i.length));
    const additionalsPrice = services.filter(item => item.payment_status === 'paid')
        .map(item => +item.price)
        .reduce((prev, cur) => prev + cur, 0);
    let servicePrice = data.service_type?.price;
    let totalPrice = servicePrice * patientsAmount + additionalsPrice;
    if (giftCard.price) {
        totalPrice -= giftCard.price;
        if (totalPrice < 0) {
            totalPrice = 0;
        }
    }

    return (
        <div className="price-summary">
            {!!giftCard?.price && (
                <div className="price-summary__row">
                    <div>
                        <Typography
                            color="grey"
                            text="Gift card: "
                        />
                        <Typography
                            color="black"
                            text={giftCard.title}
                        />
                    </div>
                    <Typography
                        text={`-$${parseFloat(giftCard.price)}`}
                        color={colors.navy}
                    />
                </div>
            )}
            <div className="price-summary__row">
                <div>
                    <Typography
                        capitalize
                        color="grey"
                        text="Type: "
                    />
                    <Typography
                        capitalize
                        color="black"
                        text={type}
                    />
                </div>
                {countRequest > 1 && <Typography
                    color={colors.navy}
                    text={`${countRequest}x`}
                />}
                <Typography
                    text={`$${data?.visit_price}`}
                    color={colors.navy}
                />
            </div>
            {!!additionalsPrice && (
                <div className="price-summary__row">
                    <Typography
                        color="grey"
                        text="Additional Services: "
                    />
                    <Typography
                        text={`$${additionalsPrice}`}
                        color={colors.navy}
                    />
                </div>
            )}
            <div className="price-summary__row">
                <Typography
                    color="grey"
                    text="Total: "
                />
                <Typography
                    text={`$${data?.total_visit_price}`}
                    color={colors.navy}
                />
            </div>
            {typeof data.is_charge_now === 'boolean' && <><Separator/>
                <Typography
                    className='price-summary_charge-now'
                    capitalize
                    color={colors.core70}
                    text={data.is_charge_now ? "Charge Now" : 'Charge Later'}
                />
            </>}
        </div>
    );
};
