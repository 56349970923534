import React from 'react'

export const HeartCardiovascular = (extra = []) => {
    return {
        type: "label",
        data: "Heart/Cardiovascular:",
        canDelete: true,
        items: [
            {
                type: "input",
                placeholder: "",
                data: "",
            },
            {
                type: "radio-single",
                data: [{checked: false, data: "irregularity"}]
            },
            {
                type: "text",
                data: '; ',
                editable: true,
                source: null
            },
            {
                type: "radio-single",
                data: [{checked: false, data: "murmur"}]
            },
            {
                type: "text",
                data: '; ',
                editable: true,
                source: null
            },
            {
                type: "radio-single",
                data: [{checked: false, data: "gallop"}]
            },
            ...extra,
            {
                type: "text",
                data: '.',
                editable: true,
                source: null
            },
        ]
    }
}