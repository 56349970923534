import * as components from "./components";
import {PatientData} from "./components/PatientData";
import {GroupBlockReviewOfSystem} from "./components/GroupBlockReviewOfSystems";

export const template = {
    name: 'UTI (female)',
    body: {
        body: [
            {
                type: "label",
                data: "PRESENTING PROBLEM:",
                items: [
                    {
                        type: "text",
                        data: 'Urinary symptoms',
                        editable: true,
                        source: null
                    },
                ]
            },
            {
                type: "label",
                data: "HISTORY OF THE PRESENT ILLNESS:",
                required: true,
                items: [
                    ...PatientData(),
                    {
                        type: "text",
                        data: 'who reports dysuria and urgency',
                        editable: true,
                        source: null
                    },
                    {
                        type: "input",
                        placeholder: "duration",
                        data: "",
                    },
                    {
                        type: "text",
                        data: '. Otherwise:',
                        editable: true,
                        source: null
                    },
                    {
                        type: "checkbox",
                        data: [
                            {checked: false, data: "fever"},
                            {checked: false, data: "chills"},
                            {checked: false, data: "back pain"},
                            {checked: false, data: "vomiting"},
                            {checked: false, data: "hematuria"}
                        ]
                    },
                    {
                        type: "text",
                        data: '.',
                        editable: true,
                        source: null
                    },
                ]
            },
            {
                type: "label",
                data: "REVIEW OF SYSTEMS:",
                items: [
                    GroupBlockReviewOfSystem({title: 'General:', radio: 'fever'}),
                    GroupBlockReviewOfSystem({title: 'HENT:', radio: 'congestion'}),
                    GroupBlockReviewOfSystem({title: 'Respiratory:', radio: 'cough'}),
                    GroupBlockReviewOfSystem({title: 'Cardiovascular:', radio: 'prior chest pain'}),
                    GroupBlockReviewOfSystem({title: 'GI:', radio: 'abdominal pain'}),
                    GroupBlockReviewOfSystem({title: 'GU:', radio: 'urinary complaints'}),
                    GroupBlockReviewOfSystem({title: 'Musculoskeletal:', radio: 'other aches or pains'}),
                    GroupBlockReviewOfSystem({title: 'Endocrine:', radio: 'generalized weakness'}),
                    GroupBlockReviewOfSystem({title: 'Neurological:', radio: 'localized weakness'}),
                    GroupBlockReviewOfSystem({title: 'Psychiatric:', radio: 'emotional stress'}),
                ]
            },
            components.PMH(),
            components.GYM(),
            components.FamSocHistory(),
            components.Medications(),
            components.Allergies(),
            components.PhysicianExamination({
                items: [
                    components.GeneralizedAppearance(),
                    components.VitalSigns(),
                    {
                        type: "label",
                        data: "Skin:",
canDelete: true,
                        required: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Warm, dry;',
                                editable: true,
                                source: null
                            },
                            ...components.RenderRadioSingleBLocks(
                                ['cyanosis', 'rash']),
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    components.Eyes(components.RenderRadioSingleBLocks(['scleral icterus'], true)),
                    {
                        type: "label",
                        data: "ENMT:",
canDelete: true,
                        items: [

                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: '. Pharynx:',
                                editable: true,
                                source: null
                            },
                            ...components.RenderRadioSingleBLocks(
                                ['erythema']),
                            {
                                type: "text",
                                data: '; airway patent:',
                                editable: true,
                                source: null
                            },
                            ...components.RenderRadioSingleBLocks(
                                ['stridor']),
                            {
                                type: "text",
                                data: '. Mucous membranes moist.',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "label",
                        data: "Neck:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            ...components.RenderRadioSingleBLocks( ['tenderness', 'stiffness', 'lymphadenopathy', 'thyromegaly']),
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    components.ChestRespiratory(),
                    components.HeartCardiovascular(),
                    {
                        type: "label",
                        data: "Abdomen:",
                        canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Soft;',
                                editable: true,
                                source: null
                            },
                            ...components.RenderRadioSingleBLocks(
                                ['mild suprapubic tenderness', 'guarding',
                                    'rebound', 'CVA tenderness']),
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    {
                        type: "label",
                        data: "PELVIC:",
                        canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Normal external genitalia,',
                                editable: true,
                                source: null
                            },
                            ...components.RenderRadioSingleBLocks(
                                ['vesicles', 'ulcers',
                                    'cervical discharge', 'adnexal mass or tenderness']),
                            {
                                type: "text",
                                data: '. Uterus:',
                                editable: true,
                                source: null
                            },
                            ...components.RenderRadioSingleBLocks(
                                ['enlarged', 'tenderness']),
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    components.Extremities(),
                    {
                        type: "label",
                        data: "Neuro/Psych:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Mental status as above. Cranial nerves grossly intact; strength symmetric.',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                ]
            }),
            components.TestPerformed({items:  [
                    {
                        type: 'blockCheckboxCondition',
                        data: {
                            checkbox: [{checked: false, data: 'None'}],
                            hiddenBlock: []
                        }
                    },
                    {
                        type: 'blockCheckboxCondition',
                        data: {
                            checkbox: [{checked: false, data: 'Covid 19 test -'}],
                            hiddenBlock: [
                                {
                                    type: "radio-block",
                                    data: [{checked: true, data: "Negative"},
                                        {checked: false, data: "Positive"}]
                                },
                            ]
                        }
                    },
                    {
                        type: 'blockCheckboxCondition',
                        data: {
                            checkbox: [{checked: false, data: 'UA:'}],
                            hiddenBlock: [
                                {
                                    type: "selector",
                                    data: {value: 'negative', label: 'Negative'},
                                    multi: false,
                                    label: 'Leukocyte esterase',
                                    options: [{value: 'negative', label: 'Negative'},
                                        {value: 'trace', label: 'Trace'},
                                        {value: '+', label: '+'},
                                        {value: '++', label: '++'},
                                        {value: '+++', label: '+++'}],
                                },
                                {
                                    type: "selector",
                                    data: {value: 'negative', label: 'Negative'},
                                    multi: false,
                                    label: 'Nitrates',
                                    options: [{value: 'negative', label: 'Negative'},
                                        {value: 'positive', label: 'Positive'},],
                                },
                                {
                                    type: "selector",
                                    data: {value: '0.2', label: '0.2'},
                                    multi: false,
                                    label: 'Urobilinogen',
                                    options: [{value: '0.2', label: '0.2'},
                                        {value: '1', label: '1'},
                                        {value: '2', label: '2'},
                                        {value: '4', label: '4'},
                                        {value: '8', label: '8'},
                                        {value: '12', label: '12'},],
                                },
                                {
                                    type: "selector",
                                    data: {value: 'negative', label: 'Negative'},
                                    multi: false,
                                    label: 'Proteins',
                                    options: [{value: 'negative', label: 'Negative'},
                                        {value: 'trace', label: 'Trace'},
                                        {value: '+', label: '+'},
                                        {value: '++', label: '++'},
                                        {value: '+++', label: '+++'},
                                        {value: '++++', label: '++++'},],
                                },
                                {
                                    type: "selector",
                                    data: null,
                                    multi: false,
                                    label: 'pH 0.0',
                                    options: [{value: '5.0', label: '5.0'},
                                        {value: '6.0', label: '6.0'},
                                        {value: '6.5', label: '6.5'},
                                        {value: '7.0', label: '7.0'},
                                        {value: '7.5', label: '7.5'},
                                        {value: '8.0', label: '8.0'},
                                        {value: '8.5', label: '8.5'}],
                                },
                                {
                                    type: "selector",
                                    data: {value: 'negative', label: 'Negative'},
                                    multi: false,
                                    label: 'Blood',
                                    options: [{value: 'negative', label: 'Negative'},
                                        {value: 'trace', label: 'Trace'},
                                        {value: '+', label: '+'},
                                        {value: '++', label: '++'},
                                        {value: '+++', label: '+++'}],
                                },
                                {
                                    type: "selector",
                                    data: null,
                                    multi: false,
                                    label: 'SG 1.000',
                                    options: [{value: '1.000', label: '1.000'},
                                        {value: '1.005', label: '1.005'},
                                        {value: '1.010', label: '1.010'},
                                        {value: '1.015', label: '1.015'},
                                        {value: '1.020', label: '1.020'},
                                        {value: '1.025', label: '1.025'},
                                        {value: '1.030', label: '1.030'},],
                                },
                                {
                                    type: "selector",
                                    data: {value: 'negative', label: 'Negative'},
                                    multi: false,
                                    label: 'Ketones',
                                    options: [{value: 'negative', label: 'Negative'},
                                        {value: '5', label: '5'},
                                        {value: '15', label: '15'},
                                        {value: '40', label: '40'}],
                                },
                                {
                                    type: "selector",
                                    data: {value: 'negative', label: 'Negative'},
                                    multi: false,
                                    label: 'Bilirubin trace',
                                    options: [{value: 'negative', label: 'Negative'},
                                        {value: 'trace', label: 'Trace'},
                                        {value: '+', label: '+'},
                                        {value: '++', label: '++'},
                                        {value: '+++', label: '+++'}],
                                },
                                {
                                    type: "selector",
                                    data: {value: 'negative', label: 'Negative'},
                                    multi: false,
                                    label: 'Glucose',
                                    options: [{value: 'negative', label: 'Negative'},
                                        {value: '100', label: '100'},
                                        {value: '250', label: '250'},
                                        {value: '500', label: '500'},
                                        {value: '1000', label: '1000'},
                                        {value: '>2000', label: '>2000'}],
                                },
                            ]
                        }
                    },
                    {
                        type: 'blockCheckboxCondition',
                        data: {
                            checkbox: [{checked: false, data: 'bHCG:'}],
                            hiddenBlock: [
                                {
                                    type: "radio-block",
                                    data: [{checked: true, data: "Negative"},
                                        {checked: false, data: "Positive"}]
                                },
                            ]
                        }
                    },
                    {
                        type: 'blockCheckboxCondition',
                        data: {
                            checkbox: [{checked: false, data: ':'}],
                            hiddenBlock: [
                                {
                                    type: "input",
                                    placeholder: "",
                                    data: "",
                                },
                            ]
                        }
                    },
                ]}),
            components.AssessmentPlan([ {
                type: 'blockCheckboxCondition',
                extraType: 'assessmentContent',
                data: {
                    checkbox: [{checked: true, data: '', isHide: true}],
                    hiddenBlock: [
                        {
                            type: "text",
                            data: 'Follow up consultation to be arranged by patient with',
                            editable: true,
                            source: null
                        },
                        {
                            type: "radio-block",
                            data: [{checked: true, data: "PCP"},
                                {checked: false, data: "urologist"},
                            ]
                        },
                        {
                            type: "text",
                            data: 'in',
                            editable: true,
                            source: null
                        },
                        {
                            type: 'input-static-text',
                            label: '',
                            data: [{data: '', mask: '999', min: 0, max: 365}],
                            deviders: []
                        },
                        {
                            type: "text",
                            data: 'days for further evaluation. If no better within 48 hours or return for fever or shaking chills.',
                            editable: true,
                            source: null
                        },
                    ]
                }
            }]),
        ]
    }
}
