import React, { useEffect, useState } from "react";
import "./style.scss";
import town from "../../../dist/images/town.svg";
import { Button } from "../../Common/Button/Button";
import "react-times/css/material/default.css";
import "react-times/css/classic/default.css";
import cn from "classnames";
import { statesButtonsTime, steps } from "../../../constants/constants";
import { Buttons } from "../Buttons";
import { SingleDatePicker } from "../../Common/SingleDatePicker/SingleDatePicker";
import { DropDown } from "../../Common/DropDown/DropDown";
import moment from "moment";
import { isToday } from "../../../utils/date";
import Analytics from "../../../utils/analytics/AnalyticsService";
import { EVENTS } from "../../../utils/analytics/Events";
import { getTimeSlots } from "../../../store/common/actions";
import { useDispatch } from "react-redux";

const firstState = [
  {
    variant: "default",
    text: "Send Doctor NOW",
  },
  {
    text: "Send Doctor LATER",
    variant: "default",
  }
];
const secondState = [
  {
    variant: "default",
    text: "Send Doctor NOW",
  },
  {
    text: "Send Doctor LATER",
    icon: "BirdSelect",
    variant: "primary",
  }
];
const thirdState = [
  {
    variant: "primary",
    text: "Send Doctor NOW",
    icon: "BirdSelect"
  },
  {
    text: "Send Doctor LATER",
    variant: "default",
  }
];

export const TimeDefinig = ({
  simpleUpdateState,
  activeButtonTime,
  timeVisiting,
  dateVisiting,
  path,
  getDataUser,
  actionNextStep,
  moveToTheStep,
  patientsAmount,
  ...props
}) => {
  const [timeOptionsDate, setTimeOptionsDate] = useState([]);
  const [buttonsTimeProps, setButtonsTimeProps] = useState(firstState);
  const clickDefault = () => simpleUpdateState("openConfirmation", true);
  const dispatch = useDispatch();

  const setTimeOptions = () => {
    const options = isToday(dateVisiting) ?
        timeOptionsDate.data?.filter(i => moment(i.value, "HH:mm").add(-1, "hour") > moment()) : timeOptionsDate?.data;
    setTimeOptionsDate(options);
    !options.find(i => i.isFree && i.value === timeVisiting.value) &&
      simpleUpdateState("timeVisiting", options.find(i => i.isFree && moment(i.value, "HH:mm").add(-1, "hour") > moment()));
  };

  useEffect(() => {
    getAllTimeSlots(dateVisiting);
    simpleUpdateState("hideSteps", false);
    simpleUpdateState("activeStep", steps.Time);
      Analytics.track(EVENTS.Comprehensive_Time_Page_Open);
  },[]);

  useEffect(() => {
    if(timeOptionsDate?.timestamp) {
      setTimeOptions();
    }
  }, [timeOptionsDate?.timestamp]);

  useEffect(
    () =>
      setButtonsTimeProps(
        activeButtonTime
          ? activeButtonTime === statesButtonsTime[1]
            ? secondState
            : thirdState
          : firstState
      ),
    [activeButtonTime]
  );

  const onClickButtonTime = text => {
    simpleUpdateState("activeButtonTime", text);
  };

  const getAllTimeSlots = date => {
    dispatch(getTimeSlots(moment(date).format('YYYY-MM-DD'), patientsAmount, props.address?.address?.address, props.address?.zipcode)).then(resp => {
      setTimeOptionsDate(resp?.data);
      if(!timeVisiting) {
        simpleUpdateState('timeVisiting', resp?.data.find(i => i.isFree && moment(i.value, "HH:mm").add(-1, "hour") > moment()));
      }
    });
  };

  const updateDate = e => {
    simpleUpdateState("dateVisiting", e);
    getAllTimeSlots(e);
  };

  const updateTime = (field, value) => simpleUpdateState(field, value);

  const checkFuture = () => {
    const time = timeVisiting?.value.split(":");
    if (!timeVisiting ||
      moment(dateVisiting)
        .set({
          hour: time[0],
          minute: time[1]
        })
        .add(-1, "h") < moment()
    )
      return true;
  };

  const showTimePickers = activeButtonTime === statesButtonsTime[1];

  const disabledPrimary = activeButtonTime === "later" && checkFuture();

  return (
    <div className="step-time-defining">
      <img src={town} className="step-time-defining_img" />
      <div className="step-time-defining_container-buttons">
        {buttonsTimeProps.map((i, index) => {
          let className = ''
          if(i.variant === 'primary') className = 'step-time-defining_container-buttons_active'
          return (
              <div
                  key={index}
                  className="step-time-defining_container-buttons_button"
              >
                <Button
                    size="big"
                    {...i}
                    className={className}
                    onClick={() => onClickButtonTime(statesButtonsTime[index])}
                />
              </div>
          )
        })}
      </div>
      {showTimePickers && (
        <div className={cn("step-time-defining_time-wrapper")}>
          <SingleDatePicker
            className=""
            label="Date"
            popperPlacement="top"
            selected={dateVisiting}
            onChange={updateDate}
            maxDate={moment().add(3, 'months').toDate()}
          />
          <div className="step-time-defining_time-wrapper_spacer" />
          <DropDown
            className="step-time-defining_time-wrapper_time"
            value={timeVisiting}
            field="timeVisiting"
            label="Please specify your time"
            options={timeOptionsDate}
            isOptionDisabled={option => !option.isFree}
            handlerSelect={updateTime}
          />
        </div>
      )}

      {activeButtonTime && (
        <Buttons
          DefaultClick={clickDefault}
          PrimaryClick={actionNextStep}
          PrimaryDisabled={disabledPrimary}
        />
      )}
    </div>
  );
};
