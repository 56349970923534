import React from "react";
import moment from "moment";
import { colors } from "../../../assets/colors/colors";
import { Typography } from "../../Common/Typography/Typography";
import { ButtonMenu } from "../../Common/ButtonMenu/ButtonMenu";
import { Edit, Delete } from "../../Common/Icons";

export const ShiftRange = ({ data, editShift, openConfirmationDelete }) => {

  const dateFrom = moment(data.frame_start)
    .set({
      hour: data.start_time.split(":")[0],
      minute: data.start_time.split(":")[1]
    })
    .format("DD MMM, h:mm a");
  const dateTo = moment(data.frame_end)
    .set({
      hour: data.end_time.split(":")[0],
      minute: data.end_time.split(":")[1]
    })
    .format("DD MMM, h:mm a");

  const show = false
  return (
    <div className="shift-range-component">
      <div className="shift-range-component_dates">
        <Typography variant="p" text={dateFrom} color={colors.navy} />
        <Typography variant="p" text={dateTo} color={colors.navy} />
      </div>
        {show && <ButtonMenu>
          {data?.doctor_info?.validation_status === 'Active' && <li
          onClick={() => {
            editShift(data);
          }}
        >
          <Edit /> <Typography variant="p" text="Edit" />
        </li>}
        <li onClick={openConfirmationDelete}>
          <Delete /> <Typography variant="p" text="Delete" />
        </li>
      </ButtonMenu>}
    </div>
  );
};
