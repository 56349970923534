import { ArrowDecrease, ArrowIncrease } from "../Common/Icons";
import React, {useMemo} from "react";
import {Views} from "react-big-calendar";
import moment from "moment";
import cn from 'classnames';
import _ from "lodash";
import {Tooltip} from "../Common/Tooltip";
export const CustomToolbar = ({view, label, calendarApi, events, isShift, ...toolbar }) => {
  const goToBack = () => toolbar.onNavigate("PREV")

  const goToNext = () => toolbar.onNavigate("NEXT")
  const isDay = view === Views.DAY
    let extraInfo = useMemo(() => {
        if (isDay && !isShift) {
            const startCurrentDay = moment(toolbar.date).startOf('day')
            const endCurrentDay = moment(toolbar.date).endOf('day')
            const groupRequests = _.groupBy(events.filter(i => moment(i.start).isBetween(startCurrentDay, endCurrentDay) || moment(i.end).isBetween(startCurrentDay, endCurrentDay) && i.requestStatus), 'requestStatus')
            Object.keys(groupRequests).map(i => {
                groupRequests[i] = groupRequests[i].reduce((acc,cur) => acc + cur.data.requests_count, 0)
            })
            return groupRequests
        }

    }, [label, isShift])
    const statuses = isDay && !isShift && Object.keys(extraInfo).map((i, idx, arr) => `${idx === 0 ? '(' : ''}${extraInfo[i]} ${i}${arr.length - 1 === idx ? ')' : '; '}`)
    return (
      <div className="custom-toolbar-calendar">
        <div className={cn("custom-toolbar-calendar_buttons", {"custom-toolbar-calendar_buttons-day": isDay})}>
          <ArrowDecrease
              onClick={goToBack}
          className="custom-toolbar-calendar_buttons_prev"
        />
        {isDay && <>
            <div className='custom-toolbar-calendar_date'>{label}</div>
            <Tooltip title={statuses}>
            <div className='custom-toolbar-calendar_requests'>
                    {statuses}
            </div>
            </Tooltip>
        </>}
        <ArrowIncrease
            onClick={goToNext}
            className="custom-toolbar-calendar_buttons_next"
        />
      </div>
    </div>
  );
};
