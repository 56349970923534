import React, { Component } from "react";
import cn from "classnames";
import {colors} from "../../../assets/colors/colors";

export const ArrowRight = props => (
  <div
    onClick={props.onClick}
    className={cn(props.className, "icon-component")}
    style={props.style}
  >
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.165 9.06507L1.05 9.95007L6 5.00007L1.05 0.0500741L0.165 0.935074L4.23 5.00007L0.165 9.06507Z"
        fill={props.color || colors.navy}
      />
    </svg>
  </div>
);
