export const PROPERTIES = {
  MIXPANEL_SUPER_PROPERTIES: ['$email', 'google_cal', 'iCal', 'outlook_cal'],
  MIXPANEL_PROFILE_PROPERTIES: [
    '$email',
    'user_type',
    'practice_areas',
    'google_cal',
    'iCal',
    'outlook_cal',
    'stripe_connect',
    'card',
  ],
  FIREBASE_PROFILE_PROPERTIES: ['user_type', 'google_cal', 'iCal', 'outlook_cal', 'stripe_connect', 'card'],
}
