import React from "react";
import "./style.scss";
import { PastMedicalHistory } from "./PastMedicalHistory";
import { AllRequestDetails } from "./AllRequestDetails";

export const ContainerPMH = props => {
  return (
    <div className="container-pmh">
      <PastMedicalHistory {...props}/>
      <AllRequestDetails {...props} />
    </div>
  );
};
