import { fetchV2 } from "../../helpers/helpers";
import { setRelations } from "./slice";

export const getRelations = () => dispatch => {
  return dispatch(fetchV2({
    url: `patients/relations/`
  })).then(resp => {
    dispatch(setRelations(resp.data?.map(i => ({
      value: i?.relation,
      label: i.relation ? `${i.relation[0].toUpperCase()}${i.relation.slice(1)}` : ''
    }))));
  });
};
