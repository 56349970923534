import React, { Component } from "react";
import cn from "classnames";

export const BirdSmall = props => (
  <div
    onClick={props.onClick}
    className={cn(props.className, "icon-component")}
    style={props.style}
  >
    <svg
      width="8"
      height="7"
      viewBox="0 0 8 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.54545 5.07952L0.636364 3.19892L0 3.82579L2.54545 6.33325L8 0.960118L7.36364 0.333252L2.54545 5.07952Z"
        fill="white"
      />
    </svg>
  </div>
);
