import React, { useEffect, useMemo, useState } from "react";
import ScheduleEvents from "../../components/ScheduleEvents";
import { Button } from "../../components/Common/Button/Button";
import {
  buttonsInnerText, PLACEHOLDERS,
  placeholders,
  routesCommon
} from "../../constants";
import { AddShifts } from "../../components/AdminPage/Shifts/AddShifts";
import "../../styles/Admin/Calendar/style.scss";
import { ReassingTo } from "../../components/AdminPage/Shifts/ReassingTo";
import {
  getDateFromISO,
  getEndOfWeek,
  getStartOfWeek,
  getStartOfWeekWithPrevDay,
  getEndOfWeekWithNextDay, getStartOfDayWithPrevDay, getStartOfDayWithNextDay
} from "../../utils/date";
import { CalendarEventShift } from "../../components/AdminPage/Shifts/CalendarEventShift";
import moment from "moment";
import { PanelFilters } from "../../components/AdminEmr/PanelFilters/PanelFilters";
import { DropDown } from "../../components/Common/DropDown/DropDown";
import { showFiltersValue } from "../../utils/common";
import { Radiobutton } from "../../components/Common/RadioButton/Radiobutton";
import {
  backToShift,
  filtersCalendar,
  routersAdmin,
  timeOptionsShift
} from "../../constants/Admin";
import { PopupContainer } from "../../components/Common/Popup/PopupContainer";
import { Typography } from "../../components/Common/Typography/Typography";
import { assignDoctorToAppointment } from "../../store/doctor/actions";
import { getMultipleRequestList } from "../../store/admin/actions";
import history from "../../helpers/history";
import { EditRequest } from "../../components/AdminPage/Shifts/EditRequest";
import { AdminSidebar } from "../../components/AdminPage/AdminSidebar/AdminSidebar";
import { useDispatch, useSelector } from "react-redux";
import { getRequestsData } from "../../store/requests/actions";
import { requestsSelector } from "../../store/requests/selectors";
import { getShiftsData } from "../../store/shifts/actions";
import { shiftsSelector } from "../../store/shifts/selectors";
import { prepareRequestsCalendar } from "../../store/requests/utils";
import { processShiftCalendar } from "../../store/shifts/utils";
import { getDataRequest } from "../../store/request/actions";
import { requestDetailsSelector } from "../../store/request/selectors";
import {clearRequestDetails} from "../../store/request/slice";
import {Views} from "react-big-calendar";
import {REQUEST_STATUS_OPTIONS} from "../../store/request/constants";
import {CreateAppointment} from "./CreateAppointment";

const initialState = {
  location: [],
  speciality: [],
  physician: [],
  statuses: [],
};

export const ShiftsPage = ({location,...props}) => {

  const [state, setState] = useState({
    selectedEventOnCalendar: {},
    // dateOfPicker: moment().toDate(),
    // dateOfCalendar: moment().toDate(),
    // countWeek: 0,
    openAddShifts: false,
    openReassignTo: false,
    showShiftsAppointments: true,
    sideBarOpen: false,
    selectedShift: null,
    openFromShiftOrAppointment: '',
    openConfirmationDelete: false,
    editDetailsShift: null,
    isMultiple: false,
    multipleUuid: null,
    closeRequest: false,
    multipleRequestList: [],
    isInMultiple: false,
    editRequestOpen: false,
    choosedFilters: {
      ...initialState
    },
    applyFilters: {
      ...initialState
    },
    triggerUpdateRequests: true
  })

  const [openCreateRequest, setOpenCreateRequest] = useState(false);

  const requests = useSelector(requestsSelector)
  const shifts = useSelector(shiftsSelector)
  const detailsRequest = useSelector(requestDetailsSelector)

  const dispatch = useDispatch()

  useEffect(()=>{
    if (location?.state) {
      const value = location.state.update;
      // updateShifts();
      setState(prev => ({
        ...prev,
        triggerUpdateRequests: !state.triggerUpdateRequests,
        showShiftsAppointments:
            (typeof value === "boolean" && value) ||
            state.showShiftsAppointments
      }));
      history.replace(`${routesCommon.admin}${routersAdmin.calendar}`);
    }
  },[location?.state])

  // useEffect(() => {
  //   const container = document.querySelector('.main-container-app')
  //   if (container) {
  //     if (state.editRequestOpen) container.classList.add('overflow-hidden')
  //     else container.classList.remove('overflow-hidden')
  //   }
  //   return () => {
  //     if (state.editRequestOpen) container.classList.add('overflow-hidden')
  //     else container.classList.remove('overflow-hidden')
  //   }
  // }, [state.editRequestOpen]);

  // useEffect(()=>{
  //   setState({triggerUpdateRequests: !state.triggerUpdateRequests})
  //   updateShifts()
  // },[state.applyFilters, state.countWeek, moment(state.dateOfPicker).format()])

  useEffect(()=>{
    props
        .getTableData({
          validation_statuses: "Active"
        })
        .then(resp => props.setActivePhysician(resp));
    props.getFiltersShifts();
    props.getLocations();
    props.getSpeciality();
    return () => dispatch(clearRequestDetails())
  },[])

  const openEditRequest = () => {
    setState(prev => ({ ...prev, editRequestOpen: true }))
  }
  const closeEditRequest = ({updateEvents}) => {
    if(updateEvents){
      // updateShifts()
      setState((prev)=>({
        ...prev,
        triggerUpdateRequests: !state.triggerUpdateRequests,
      }))
      dispatch(getDataRequest({uuid: detailsRequest?.data?.uuid}))
    }
    setState(prev => ({ ...prev, editRequestOpen: false }));
  }

  const getEvents = (after, before, filters = {}, modeLoading) => {
    if (state.showShiftsAppointments) return dispatch(getRequestsData({
      event_date_after: after,
      event_date_before: before,
      modeLoading,
      ...filters
    }))
    else return dispatch(getShiftsData({
        shift_date_after: after,
        shift_date_before: before,
        ...filters
      }))
  };

  const clearSelectedData = () => {
    return {
      selectedEventOnCalendar: {},
      sideBarOpen: false,
      isMultiple: false,
      isInMultiple: false,
      multipleUuid: null,
      selectedShift: null,
      multipleRequestList: []
    };
  };

  const onCreateRequestPressed = () => setOpenCreateRequest(true)

  const onCloseCreateRequestPressed = () => setOpenCreateRequest(false)
  const openAddShifts = () =>
    setState(prev => ({
      ...prev,
      openAddShifts: true,
      ...clearSelectedData()
    }));
  const closeAddShifts = () =>
    setState(prev => ({ ...prev, openAddShifts: false, editDetailsShift: null }));

  const openReassignTo = () => {
    setState(prev => ({ ...prev, openReassignTo: true }));
  };
  const closeReassignTo = () => setState(prev => ({ ...prev, openReassignTo: false }));

  const assignPhysician = doctor => {
    assignDoctorToAppointment({
      service_request: detailsRequest.data?.uuid,
      doctor: doctor.value
    }).then(resp => {
      if (resp.uuid) {
        // updateShifts();
        setState((prev)=>({...prev, triggerUpdateRequests: !state.triggerUpdateRequests}))
        openSideBar({
          ...state.selectedEventOnCalendar,
          doctor_uuid: doctor.value
        });
        closeReassignTo();
      }
    });
  };

  // const updateShifts = (filters = state.applyFilters) => {
  //   const date = moment(state.dateOfPicker).add(
  //     state.countWeek,
  //     "week"
  //   );
  //   getEvents(getStartOfWeekWithPrevDay(date), getEndOfWeekWithNextDay(date), {
  //     regions: filters.location.map(i => i.value).join(","),
  //     speciality: filters.speciality.map(i => i.value).join(","),
  //     doctors: filters.physician.map(i => i.value).join(",")
  //   });
  // };

  const simpleUpdate = (field, value) => {
    setState(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const selectFilters = (field, value) => {
    setState(prev => ({
      ...prev,
      choosedFilters: {
        ...state.choosedFilters,
        [field]: value
      }
    }));
  };

  // const updateDate = e => {
  //   setState(prev => ({
  //     ...prev,
  //     dateOfPicker: e,
  //     dateOfCalendar: e,
  //     countWeek: 0
  //   }));
  // };

  const applyFilterAction = () => {
    setState(prev => ({
      ...prev,
      applyFilters: {
        ...prev.choosedFilters
      },
      triggerUpdateRequests: !state.triggerUpdateRequests,
    }));
  };

  const deleteAppliedFilters = () => {
    setState(prev => ({
      ...prev,
      choosedFilters: { ...initialState },
      applyFilters: { ...initialState }
    }));
  };

  const switchEvents = value => {
    setState(prev => ({
      ...prev,
      showShiftsAppointments: !prev.showShiftsAppointments,
      choosedFilters: {
        ...initialState
      },
      applyFilters: {
        ...initialState
      },
      ...clearSelectedData(),
      triggerUpdateRequests: !state.triggerUpdateRequests,
    }));
  };
  const openSideBar = (data, fromShiftOrAppointment = "") => {
    let stateNew = { ...state };
    if (state.showShiftsAppointments || fromShiftOrAppointment === backToShift) {
      if(data.is_grouped) {
        stateNew = {...stateNew,
          isMultiple: true,
          multipleUuid: data.uuid,
          isInMultiple: false
        }
        getMultipleRequestList(data.uuid).then(resp => {
          setState(prev => ({
            ...prev,
            multipleRequestList: resp,
            closeRequest: data.uuid === prev.multipleUuid,
          }));
        });
      } else {
        const isInMultiple = !!state.multipleRequestList.find(i => i.uuid === data.uuid);
        if(!isInMultiple) {
          stateNew = {
            ...stateNew, isMultiple: false, closeRequest: true, multipleUuid: null
          };
        }
        stateNew = { ...stateNew, isInMultiple };
      }
      dispatch(getDataRequest({uuid: data.uuid, isShift: true}))
      props.getDoctorsForAssign(data.uuid);
    } else {
      stateNew.selectedShift = data;
      props.getAppointmentsDoctor({
        doctors: data?.doctor_info?.uuid,
        event_date_after: encodeURIComponent(`${data.start_date}T${data.start_time}${moment().format('Z')}`),
        event_date_before: encodeURIComponent(`${data.start_date}T${data.end_time}${moment().format('Z')}`)
      });
    }
    setState(prev => ({
      ...prev,
      ...stateNew,
      selectedEventOnCalendar:
        fromShiftOrAppointment === backToShift
          ? state.selectedEventOnCalendar
          : data,
      openFromShiftOrAppointment: fromShiftOrAppointment,
      sideBarOpen: true
    }));
  };
  const closeSideBar = () => {
    props.clearDoctorData();
    setState(prev => ({
      ...prev,
      ...clearSelectedData(),
      selectedShift: null
    }));
  };
  const editShift = data => {
    setState(prev => ({
      ...prev,
      editDetailsShift: {
        recurrence_state: data.recurrence_state,
        dateFrom: getDateFromISO(`${data.frame_start}T${data.start_time}Z`),
        dateTo: getDateFromISO(`${data.frame_end}T${data.end_time}Z`),
        timeFrom: timeOptionsShift.find(
          i => i.value === data.start_time.substring(0, 5)
        ),
        timeTo: timeOptionsShift.find(
          i => i.value === data.end_time.substring(0, 5)
        ),
        doctor: props.physiciansActive.find(i => i.value === data.doctor_info?.uuid),
        uuid: data.uuid,
        is_backup: data.is_backup
      },
      openAddShifts: true,
      ...clearSelectedData()
    }));
  };

  // const switchWeek = count => {
  //   setState(prev => ({ ...prev, countWeek: count }));
  // };

  const deleteShift = (uuid, onlyCurrent) =>
    props.deleteShift(uuid, onlyCurrent).then(resp => {
      setState(prev => ({
        ...prev,
        ...clearSelectedData(),
        triggerUpdateRequests: !state.triggerUpdateRequests,
        openConfirmationDelete: false
      }));
    });

  const closeConfirmationDelete = () => {
    setState(prev => ({
      ...prev,
      openConfirmationDelete: false
    }));
  };
  const openConfirmationDelete = () => {
    setState(prev =>({
      ...prev,
      openConfirmationDelete: true
    }));
  };

  const declineAppointment = (uuid, reason, activate) =>
    props.declineAppointment(uuid, reason, activate).then(() => {
      closeSideBar();
      // updateShifts();
      setState((prev)=>({...prev, triggerUpdateRequests: !state.triggerUpdateRequests}))
    });

  const navigateToUploadVisitFile = () => {
    history.push(`${routesCommon.admin}${routersAdmin.visitFile}`)
  }

  const getAppliedFilters = () => ({
    regions: state.applyFilters.location.map(i => i.value).join(","),
    speciality: state.applyFilters.speciality.map(i => i.value).join(","),
    doctors: state.applyFilters.physician.map(i => i.value).join(","),
    status: state.applyFilters.statuses.map(i => i.value).join(","),
  })

  const requestEventsWeek = (date, timeMode, modeLoading, additionalFilters) => {
    const filters = {...getAppliedFilters(), ...additionalFilters}
    if(timeMode === Views.DAY) return getEvents(getStartOfDayWithPrevDay(date), getStartOfDayWithNextDay(date), filters);
    else if(timeMode === Views.WEEK) return getEvents(getStartOfWeekWithPrevDay(date), getEndOfWeekWithNextDay(date), filters);
    else if(timeMode === Views.LIST) return getEvents(date.start, date.end, filters, modeLoading);
  }

  const triggerUpdateData = () => setState((prev)=>({...prev, triggerUpdateRequests: !state.triggerUpdateRequests}))

  const events = useMemo(() => {
    if(requests && state.showShiftsAppointments) {
      return prepareRequestsCalendar(requests?.data);
    } else if(shifts && !state.showShiftsAppointments) {
      return processShiftCalendar(shifts?.data);
    }
    return [];
  }, [requests?.timestamp, shifts?.timestamp]);

  let filtersValue = showFiltersValue(state.applyFilters);
    let deleteShiftDoctor = "";
    if (props.doctor.user)
      deleteShiftDoctor = `Do you really want to delete ${props.doctor.user.firstName} ${props.doctor.user.lastName}’s shift?`;
    return (
      <>
        <ScheduleEvents
          {...state}
          requestEventsWeek={requestEventsWeek}
          events={events}
          activeRequest={detailsRequest}
          requests={requests}
          onClickListRequest={openSideBar}
          className="admin-shifts-calendar"
          CustomEvent={data => (
            <CalendarEventShift
              selectedEvent={state.selectedEventOnCalendar}
              onClick={openSideBar}
              appointment={state.showShiftsAppointments}
              {...data}
            />
          )}
        >
          {!state.showShiftsAppointments && (
            <Button
              text={buttonsInnerText.add_shift}
              size="full"
              variant="primary"
              uppercase
              onClick={openAddShifts}
            />
          )}
          <div className="admin-shifts-calendar_filters-radiobuttons">
            {filtersCalendar.map((i, index) => (
              <Radiobutton
                label={i}
                key={index}
                onClick={switchEvents}
                checked={
                  index === 0
                    ? state.showShiftsAppointments
                    : !state.showShiftsAppointments
                }
              />
            ))}
          </div>
          <Button
              className='admin-shifts-calendar_create-appointment'
              text={'Create an appointment'}
              size="full"
              variant="primary"
              onClick={onCreateRequestPressed}
          />
          <Button
               className='admin-shifts-calendar_visit-file'
              text={buttonsInnerText.upload_visit_file}
              size="full"
              variant="primary"
              onClick={navigateToUploadVisitFile}
          />
          {/*<Button*/}
          {/*    className='admin-shifts-calendar_visit-file'*/}
          {/*    text='edit request'*/}
          {/*    size="full"*/}
          {/*    variant="primary"*/}
          {/*    onClick={openEditRequest}*/}
          {/*/>*/}

          <PanelFilters
            className="admin-shifts-calendar_filters-container"
            filtersValue={filtersValue}
            noSearch
            isStatic
            applyFilterAction={applyFilterAction}
            applyFilters={state.applyFilters}
            deleteAppliedFilters={deleteAppliedFilters}
          >
            <DropDown
              multi
              value={state.choosedFilters.location}
              field="location"
              handlerSelect={selectFilters}
              label={placeholders.location}
              options={props.filtersShifts.locations}
            />
            <DropDown
              multi
              value={state.choosedFilters.speciality}
              field="speciality"
              handlerSelect={selectFilters}
              label={placeholders.speciality}
              options={props.filtersShifts.speciality}
            />
            <DropDown
              multi
              value={state.choosedFilters.physician}
              field="physician"
              handlerSelect={selectFilters}
              label={placeholders.physician}
              options={props.filtersShifts.physiciansOptions}
            />
            <DropDown
                multi
                value={state.choosedFilters.statuses}
                field="statuses"
                handlerSelect={selectFilters}
                label={PLACEHOLDERS.STATUS}
                options={REQUEST_STATUS_OPTIONS}
            />
          </PanelFilters>
        </ScheduleEvents>
        <AddShifts
            deleteShift={deleteShift}
          getFiltersShifts={props.getFiltersShifts}
          editDetailsShift={state.editDetailsShift}
          filtersShifts={props.filtersShifts}
          createShifts={props.createShifts}
          physicians={props.physicians}
          locations={props.locations}
          speciality={props.speciality}
          open={state.openAddShifts}
          onClose={closeAddShifts}
          getTableData={props.getTableData}
          updateShifts={triggerUpdateData}
        />
        <ReassingTo
          physicians={props.doctorsForAssign}
          open={state.openReassignTo}
          onClose={closeReassignTo}
          assignPhysician={assignPhysician}
        />
        <AdminSidebar
          declineAppointment={declineAppointment}
          selectedEvent={state.selectedEventOnCalendar}
          openConfirmationDelete={openConfirmationDelete}
          openFromShiftOrAppointment={state.openFromShiftOrAppointment}
          selectedShift={state.selectedShift}
          showShiftsAppointments={state.showShiftsAppointments}
          openReassignTo={openReassignTo}
          editShift={editShift}
          viewDetailsAppointmentOrShift={openSideBar}
          filtersShifts={props.filtersShifts}
          detailsAppointment={detailsRequest}
          appointmentsSelectedDoctors={props.appointmentsSelectedDoctors}
          isMultiple={state.isMultiple}
          multipleRequestList={state.multipleRequestList}
          multipleUuid={state.multipleUuid}
          closeRequest={state.closeRequest}
          appointment={state.showShiftsAppointments}
          open={state.sideBarOpen}
          onClose={closeSideBar}
          isInMultiple={state.isInMultiple}
          simpleUpdate={simpleUpdate}
          openEditRequest={openEditRequest}
        />
        <PopupContainer
          close={closeConfirmationDelete}
          open={state.openConfirmationDelete}
          textPrimary={buttonsInnerText.delete}
          actionDefaultButton={closeConfirmationDelete}
          actionPrimaryButton={deleteShift}
          uppercaseButton
          title="The shift information will be lost"
          className="admin-shifts-page_confirmation-delete"
        >
          <Typography variant="p" text={deleteShiftDoctor} className="" />
        </PopupContainer>
        {state.editRequestOpen && (
          <EditRequest
            request={detailsRequest}
            open={state.editRequestOpen}
            close={closeEditRequest}
          />
        )}
        {openCreateRequest && (
            <CreateAppointment onClose={onCloseCreateRequestPressed}/>
        )}
      </>
    );
}
