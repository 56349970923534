import React from "react";
import "./style.scss";
import { colors } from "../../../../../assets/colors/colors";
import { Typography } from "../../../../Common/Typography/Typography";
import { ArrowRight } from "../../../../Common/Icons";
import { getTimeInFormat12, formatViewDate } from "../../../../../utils/date";
import { visitNoteStatuses } from "../../../../../constants";
import { RequestStatus } from "../../../../Common/RequestStatus/RequestStatus";
import cn from 'classnames';
import {ButtonV2} from "../../../../Common/ButtonV2";
import {COLORS_BTN, VARIATIONS_BTN} from "../../../../Common/ButtonV2/constants";

export const ListRequests = ({ data, changeShowingRequest, current }) => {
  return (
    <div className="list-requests-details">
      {data.map((i, ind) => (
        <ItemListRequest
          current={current}
          key={ind}
          data={i}
          changeShowingRequest={changeShowingRequest}
        />
      ))}
    </div>
  );
};

const ItemListRequest = ({ data, changeShowingRequest, current }) => {
  const speciality = data.doctor_speciality;
  const doctor = data.doctor_name || "Doctor is not assigned yet"
  const date = formatViewDate(data.application_time);
  const timeFrom = getTimeInFormat12(data.application_can_start_at);
  const timeTo = getTimeInFormat12(data.application_time);
  let status = visitNoteStatuses[data.status.toLowerCase()]
  if(data.status === "Pending" && !data.doctor_name) status = visitNoteStatuses.draft
  const uuidRequest = window.location.pathname.split('/').reverse()[0]

  return (
    <div className={cn(
      "list-requests-details_item",
      {
        'list-requests-details_item--current': uuidRequest === data.service_request_uuid
      }
    )}>
      <div className="list-requests-details_item_header">
        <Typography
          variant="title"
          text={doctor}
          className="list-requests-details_item_header_doctor custom-input-container_error"
        />
        <RequestStatus
          status={status?.name}
          className="custom-input-container_error list-requests-details_item_header_status"
        />
      </div>
      <Typography variant="p" text={speciality} color={colors.navy} className='list-requests-details_item_specialty'/>
      <div className="list-requests-details_item_footer">
        <div className="list-requests-details_item_footer_time">
          <Typography variant="h4" text={date} color={colors.grey} />
          <Typography
            className="list-requests-details_item_footer_time_time"
            variant="h4"
            text={` ${timeFrom} - ${timeTo}`}
            color={colors.grey}
          />
        </div>
        <ButtonV2 className="list-requests-details_item_footer_button"
                  onClick={() => {
                    changeShowingRequest(data)
                  }} variant={VARIATIONS_BTN.TEXT}
                  color={COLORS_BTN.PRIMARY}><Typography
            uppercase
            variant="h4"
            text="details"
            color={colors.navy}
        />
          <ArrowRight/></ButtonV2>
      </div>
    </div>
  );
};
