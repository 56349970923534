import React from 'react'
import { Typography } from "../Typography/Typography";
import { colors } from "../../../assets/colors/colors";
import { ButtonMenu } from "../ButtonMenu/ButtonMenu";
import { Delete, Edit, Plus } from "../Icons";
import { Button } from "../Button/Button";
import { buttonsInnerText } from "../../../constants";
import { InfoCloud } from "../InfoCloud/InfoCloud";
import cn from 'classnames';
import './style.scss';

const notesForPatient = ['discharge', 'covid', 'receipt', 'excuse'];
const notesForPcp = ['note', 'covid'];

export const ContainerWithActions = ({
  edit,
  deleteItem,
  children,
  title,
  addItem,
  hideButtonMenu,
  hideAddItem,
  sendHandler,
  className,
  selectedElements,
  isNotes,
  doctors,
  sendToPatientHandler
}) => {
  const sendToPcpDisabled = !selectedElements?.filter(i => notesForPcp.find(j => i === j)).length || !doctors?.length;
  const sentToPatientDisabled = !selectedElements?.filter(i => notesForPatient.find(j => i === j)).length;
  const isDisabled = !selectedElements?.length;

  return (
    <div className={cn('container-with-actions', className)}>
      <div className="container-with-actions_header">
        <Typography variant="p" text={title} color={colors.greyMiddle} />
        {!hideButtonMenu && (
          <ButtonMenu horizontal>
            {edit && (
              <li onClick={edit}>
                <Edit /> <Typography variant="p" text="Edit" />
              </li>
            )}
            {deleteItem && (
              <li onClick={deleteItem}>
                <Delete /> <Typography variant="p" text="Delete" />
              </li>
            )}
          </ButtonMenu>
        )}
      </div>
      <div className="container-with-actions_body">{children}</div>
      <div className="container-with-actions_footer" style={{ justifyContent: isNotes ? 'flex-end' : 'space-between' }}>
        {!hideAddItem ? (
          <button className="no-default-style-button" onClick={addItem}>
            <Plus />
            <Typography variant="h4" uppercase text="add" color={colors.navy} />
          </button>
        ) : (
          <span />
        )}
        {sendHandler && (
          <>
            {isNotes ? (
              <>
                <InfoCloud dangerouslySetInnerHTML={!doctors?.length ? 'Create PCP to send document' : ''} top>
                  <Button
                    text={buttonsInnerText.send_to_pcp}
                    size="middle"
                    variant="default"
                    capitalize
                    disabled={sendToPcpDisabled}
                    onClick={sendHandler}
                    style={{ marginRight: isNotes ? '16px' : '0' }}
                  />
                </InfoCloud>
                <Button
                  text={buttonsInnerText.send_to_patient}
                  size="middle"
                  variant="default"
                  capitalize
                  disabled={sentToPatientDisabled}
                  onClick={sendToPatientHandler}
                />
              </>
            ) : (
              <Button
                text={buttonsInnerText.send}
                size="middle"
                variant="default"
                capitalize
                disabled={isDisabled}
                onClick={sendHandler}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};
