import React from "react";
import {assessmentsPlanKeys} from "../../constants";

export const AssessmentPlan = (extra=[], textBeforeSelectors = '') => {
    return {
        type: "label",
        data: "ASSESSMENT/PLAN:",
        required: true,
        items: [
            {
                assessmentPlanKey: assessmentsPlanKeys.txtFirstPart,
                type: "text",
                data: textBeforeSelectors || 'The patient received verbal instructions regarding this condition. Written instructions will be send to the patient following the visit.',
                editable: true,
                source: null
            },
            {
                assessmentPlanKey: assessmentsPlanKeys.textAreaAssessmentPlan,
                type: "textarea",
                data: '',
                isRequired: false,
                placeholder: 'Assessment/Plan',
                editable: true
            },
            {
                assessmentPlanKey: assessmentsPlanKeys.diagnosis,
                type: 'diagnoses',
                data: [null],
                textData: [{placeholder: '', data: ''}],
                textAddButton: 'DIAGNOSIS'
            },
            {
                assessmentPlanKey: assessmentsPlanKeys.medications,
                type: 'medicationsPrescribed',
                data: [null],
                noTextField: true,
                disabled: true,
                noAddOptions: true,
                veradigm: true,
                textData: [{placeholder: '', data: ''}],
                textAddButton: 'MEDICATIONS PRESCRIBED'
            },
            ...extra
        ]
    }
}
