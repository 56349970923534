import React, { Component } from "react";
import cn from "classnames";
import {colors} from "../../../assets/colors/colors";

export const Bell = props => (
  <div
    onClick={props.onClick}
    className={cn(props.className, "icon-component")}
    style={props.style}
  >
    {props.active ? <svg width="21" height="25" viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.55 6.91398L4.12 5.48398C1.72 7.31398 0.14 10.134 0 13.334H2C2.15 10.684 3.51 8.36398 5.55 6.91398ZM17.94 13.334H19.94C19.79 10.134 18.21 7.31398 15.82 5.48398L14.4 6.91398C16.42 8.36398 17.79 10.684 17.94 13.334ZM15.97 13.834C15.97 10.764 14.33 8.19398 11.47 7.51398V6.83398C11.47 6.00398 10.8 5.33398 9.97 5.33398C9.14 5.33398 8.47 6.00398 8.47 6.83398V7.51398C5.6 8.19398 3.97 10.754 3.97 13.834V18.834L1.97 20.834V21.834H17.97V20.834L15.97 18.834V13.834ZM9.97 24.834C10.11 24.834 10.24 24.824 10.37 24.794C11.02 24.654 11.55 24.214 11.81 23.614C11.91 23.374 11.96 23.114 11.96 22.834H7.96C7.97 23.934 8.86 24.834 9.97 24.834Z" fill={colors.navy}/>
          <circle cx="17" cy="4" r="3.5" fill="#FF6666" stroke="white"/>
        </svg>
         : <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.55 1.58L4.12 0.15C1.72 1.98 0.14 4.8 0 8H2C2.15 5.35 3.51 3.03 5.55 1.58V1.58ZM17.94 8H19.94C19.79 4.8 18.21 1.98 15.82 0.15L14.4 1.58C16.42 3.03 17.79 5.35 17.94 8ZM15.97 8.5C15.97 5.43 14.33 2.86 11.47 2.18V1.5C11.47 0.67 10.8 0 9.97 0C9.14 0 8.47 0.67 8.47 1.5V2.18C5.6 2.86 3.97 5.42 3.97 8.5V13.5L1.97 15.5V16.5H17.97V15.5L15.97 13.5V8.5ZM9.97 19.5C10.11 19.5 10.24 19.49 10.37 19.46C11.02 19.32 11.55 18.88 11.81 18.28C11.91 18.04 11.96 17.78 11.96 17.5H7.96C7.97 18.6 8.86 19.5 9.97 19.5Z"
        fill={'#5E6878'}
      />
    </svg>}
  </div>
);
