import {fetchV2, fetchWrapper} from "../../helpers/helpers";
import TwilioChat from 'twilio-chat'
import {setRequestsTypes} from "../constants/slice";
import {
    SET_USER_DATA,
    SET_TWILIO_CLIENT,
    SET_NOTIFICATIONS,
    SET_MESSAGES,
    UPDATE_NOTIFICATIONS,
    SET_REQUEST_TYPES
} from "./actionTypes";
import {converterDataField} from "../../utils/common";
import moment from "moment";
import {durationInSec, getTimeInFormat24} from "../../utils/date";
import otherSymptoms from "../../dist/img/otherSymptoms.png";

const setDataUser = payload => ({type: SET_USER_DATA, payload});
const setTwilioClient = payload => ({type: SET_TWILIO_CLIENT, payload});
export const setNotification = payload => ({type: SET_NOTIFICATIONS, payload});
export const setMessages = payload => ({type: SET_MESSAGES, payload});
const setRequestTypes = payload => ({type: SET_REQUEST_TYPES, payload});

export const updateNotification = payload => ({type: UPDATE_NOTIFICATIONS, payload});

const getDataUser = () => dispatch =>
    fetchWrapper({
        url: `users/${window.localStorage.getItem("user_uuid")}/`
    })
        .then(resp => {
            const data = converterDataField(resp, true);
            dispatch(setDataUser(data));
            return data;
        })
        .catch(resp => {
            return resp;
        });


const getTokenTwilio = () => dispatch =>
    fetchWrapper({
        url: `twilio/token/`
    })
        .then(resp => {
            TwilioChat.create(resp.twilio_token).then((client) => {
                if (client) {
                    dispatch(setTwilioClient(client))
                    client.on('tokenAboutToExpire', async () => {
                        fetchWrapper({
                            url: `twilio/token/`
                        }).then((resp) => {
                            client.updateToken(resp.twilio_token)
                        })
                    })
                }
            })
        })
        .catch(resp => {
            return resp;
        })


const getChatTwilioSid = ({request}) => {
    return fetchWrapper({
        url: `twilio/channel/${request}/`
    })
}

const setAllMessagesRead = ({request}) => {
    return fetchWrapper({
        method: 'PATCH',
        useLoading: false,
        url: `twilio/channel/${request}/unread_messages/`
    })
}


const getNotifications = (uuidRequest, chatIsOpen) => dispatch => {
    return fetchWrapper({
        url: `notifications/`,
        useLoading: false,
    }).then((resp) => {
        dispatch(setNotification(resp))
        if (uuidRequest && chatIsOpen) dispatch(updateNotification({uuid: uuidRequest}))
        return resp
    })
}

const getRequestTypes = (isAdmin) => dispatch =>
    fetchWrapper({
        url: 'service-requests/types/'
    })
        .then(resp => {
            const types = resp.map(i => i.name === 'common' || i.name === 'covid' ? {
                ...i,
                symptoms: [...i.symptoms, {name: "Other (You’ll Need to Specify)", uuid: "", image: otherSymptoms}]
            } : i)

            dispatch(setRequestsTypes({data: types, isAdmin: isAdmin}))
            dispatch(setRequestTypes(types));
        })
        .catch(resp => resp);

const getTimeSlots = (date, patientsNumber, address, zip_code) => dispatch => {
    const body = {}
    if (date) body.date = date
    if (zip_code) body.zip_code = zip_code
    if (patientsNumber) body.patients_number = patientsNumber
    if (address) body.address_line = address
    return dispatch(fetchV2({
        url: `shifts/day-slots/`,
        body
    })).then(resp => {
        const timeSlots = resp.data?.map(item => {
            const startTime = moment.utc(item.start.substring(0, 19)).format('hh:mm a');
            const endTime = moment.utc(item.end.substring(0, 19)).format('hh:mm a');
            const duration = durationInSec({start: item.start, end: item.end})
            return {
                duration: duration,
                value: moment.utc(item.start.substring(0, 19)).format('HH:mm'),
                label: `${startTime} - ${endTime}`,
                isFree: item.is_free
            };
        });
        return {
            ...resp,
            data: timeSlots
        };
    });
};

export {
    getDataUser,
    getTokenTwilio,
    getChatTwilioSid,
    setAllMessagesRead,
    getNotifications,
    getRequestTypes,
    getTimeSlots,
};
