import React, {useCallback, useEffect, useState} from "react";
import "./style.scss";
import {ItemRadio} from "../ItemRadio";
import {DisabledOverlay} from "../DisabledOverlay";
import {IconButton} from "../../../../Common/IconButton";
import {Text} from "../Text";

export const RadioSingle = ({data, updateState, DbData, disable}) => {
    const item = data.data[0]
    const updateStatus = () => updateState({...data, data: [{...item, checked: !item.checked}]});
    const updateText = (value) => updateState({...data, data: [{...item, data: value}]})
    const updateTitle = (value) => {
        updateState({...data, title: value})
    }
    return (
        <span className="emr-notes-radio-single">
            {data.title && <Text data={data.title} DbData={DbData} updateState={updateTitle} disabled={disable}/>}
            <span className='emr-notes-radio-single_radios'>
                <ItemRadio
                    data={item.data}
                    checked={item.checked}
                    last={false}
                    updateStatus={updateStatus}
                    updateText={updateText}
                />
                <ItemRadio
                    negative
                    data={item.data}
                    checked={!item.checked}
                    last
                    updateStatus={updateStatus}
                    updateText={updateText}
                />
            </span>
            <DisabledOverlay show={disable}/>
        </span>
    );
};
