import React from 'react';
import cn from 'classnames';
import './style.scss';

export const Symptom = ({ className, text }) => {
  return(
    <span className={cn('symptom', className)}>
      {text}
    </span>
  );
};
