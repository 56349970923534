import React, {useEffect, useState} from "react";
import "./style.scss";
import InputMask from "react-input-mask";
import _ from "lodash";
import {lengthObj} from "../../../../../utils/common";
import {DisabledOverlay} from "../DisabledOverlay";
import {checkIsVitalRequired} from '../../constants';
import cn from 'classnames'

export const InputStaticText = ({updateState, data, disabled, error}) => {
    const [config, setConfig] = useState(data.data)
    const [errors, setErrors] = useState({})
    const isError = !data.noMandatory && error ? config.find(i => !i.data) : checkIsVitalRequired(data);
    return (
        <span className='emr-notes-input-static-text-container'>
          {data.label}
            <span className={cn("emr-notes-input-static-text", {'emr-notes-input-static-text_error': isError })} onBlur={() => {
                let errorsValidation = {}
                config.forEach((i, idx, arr) => {
                    if(i.data === '') return
                    let value = Number(i.data.replace(/\_|\s/g, ''))
                    let prevValue = arr[idx - 1] && Number(arr[idx - 1].data.replace(/\_|\s/g, ''))
                    if (isNaN(value) ||
                        !(value <= i.max && value >= i.min) ||
                        (data.checkSecondSmallerFirst && prevValue && prevValue <= value)
                    ) errorsValidation[idx] = true
                })
                if (lengthObj(errorsValidation)) setErrors(errorsValidation)
                else {
                    setErrors({})
                    updateState({...data, data: config});
                }
            }}>
      {config.map((i, index) => {
          return (
              <>
                  <InputMask className="emr-notes-input-static-text_text"
                             value={i.data}
                             style={{border: errors[index] ? '1px solid red' : 'none'}}
                             onChange={(e) => {
                                 let value = e.target.value
                                 const isPeriod = value.split('.').length === 2
                                 const wasPoint = i.data.indexOf('.') + 1
                                 const deletePoint = value.indexOf('.')
                                 if(wasPoint && deletePoint === -1) value = i.data.replace(/\.[0-9]/, '')
                                 if(value.split('.').length > 2) return

                                 setConfig(config.map((item, idx) => index === idx ? {
                                     ...item,
                                     data: isPeriod ? (+value).toFixed(1) : value
                                 } : item))
                             }}
                             formatChars={{
                                 '9': '[0-9]',
                                 '8': '[0-9\.]'
                             }
                             }
                             maskChar=''
                             mask={i.mask}/>
                  {data.deviders[index] && (
                      <span className="emr-notes-input-static-text_devider">
              {data.deviders[index]}
            </span>
                  )}
              </>
          )
      })}
                <DisabledOverlay show={disabled}/>
    </span>
      </span>
    );
};
