import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import './style.scss'
import { ADMIN, buttonsInnerText, PENDING, routesCommon } from "../../../constants";
import DocumentZone from "../../../components/DoctorFlow/DocumentZone/DocumentZone";
import { UserInfo } from "../../../components/CreateReqest/StepRequestDataInfo/UserInfo";
import { Button } from "../../../components/Common/Button/Button";
import { VisitDetails } from "../../../components/AdminPage/VisitRequest/VisitDetails";
import history from "../../../helpers/history";
import { routersAdmin } from "../../../constants/Admin";
import { createMultipleRequest, getUserDataViaPhone } from "../../../store/admin/actions";
import moment from "moment";
import { convertPhoneValue, converterDataField } from "../../../utils/common";
import { Typography } from "../../../components/Common/Typography/Typography";
import { colors } from "../../../assets/colors/colors";

const multiVisitCreationConstants = {
  file: {
    title: 'Upload the XLSX file',
    subTitle: 'Make sure all fields are completed'
  },
  info: {
    title: 'Organiser',
    subTitle: 'Please Provide your contact information'
  },
  details: {
    title: 'Establish Visit Details'
  },
  mainTitle: {
    title: 'Multiple visit creation'
  }
};

const initialState = {
  lastName: "",
  firstName: "",
  birthday: "",
  phone: "",
  email: "",
  sex: ""
};

export const VisitFileRequestFlow = () => {
  const [file, setFile] = useState(null);
  const [user, setUser] = useState({ ...initialState });
  const [disabled, setDisabled] = useState(true);
  const [errors, setErrors] = useState({});
  const userRef = useRef(null);
  const visitRef = useRef(null);
  const dispatch = useDispatch();

  const updateFile = (file) => {
    if (file) {
      const format = file.name.split('.').reverse()[0];
      setFile({
        file: format,
        size: file.size,
        fileData: file,
        name: file.name,
        statusFile: PENDING
      });
      setErrors(prev => ({
        ...prev,
        file: false,
        non_field_errors: false
      }));
    }
  };

  const deleteFile = () => {
    setFile(null);
    setErrors(prev => ({
      ...prev,
      non_field_errors: false,
      file: false
    }));
  };

  const navigateToCalendar = () => {
    history.push(`${routesCommon.admin}${routersAdmin.calendar}`);
  };

  const getUserData = phone => {
    getUserDataViaPhone(phone).then(resp => {
      if(resp.uuid) {
        setDisabled(true);
        setUser(prev => ({
          ...prev,
          firstName: resp.first_name,
          lastName: resp.last_name,
          sex: resp.sex,
          phone: convertPhoneValue(resp.phone_number, true),
          email: resp.email,
          birthday: moment(resp.date_of_birth).format('MM/DD/YYYY')
        }));
        setErrors(prev => ({
          ...prev,
          ...initialState
        }));
      } else {
        setErrors(converterDataField(resp, true));
        throw new Error('');
      }
    }).catch(() => {
      setDisabled(false);
      setUser({ ...initialState, phone: convertPhoneValue(phone, true) });
    });
  };

  const setFieldsDisable = phone => {
    setDisabled(true);
    setUser({ ...initialState, phone });
  };

  const create = async () => {
    const userInfo = userRef.current?.getUserInfo();
    const visitDetails = await visitRef.current?.getVisitDetails();
    if(!file) {
      setErrors({
        file: 'Please upload the list of patients.'
      });
    }
    if(!!userInfo && !!visitDetails && !!file) {
      const data = new FormData();
      const start = visitDetails?.startTime?.value
      const end = visitDetails?.endTime?.value
      const startTime = moment(
          `${moment(visitDetails.date).format("YYYY-MM-DD")}-${start}-00`,
          "YYYY-MM-DD-HH:mm-ss"
      );
      let endTime = moment(
          `${moment(visitDetails.date).format("YYYY-MM-DD")}-${end}-00`,
          "YYYY-MM-DD-HH:mm-ss"
      );

      if (endTime.isBefore(startTime)) {
        endTime = endTime.add(1, 'days');
      }
      data.append('file', file?.fileData);
      data.append('doctor_uuid', visitDetails ? visitDetails?.assigned.value : '');
      data.append('requestor_phone_number', userInfo.phone);
      data.append('requestor_first_name', userInfo.firstName);
      data.append('requestor_last_name', userInfo.lastName);
      data.append('requestor_email', userInfo.email);
      data.append('requestor_date_of_birth', moment(userInfo.birthday).format('YYYY-MM-DD'));
      data.append('requestor_sex', userInfo.sex);
      data.append('application_can_start_at', moment(startTime.valueOf()).format("YYYY-MM-DDTHH:mm:ssZ"));
      data.append('application_time', moment(endTime.valueOf()).format("YYYY-MM-DDTHH:mm:ssZ"));
      data.append('service_type', visitDetails?.type.value.replace(/-/g,'_'));
      data.append('zip_code', visitDetails?.zipcode);
      data.append('address_line', visitDetails?.address.address);
      data.append('address', visitDetails?.address.street)
      data.append('apartment', visitDetails?.apartment);
      data.append('city', visitDetails?.address.city);
      data.append('comment', visitDetails?.comment);
      data.append('state', visitDetails?.address.state);
      dispatch(() => {
        createMultipleRequest(data)
          .then(resp => {
            if(!resp.created) {
              setErrors(converterDataField(resp, true));
              throw new Error('');
            } else {
              history.push(`${routesCommon.admin}${routersAdmin.calendar}`);
            }
          })
          .catch(() => {
            history.push(`${routesCommon.admin}${routersAdmin.visitFile}`);
          })
      });
    }
  };

  return (
    <div className='requests-file'>
      <div className='requests-file_title'>
        {multiVisitCreationConstants.mainTitle.title}
      </div>
      <Section
        title={multiVisitCreationConstants.file.title}
        subTitle={multiVisitCreationConstants.file.subTitle}
      >
        <DocumentZone
          flag={ADMIN}
          file={file}
          noSendData
          onDelete={deleteFile}
          updateState={updateFile}
          outerError={errors.file}
        />
        {(errors.file || errors.non_field_errors) && (
          <Typography
            variant="h5"
            text={errors.file || errors.non_field_errors}
            color={colors.redError}
            className="custom-input-container_error"
          />
        )}
      </Section>
      <Section
        title={multiVisitCreationConstants.info.title}
        subTitle={multiVisitCreationConstants.info.subTitle}
      >
        <Typography
          text="If your Cell Phone # is registered with us, these fields will automatically populate."
          variant="p"
          color="black"
          className="requests-file__notification"
        />
        <UserInfo
          errorsFromResp={errors}
          initialState={initialState}
          simpleUpdateState={() => {}}
          isMultipleRequest
          user={user}
          isUser
          ref={userRef}
          disabled={disabled}
          getUserData={getUserData}
          setFieldsDisable={setFieldsDisable}
        />
      </Section>
      <Section
        title={multiVisitCreationConstants.details.title}
        subTitle={multiVisitCreationConstants.details.subTitle}
      >
        <VisitDetails
          ref={visitRef}
        />
      </Section>
      <Footer createAction={create} cancelAction={navigateToCalendar}/>
    </div>
  );
};

const Section = ({children, title, subTitle}) => {
  return (
    <div className='requests-file_section'>
      <div className='requests-file_section_header'>
        <span className='requests-file_section_header_title'>{title}</span>
        {subTitle && <span className='requests-file_section_header_sub-title'>{subTitle}</span>}
      </div>
      <div className='requests-file_section_body'>
        {children}
      </div>
    </div>
  );
};

const Footer = ({cancelAction, createAction}) => {
  return (
    <div className='requests-file_footer'>
      <div className='requests-file__buttons'>
        <Button
          text={buttonsInnerText.cancel}
          size="middle"
          variant="default"
          disabled={false}
          onClick={cancelAction}
        />
        <Button
          text={buttonsInnerText.create}
          size="middle"
          variant="primary"
          disabled={false}
          onClick={createAction}
        />
      </div>
    </div>
  );
};
