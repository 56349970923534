
import React, {useEffect, useMemo, useRef, useState} from "react";
import ReactDOM from 'react-dom'
import "./style.scss";
import { Typography } from "../../../../../Common/Typography/Typography";
import { colors } from "../../../../../../assets/colors/colors";
import { ButtonMenu } from "../../../../../Common/ButtonMenu/ButtonMenu";
import { Delete, Edit, Eye, CloudDownload} from "../../../../../Common/Icons";
import { Button } from "../../../../../Common/Button/Button";
import {BTN_LABELS, buttonsInnerText} from "../../../../../../constants";
import { CreatorEditorFollowUp } from "../CreaterEditorFollowUp";
import { Checkbox } from "../../../../../Common/Checkbox/Checkbox";
import {
  checkFileFormat,
  lengthObj,
  removeLoadingPdf,
  setFormatBE,
  showPreview
} from "../../../../../../utils/common";
import pureFolder from "../../../../../../dist/icons/pureFolder.svg";
import {toast} from "react-toastify";
import {SendAttachFollowNotes} from "../SendAttachFollowNotes";
import conversionBytes from "../../../../../../utils/conversionBytes";
import {PopupContainer} from "../../../../../Common/Popup/PopupContainer";
import {DropDown} from "../../../../../Common/DropDown/DropDown";
import {ContainerWithActions} from "../../../../../Common/ContainerWithActions/ContainerWithActions";
import {FileBlock} from "../../../../../Common/FileBlock/FileBlock";
import {EditorExcuse} from "../EditorExcuse";
import {GenerationPDFnotes} from "../../../../GenerationPDFnotes/GenerationPDFnotes";
import {EditorReceipt} from "../EditorReceipt/EditorReceipt";
import {PdfGenerator} from "../../../componentsPDF/PdfGenerator/PdfGenerator";
import fileDownload from "js-file-download";
import {configPMH, configPMHExtra} from "../../../../Templates/constants";
import {EditorCovid} from "../EditorCovid";
import {getSingleFromGroupRequest} from "../../../../../../store/request/utils";
import {completeRequest as completeRequestAction} from "../../../../../../store/request/actions";
import {useDispatch} from "react-redux";

const types = {
    attachment: "attachment",
    follow: "follow",
    note: "note"
};

const modesNotes = {
    preview: {mode: 'preview'},
    download: {mode: 'download'},
    complete: {mode: 'complete'},
    sending: {
        mode: 'sending'
    }
};

export const FollowAttachementNotes = props => {
    const [popup, openPopup] = useState(false);
    const [selectedFollowUps, setSelectedFollowUps] = useState([]);
    const [editFollowUp, setEditFollowUp] = useState(null);
    const [selectedAttachments, setSelectedAttachments] = useState([]);
    const [attachmentEdit, setAttachmentEdit] = useState(null);
    const [openSendSelected, setOpenSendSelected] = useState(false);
    const [typeSendingBlock, setTypeSendingBlock] = useState("");
    const [openSelectedPopup, setOpenSelectedPopup] = useState(false)
    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [excusePopup, setExcusePopup] = useState(false)
    const [covidPopup, setCovidPopup] = useState(false)
    const [receiptPopup, setReceiptPopup] = useState(false)
    const [notesView, setNotesView] = useState([])
    const [selectedNotesView, setSelectedNotesView] = useState([])
    const [receiptStatus, setReceiptStatus] = useState(null)
    const [renderFolloUps, setRenderFollowUps] = useState(null)
    const [modeVisitNotes, setModeVisitNotes] = useState(null)

    const [updateEcsueAfterCompleted, setUpdateEcsueAfterCompleted] = useState(false)
    const [updateCovidAfterCompleted, setUpdateCovidAfterCompleted] = useState(false)
    const [updateFollowUpsAfterCompleted, setUpdateFollowUpsAfterCompleted] = useState(false)
    const [openConfirmSendPatient, setOpenConfirmSendPatient] = useState(false)

    const dispatch = useDispatch()
    const targetRequest = useMemo(()=>{
        return getSingleFromGroupRequest({request: props.dataRequest, uuid: props.id})
    },[props.dataRequest?.timestamp]);

    useEffect(() => {
        setReceiptStatus(props.receiptData.status)
    }, [props.receiptData?.status])

    useEffect(() => {
        if (renderFolloUps) sendFollowUpsDocument(renderFolloUps)
        if (modeVisitNotes) generatePdfForNote()
    })

    useEffect(()=>{
        if(updateFollowUpsAfterCompleted){
            setModeVisitNotes(modesNotes.complete)
        }
    }, [JSON.stringify(props.followUps)])

    const openExcuse = () => {
        setExcusePopup(true)
    }
    const closeExcuse = () => {
        setExcusePopup(false)
    }

    const openCovid = () => {
        setCovidPopup(true)
    }
    const closeCovid = () => {
        setCovidPopup(false)
    }

    const openReceipt = () => setReceiptPopup(true)
    const closeReceipt = () => setReceiptPopup(false)

    const inputFile = useRef(null);

    const handleSelectedTemplate = (field, value) => {
        setSelectedTemplate(value)
    }
    const openFollowUp = () => {
        openPopup(true);
    };
    const closeFollowUp = () => {
        setEditFollowUp(null);
        openPopup(false);
    };
    const openPopupSend = type => {
        setTypeSendingBlock(type);
        setOpenSendSelected(true);
    };

    const closePopupSend = () => {
        setTypeSendingBlock("");
        setOpenSendSelected(false);
    };

    const updateExcuse = (body) => props.createUpdateExcuse(targetRequest.uuid, !props.excuse, body).then(()=>{
        if('request completed' === targetRequest?.status_request?.toLowerCase()) {
            setUpdateEcsueAfterCompleted(true)
            setModeVisitNotes(modesNotes.complete)
        }
    })

    const updateCovid = (body) => props.createUpdateCovid(targetRequest.uuid, !props.covid, body).then(()=>{
        if('request completed' === targetRequest?.status_request?.toLowerCase()) {
            setUpdateCovidAfterCompleted(true)
            setModeVisitNotes(modesNotes.complete)
        }
    })
    const handlerInputFile = event => {
        const file = event?.target?.files[0];
        if (file) {
            let checking = checkFileFormat({size: file.size, path: file.name});
            if (!checking) {
                toast("Is not valid format or size", {autoClose: 3000});
                return;
            }
            const format = file.name.split(".").reverse()[0];
            let fileName = file.name.split(".");
            fileName.pop();
            fileName = fileName.join("");
            setAttachmentEdit([{format: format, name: fileName, fileLocal: file, size: file.size}]);
        }
    };

    const sendFile = name => {
        let reader = new FileReader();

        reader.onloadend = async () => {
            let data = new FormData();
            data.append("file", attachmentEdit[0].fileLocal);
            data.append("name", name);
            props
                .createUpdateAttachments(data, targetRequest.uuid, null, true)
                .then(resp => {
                    if (resp.uuid) {
                        closeEditMode()
                    }
                });
        };

        reader.readAsDataURL(attachmentEdit[0].fileLocal);
    };

    const updateName = value => {
        props
            .createUpdateAttachments(
                {name: value},
                targetRequest.uuid,
                attachmentEdit[0].uuid,
                false
            )
            .then(resp => {
                if (resp.uuid) {
                    closeEditMode()
                }
            });
    };
    const saveFollowUp = async (name, text) => {

        props.createUpdateFollowUp(
            {name: name, content: text},
            targetRequest.uuid,
            editFollowUp?.uuid
        )
            .then(resp => {
                if (resp.uuid) {
                    closeFollowUp();

                    if('request completed' === targetRequest?.status_request.toLowerCase()) {
                        setUpdateFollowUpsAfterCompleted(true)
                    }
                }
            });
    };

    const handlerFollowUp = item => {
        const isItemSelected = selectedFollowUps.find(i => i.uuid === item.uuid);
        if (isItemSelected)
            setSelectedFollowUps(selectedFollowUps.filter(i => i.uuid !== item.uuid));
        else setSelectedFollowUps([...selectedFollowUps, item]);
    };
    const handlerAttachment = item => {
        const isItemSelected = selectedAttachments.find(i => i.uuid === item.uuid);
        if (isItemSelected)
            setSelectedAttachments(
                selectedAttachments.filter(i => i.uuid !== item.uuid)
            );
        else setSelectedAttachments([...selectedAttachments, item]);
    };

    const closeEditMode = () => {
        setAttachmentEdit(null);
        inputFile.current.value = null
    };
    const saveAttachmentsEdit = name => {
        if (attachmentEdit[0].fileLocal) sendFile(name);
        else updateName(name);
    };

    const sendAttachmentDocument = (pcpUuid, type, patient) => {
        const body = {
            service_request: props.id,
            pcps: pcpUuid,
            attachments: selectedAttachments.map(i => i.uuid)
        }
        if (patient) body.patient = patient
        props.sendSelectedAttachments(body, type).then(resp => {
            closePopupSend()
            setSelectedAttachments([])
        })
    }


    const sendFollowUpsDocument = ({pcpUuid, type, patient, download}) => {
        let followupRef = (download ? [renderFolloUps] : selectedFollowUps)
            .map((i, index) => showPreview(document.getElementById(i.uuid), i.name, false, download, true).then(resp => resp))
        Promise.all(followupRef).then(resp => {
            if (!download) {
                let data = new FormData();
                data.append('service_request', props.id)
                patient && data.append('patient', patient)
                pcpUuid.length && pcpUuid.forEach(i => data.append('pcps', i))
                resp.map((element, i) => data.append('files', element, `FollowUp_i.pdf`));
                props.sendSelectedAttachments(data, type, true).then(resp => {
                    closePopupSend()
                    setSelectedFollowUps([])
                })
            }
            removeLoadingPdf()
        }).finally(() => {
            setRenderFollowUps(null)
        })
    }

    const sendToSelected = (pcpUuid, type, patient, pcpUuidCovid) => {
        if (type === "attachment") sendAttachmentDocument(pcpUuid, type, patient)
        else if (type === 'follow') setRenderFollowUps({pcpUuid, type, patient})
        else setModeVisitNotes({...modesNotes.sending, pcpUuid, type, patient, pcpUuidCovid})
    };

    const viewSelectedTemplate = (name) => {
        if (selectedNotesView.find(i => i === name)) setSelectedNotesView(selectedNotesView.filter(i => i !== name))
        else setSelectedNotesView([...selectedNotesView, name])
    }
    const viewTemplate = (name, downloadMode) => {
        setNotesView([name])
        setModeVisitNotes(downloadMode ? modesNotes.download : modesNotes.preview)
    }

    const updateReceipt = (cptCodeValues, status) => {
        const body = {
            receipt_elements: cptCodeValues.map(i => ({
                uuid: i.uuid,
                cpt: i.cpt?.value || null,
                price: i.price,
            })),
            status: status
        }

        return props.getReceipt(props.id, body).then(resp => {
            closeReceipt()
            return resp
        })
    }

    const availableToSent = (data, noteName) => {
        let status = true;
        if(data?.status) {
            data.status === 'final' || data.status === 'sent' ? status = true : status = false;
        }
        return status && selectedNotesView.find(i => i === noteName);
    };

    const sendToPatient = () => {
        const optionsNotes = [];
        if(availableToSent(props.excuse, 'excuse')) optionsNotes.push('excuse');
        if(availableToSent(props.covid, 'covid')) optionsNotes.push('covid');
        if(availableToSent(props.dischargeData, 'discharge')) optionsNotes.push('discharge');
        if(availableToSent(props.receiptData, 'receipt')) optionsNotes.push('receipt');
        const pdfs = optionsNotes.map(i => showPreview(document.getElementById(`pdf-prewie-${i}`), i, false, false, true).then(resp => resp));
        Promise.all([...pdfs]).then(resp => {
            let data = new FormData();
            const groupNote = resp.splice(0 , optionsNotes.length);
            groupNote.forEach((element, index) => {
                let name = optionsNotes[index];
                if(name === 'covid') name = 'covid_test_result';
                data.append(name, element, `${name}.pdf`);
            });
            setUpdateEcsueAfterCompleted(false);
            setUpdateCovidAfterCompleted(false);
            removeLoadingPdf();
            props.sendSelectedNotes(data, targetRequest.uuid, optionsNotes).then(resp => {
                closePopupSend()
                updatePMHfromRequest()
                setSelectedNotesView([])
            })
        }).finally(() => {
            setModeVisitNotes(null);
        });
    };

    const sendToPcp = () => {
        const optionsNotes = [];
        if(availableToSent(props.excuse, 'note')) optionsNotes.push('note');
        if(availableToSent(props.covid, 'covid')) optionsNotes.push('covid');
        const pdfs = optionsNotes.map(i => showPreview(document.getElementById(`pdf-prewie-${i}`), i, false, false, true).then(resp => resp));
        Promise.all(pdfs).then(resp => {
            const data = new FormData();
            modeVisitNotes.pcpUuid.forEach(i => data.append('pcps', i));
            modeVisitNotes.pcpUuidCovid.forEach(i => data.append('pcps_covid', i));
            resp.forEach((element, index) => {
                let name = optionsNotes[index];
                if(name === 'covid') name = 'covid_test_result';
                if(name === 'note') name = 'emr_note';
                data.append(name, element, `${name}.pdf`);
            });
            removeLoadingPdf();
            props.sendSelectedNotes(data, targetRequest.uuid, optionsNotes).then(() => {
                closePopupSend();
                updatePMHfromRequest();
                setSelectedNotesView([]);
            })
        }).finally(() => {
            setModeVisitNotes(null);
        });
    };

    const completeRequest = () => {
        let followUps = (updateEcsueAfterCompleted || updateCovidAfterCompleted) ? [] : props.followUps.map((i, index) => showPreview(document.getElementById(i.uuid), i.name, false, false, true).then(resp => resp))
        let optionsNotes = ['receipt', 'discharge']
        if(props.excuse) optionsNotes.push('excuse')
        if(props.covid) optionsNotes.push('covid')
        if(updateEcsueAfterCompleted) optionsNotes = ['excuse']
        if(updateCovidAfterCompleted) optionsNotes = ['covid']
        if(updateFollowUpsAfterCompleted) optionsNotes = []
        let pdfs = optionsNotes.map((i, index) =>
            showPreview(document.getElementById(`pdf-prewie-${i}`), i, false, false, true).then(resp => resp))
        Promise.all([...pdfs, ...followUps]).then(resp => {
            let data = new FormData();
            const groupNote = resp.splice(0 , optionsNotes.length)
            const groupFollowUps = resp
            groupNote.forEach((element, index) => {
                let name = optionsNotes[index]
                if(name === 'covid') name = 'covid_test_result'
                data.append(name, element, `${name}.pdf`)
            });
            groupFollowUps.forEach((element, index) => {
                data.append('follow_ups', element, `Follow_Up.pdf`)
            });
            setModeVisitNotes(null);
            setUpdateEcsueAfterCompleted(false);
            setUpdateCovidAfterCompleted(false);
            setUpdateFollowUpsAfterCompleted(false);
            removeLoadingPdf();
            dispatch(completeRequestAction(data, targetRequest, props.dataRequest, props.listLastRequests)).then(() => {
                updatePMHfromRequest()
            })
        });
    };

    const generatePdfForNote = () => {
        if (modesNotes.preview.mode === modeVisitNotes?.mode) {
            const elem = document.getElementById(`pdf-prewie-${notesView[0]}`);
            showPreview(elem, notesView[0], true, false).then(() => {
                setModeVisitNotes(null);
            });
        } else if (modesNotes.sending.mode === modeVisitNotes?.mode) {
            if(modeVisitNotes?.sendTo === 'patient') {
                sendToPatient();
            } else {
                sendToPcp();
            }
        } else if (modesNotes.complete.mode === modeVisitNotes?.mode) {
            completeRequest();
        } else if (modesNotes.download.mode === modeVisitNotes?.mode) {
            let elem = document.getElementById(`pdf-prewie-${notesView[0]}`);
            showPreview(elem, notesView[0], true, true).then(() => {
                setModeVisitNotes(null);
            });
        }
    }

    const updatePMHfromRequest = () => {
        if(props.templateData.status === 'sent' || targetRequest?.status_request?.toLowerCase() === 'request completed') return
        let dataPMH = {}
        props.templateData.body.forEach(i => i.items.forEach((y) => {
            if (configPMH[y.type]) {
                dataPMH[y.type] = [...props.pmhDetailInfo[y.type], ...y.data]
            } else if (configPMHExtra[y.type]) {
                const field = y.type === 'diagnoses' ? 'pastMedicalHistory' : 'medications'
                dataPMH[field] = [...(dataPMH[field] || []), ...y.data]
            }
        }))
        dataPMH = {
            // medicationAllergies: dataPMH.medicationAllergies.map(i => i.label).filter(el => el),
            // medications: dataPMH.medications.map(i => i.label).filter(el => el),
            appointedAllergies: props.pmhDetailInfo.appointedAllergies,
            appointedMedications: props.pmhDetailInfo.appointedMedications,
            familySocialHistory: dataPMH.familySocialHistory.filter(i=>i).map(i => ({name: i.label})),
            pastMedicalHistory: dataPMH.pastMedicalHistory.filter(i=>i).map((i) => ({name: i.label, code: i.code})),
        }
        props.getUpdatePMHinfo(targetRequest?.patient_uuid, setFormatBE(dataPMH), true)
    }
    const isRecipt = props.receiptData?.status
    const isDischarge = props.dischargeData?.status
    const isExcuse = props.excuse?.status
    const isCovid = props.covid?.status
    const isCovidSent = isCovid === 'sent'
    const isVisitNote = props.templateData?.status
    const reciptNotDraft = isRecipt !== 'draft'
    const visitNoteNotDraft = isVisitNote !== 'draft'
    const doesReceiptSent = isRecipt === 'sent'
    const doesNoteSent = isVisitNote === 'sent'
    const doestDischargeSent = isDischarge === 'sent'
    const requestIsCompleted = targetRequest?.status_request && ['request completed', 'declined'].find(i => i === targetRequest?.status_request.toLowerCase())
const requestIsCompletedRight = targetRequest?.status_request && 'request completed' === targetRequest?.status_request.toLowerCase()
    const canDelete = isVisitNote !== 'sent' && !requestIsCompleted && props.isCurrentDoctor && !doestDischargeSent
    const showCompleteButton = reciptNotDraft && visitNoteNotDraft && isRecipt && isVisitNote && targetRequest?.status_request.toLowerCase() === 'visit completed' && props.isCurrentDoctor
    const canSelectNotes = !visitNoteNotDraft;
    let useSelectedNoteOrNot = modeVisitNotes?.mode === modesNotes.complete.mode ?
        ['receipt', 'discharge', 'excuse', 'covid'] :
        modeVisitNotes?.mode === modesNotes.preview.mode || modeVisitNotes?.mode === modesNotes.download.mode ?
            notesView : selectedNotesView

    if(modeVisitNotes?.mode === modesNotes.complete.mode && !props.excuse){
        useSelectedNoteOrNot = useSelectedNoteOrNot.filter(i => i !== 'excuse')
    }
    if(modeVisitNotes?.mode === modesNotes.complete.mode && !props.covid){
        useSelectedNoteOrNot = useSelectedNoteOrNot.filter(i => i !== 'covid')
    }

    let usedFollowUps =  modeVisitNotes?.mode === modesNotes.complete.mode ? props.followUps :
        (renderFolloUps ? (renderFolloUps.download ? [renderFolloUps] : selectedFollowUps) : [])

    if(updateEcsueAfterCompleted && !updateFollowUpsAfterCompleted){
        usedFollowUps = []
        useSelectedNoteOrNot = ['excuse']
    }
    if(updateCovidAfterCompleted && !updateFollowUpsAfterCompleted){
        usedFollowUps = []
        useSelectedNoteOrNot = ['covid']
    }
    if(updateFollowUpsAfterCompleted && !updateEcsueAfterCompleted){
        useSelectedNoteOrNot = []
    }
    if(updateFollowUpsAfterCompleted && !updateCovidAfterCompleted){
        useSelectedNoteOrNot = []
    }

    const disableSendToPatient = props.role === 'admin' && !requestIsCompletedRight
    return (
        <>
            {usedFollowUps.map((i, index) => <PdfGenerator data={i} date={i.createdAt} uuid={i.uuid} index={index} name={i.name}
                                                 signature={props.signature}
                                                 dataRequest={props.dataRequest?.data}
                                                 content={i.content}/>)}
            {/*{<PdfGenerator/>}*/}
            {(modeVisitNotes) && <GenerationPDFnotes className='selected_template'
                                                     notesView={useSelectedNoteOrNot}
                                                     targetRequest={targetRequest}
                // notesView={['discharge']}
                                                     {...props}
                                                     dataRequest={props.dataRequest?.data}
            />}
            <div className="follow-attachement-notes">
                {props.templateData ? (
                    <ContainerWithActions
                        title="EMR Note"
                        hideAddItem
                        sendHandler={() => {
                            openPopupSend(types.note);
                        }}
                        deleteItem={() => {}}
                        selectedElements={selectedNotesView}
                        className="container-with-actions__request"
                        isNotes
                        doctors={props.pcpDoctors}
                        sendToPatientHandler={() => {
                            if(props.role === 'admin') setOpenConfirmSendPatient(true)
                            else setModeVisitNotes({ ...modesNotes.sending, sendTo: 'patient' })
                        }}
                    >
                        <div
                            className="follow-attachement-notes_notes-item"
                        >
                            <div className='follow-attachement-notes_notes-item_actions'>
                                <Checkbox
                                    field='note'
                                    onClick={viewSelectedTemplate}
                                    disabled={canSelectNotes || disableSendToPatient}
                                    checked={selectedNotesView.find(i => i === 'note')}
                                    label={
                                        <>
                                            <Typography variant="p" text="Visit Note "/>
                                            <Typography
                                                variant="p"
                                                color={colors.greyMiddle}
                                                text="(Only for PCP)"
                                            />
                                        </>
                                    }
                                />
                                <button className='no-default-style-button'
                                        onClick={() => viewTemplate('note')}>
                                    <Eye/>
                                </button>
                            </div>
                            <Typography
                                className='follow-attachement-notes_notes-item_status'
                                variant="h5"
                                color={colors.navy}
                                capitalize
                                text={props.templateData.status}
                            />
                            <ButtonMenu className='notes-item_menu'>
                                {!doesNoteSent && !requestIsCompleted && props.isCurrentDoctor && !doestDischargeSent &&
                                <li className='follow-attachement-notes_li-edit' onClick={props.redirectToTemplate}>
                                    <Edit/><Typography variant="p" text="Edit"/></li>}
                                <li className='follow-attachement-notes_li-download'
                                    onClick={() => viewTemplate('note', true)}><CloudDownload color='#5F6368'/>
                                    <Typography
                                        variant="p" text="Download"/>
                                </li>
                                {canDelete && <li className='follow-attachement-notes_li-delete'
                                                  onClick={() => props.deleteVisitNote(targetRequest.uuid)}><Delete
                                    color='#5F6368'/> <Typography
                                    variant="p" text="Delete"/>
                                </li>}

                            </ButtonMenu>
                        </div>
                        <div
                            className="follow-attachement-notes_notes-item"
                        >
                            <div className='follow-attachement-notes_notes-item_actions'>
                                <Checkbox field='discharge'
                                          onClick={viewSelectedTemplate}
                                          checked={selectedNotesView.find(i => i === 'discharge')}
                                          disabled={canSelectNotes || disableSendToPatient} label='Discharge Summary'/>
                                <button disabled={!isDischarge} className='no-default-style-button'
                                        onClick={() => viewTemplate('discharge')}>
                                    <Eye color={!!isDischarge ? colors.navy : colors.grey}/>
                                </button>
                            </div>
                            <Typography
                                className='follow-attachement-notes_notes-item_status'
                                variant="h5"
                                color={colors.navy}
                                capitalize
                                text={isDischarge}
                            />
                            {isDischarge && <ButtonMenu className='notes-item_menu'>
                                <li className='follow-attachement-notes_li-download'
                                    onClick={() => viewTemplate('discharge', true)}><CloudDownload color='#5F6368'/>
                                    <Typography
                                        variant="p" text="Download"/>
                                </li>
                            </ButtonMenu>}
                        </div>
                        <div
                            className="follow-attachement-notes_notes-item"
                        >
                            <div className='follow-attachement-notes_notes-item_actions'>
                                <Checkbox
                                    field='receipt'
                                    onClick={viewSelectedTemplate}
                                    disabled={!reciptNotDraft || canSelectNotes || disableSendToPatient}
                                    checked={selectedNotesView.find(i => i === 'receipt')}
                                    label='Patient Receipt'/>
                                <button disabled={!(isRecipt && reciptNotDraft)}
                                        className='no-default-style-button'
                                        onClick={() => viewTemplate('receipt')}>
                                    <Eye color={isRecipt && reciptNotDraft ? colors.navy : colors.grey}/>
                                </button>
                            </div>
                            <Typography
                                className='follow-attachement-notes_notes-item_status'
                                variant="h5"
                                color={colors.navy}
                                capitalize
                                text={isRecipt}
                            />
                            {isRecipt && <ButtonMenu className='notes-item_menu'>
                                {!doesReceiptSent && !requestIsCompleted && props.isCurrentDoctor &&
                                <li className='follow-attachement-notes_li-edit' onClick={openReceipt}>
                                    <Edit/><Typography variant="p" text="Edit"/></li>}
                                <li className='follow-attachement-notes_li-download'
                                    onClick={() => viewTemplate('receipt', true)}><CloudDownload color='#5F6368'/>
                                    <Typography
                                        variant="p" text="Download"/>
                                </li>
                            </ButtonMenu>}
                        </div>
                        <div
                            className="follow-attachement-notes_notes-item"
                        >
                            <div className='follow-attachement-notes_notes-item_actions'>
                                <Checkbox
                                    field='covid'
                                    disabled={!isCovid || disableSendToPatient}
                                    onClick={viewSelectedTemplate}
                                    checked={selectedNotesView.find(i => i === 'covid')}
                                    label='COVID-19 Test Results'/>
                                <button disabled={!isCovid} className='no-default-style-button'
                                        onClick={() => viewTemplate('covid')}>
                                    <Eye color={isCovid ? colors.navy : colors.grey}/>
                                </button>
                            </div>
                            <Typography
                                className='follow-attachement-notes_notes-item_status'
                                variant="h5"
                                color={colors.navy}
                                capitalize
                                text={isCovid}
                            />
                            <ButtonMenu className='notes-item_menu'>
                                {props.isCurrentDoctor && !isCovidSent &&
                                <li className='follow-attachement-notes_li-edit' onClick={openCovid}><Edit/><Typography
                                    variant="p" text="Edit"/></li>}
                                <li className='follow-attachement-notes_li-download'
                                    onClick={() => viewTemplate('covid', true)}><CloudDownload color='#5F6368'/>
                                    <Typography
                                        variant="p" text="Download"/>
                                </li>
                            </ButtonMenu>
                        </div>
                        <div
                            className="follow-attachement-notes_notes-item"
                        >
                            <div className='follow-attachement-notes_notes-item_actions'>
                                <Checkbox
                                    field='excuse'
                                    disabled={!isExcuse || disableSendToPatient}
                                    onClick={viewSelectedTemplate}
                                    checked={selectedNotesView.find(i => i === 'excuse')}
                                    label='Excuse From Work/School'/>
                                <button disabled={!isExcuse} className='no-default-style-button'
                                        onClick={() => viewTemplate('excuse')}>
                                    <Eye color={isExcuse ? colors.navy : colors.grey}/>
                                </button>
                            </div>
                            <Typography
                                className='follow-attachement-notes_notes-item_status'
                                variant="h5"
                                color={colors.navy}
                                capitalize
                                text={isExcuse}
                            />
                            <ButtonMenu className='notes-item_menu'>
                                {props.isCurrentDoctor &&
                                <li className='follow-attachement-notes_li-edit' onClick={openExcuse}><Edit/><Typography
                                    variant="p" text="Edit"/></li>}
                                <li className='follow-attachement-notes_li-download'
                                    onClick={() => viewTemplate('excuse', true)}><CloudDownload color='#5F6368'/>
                                    <Typography
                                        variant="p" text="Download"/>
                                </li>
                            </ButtonMenu>
                        </div>
                    </ContainerWithActions>
                ) : (
                    props.isCurrentDoctor && <div className="follow-attachement-notes_notes-button">
                        <Button
                            text={buttonsInnerText.create_emr_notes}
                            size="large"
                            variant="primary"
                            onClick={() => setOpenSelectedPopup(true)}
                        />
                    </div>
                )}
                <ContainerWithActions
                    title="Attachment"
                    sendHandler={() => openPopupSend(types.attachment)}
                    hideButtonMenu
                    hideAddItem={attachmentEdit}
                    addItem={() => {
                        inputFile.current.click();
                    }}
                    selectedElements={selectedAttachments}
                    className="container-with-actions__request"
                >
                    {(attachmentEdit || props.attachments).map((i, index) => {
                        const checked = selectedAttachments.find(y => y.uuid === i.uuid)
                        const canManage = i.created_by?.uuid === (props.doctorData?.user?.uuid || props.doctorData?.uuid)
                        return (
                            <div key={index} className="attachment-item">
                                <FileBlock
                                    select={() => {
                                        handlerAttachment(i);
                                    }}
                                    size={i.size}
                                    edit={attachmentEdit}
                                    cancel={closeEditMode}
                                    save={saveAttachmentsEdit}
                                    checked={checked}
                                    file={i}
                                />
                                {!attachmentEdit && (
                                    <ButtonMenu>
                                        {canManage && <><li
                                            onClick={() => {
                                                setAttachmentEdit([i]);
                                            }}
                                            className='attachment-item_button-menu follow-attachement-notes_li-edit'
                                        >
                                            <Edit/>
                                            <Typography variant="p" text="Edit"/>
                                        </li>
                                        <li
                                            onClick={() => {
                                                props
                                                    .deleteAttachments(i.uuid, targetRequest.uuid)
                                                    .then(resp => {
                                                        if (resp) setSelectedAttachments(selectedAttachments.filter(item => item.uuid !== i.uuid));
                                                    });
                                            }}
                                            className='attachment-item_button-menu follow-attachement-notes_li-delete'
                                        >
                                            <Delete/>
                                            <Typography variant="p" text="Delete"/>
                                        </li> </>}
                                        <li
                                            className='follow-attachement-notes_li-download'
                                        >
                                            <a download href={i.file_download_link}>
                                                <CloudDownload color='#5F6368'/> <Typography variant="p"
                                                                                             text="Download"/>
                                            </a>
                                        </li>
                                    </ButtonMenu>
                                )}
                            </div>
                        );
                    })}
                    <input
                        type="file"
                        ref={inputFile}
                        onChange={handlerInputFile}
                        className="attachment-input-file"
                    />
                </ContainerWithActions>
                <ContainerWithActions
                    sendHandler={() => openPopupSend(types.follow)}
                    addItem={() => {
                        openFollowUp();
                    }}
                    hideAddItem={props.role === 'admin'}
                    hideButtonMenu
                    title="Follow Ups"
                    selectedElements={selectedFollowUps}
                    className="container-with-actions__request"
                >
                    {props.followUps.map((i, index) => {
                        const checked = selectedFollowUps.find(y => y.uuid === i.uuid)
                        const canManage = i.createdBy?.uuid === props.doctorData?.uuid
                        return (
                            <div key={index} className="follow-ups-item">
                                <Checkbox
                                    label={i.name}
                                    className="step-symptoms-list_wrapper_data-item_checkbox"
                                    onClick={() => {
                                        handlerFollowUp(i);
                                    }}
                                    checked={checked}
                                />
                                <ButtonMenu>
                                    {canManage && <> <li
                                        className='follow-attachement-notes_li-edit'
                                        onClick={() => {
                                            setEditFollowUp(i);
                                            openFollowUp();
                                        }}
                                    >
                                        <Edit/> <Typography variant="p" text="Edit"/>
                                    </li><li
                                        className='follow-attachement-notes_li-delete'
                                        onClick={() => {
                                            props.deleteFollowUp(i.uuid, targetRequest.uuid).then((resp) => {
                                                if (resp && checked) handlerFollowUp(i)
                                            });
                                        }}
                                    >
                                        <Delete/> <Typography variant="p" text="Delete"/>
                                    </li> </>}
                                    <li className='follow-attachement-notes_li-download'
                                        onClick={() => setRenderFollowUps({...i, download: true})}>
                                        <CloudDownload color='#5F6368'/>
                                        <Typography
                                            variant="p" text="Download"
                                        />
                                    </li>
                                </ButtonMenu>
                            </div>
                        )
                    })}
                </ContainerWithActions>
                {showCompleteButton && <div className="follow-attachement-notes_complete-request">
                    <Button
                        text={buttonsInnerText.complete_request}
                        capitalize
                        size="large"
                        variant="primary"
                        onClick={() => setModeVisitNotes(modesNotes.complete)}
                    />
                </div>}
            </div>
            <SendAttachFollowNotes
                selectedNotes={selectedNotesView}
                type={typeSendingBlock}
                open={openSendSelected}
                patient={targetRequest}
                close={closePopupSend}
                pcpList={props.pcpDoctors}
                sendToSelected={sendToSelected}
            />
            <CreatorEditorFollowUp
                open={popup}
                close={closeFollowUp}
                data={editFollowUp}
                saveFollowUp={saveFollowUp}
            />
            <EditorExcuse open={excusePopup}
                          patient={targetRequest}
                          close={closeExcuse}
                          defaultValues={props.excuse}
                          doctorArrived={props.dataRequest?.data?.application_can_start_at}
                          updateExcuse={updateExcuse}/>
            <EditorCovid
                defaultValue={props.covid}
                close={closeCovid}
                updateCovid={updateCovid}
                open={covidPopup}

            />
            {!!lengthObj(props.receiptData) && <EditorReceipt
                open={receiptPopup}
                close={closeReceipt}
                targetRequest={targetRequest}
                dataRequest={props.dataRequest?.data}
                cptCodes={props.cptCodes.map(item => ({ ...item, code: item.label, label: `(${item.label}) ${item.name}` }))}
                signature={props.signature}
                updateReceipt={updateReceipt}
                receiptData={props.receiptData}
            />}
            <PopupContainer
                open={openSelectedPopup}
                close={() => setOpenSelectedPopup(false)}
                title="Please, Create EMR Note"
                extraTitle=""
                textDefault="CANCEL"
                textPrimary='NEXT'
                actionPrimaryButton={() => {
                    props.redirectToTemplate(selectedTemplate)
                }}
                actionDefaultButton={() => setOpenSelectedPopup(false)}
            >
                <DropDown
                    className="selecting-template"
                    value={selectedTemplate}
                    label="Template"
                    options={props.templates}
                    handlerSelect={handleSelectedTemplate}
                />
            </PopupContainer>

            <PopupContainer
                capitalize
                open={openConfirmSendPatient}
                close={() => setOpenConfirmSendPatient(false)}
                title="Confirmation"
                extraTitle=""
                textDefault={BTN_LABELS.CANCEL}
                textPrimary={BTN_LABELS.SEND}
                actionPrimaryButton={() => {
                    setOpenConfirmSendPatient(false)
                    setModeVisitNotes({ ...modesNotes.sending, sendTo: 'patient' })
                }}
                actionDefaultButton={() => setOpenConfirmSendPatient(false)}
            >
                <div style={{marginBottom: 20}}>The Documents Will Be Sent To Patient Via Email?</div>
            </PopupContainer>
        </>
    );
};


