import React, { useState } from "react";
import { Textarea } from "../../Common/TextArea/Textarea";
import { CommentArrow } from "../../Common/Icons";
import { colors } from "../../../assets/colors/colors";

export const SideBarTextArea = ({ updateComment, extraComment, data, detailsRequest }) => {
  const [text, setText] = useState("");

  const updateText = e => {
    setText(e.target.value);
  };

  const onSubmit = () => {
    updateComment(text, data);
    setText("");
  };

  const note = data?.doctor_comment;
  if(extraComment) {
    const additionalComment = detailsRequest?.location.comment;
    if(additionalComment) {
      return(
        <div className="side-bar__textarea">
          <div className='side-bar__textarea--comment-title'>Additional Info</div>
          <div className="side-bar__textarea--comment">{additionalComment}</div>
        </div>
      );
    }
    else return (<></>);
  } else {
    return(
      <>
        {!note && (
          <>
            <Textarea
              onChange={updateText}
              value={text}
              className="side-bar__textarea-title"
              placeholder="Note"
              maxLength={200}
            />
            <div className="side-bar__textarea--icon-container">
              <CommentArrow
                className="side-bar__textarea--icon"
                onClick={text ? onSubmit : () => {}}
                color={text ? colors.navy : ""}
              />
            </div>
          </>
        )}
        {note && (
          <div className="side-bar__textarea">
            <div className='side-bar__textarea--comment-title'>Note</div>
            <div className="side-bar__textarea--comment">{note}</div>
          </div>
        )}
      </>
    );
  }
};
