export const routersPatient = {
  schedule: "/list-requests",
  personal_info: "/personal-info",
  details: '/details',
  info: '/info',
  services: '/services',
  cards: '/cards',

};

export const patientProfileTabs = ["Profile", "Payment method"];
export const addNewCard = "addNewCard";

export const PATIENT = "patient";

export const checkboxValueRequest = [
  { value: "future", label: "Future Visits" },
  { value: "past", label: "Past Visits" }
];
