import React, {useCallback, useEffect, useState} from 'react';
import {Typography} from "../../Common/Typography/Typography";
import {Checkbox} from "../../Common/Checkbox/Checkbox";
import {Button} from "../../Common/Button/Button";
import {buttonsInnerText} from "../../../constants";
import history from "../../../helpers/history";
import {routersPatient} from "../../../constants/Patient";
import {PopupContainer} from "../../Common/Popup/PopupContainer";
import indicator from "../../../dist/icons/indicatorCircle.svg";
import indicatorPassive from "../../../dist/icons/indicatorCirclePassive.svg";
import visa from "../../../dist/icons/visa.svg";
import american from "../../../dist/icons/american.svg";
import mastercard from "../../../dist/icons/mastercard.svg";
import {colors} from "../../../assets/colors/colors";
import {Trash} from "../../Common/Icons/trash";
import CreditCard from "../../../dist/images/CreditCard.svg";
import cn from "classnames";
import {ArrowLeft} from "../../Common/Icons";
import {AddCard} from "../PersonalInfo/AddCard";
import {Payment} from "../PersonalInfo/Payment";
import {ContainerPayment} from "../PersonalInfo/ContainerPayment";

export const CardsForServices = ({payAction, defaultAddress, simpleUpdateState, goBackAction, cards,createRequestMode, baseUrl, approveDeclineServices, selectedServices, id, setSelectedServices, addNewCard}) => {
const [selectedCard, setSelectedCard] = useState(null)
    const [title, setTitle] = useState('Payments')
    const [isAddNewCard, setIsAddNewCard] = useState(false)

    useEffect(()=>{
        const btnGroup = document.getElementById('btn-group')
        if(btnGroup) {
            if (isAddNewCard) btnGroup.style.display = 'none'
            else btnGroup.style.display = 'flex'
        }
    },[isAddNewCard])

    const controlAddNewCard = useCallback((value) => {
        setIsAddNewCard(value)
        updateTitle(value)
    },[])

    useEffect(()=>{
        setSelectedCard(cards.find(i => i.is_default))
    }, [cards.length])

    useEffect(()=>{
        simpleUpdateState && simpleUpdateState("hideStepsHeader", true);
    },[])

    useEffect(() => {
        if (!selectedServices?.length && !createRequestMode) history.push(baseUrl + routersPatient.services)
    }, [])

    const payServices = (dataOrIdCard) => {
        if (payAction) payAction(dataOrIdCard || selectedCard.payment_profile_id)
        else {
            let body = {assignments_ids: selectedServices.map(i => i.uuid)}
            if (typeof dataOrIdCard === "object") body.card_info = dataOrIdCard
            else body.payment_profile_id = dataOrIdCard || selectedCard.payment_profile_id

            approveDeclineServices(body, id).then(() => {
                setSelectedServices && setSelectedServices([])
                history.push(baseUrl + routersPatient.info)
            })
        }
    }
    const changeDefaultCard = (item) => {
        return Promise.resolve().then(() => {
            setSelectedCard(item)
        })
    }
    const goBack = () => {
        if (isAddNewCard) {
            setIsAddNewCard(false);
        } else if (goBackAction) {
            goBackAction()
        } else {
            history.push(baseUrl + routersPatient.services)
        }
    }
    const updateTitle = (isAddNewCard) => {
    if(isAddNewCard) setTitle('Add Card')
        else setTitle('Payments')
    }


    return <div className='details-request-cards'>
        <div className='details-request-services_header'> <ArrowLeft className='details-request-services_header_arrow' onClick={goBack} /><Typography
            variant="h2"
            text={title}
        />
        </div>

        <div className='details-request-cards_list'>
            <ContainerPayment
                updateTitle={updateTitle}
                hideHeaderAddCard
                changeDefaultCard={changeDefaultCard}
                buttonNameAddCard={buttonsInnerText.pay}
                checkWishSaveForLater
                listCards={cards}
                defaultAddress={defaultAddress}
                addNewCard={addNewCard}
                actionAddCard={payServices}
                controlAddNewCard={controlAddNewCard}
                isAddNewCard={isAddNewCard}
                btnItems={<><Button
                    disabled={!cards.length}
                    text={buttonsInnerText.pay}
                    size="middle"
                    variant="primary"
                    onClick={()=>payServices()}
                /></>}
            />
        </div>
    </div>
}
