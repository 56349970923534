import React, { Component } from "react";
import "./style.scss";
import cn from "classnames";

import { createUseStyles } from "react-jss";
import { colors } from "../../../assets/colors/colors";
import * as Icons from "../Icons";

const styles = createUseStyles({
  large: {
    width: 170,
    borderRadius: 4
  },
  big: {
    width: 161,
    borderRadius: 10
  },
  middle: {
    width: 124,
    borderRadius: 4
  },
  full: {
    width: "100%",
    borderRadius: 4
  },
  small: {},
  simple: {
    backgroundColor: "unset"
  },
  default: {
    color: colors.navy,
    backgroundColor: colors.lightBlue,
    border: "1px solid",
    borderColor: colors.navy,

    "&:hover": {
      backgroundColor: colors.blueAliceLight
    },
    "&:active": {
      backgroundColor: colors.blueAlice
    }
  },
  primary: {
    color: colors.white,
    backgroundColor: colors.navy,

    "&:hover": {
      backgroundColor: colors.sea
    },
    "&:active": {
      backgroundColor: colors.denim
    }
  },
  defaultDisabled: {
    color: colors.lightGrey,
    backgroundColor: colors.lightGreyMuted,
    border: "1px solid",
    borderColor: colors.lightGrey
  },
  primaryDisabled: {
    color: colors.white,
    backgroundColor: colors.lightGrey
  }
});

export const Button = ({
  text,
  variant,
  className,
  state,
  size,
  disabled,
  icon,
  onClick,
  side,
  uppercase,
  color,
  capitalize,
  ...props
}) => {
  const sideIcon = side || "right";
  const action = disabled ? () => {} : onClick;
  const Icon = Icons[icon];
  return (
    <button
      {...props}
      onClick={action}
      className={cn(
        className,
        { "button-common-component_uppercase": uppercase },
        { "button-common-component_capitalize": capitalize },
        styles()[disabled ? `${variant}Disabled` : variant],
        styles()[state],
        styles()[size],
        "button-common-component"
      )}
    >
      {Icon && sideIcon === "left" && (
        <Icon
          className="button-common-component_img button-common-component_img_left"
          style={{ color: color }}
        />
      )}
      <span style={{ color: color }}>{text}</span>
      {Icon && sideIcon === "right" && (
        <Icon
          className="button-common-component_img button-common-component_img_right"
          style={{ color: color }}
        />
      )}
    </button>
  );
};
