import React, {useEffect, useState} from "react";
import Select, { components } from "react-select";
import classNames from "classnames";
import "./style.scss";
import CreatableSelect from "react-select/creatable";
import {fetchWrapper} from "../../../../../helpers/helpers";
import {useDebounce} from "../../../../../utils/customHooks";


export const DropDown = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [loadOptions, setLoadOptions] = useState([])
const [searchValue, setSearchValue] = useState('')

  const debounceSearchValue = useDebounce(searchValue, 1000)
  useEffect(() => {
    debounceSearchValue && getOptions()
  }, [debounceSearchValue])

  const getOptions = () => {
    fetchWrapper({
      url: props.urlOptions,
      body: {
        search: debounceSearchValue
      }
    }).then(resp => {

      setLoadOptions(props.proceedOptions(resp))
    })
  }

  const error = props.error && (props.data?.multi ? !props.data?.data.length  : !props.data?.data)
  let allProps = {
    value: props.data?.data,
    className: classNames(
      "emr-notes-container",
      props.className,
      props.classNameOpenTop,
      { "emr-notes-container_isOpen": isOpen }
    ),
    onMenuOpen: () => {
      if(!isOpen) {
        if(props.urlOptions) getOptions()
        else setLoadOptions(props.data?.options)
        setIsOpen(true);
      }
    },
    onMenuClose: () => {
      setIsOpen(false);
      setSearchValue('')
      setLoadOptions([])
    },
    classNamePrefix: "emr-notes-drop-down",
    onChange: (valuet, action) => {
      if (!!props.data.multi && !valuet) {
        valuet = [];
      }
      let newValuet = valuet
      if(props.creatable){
        if(Array.isArray(valuet)) {
          newValuet = valuet.map(i => {
            return i.__isNew__ ? {value: i.value, label: i.value} : i
          })
        }else{
          newValuet = {value: valuet.value, label: valuet.value}
        }
      }
      // setValue(valuet)
      props.updateState({...props.data, data: newValuet})
    },
    // menuIsOpen: true,
    options: loadOptions,
    noOptionsMessage: props.noOptionsMessage,
    isDisabled: props.disabled,
    placeholder: props.placeholder || "",
    isMulti: !!props.data?.multi,
    onInputChange: valueInput => {
      setSearchValue(valueInput)
    },
    hideSelectedOptions: false,
    closeMenuOnSelect: !props.data?.multi,
    menuPlacement: props.menuPlacement || "bottom",
    components: {
      DropdownIndicator: ({ isFocused }) => {
        return <div className="indicator" />;
      },
      IndicatorSeparator: () => null,
      ClearIndicator: () => null,
      Option: props => <components.Option {...props} />,
      ...props.components
    }
  };

if(props.urlOptions) allProps.filterOptions = (options, filter, current_values)=> options


  const isValue = Array.isArray(props.data?.data)
    ? props.data.data?.length
    : !!props.data?.data;
  return (
    <div
      className={classNames("emr-notes-wrapper", props.className, {
        "emr-notes-wrapper_error": error,
        "emr-notes-wrapper_disabled": props.disabled,
        'emr-notes-wrapper_open': isOpen
      })}
    >
      {props.data.label && (
        <span
          className={classNames("emr-notes-wrapper_label", {
            "emr-notes-wrapper_label_isValue": isValue,
            "emr-notes-wrapper_label_isOpen": isOpen,
            "emr-notes-wrapper_label_error": error
          })}
        >
          {props.data.label}
        </span>
      )}
      {props.creatable ? (
          <CreatableSelect {...allProps} />
      ) : (
          <Select {...allProps} />
      )}
    </div>
  );
};
