import mixpanel from 'mixpanel-browser/src/loader-module'
import { EVENTS } from './Events'
import { PROPERTIES } from './Properties'



const getQueryParam = (url, param) => {
  // Expects a raw URL
  const newParam = param.replace(/[[]/, '[').replace(/[]]/, ']')
  const regexS = `[?&]${newParam}=([^&#]*)`
  const regex = new RegExp(regexS)
  const results = regex.exec(url)

  if (results === null || (results && typeof results[1] !== 'string' && results[1].length)) {
    return ''
  } else {
    return decodeURIComponent(results[1]).replace(/\W/gi, ' ')
  }
}


const getCampaignParams = () => {
  const campaignKeywords = 'utm_source utm_medium utm_campaign utm_content utm_term'.split(' ')
  let kw = ''
  const params = {}
  const firstParams = {}

  for (let index = 0; index < campaignKeywords.length; ++index) {
    kw = getQueryParam(document.URL, campaignKeywords[index])
    if (kw.length) {
      params[`${campaignKeywords[index]} [last touch]`] = kw
    }
  }

  for (let index = 0; index < campaignKeywords.length; ++index) {
    kw = getQueryParam(document.URL, campaignKeywords[index])
    if (kw.length) {
      firstParams[`${campaignKeywords[index]} [first touch]`] = kw
    }
  }

  return { params: params, firstParams: firstParams }
}


export class MixpanelService {
  constructor() {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN)
    this.isIdentified = false
  }

  track(event, payload) {
    this.setSuperProps(payload)
    this.setProfileProps(payload)
    mixpanel.track(event, payload)
  }

  register(profile) {
    if (this.isIdentified) {
      this.logout()
    }

    if (profile.uuid) {
      mixpanel.alias(profile.uuid)
    }

    this.identify(profile)
    this.setSuperProps(profile)
    this.setProfileProps(profile)

    mixpanel.track(EVENTS.REGISTRATION, profile)
    this.isIdentified = true
  }

  login(profile) {
    this.identify(profile)
    this.setSuperProps(profile)
    this.setProfileProps(profile)
    const user = {
      user_type: profile.user_type,
      auth_type: profile.auth_type,
      userID: profile.userID,
      error: profile.error,
    }
    mixpanel.track(EVENTS.LOGIN, user)
  }

  logout() {
    mixpanel.reset()
    this.isIdentified = false
  }

  identify(profile) {
    if (!profile) {
      return false
    }

    mixpanel.identify(profile.uuid)
    this.isIdentified = true
  }

  setSuperProps(payload) {
    if (!payload) {
      return
    }
    const superToSend = {}
    PROPERTIES.MIXPANEL_SUPER_PROPERTIES.forEach(v => {
      if (payload.hasOwnProperty(v)) {
        superToSend[v] = payload[v]
      }
    })

    if (Object.keys(superToSend).length) {
      mixpanel.register(superToSend)
    }
  }

  setProfileProps(payload) {
    if (!payload) {
      return
    }
    const superToSend = {}
    PROPERTIES.MIXPANEL_PROFILE_PROPERTIES.forEach(v => {
      if (payload.hasOwnProperty(v)) {
        superToSend[v] = payload[v]
      }
    })

    if (Object.keys(superToSend).length) {
      mixpanel.people.set(superToSend)
    }
  }
}
