import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    displayChat: false
}

const commonSlice = createSlice({
    name: 'commonSlice',
    initialState,
    reducers: {
        setDisplayChat: (state, {payload})=> {
            state.displayChat = payload
        },
    }
})

export const {
    setDisplayChat
} = commonSlice.actions;
export const commonReducer = commonSlice.reducer;
