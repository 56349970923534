import {setTimeZoneForDate, getDateFromISO} from "../../utils/date";
import moment from "moment";

export const prepareRequestsCalendar = (data) => {
    return data.map(i => ({
        ...i, application_can_start_at: setTimeZoneForDate(i.application_can_start_at),
        application_time: setTimeZoneForDate(i.application_time)
    })).map(i => ({
        id: i.uuid,
        data: i,
        start: getDateFromISO(i.application_can_start_at),
        end: getDateFromISO(i.application_time),
        requestStatus: i.service_request_status
    }))
}

export const sortRequestsFuturePast = ({data, toFuture, toPast}) => {
    if(toFuture) return [...data].sort((a, b) => moment(b.application_time) - moment(a.application_time))
    if(toPast) return [...data].sort((a, b) => moment(a.application_time) - moment(b.application_time))
    return []
}
