import React from "react";
import { PopupContainer } from "../../Common/Popup/PopupContainer";
import { Chat } from "../../Chats";
import {
  Next,
  OrderDetailsContainer,
  TriggerOptionalServices,
  Confirm,
  Here,
  MinsLeft,
  AddNewServicesContainer,
  PatientsList,
  Examination
} from "../Steps";
import { DOCTOR_STEPS } from "../../Sidebar/constants";
import { updateRequestData, changeRequestDetails } from "../../../store/request/actions";
import { useDispatch } from "react-redux";
import { COMPLETED } from "../../../store/request/constants";
import { setDisplayChat } from "../../../store/stable/slice";

export const ContainerComponentsRequest = props => {
  const { changeState } = props;
  const dispatch = useDispatch();

  const onChangeHour = field => {
    let hour = field;
    if(+hour > 12) {
      hour = '12';
    }
    changeState({ inputHour: hour });
  };

  const openTimerEditor = () => {
    changeState({ activePage: DOCTOR_STEPS.NEXT });
  };

  const closeChat = () => {
    dispatch(setDisplayChat(false))
    // changeState({ openChat: false });
  };

  const closePopup = () => {
    changeState({ showPopUp: false });
  };

  const onChangeMin = field => {
    let min = field;
    if(+min < 0) {
      min = '0';
    } else if(+min > 59) {
      min = '59';
    }
    changeState({ inputMin: min });
  };

  const updateCheckbox = field => {
    if(!props.checkboxServices.find(i => i.uuid === field?.uuid)) {
      changeState({ checkboxServices: [...props.checkboxServices, field] });
    } else {
      changeState({ checkboxServices: props.checkboxServices.filter(i => i.uuid !== field?.uuid) });
    }
  };

  const updateComment = (text, data) => {
    dispatch(updateRequestData(data.uuid, { doctor_comment: text })).then(resp => {
      if(resp.data.uuid) {
        dispatch(changeRequestDetails('doctor_comment', props.patientForExamination, text));
      }
    });
  };

  const openPatientList = () => {
    changeState({ activePage: DOCTOR_STEPS.PATIENTS_LIST });
  };

  const additionalAct = {
    ...props,
    onChangeHour,
    onChangeMin,
    updateComment,
    updateCheckbox,
    openTimerEditor,
    openPatientList,
    user: props.data?.patient,
    showExtendedInfo: true,
    showEmail: true,
    isNew: props.data?.patient_is_new,
    doctor: true,
    isShowSuite: true,
    isOutWorkingZone: props.data?.location?.is_out_of_working_zone
  };

  const examined = props.data?.patients?.filter(i => i.examination_status === COMPLETED).length;
  const totalPatients = props.data?.patients_number;

  if(!props.data) {
    return (<></>);
  } else {
    let popupTitle = 'Do you want to Complete your Request?';
    if(props.data?.is_group) {
      if(!examined) {
        popupTitle = 'Please, Finish Examination For At Least One Patient To Complete The Visit.';
      } else {
        popupTitle = 'Do You Really Want To Complete The Group Visit?';
      }
    }
    const notExamined = props.data?.is_group && !examined;

    return (
      <>
        <div className={props.openChat && 'side-bar-wrapper'}>
          {props.activePage === DOCTOR_STEPS.CONFIRM && (
            <Confirm {...additionalAct} />
          )}
          {props.activePage === DOCTOR_STEPS.NEXT && (
            <Next {...additionalAct} />
          )}
          {props.activePage === DOCTOR_STEPS.MINS_LEFT && (
            <MinsLeft {...additionalAct} />
          )}
          {props.activePage === DOCTOR_STEPS.HERE && (
            <Here {...additionalAct} />
          )}
          {props.activePage === DOCTOR_STEPS.TRIGGER_SERVICES && (
            <TriggerOptionalServices {...additionalAct} />
          )}
          {props.activePage === DOCTOR_STEPS.ORDER_DETAILS && (
            <OrderDetailsContainer {...additionalAct} />
          )}
          {props.activePage === DOCTOR_STEPS.ADD_SERVICES && (
            <AddNewServicesContainer {...additionalAct} />
          )}
          {props.activePage === DOCTOR_STEPS.PATIENTS_LIST && (
            <PatientsList {...additionalAct} />
          )}
          {props.activePage === DOCTOR_STEPS.EXAMINATION && (
            <Examination {...additionalAct} />
          )}
          {props.showPopUp && (
            <PopupContainer
              open={props.showPopUp}
              close={closePopup}
              textPrimary={notExamined ? 'Back to request' : 'Complete'}
              textDefault="Cancel"
              actionDefaultButton={closePopup}
              actionPrimaryButton={notExamined ? closePopup : props.completeRequest}
              title={popupTitle}
              hideCancel={notExamined}
              capitalize
            >
              {props.data?.is_group && (examined !== totalPatients || !examined) && (
                <div className="side-bar__doctor-popup__container">
                  <div className="side-bar__doctor-popup__content">
                    {examined} of {totalPatients} patients have been examined.<br/>
                    {!!examined && 'EMR notes can be created only for submitted Patients.'}
                  </div>
                </div>
              )}
            </PopupContainer>
          )}
          <Chat
            dataRequest={props.data}
            isDoctor
            open={props.openChat}
            closeChat={closeChat}
            closeSideBar={props.closeSideBar}
          />
        </div>
      </>
    );
  }
};
