import React, { useCallback, useEffect, useState } from "react";
import "./style.scss";
import cn from "classnames";
import { colors } from "../../../../../assets/colors/colors";
import {ItemRadio} from "../ItemRadio";
import {DisabledOverlay} from "../DisabledOverlay";

export const RadioBlock = ({ data, updateState, DbData, error, disabled}) => {
  const updateStatus = index => {
    updateState({
      ...data,
      data: data.data.map((i, idx) =>
          idx === index ? {...i, checked: true} : {...i, checked: false}
      )
    });
  };

  const updateText = (index, value) =>{
    updateState({...data,
      data: data.data.map((i, idx) => (idx === index ? {...i, data: value} : i))
    })};

  const isError = !data.data.filter(i => i.checked).length && error
  return (
    <span className={cn("emr-notes-radio",{'emr-notes-radio_error': isError})}>
      {data.data.map((i, index) => (
        <ItemRadio
          {...i}
          key={index}
          last={data.data.length - 1 === index}
          id={index}
          updateStatus={() => {
            updateStatus(index);
          }}
          updateText={value => {
            updateText(index, value);
          }}
        />
      ))}
      <DisabledOverlay show={disabled}/>
    </span>
  );
};


