import React, {useEffect, useMemo, useRef, useState} from "react";
import "./style.scss";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import DatePicker from "react-datepicker";
import { ArrowIncrease, ArrowDecrease } from "../Common/Icons";
import "./styleCalendar/styles.scss";
import cn from "classnames";
import { CustomToolbar } from "./CustomToolbar";
import {CALENDAR_TIME_MODES_OPTIONS, CALENDAR_VIEW_MODE, CALENDAR_VIEW_MODE_OPTIONS, month} from "../../constants";
import ButtonGroup from "../Common/ButtonGroup/ButtonGroup";
import moment from "moment";
import {ContainerEvents} from "../PatientFlow/Schedule/ContainerEvents";
import {useSelector} from "react-redux";
import {countRequestsSelector} from "../../store/requests/selectors";
import {chunkRequests} from "../../utils/requests";
import {checkboxValueRequest} from "../../constants/Patient";
import {MODES_ADDITIONAL_LOADING} from "../../store/requests/constants";
import {dateFormatCalendarRequests, setTimeZoneForDate} from "../../utils/date";
import _ from 'lodash'
import {sortRequestsFuturePast} from "../../store/requests/utils";
import {colors} from "../../assets/colors/colors";
import {Typography} from "../Common/Typography/Typography";

const localizer = momentLocalizer(moment);

export const ScheduleEvents = ({isDoctor, ...props}) => {
    const [timeMode, setTimeMode] = useState(CALENDAR_TIME_MODES_OPTIONS[0].value)
    const [viewMode, setViewMode] = useState(CALENDAR_VIEW_MODE_OPTIONS[1].value)
    const [dateOfCalendar , setDateOfCalendar] = useState(moment().toDate())
    const [dateOfPicker , setDateOfPicker] = useState()
    const [customTitle, setCustomTitle] = useState('')
    const countRequestInLine = useSelector(countRequestsSelector)
    const calendar = useRef()
    const isShift = !props.showShiftsAppointments
    const requests = useMemo(()=>{
        if(props.requests){
            return sortRequestsFuturePast({data: props.requests.data.map(i => ({...i, application_can_start_at: setTimeZoneForDate(i.application_can_start_at)})), toFuture: true})
        }
        return []
    },[props.requests?.timestamp])

    useEffect(() => {
        if(viewMode !== CALENDAR_VIEW_MODE_OPTIONS[0].value) props.requestEventsWeek(dateOfCalendar, timeMode)
    }, [timeMode, viewMode, moment(dateOfCalendar).format(), moment(dateOfPicker).format('YYYY-DD-MM'), props.showMyRequests, props.triggerUpdateRequests])
    useEffect(() => {
        if (calendar) calendar.current.props.onView(timeMode)
    }, [timeMode])

    useEffect(()=>{
        setCustomTitle(viewMode)
    },[viewMode])

    useEffect(()=>{
        setViewMode(CALENDAR_VIEW_MODE_OPTIONS[1].value)
    }, [isShift])

    useEffect(()=>{
        if(viewMode === CALENDAR_VIEW_MODE_OPTIONS[0].value && dateOfPicker) loadListInitialData()
    },[moment(dateOfPicker).format('YYYY-DD-MM')])

    const loadListInitialData = () => {
        const dates = {
            start:dateFormatCalendarRequests(moment(dateOfPicker).subtract(1, 'week').startOf('day')),
            end: dateFormatCalendarRequests(moment(dateOfPicker).endOf('day').add(1, 'week'))
        }
        return props.requestEventsWeek(dates, Views.LIST, false, {grouping: true})
    }
    const loadMoreData = ({mode}) => {
        const rangeType1 = 'day'
        const rangeType2 = 'month'
        let dates = {}
        if(mode === MODES_ADDITIONAL_LOADING.TOP){
            const firstEventDate = requests[requests.length - 1]?.application_can_start_at
            dates.start = dateFormatCalendarRequests(moment(firstEventDate).startOf('day').subtract(1, rangeType2))
            dates.end = dateFormatCalendarRequests(moment(firstEventDate).endOf('day').add(1, rangeType1))
        }else{
            const lastEventDate = requests[0]?.application_can_start_at
            dates.start = dateFormatCalendarRequests(moment(lastEventDate).startOf('day').subtract(1, rangeType1))
            dates.end = dateFormatCalendarRequests(moment(lastEventDate).endOf('day').add(1, rangeType2))
        }
        props.requestEventsWeek(dates, Views.LIST, mode, {grouping: true})
    }

    const handleNavigate = (date, view, action) => {
        setDateOfCalendar(moment(date).toDate())
    };

    const changeTimeMode =(value) => setTimeMode(value)
    const changeViewMode =(value) => {
        if(value === CALENDAR_VIEW_MODE_OPTIONS[0].value) return loadListInitialData().finally(i => setViewMode(value))
            setViewMode(value)
    }

    const updateDatePicker = (e) => {
        const newDate = e || moment().toDate()
        setDateOfPicker(newDate)
        setDateOfCalendar(newDate)
    }
    const separateDayRange = (data) => {
        let groups = _.groupBy([...data].reverse(), (i) => moment(i.application_can_start_at).startOf('day').format());
        let arr = []
        Object.keys(groups).map(i => {
            const isToday = moment().startOf('day').isSame(moment(i))
            const chunks = chunkRequests(groups[i], countRequestInLine)
            arr.push({title: isToday ? 'Today': moment(i).format('MMMM DD'), uuid: moment(i).format(), lengthChildren: chunks.length })
            arr = [...arr, ...chunks]
        })
        return arr
    }
    const listOptions = useMemo(()=>{
        if(viewMode === CALENDAR_VIEW_MODE_OPTIONS[0].value) return separateDayRange(requests)
        return []
    },[viewMode, props.requests?.timestamp, countRequestInLine])

    const activeRequestUUID = props.activeRequest?.data?.uuid
    return (
      <div className={cn("component-schedule-events", props.className, {'component-schedule-events_day': timeMode === Views.DAY})}>
        <div className="component-schedule-events_inline-calendar-container">
          <DatePicker
            inline
            selected={dateOfPicker || moment().toDate()}
            useWeekdaysShort
            fixedHeight
            onChange={updateDatePicker}
            renderCustomHeader={props => <HeaderDatePicker {...props} currentDay={dateOfPicker || moment().toDate()} clickToday={updateDatePicker} />}
          />
          {props.children}
        </div>
          <div className="component-schedule-events_schedule-container">
              <ModesBlock isDoctor={isDoctor} isShift={isShift} title={customTitle} viewMode={viewMode} changeViewMode={changeViewMode} timeMode={timeMode} changeTimeMode={changeTimeMode} dateOfCalendar={dateOfCalendar}/>
              {viewMode === CALENDAR_VIEW_MODE_OPTIONS[1].value ?
                  <div className='component-schedule-events_schedule-container_scroll-area'>
                      <Calendar
                          formats={{dayHeaderFormat: 'dddd DD'}}
                          ref={calendar}
                          showMultiDayTimes={true}
                          events={props.events}
                          step={15}
                          timeslots={4}
                          localizer={localizer}
                          startAccessor="start"
                          endAccessor={({ end }) => new Date(end.getTime() - 1)}
                          defaultView={timeMode}
                          date={dateOfCalendar}
                          onNavigate={handleNavigate}
                          components={{
                              toolbar: toolbar => {
                                  return (
                                      <CustomToolbar
                                          {...toolbar}
                                          isShift={isShift}
                                          events={props.events}
                                          calendarApi={calendar}
                                      />
                                  );
                              },
                              event: props.CustomEvent,
                              week: {
                                  header: data => {
                                      const selected =
                                          moment(data.date).format("DD-MMM-YYYY") ===
                                          moment(dateOfPicker).format("DD-MMM-YYYY");
                                      return (
                                          <div
                                              className={cn("header-day-cell", {
                                                  "header-day-cell_selected": selected
                                              })}
                                          >
                                              <div className="header-day-cell_name-day">
                                                  {data.label.substring(3)}
                                              </div>
                                              <div className="header-day-cell_number-day">
                                                  <span>{data.label.substring(0, 2)}</span>
                                              </div>
                                          </div>
                                      );
                                  }
                              },

                              agenda: {
                                  // event: () => {
                                  //   return <div>qweqw</div>;
                                  // }
                              }
                          }}
                      />
                  </div> : <ContainerEvents
                  data={listOptions}
                  indicatorTopColor
                  fullPermissions
                  updateDatePicker={updateDatePicker}
                  activeRequestUUID={activeRequestUUID}
                  showDetails={props.onClickListRequest}
                  dateOfPicker={dateOfPicker}
                  setCustomTitle={setCustomTitle}
                  loadMoreData={loadMoreData}
              />}
          </div>
      </div>
    )
}

const ModesBlock = ({ title,isDoctor, timeMode, changeTimeMode, viewMode, changeViewMode, isShift, dateOfCalendar}) => {
    const isList = viewMode === CALENDAR_VIEW_MODE_OPTIONS[0].value
    const hideViewModes = isDoctor || isShift
    const startOfWeek = moment(dateOfCalendar).startOf('week');
    const endOfWeek =  moment(dateOfCalendar).endOf('week');

    let monthTitle = startOfWeek.format('MMMM YYYY');
    if(startOfWeek.year() !== endOfWeek.year()){
        monthTitle = startOfWeek.format('MMMM YYYY') + ' – ' + endOfWeek.format('MMMM YYYY');
    }else if(startOfWeek.month() !== endOfWeek.month()){
        monthTitle = startOfWeek.format('MMMM') + ' – ' + endOfWeek.format('MMMM YYYY');
    }
    return <div className='calendar-modes-block'>
        <span className='calendar-modes-block_title'>{title}</span>
        {!isList && <ButtonGroup
            v2
            classNameContainer='calendar-modes-block_time-mode'
            page={timeMode}
            buttonsTab={CALENDAR_TIME_MODES_OPTIONS}
            changePage={changeTimeMode}
        />}
        <Typography
            className='calendar-modes-block_month-title'
            variant="title"
            text={monthTitle}
        />
        {!hideViewModes && <ButtonGroup
            v2
            classNameContainer='calendar-modes-block_view-mode'
            page={viewMode}
            buttonsTab={CALENDAR_VIEW_MODE_OPTIONS}
            changePage={changeViewMode}
        />}
    </div>
}

const HeaderDatePicker = ({ date, decreaseMonth, increaseMonth,clickToday, currentDay }) => {
    const active = !moment(currentDay).startOf('day').isSame(moment().startOf('day'))
    return (
        <div className="schedule-date-picker">
            <div className="schedule-date-picker_date">
      <span className="">
        {month[date.getMonth()]} {date.getFullYear()}
      </span>
            </div>
            <div className="schedule-date-picker_control">
                <ArrowDecrease
                    onClick={decreaseMonth}
                    className="schedule-date-picker_control_decrease"
                />
                <span className={cn('schedule-date-picker_control_today', {'active': active})} onClick={()=>clickToday()}>Today</span>
                <ArrowIncrease onClick={increaseMonth} className="schedule-date-picker_control_increase" />
            </div>
        </div>
    );
}

export default ScheduleEvents;
