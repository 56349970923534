import {Typography} from "../Common/Typography/Typography";
import {colors} from "../../assets/colors/colors";
import pureFolder from "../../dist/icons/pureFolder.svg";
import EllipseGreen from "../../dist/icons/EllipseGreen.svg";
import {ADMIN, buttonsInnerText} from "../../constants";
import signatureImg from "../../dist/img/signature.svg";
import cn from "classnames";
import {Clear, Cross} from "../Common/Icons";
import SignatureCanvas from "react-signature-canvas";
import {Button} from "../Common/Button/Button";
import React, {useRef, useState} from "react";
import './style.scss'

export const SignatureBlock = ({signature, canAdd, action, isEmr, titlePopup = 'Draw a signature here'}) => {
    const [open, setOpen] = useState(false)
    const [signatureDrew, setSignatureDrew] = useState(false)

    const signatureRef = useRef(null)
    const save = () => {
        var blobBin = atob(signatureRef.current.getTrimmedCanvas().toDataURL('image/png').split(',')[1]);
        var array = [];
        for(var i = 0; i < blobBin.length; i++) {
            array.push(blobBin.charCodeAt(i));
        }
        var file=new Blob([new Uint8Array(array)], {type: 'image/png'});
        var data = new FormData();
        data.append("signature", file, 'signature.png');
        action(data).then(()=>{
            setOpen(false);
            signatureRef.current.clear();
            setSignatureDrew(false);
        })
    }
    return <>
        {signature ?
            <div className='container-sign_signature-file'>
                <Typography variant='p' color={colors.darkMuted} text={isEmr ? "Patient's agreement for sending documents to third parties." : 'Signature'} className='container-sign_signature-file_title'/>
                <a className='container-sign_signature-file_folder' href={signature} target="_blank">
                    <img src={pureFolder} className='container-sign_signature-file_main'/>
                    <img src={EllipseGreen} alt="ellipse" className="container-sign_signature-file_folder_green"/>
                    <div className='container-sign_signature-file_folder_format'>PNG</div>
                    <Typography variant='h4' color={colors.blackMuted} text='Signature.png'/>
                </a>

            </div> : (canAdd ?
                (isEmr ? <EmrPrevie setOpen={setOpen}/>: <div className='container-sign_signature' onClick={()=> setOpen(true)}>
                    <img src={signatureImg}/>
                    <Typography variant='p' color={colors.navy} text='Add signature'/>
                </div>) : <></>)}
        <div className={cn('overlay-signature', {'overlay-signature_open':open})}>
            <div className='overlay-signature_container'>
                <div className='overlay-signature_container_header'>
                    <Typography variant='h3' color={colors.black} text={titlePopup}/>
                    <Cross onClick={()=> {
                        signatureRef.current.clear()
                        setSignatureDrew(false)
                        setOpen(false)
                    }}/>
                </div>
                <div className='overlay-signature_container_body'>
                    <SignatureCanvas penColor='#5F6368'
                                     onEnd={()=> setSignatureDrew(true)}
                                     ref={signatureRef}
                                     canvasProps={{width: 691, height: 340, className: 'sig-canvas'}}/>
                    <Clear onClick={()=>{
                        signatureRef.current.clear()
                        setSignatureDrew(false)
                    }} active={signatureDrew}/>
                </div>
                <div className='overlay-signature_container_footer'>
                    <Button onClick={save} variant='primary' size='middle' text='Save' disabled={!signatureDrew}/>
                </div>
            </div>
        </div>
    </>
}

const EmrPrevie = ({setOpen}) => {
    return <div className='container-sign_signature container-sign_signature_emr'>
        <span>Patient's agreement for sending documents to third parties.</span>
        <Button
            text='Patient’s signature'
            size="large"
            variant="default"
            onClick={()=> setOpen(true)}
        />
    </div>
}
