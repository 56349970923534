import React, { useEffect, useState } from "react";
import { SidebarWrapper } from "../../Sidebar/SidebarWrapper";
import { SideBarRequest } from '../../Sidebar/Sidebar';
import { ADMIN_STEPS, USER_ROLES } from "../../Sidebar/constants";
import {
  DECLINED,
  IN_PROGRESS,
  REQUEST_COMPLETED,
  VISIT_COMPLETED,
  visitNoteStatuses
} from "../../../constants";
import { ContainerDetailsAppointmentAdmin } from "../../SideBarRequest/CompoenentsRequestAdmin";
import { backToShift } from "../../../constants/Admin";
import {getDateFromISO} from "../../../utils/date";
import moment from "moment";

export const AdminSidebar = props => {
  const [activePage, setActivePage] = useState();
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [status, setStatus] = useState({});
  const [reasonCancellation, setReasonCancellation] = useState('')

  useEffect(() => {
    const containerPopup = document.querySelector(".container-sidebar--open");
    if(containerPopup) {
      if(confirmationPopup) {
        containerPopup.classList.add("container-sidebar--open_decline");
      } else {
        containerPopup.classList.remove("container-sidebar--open_decline");
      }
    }
  }, [confirmationPopup]);

  useEffect(() => {
    if(props.appointment) {
      getStep();
    } else {
      if(props.selectedShift) {
        setActivePage(ADMIN_STEPS.SHIFT_PAGE);
      }
      getStep();
    }
  }, [props.detailsAppointment?.timestamp, props.selectedShift]);

  const declineAction = () => {
    setConfirmationPopup(false);
    return props.declineAppointment(props.detailsAppointment.data.uuid, reasonCancellation, isDeclined).then(()=>{
      setReasonCancellation('')
    });
  };

  const getStep = () => {
    if(props.detailsAppointment) {
      if(props.detailsAppointment.data.status) {
        setStatus({
          ...visitNoteStatuses[props.detailsAppointment?.data?.status.toLowerCase()],
          reason: props.detailsAppointment?.data?.cancellation_reason
        });
      }
      if (!props.isMultiple && (props.showShiftsAppointments || props.openFromShiftOrAppointment === backToShift) || props.isMultiple && props.isInMultiple) {
        setActivePage(ADMIN_STEPS.ORDER_DETAILS);
      } else if(props.isMultiple && !props.isInMultiple) {
        setActivePage(ADMIN_STEPS.MULTIPLE);
      }
    }
  };

  const handlerBack = () => {
    if(props.isInMultiple && activePage === ADMIN_STEPS.ORDER_DETAILS) {
      setActivePage(ADMIN_STEPS.MULTIPLE);
      props.simpleUpdate('isInMultiple', false);
    } else if(props?.selectedShift && activePage === ADMIN_STEPS.MULTIPLE ||
      props.openFromShiftOrAppointment === backToShift && activePage === ADMIN_STEPS.ORDER_DETAILS
    ) {
      setActivePage(ADMIN_STEPS.SHIFT_PAGE);
    } else if(activePage === ADMIN_STEPS.PATIENTS_LIST) {
      setActivePage(ADMIN_STEPS.ORDER_DETAILS);
    }
  };

  const actionAdditional = () => {
    if(activePage === ADMIN_STEPS.SHIFT_PAGE)props.editShift(props.selectedShift)
    else props.openEditRequest()
  }

  const isDeclined = props.detailsAppointment?.data?.status?.toLowerCase() === DECLINED;
  const doctorShift = props.selectedEvent?.doctor_info;
  const visitCompleted = props.detailsAppointment?.data?.status?.toLowerCase() === VISIT_COMPLETED;
  const requestCompleted = props.detailsAppointment?.data?.status?.toLowerCase() === REQUEST_COMPLETED;
  const inProgress = props.detailsAppointment?.data?.status?.toLowerCase() === IN_PROGRESS;
  const hideArrow = (!props.selectedShift || !props.detailsAppointment) && props.isMultiple && activePage === ADMIN_STEPS.MULTIPLE ||
    activePage === ADMIN_STEPS.SHIFT_PAGE ||
    activePage === ADMIN_STEPS.ORDER_DETAILS && props.openFromShiftOrAppointment !== backToShift && !props.isInMultiple ||
    props.isMultiple && !props.isInMultiple && activePage !== ADMIN_STEPS.MULTIPLE;
  let hideAdditional = isDeclined;
  let hidePrimary = inProgress || visitCompleted || requestCompleted || isDeclined || activePage === ADMIN_STEPS.SHIFT_PAGE;
  let hideSecondary = false;

  if(activePage === ADMIN_STEPS.SHIFT_PAGE) hideAdditional = false
  if (activePage === ADMIN_STEPS.SHIFT_PAGE && props.selectedShift && moment(getDateFromISO(`${props.selectedShift.end_date}T${props.selectedShift.end_time}Z`)) < moment()){
    hideAdditional = true
    hideSecondary = true
  }
    return (
    <SideBarRequest
      open={props.open}
    >
      <SidebarWrapper
        {...props}
        activePage={activePage}
        handlerBack={hideArrow ? null : handlerBack}
        data={props.detailsAppointment?.data}
        actionPrimary={props.openReassignTo}
        hideSecondary={hideSecondary}
        hidePrimary={hidePrimary}
        userRole={USER_ROLES.ADMIN}
        actionSecondary={() => setConfirmationPopup(true)}
        actionAdditional={actionAdditional}
        hideAdditional={hideAdditional}
      >
        <ContainerDetailsAppointmentAdmin
          {...props}
          declineAction={declineAction}
          confirmationPopup={confirmationPopup}
          status={status}
          reasonCancellation={reasonCancellation}
          setReasonCancellation={setReasonCancellation}
          doctorShift={doctorShift}
          closeConfirmation={() => {
            setReasonCancellation('')
            setConfirmationPopup(false)
          }}
          activePage={activePage}
          setActivePage={setActivePage}
        />
      </SidebarWrapper>
    </SideBarRequest>
  );
};
