import React, { Component } from "react";
import "./style.scss";
import { dataValue } from "../../constants";
import {Button} from "../../../../Common/Button/Button";

export const Label = ({ data, updateState, parentData, actions}) => {
    const action = ()=>{
        if(actions?.deleteBlock) actions.deleteBlock({data: {...parentData, isDelete: !parentData.isDelete}})
    }
    const text = data
    if(!text) return <></>
  return (
      <div className='container-label'>
    <h4
        className='container-label_label'
      onBlur={e => {
        updateState(e.target.innerText);
      }}
      contentEditable={false}
    >
      {text}
    </h4>
          {parentData?.canDeleteBlock && <Button
              text={parentData?.isDelete ? 'Revert' : 'Delete'}
              size="middle"
              variant="primary"
              onClick={action}
          />}
      </div>

  );
};
