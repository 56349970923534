import cn from "classnames";
import {UnArchiveFiles} from "../components/Common/Icons";
import React from "react";
import {colors} from "../assets/colors/colors";

const defaultStyles = {
    fontSize: 14,
    letterSpacing: "0.25px",
    textTransform: "capitalize",
    color: colors.tuna,
    fontWeight: 500,
    marginRight: 0,
    height: 52
};

export const dataHeader = [
    {
        headerClassName: "table-header-archive",
        label: "FILE",
        dataKey: "file_type",
        headerStyle: {width: "calc(60% - 20px)", ...defaultStyles},
        style: {width: "60%"},
        customCell: ({rowData}) => {
            return (
                <span className={cn({"row-container": rowData.folder})}>
          {rowData.folder ? rowData.file : rowData.file_type}
        </span>
            );
        }
    },
    {
        headerClassName: "table-header-archive",
        label: "EXPIRATION DATE",
        dataKey: "expiry_date",
        headerStyle: {width: "25%", ...defaultStyles},
        style: {width: "25%"}
    },
    {
        headerClassName: "table-header-archive",
        label: "UPLOADING DATE",
        dataKey: "uploaded_at",
        headerStyle: {width: "25%", ...defaultStyles},
        style: {width: "25%"}
    },
    {
        headerClassName: "table-header-archive",
        label: "ARCHIVED DATE",
        dataKey: "archived_at",
        headerStyle: {width: "25%", ...defaultStyles},
        style: {width: "25%"}
    },
    {
        headerClassName: "table-header-archive",
        label: "",
        dataKey: "",
        headerStyle: {width: "25%", ...defaultStyles},
        style: {width: "25%"},
        customCell: ({rowData, parent}) => {
            if (rowData.uploaded_at && parent.props.actions.canUnArchived(rowData.file_type)) {
                return (
                    <UnArchiveFiles
                        onClick={() =>
                            parent.props.actions.archiveFile(rowData.uuid, false)
                        }
                    />
                );
            }
        }
    }
];
