import createReducer from "../createReducer.js";
import { CATCH_ERROR, CLEAR_ERROR } from "./error.actionTypes";

const error = {
  show: false,
  errorMessage: ""
};

function catchError(newsState, { data }) {
  return { ...newsState, show: true, errorMessage: data.errorMessage };
}

function clearError(newsState, { data }) {
  return { ...newsState, show: false };
}

const errorReducer = createReducer(error, {
  [CATCH_ERROR]: catchError,
  [CLEAR_ERROR]: clearError
});

export default errorReducer;
