import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    data: null
}

const shiftsSlice = createSlice({
    name: 'shiftsSlice',
    initialState,
    reducers: {
        setShiftsData: (state, {payload}) => {
            state.data = payload
        },
        clearShiftsData: (state, {payload}) => {
            state.data = null
        },
    }
})

export const {setShiftsData, clearShiftsData} = shiftsSlice.actions
export const shiftsReducer = shiftsSlice.reducer
