import React, { Component } from "react";
import cn from "classnames";

export const Filters = props => (
  <div
    onClick={props.onClick}
    className={cn(props.className, "icon-component")}
    style={props.style}
  >
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.6"
        d="M1.54646 1.78553L8.91045 12.3713C8.96875 12.4551 9 12.5547 9 12.6568V21C9 21.412 9.47038 21.6472 9.8 21.4L12.8 19.15C12.9259 19.0556 13 18.9074 13 18.75V12.1626C13 12.0569 13.0335 11.954 13.0956 11.8685L20.4225 1.79409C20.6628 1.4636 20.4268 1 20.0181 1H1.95691C1.55306 1 1.31584 1.45401 1.54646 1.78553Z"
        fill="black"
        stroke="black"
      />
    </svg>
  </div>
);
