import React, { useState } from "react";
import { Button } from "../Common/Button/Button";
import Popup from "reactjs-popup";
import { CodeInputs } from "../Common/CodeInputs/CodeInputs";
import Flag from "react-world-flags";
import { Typography } from "../Common/Typography/Typography";
import { Cross } from "../Common/Icons";
import { colors } from "../../assets/colors/colors";
import phone from "../../dist/icons/phone.svg";
import "./style.scss";
import { InputFlag } from "../Common/InputFlag/InputFlag";
import {
  validationMobileCode,
  verificatinPhone
} from "../../store/createRequest/actions";

export const LoginFrom = ({ pushToPage, prefix, onClose, open, callBackCustom}) => {
  const [inputCode, setInputCode] = useState(false);
  const [disableBtnResend, setDisableBtnResend] = useState(true);
  const [number, setNumber] = useState("");
  const [code, setValueCode] = useState("");

  const onCloseEvent = () => {
    setInputCode(false);
    setDisableBtnResend(false);
    setNumber("");
    setValueCode(null);
    onClose();
  };
  const handlerShowComponentInputCode = e => {
    e.preventDefault();
    if (!inputCode) {
      verificatinPhone({ phone_number: number, target: prefix }).then(() => {
        setTimeout(() => {
          setDisableBtnResend(false);
        }, 30000);
        setInputCode(true);
      });
    } else {
      if (code.length === 6)
        validationMobileCode({
          user_uuid: window.localStorage.getItem("user_uuid"),
          validation_code: code
        })
          .then(() => pushToPage())
          .catch(() => {});
    }
  };

  const resendCode = () => {
    setDisableBtnResend(true);
    verificatinPhone({ phone_number: number, target: prefix }).then(() => {
      setTimeout(() => {
        setDisableBtnResend(false);
      }, 30000);
    });
  };

  const dangerouslySetInnerHTML = inputCode
      ? `to your cell phone
                  number at <number>${number.replace(/\s\_/g, "").substring(2)}</number>`
      : "pre-registration account";

  return (
      <form onSubmit={handlerShowComponentInputCode}>
        <Popup
            closeOnDocumentClick={false}
            open={open}
            onClose={onCloseEvent}
            className="login-form-code"
            contentStyle={{
              padding: 30,
              width: 494,
              zIndex: 10000,
              borderRadius: 4,
              background: "#FFFFFF",
              border: "none",
              boxShadow:
                  "0px 7px 8px rgba(0, 0, 0, 0.2), 0px 5px 22px rgba(0, 0, 0, 0.12), 0px 12px 17px rgba(0, 0, 0, 0.14)"
            }}
        >
          <div className="login-form-code-content_header">
            <div className="login-form-code-content_header_titles">
              <Typography
                  variant="h3"
                  capitalize
                  text={
                    inputCode
                        ? "Security is very important to Us."
                        : "Please Enter Your Cell Phone Number Below"
                  }
                  className=""
              />
            </div>
            <Cross onClick={onClose} />
          </div>
          <div className="login-form-code-content_labels">
            <Typography
                variant="label"
                text={
                  inputCode
                      ? "We will now send secure verification correspondence"
                      : "This will allow us to safely and securely manage your"
                }
                className=""
            />
            <Typography
                variant="label"
                dangerouslySetInnerHTML={dangerouslySetInnerHTML}
                className="title-with-number"
            />
          </div>
          <img src={phone} className="login-form-code-content_img" />

          {inputCode ? (
              <CodeInputs
                  prefix={prefix}
                  onChange={setValueCode}
                  className="login-form-code-content_code"
              />
          ) : (
              <InputFlag
                  onChange={setNumber}
                  className="login-form-code-content_mobile"
              />
          )}
          <div className="login-form-code-content_buttons">
            {inputCode && (
                <Button
                    disabled={disableBtnResend}
                    text="Resend"
                    size="middle"
                    variant="default"
                    onClick={resendCode}
                />
            )}
            <Button
                text="Next"
                size="middle"
                variant="primary"
            />
          </div>
        </Popup>
      </form>
  );
};
