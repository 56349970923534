import { fetchWrapper } from "../../helpers/helpers";
import { SET_TABLE_DATA } from "./actionTypes";
import history from "../../helpers/history";

// const throwErrorToStore = data => ({type: CATCH_ERROR, data});
const setTableData = payload => ({ type: SET_TABLE_DATA, payload });

const getFilters = async body =>
  await fetchWrapper({
    url: "electronic-medical-record/service-requests/filters/",
    body: {...body, statuses: body.checkbox}
  }).then(resp => {
    let filters = {};
    for (let key in resp) {
      filters[key] = resp[key].map(i => ({
        value: i.uuid,
        label: i.name || "N/A"
      }));
    }
    return filters;
  });

const getTableData = (filters, data = { results: [] }) => dispatch =>
  fetchWrapper({
    url: "electronic-medical-record/service-requests/",
    body: filters
  }).then(resp => {
    if (resp.results)
      dispatch(
        setTableData({ ...resp, results: data.results.concat(resp.results) })
      );
  });

export { getFilters, getTableData };
