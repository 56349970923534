import React from 'react';
import cn from "classnames";
import history from "../../../helpers/history";
import {UserData} from "../../../compoenentsAdditional/UserData";
import {DropdownArrow} from "../Icons/dropdownArrow";
import {Symptom} from "../Symptom/Symptom";
import {Typography} from "../Typography/Typography";
import {colors} from "../../../assets/colors/colors";
import {ButtonV2} from "../ButtonV2";
import {COLORS_BTN} from "../ButtonV2/constants";
import {BTN_LABELS, routesCommon} from "../../../constants";
import {routersDoctor} from "../../../constants/Doctors";
import './style.scss';

export const PatientCard = ({
                                classNames,
                                symptoms,
                                user,
                                hideArrow,
                                onClick,
                                showExtendedInfo,
                                isCalendar,
                                data,
                                otherSymptoms,
                                completed,
                                isNew,
                                showRelation,
                                note
                            }) => {
    const openEmr = () => {
        history.push(`${routesCommon.doctor}${routersDoctor.request}${routersDoctor.info_request}/${data.uuid}`);
    };

    return (
        <div
            className={cn(
                "patient-card",
                classNames
            )}
            onClick={onClick}
        >
            {isCalendar && (
                <ButtonV2
                    className="patient-card__open-emr"
                    color={COLORS_BTN.PRIMARY}
                    uppercase
                    onClick={openEmr}
                >
                    {BTN_LABELS.OPEN_EMR}
                </ButtonV2>
            )}
            <div className='patient-card_user'>
                <UserData
                    user={user}
                    showEmail
                    showExtendedInfo={showExtendedInfo}
                    completed={completed}
                    isNew={isNew}
                />
                {(note || showRelation) && <div className='patient-card_user_note'>
                    {showRelation && user?.relationship?.label && <><Typography
                        color={colors.grey}
                        text='Relationship To Organizer:'
                        variant="p"
                    />
                        <Typography
                            capitalize
                            color={colors.black}
                            text={user.relationship?.label}
                            variant="title"
                        />
                    </>}
                  <div className='patient-card_user_note_space'/>
                    {note && <>
                        <Typography
                            color={colors.grey}
                            text='Notes:'
                            variant="p"
                        />
                        <Typography
                            capitalize
                            color={colors.black}
                            text={note}
                            variant="title"
                        /></>}
                </div>}
            </div>
            {!hideArrow && <DropdownArrow className='patient-card__arrow'/>}
            {!!symptoms.length && (
                <div className="patient-card__symptoms">
                    {symptoms.map((item, i) => (
                        <Symptom
                            key={i}
                            text={item?.name}
                        />
                    ))}
                </div>
            )}
            {otherSymptoms && (
                <div className="patient-card__comment ">
                    <Typography
                        color={colors.greyMiddle}
                        variant="p"
                        text="Comment"
                        className="patient-card__comment--title"
                    />
                    <Typography
                        color={colors.black}
                        variant="p"
                        text={otherSymptoms}
                        className="patient-card__comment--text"
                    />
                </div>
            )}
        </div>
    );
};
