import React, { useState } from "react";
import "./style.scss";
import cn from "classnames";
import ReactCodeInput from "react-code-input";

const extraProps = {
  className: "login__code-input"
};

export const CodeInputs = ({ onChange, prefix, className }) => {
  const [code, setValueCode] = useState("");

  const test = (
    <div className={cn("login__code-pin", className)}>
      <div className="login__code-container">
        <input
          className="login__code-input"
          type="number"
          value={code}
          maxLength="6"
          onChange={e => handlerSetValueCode(e)}
        />
      </div>
    </div>
  );

  const handlerSetValueCode = e => {
    setValueCode(e);
    onChange(e);
  };

  return (
    <div className={cn("login__code-pin", className)}>
      <div className="login__code-container">
        <ReactCodeInput
          value={code}
          onChange={handlerSetValueCode}
          fields={6}
          {...extraProps}
          type="tel" inputmode="numeric"
        />
      </div>
    </div>
  );
};
