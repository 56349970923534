import {BTN_LABELS, buttonsInnerText, statusesTemplate} from "../../../constants";
import React from "react";

import './style.scss'
import {ButtonV2} from "../ButtonV2";
import {COLORS_BTN, VARIATIONS_BTN} from "../ButtonV2/constants";


export const CustomSaveButton = ({onClick}) => {

    const sendStatus = (status) => {
        onClick(status)
    }

    return(
        <div className='container-buttons_save-button'>
            <ButtonV2
                className='container-buttons_save-button_button' tabIndex='-1'
                uppercase
                variant={VARIATIONS_BTN.CONTAINED}
                color={COLORS_BTN.PRIMARY}
            >{BTN_LABELS.SAVE}</ButtonV2>
            <div className='container-buttons_save-button_options'>
                <ButtonV2 uppercase color={COLORS_BTN.PRIMARY} variant={VARIATIONS_BTN.TEXT} onClick={() => sendStatus(statusesTemplate.draft)}>
                    {BTN_LABELS.SAVE_AS_DRAFT}
                </ButtonV2>
                <ButtonV2 uppercase color={COLORS_BTN.PRIMARY} variant={VARIATIONS_BTN.TEXT} onClick={() => sendStatus(statusesTemplate.final)}>
                    {BTN_LABELS.SAVE_AS_FINAL}
                </ButtonV2>
            </div>
        </div>
    )
}
