import React from "react";
import {
    placeholdersPMH
} from "../../../../../../constants/Doctors";
import {DropDown} from "../../../../../Common/DropDown/DropDown";
import {Typography} from "../../../../../Common/Typography/Typography";
import {colors} from "../../../../../../assets/colors/colors";
import {Plus} from "../../../../../Common/Icons";
import {Trash} from "../../../../../Common/Icons/trash";
import {VeradigmButton} from "../../../../Templates/Components/VeradigmButton";
import {Medications} from "../../Medications";
import {Textarea} from "../../../../../Common/TextArea/Textarea";

export const PMHEdit = ({
                            state,
                            updateState,
                            errors,
                            medicationsOptions,
                            medicationAllergiesOptions,
                            dataRequest
                        }) => {
    return (
        <div className="container-pmh-edit">
            <div className="container-pmh-edit_left-column">
                <div className="container-pmh-edit_left-column_block">
                    <DropDown
                        multi
                        urlLoadingOptions='electronic-medical-record/icd-10-list/'
                        proccedOptions={i => ({
                                value: i.code,
                                label: i.name + ` (${i.code})`,
                            })
                        }
                        value={state.pastMedicalHistory}
                        field="pastMedicalHistory"
                        handlerSelect={updateState}
                        label={placeholdersPMH.pmh}
                        error={errors["pastMedicalHistory"]}
                    />
                </div>
                <div className="container-pmh-edit_left-column_block">
                    <Medications options={medicationsOptions} dataRequest={dataRequest}/>
                    <Textarea
                        maxLength={2000}
                        placeholder="Medications"
                        value={state?.appointedMedications}
                        onChange={(e)=>{updateState('appointedMedications', e.target.value)}}
                    />
                </div>
            </div>
            <div className="container-pmh-edit_right-column">
                <div className="container-pmh-edit_left-column_block">
                    <DropDown
                        multi
                        creatable
                        urlLoadingOptions='electronic-medical-record/past-medical-history/family-social-history-choices/'
                        value={state.familySocialHistory}
                        field="familySocialHistory"
                        proccedOptions={el => ({value: el.name, label: el.name})}
                        handlerSelect={updateState}
                        label={placeholdersPMH.familySocial}
                        error={errors["familySocialHistory"]}
                    />
                </div>
                <div className="container-pmh-edit_left-column_block">
                    <Typography
                        color={colors.greyMiddle}
                        variant="p"
                        capitalize
                        text={placeholdersPMH.allergies}
                    />
                    <div className='container-pmh-edit_left-column_block_veradigm-values'>
                    {medicationAllergiesOptions.map(i => <Typography color={colors.black} variant="title" text={i.label}/>)}
                    </div>
                    <Textarea
                        maxLength={2000}
                        placeholder="Allergies"
                        value={state?.appointedAllergies}
                        onChange={(e)=>{updateState('appointedAllergies', e.target.value)}}
                    />
                    <VeradigmButton viewButtonAsText dataRequest={dataRequest} title='Go To Veradigm'/>
                    <Textarea
                        maxLength={2000}
                        placeholder="Additional Information"
                        value={state?.comment}
                        onChange={(e)=>{updateState('comment', e.target.value)}}
                    />
                </div>
                <div className="container-pmh-edit_left-column_block">

                </div>
            </div>
        </div>
    );
};
