import React from 'react';
import {
  RequestInfo,
  Separator,
  Map,
  ApproximateEta,
  RequestedBy
} from '../../SideBarRequest/components';
import { PatientSidebar } from "../../Common/PatientSidebar";
import { GroupCard } from "../../Common/GroupCard/GroupCard";

export const MinsLeft = props => {
  return(
    <>
      <ApproximateEta
        {...props}
        editable
      />
      <RequestInfo {...props} />
      <Separator />
      <RequestedBy {...props} />
      {props.data?.is_group ? (
        <GroupCard
          onClick={props.openPatientList}
          data={props.data}
        />
      ) : (
        <PatientSidebar
          {...props}
          detailsRequest={props.data}
          data={props.data?.patients[0]}
          hideNote
          hideSignature
          hideExamination
          hideAddButton
        />
      )}
      <Map {...props} comment={props.data?.location.comment} isShowSuite />
    </>
  );
};
