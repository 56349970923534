import React, { Component } from "react";
import cn from "classnames";

export const CloseExpand = props => (
  <div
    onClick={props.onClick}
    className={cn(props.className, "icon-component")}
    style={props.style}
  >
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.09444 10.4141L1.10152 12.4152H5.58457V16.8983H7.57861L7.58569 10.4141H1.09444ZM16.9054 7.58567L16.8983 5.58456H12.4152V1.1015L10.4141 1.09443V7.58567H16.9054Z"
        fill="#5F6368"
      />
    </svg>
  </div>
);
