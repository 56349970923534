import React, { Component } from "react";
import cn from "classnames";

export const Download = props => (
  <div
    onClick={props.onClick}
    className={cn(props.className, "icon-component")}
    style={props.style}
  >
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 8.72964L14.59 7.3478L9 12.8164V0.889404H7V12.8164L1.42 7.338L0 8.72964L8 16.5699L16 8.72964Z"
        fill="black"
      />
      <line y1="18.51" x2="16" y2="18.51" stroke="black" stroke-width="2" />
    </svg>
  </div>
);
