import 'firebase/analytics'
import ReactGA from 'react-ga'
import history from "../../helpers/history";
import ReactPixel from "react-facebook-pixel";

const trackingId = process.env.REACT_APP_GOOGLE_ANALYTCS_TRACKING_ID

export class GoogleAnalyticsService {
  constructor() {
    // ReactGA.initialize(trackingId)
    // ReactGA.pageview(window.location.pathname)
    history.listen(location => {
      // ReactGA.set({ page: location.pathname })
      // ReactGA.pageview(location.pathname)
      if(window.gtag){
        window.gtag('event','page_view',window.location.pathname)
      }
    })
  }

  track(event, payload) {
    // ReactGA.event({
    //   category: 'UPLIN',
    //   action: event
    // });
    if(window.gtag){
      window.gtag('event',event,payload)
    }
  }

}
