import React from "react";
import cn from "classnames";
import { Typography } from "../../../Common/Typography/Typography";
import "./style.scss";
import { Delta } from "../../../Common/Delta/Delta";
import { InfoWithMessage } from "../../../Common/infoWithMessage/infoWithMessage";
import {colors} from "../../../../assets/colors/colors";

export const TimeButton = ({
  children,
  label,
  className,
  last,
  delta,
  value,
  labelnfo,
  ...rest
}) => {
  const timeDashboard = [
    "Order Response Time",
    "Request to Door Time",
    "Visit Duration"
  ];
  const infoCondition = timeDashboard.find(i => i === label);

  return (
    <div className={cn("dashboard-time", className)}>
      {!!label && (
        <div className="dashboard-time__container">
          <Typography variant="h3" text={label} className="dashboard-title" />
          {infoCondition && (
            <InfoWithMessage
              label={labelnfo}
            />
          )}
        </div>
      )}
      <div className={cn("time-wrapper", { last })}>
        <Typography
          variant="h1"
          text={value || 0}
          className="card-title"
          color={colors.navy}
        />
        {delta !== undefined && (
          <Delta delta={delta} extraText="from previous 30 days" />
        )}
      </div>
    </div>
  );
};
