import React, { Component } from "react";
import "./style.scss";
import { dataValue } from "../../constants";
import _ from 'lodash'
import {DisabledOverlay} from "../DisabledOverlay";
export const Text = ({ DbData, data, updateState,disabled}) => {
    const text = _.get(DbData, data.source, data.data)
  return (
    <span
      className="emr-note-text emr-note-text_inner-text"
      onBlur={e => {
        updateState({...data, data: e.target.innerText});
      }}
      contentEditable={data.editable}
    >
        <span>{text}</span>
        <DisabledOverlay show={disabled}/>
    </span>
  );
};
