import {
  errorIsEmpty,
  regexsWithMessage,
  validationMessagesAge
} from "../constants";
import moment from "moment";
import { converterDataField } from "./common";
import { PhoneNumberUtil } from 'google-libphonenumber';
export const validationIsEmpty = object => {
  let errors = {};
  for (let key in object) {
    errors[key] = !!object[key] ? "" : errorIsEmpty;
  }
  return errors;
};

export const validationRequiredField = value => {
  return !!value ? "" : errorIsEmpty
};

export const validationLength = (value, name, length = 3) => {
  if (value.length < length) return `${name} min length is ${length}`;
  else return "";
};

export const validationDOB = date => {
  if (
    !regexsWithMessage.birthday.regex.test(date) ||
    !moment(date, "MM/DD/YYYY").isValid() ||
    moment().diff(moment(date).add(1, 'day'), 'years') >= 130 ||
    moment(moment().format('MM/DD/YYYY')).diff(moment(date, "MM/DD/YYYY"), 'days') < 0
  )
    return regexsWithMessage.birthday.message;
  else return "";
};

export const validationZipCodeRegex = zipcode => {
  if (!regexsWithMessage.zipcode.regex.test(zipcode))
    return regexsWithMessage.zipcode.message;
  else return "";
};

export const validation18Year = date => {
  if (
    moment()
      .add(-18, "y")
      .valueOf() < moment(date, "MM/DD/YYYY").valueOf()
  )
    return validationMessagesAge.birthday18Year;
  else return "";
};
export const validation6Month = date => {
  if (
    moment()
      .add(-6, "M")
      .valueOf() < moment(date, "MM/DD/YYYY").valueOf()
  )
    return validationMessagesAge.birthday6Month;
  else return "";
};

export const validationPhone = (
  phone,
  placeholder = regexsWithMessage.phone.message
) => {
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    let t = '+1' + phone.replace(/_|\s|-|\)|\(/g, "");
    let result = phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(t));
    if(result){
      return '';
    }
    return placeholder;
  } catch (error) {
    return placeholder;
  }
};

export const validationEmail = email => {
  if (!regexsWithMessage.email.regex.test(email.replace(/_|\s/g, "")))
    return regexsWithMessage.email.message;
  else return "";
};

export const validationNPI = npi => {
  let valueLength = npi?.replace(/_|-/g, '').length
  if (valueLength !== 10)
    return valueLength === 0 ? errorIsEmpty : `NPI length is 10`;
  else return '';
};

export const validationDropdown = field => {
  if(!field) {
    return 'Please fill in all the required fields';
  } else return '';
};

export const validationAddressFields = results => {
  return results.address_components.filter(i =>
      i.types.indexOf('administrative_area_level_1') >= 0 ||
      i.types.indexOf('route') >= 0 ||
      i.types.indexOf('street_number') >= 0 ||
      i.types.indexOf('locality') >= 0
  ).length === 4;
};

export const validationFromBE = resp => {
  let errorFromBE = converterDataField(resp, true);
  for (let key in errorFromBE) {
    errorFromBE[key] = errorFromBE[key][0];
  }
  return errorFromBE;
};

export const validationFieldLength = ({home_address, suite, ...object}, fieldToSkip = '') => {
  for(let key in object) {
    if(!object[key] && key !== fieldToSkip) {
      return 'error';
    }
  }
};

export const validationNameFields = text => {
  if(!text?.trim()?.length) {
    return 'Please fill in all the required fields';
  }
};

export const validateTimePeriod = (timeStart, timeFinish) => {
  if(!timeStart || !timeFinish){
    return errorIsEmpty
  }
  let startTime = moment(timeStart, 'HH:mm');
  let endTime = moment(timeFinish, 'HH:mm');
  if(endTime.isBefore(startTime)){
    endTime = endTime.add(1, 'days');
  }
  let difference = endTime.diff(startTime, 'minutes');
  if (difference < 30) return "Time period is shorter than 0.5h";
  if (difference > 240) return "Time period is longer than 4h";

  if (difference % 30 !== 0)  return "Overall time period should be a multiple of 30 mins";
};