import Pusher from "pusher-js";
import React from "react";
import history from "../helpers/history";
import {
    configPusher,
    rolesApp,
    routesCommon
} from "../constants";
import {routersPatient} from "../constants/Patient";
import {toast} from "react-toastify";
import {PusherNotification} from "../components/Pusher";
import {BUTTONSDOCTOR, routersDoctor} from "../constants/Doctors";
import {routersAdmin} from "../constants/Admin";
import {getNotifications, setMessages, updateNotification} from "../store/common/actions";
import {updateStatusMessRequest} from "../store/requests/slice";
import {setDisplayChat} from "../store/stable/slice";

let pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY || '81b704e9d37ba939c6ce', {
    cluster: process.env.REACT_APP_PUSHER_CLUSTER || 'eu',
    forceTLS: true
});

Pusher.logToConsole = true;

const callToster = (data, pathName, additionalData = {}, useNavigate = true, callback, extraConfigOptions= {}) => {
    toast(
        <PusherNotification
            body={data.body}
            title={data.title}
            actionButton={useNavigate ? () => {
                callback && callback()
                history.push({
                    pathname: pathName,
                    state: {...data.data, ...additionalData}
                });
            } : null}
        />,
        {...configPusher, ...extraConfigOptions}
    );
};

const events = {
    [`${rolesApp.admin}`]: (channel, dispatch) => {
        channel.bind("updateDoctorsAdmin", data => {
            callToster(data, `${routesCommon.admin}${routersAdmin.calendar}`, {
                update: true
            });
        });
        channel.bind("updateCalendars", data => {
            callToster(data, `${routesCommon.admin}${routersAdmin.calendar}`);
        });
    },
    [`${rolesApp.doctor}`]: (channel, dispatch) => {
        channel.bind("receiveNewMessage", data => {
            const chatIsOpen = document.querySelector('.chat-block_open')

            const uuidRequest = data?.data?.service_request_uuid
            if(!chatIsOpen) dispatch(updateStatusMessRequest({mess: true, uuid: uuidRequest}))
            dispatch(getNotifications(uuidRequest, chatIsOpen)).then((resp)=>{
                if(!chatIsOpen) {
                    callToster(data, `${routesCommon.doctor}${routersDoctor.schedule}`, {
                        service_request_uuid: uuidRequest,
                    }, true, () => {
                        dispatch(setMessages({clear: true}))
                        dispatch(updateNotification({uuid: uuidRequest}))
                        dispatch(setDisplayChat(true))
                    }, {autoClose: 10000});
                }
            })
        });

        channel.bind("requestIsReadyDoctor", data => {
            callToster(data, `${routesCommon.doctor}${routersDoctor.schedule}`);
        });
        channel.bind("goToDetailsEmrNote", data => {
            callToster(data, `${routesCommon.doctor}${routersDoctor.request}${routersDoctor.info_request}/${data.data.service_request_uuid}`);
        });
        channel.bind("newAppointmentDoctor", data => {
            callToster(data, `${routesCommon.doctor}${routersDoctor.schedule}`);
        });
        channel.bind("acceptDoctorAssignment", data => {
            callToster(data, `${routesCommon.doctor}${routersDoctor.schedule}`);
        })
        channel.bind("updateDocumentsDoctor", data => {
            callToster(data, `${routesCommon.doctor}${routersDoctor.personal_info}`, {
                step: BUTTONSDOCTOR[1]
            });
        });
        channel.bind("moveToAdditionalInfo", data => {
            callToster(data, `${routesCommon.doctor}${routersDoctor.schedule}`, { isAdditional: true });
        });
        channel.bind("updateCalendars", data => {
            callToster(data, `${routesCommon.doctor}${routersDoctor.schedule}`);
        });
        channel.bind("updateCalendarsDoctors", data => {
            callToster(data, `${routesCommon.doctor}${routersDoctor.schedule}`);
        });
    },
    [`${rolesApp.patient}`]: (channel, dispatch) => {
        channel.bind("receiveNewMessage", data => {
            const chatIsOpen = document.querySelector('.chat-block_open')
            const uuidRequest = data?.data?.service_request_uuid
            if(!chatIsOpen) dispatch(updateStatusMessRequest({mess: true, uuid: uuidRequest}))
            dispatch(getNotifications(uuidRequest, chatIsOpen)).then((resp)=>{
                if(!chatIsOpen) {
                    callToster(data, `${routesCommon.patient}${routersPatient.details}/${uuidRequest}`, {
                        service_request_uuid: uuidRequest,
                    }, true, () => {
                        dispatch(setMessages({clear: true}))
                        dispatch(updateNotification({uuid: uuidRequest}))
                        dispatch(setDisplayChat(true))
                    }, {autoClose: 10000});
                }
            })
        });

        channel.bind("updateRequestPatient", data => {
            callToster(data, `${routesCommon.patient}${routersPatient.details}/${data.data.service_request_uuid}`);
        });
        channel.bind("updateRequestPatientETA", data => {
            callToster(data, `${routesCommon.patient}${routersPatient.details}/${data.data.service_request_uuid}`);
        });
        channel.bind("moveToAdditionalInfo", data => {
            callToster(data, `${routesCommon.patient}${routersPatient.details}/${data.data.service_request_uuid}`,
                {},
                true,
                () => {
                window.localStorage.setItem('listPatients', true)
            });
        });
        channel.bind("timeToRequestPatient", data => {
            callToster(data, `${routesCommon.patient}${routersPatient.details}/${data.data.service_request_uuid}`);
        });
        channel.bind("updateCalendars", data => {
            callToster(data, `${routesCommon.patient}${routersPatient.schedule}`);
        });

        channel.bind("chat-uuid", data => {
            callToster(data, `${routesCommon.patient}${routersPatient.schedule}`);
        });
    }
};

export const unsubscribePusher = uuid => {
    uuid && pusher.unsubscribe(uuid);
};

export const subscribeToEvents = (role, uuid) => dispatch => {
    let channel = pusher.subscribe(uuid);
    events[role](channel, dispatch);
};
