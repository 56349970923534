import React from "react";
import cn from "classnames";
import './style.scss';

export const SideBarRequest = ({
  open,
  children
}) => {
  return (
    <div
      className={cn("container-sidebar", {
        "container-sidebar--open": open
      })}
    >
      {children}
    </div>
  );
};
