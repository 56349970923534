import * as components from "./components";
import {PatientData} from "./components/PatientData";
import {GroupBlockReviewOfSystem} from "./components/GroupBlockReviewOfSystems";

export const template = {
    name: 'Neck pain (cervical pain)',
    body: {
        body: [
            {
                type: "label",
                data: "PRESENTING PROBLEM:",
                items: [
                    {
                        type: "text",
                        data: 'Neck pain (cervical pain)',
                        editable: true,
                        source: null
                    },
                ]
            },
            {
                type: "label",
                data: "HISTORY OF THE PRESENT ILLNESS:",
                required: true,
                items: [
                    ...PatientData(),
                    {
                        type: "text",
                        data: 'who reports',
                        editable: true,
                        source: null
                    },
                    {
                        type: "radio-block",
                        data: [{checked: false, data: "non"},
                            {checked: false, data: "radicular"},
                        ]
                    },
                    {
                        type: "radio-block",
                        data: [{checked: false, data: "mild"},
                            {checked: false, data: "moderate"},
                            {checked: false, data: "severe"},
                        ]
                    },
                    {
                        type: "text",
                        data: 'pain in the neck and',
                        editable: true,
                        source: null
                    },
                    {
                        type: "radio-block",
                        data: [{checked: false, data: "back"},
                            {checked: false, data: "arm"},
                        ]
                    },
                    {
                        type: "text",
                        data: ', beginning',
                        editable: true,
                        source: null
                    },
                    {
                        type: "input",
                        placeholder: "duration",
                        data: "",
                    },

                    {
                        type: "text",
                        data: '.',
                        editable: true,
                        source: null
                    },
                    {
                        type: "radio-block",
                        data: [{checked: false, data: "Has"},
                            {checked: false, data: "no"},
                        ]
                    },

                    {
                        type: "text",
                        data: 'history of trauma details. Otherwise:',
                        editable: true,
                        source: null
                    },
                    {
                        type: "checkbox",
                        data: [
                            {checked: false, data: "paresthesias"},
                            {checked: false, data: "weakness,"},
                            {checked: false, data: "fever"},
                            {checked: false, data: "sore throat"},
                        ]
                    },
                    {
                        type: "text",
                        data: '.',
                        editable: true,
                        source: null
                    },
                    {
                        type: "radio-block",
                        data: [{checked: false, data: "Has"},
                            {checked: false, data: "no"},
                        ]
                    },
                    {
                        type: "text",
                        data: 'history of prior neck problem.',
                        editable: true,
                        source: null
                    },
                ]
            },
            {
                type: "label",
                data: "REVIEW OF SYSTEMS:",
                items: [
                    GroupBlockReviewOfSystem({title: 'General:', radio: 'fever'}),
                    GroupBlockReviewOfSystem({title: 'HENT:', radio: 'congestion'}),
                    GroupBlockReviewOfSystem({title: 'Respiratory:', radio: 'cough'}),
                    GroupBlockReviewOfSystem({title: 'Cardiovascular:', radio: 'chest pain'}),
                    GroupBlockReviewOfSystem({title: 'GI:', radio: 'abdominal pain'}),
                    GroupBlockReviewOfSystem({title: 'GU:', radio: 'urinary complaints'}),
                    GroupBlockReviewOfSystem({title: 'Musculoskeletal:', radio: 'other aches or pains'}),
                    GroupBlockReviewOfSystem({title: 'Endocrine:', radio: 'generalized weakness'}),
                    GroupBlockReviewOfSystem({title: 'Neurological:', radio: 'prior localized weakness'}),
                    GroupBlockReviewOfSystem({title: 'Psychiatric:', radio: 'emotional stress'}),
                ]
            },
            components.PMH(),
            components.GYM(),
            components.FamSocHistory(),
            components.Medications(),
            components.Allergies(),
            components.PhysicianExamination({
                items: [
                    {
                        type: "label",
                        data: "Generalized Appearance:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Alert, cooperative, in',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-block",
                                data: [{checked: false, data: "no"},
                                    {checked: false, data: "mild"},
                                    {checked: false, data: "moderate"},
                                    {checked: false, data: "severe"}
                                ]
                            },
                            {
                                type: "text",
                                data: 'distress.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    components.VitalSigns(),
                    {
                        type: "label",
                        data: "Skin:",
canDelete: true,
                        required: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Warm, dry;',
                                editable: true,
                                source: null
                            },
                            ...components.RenderRadioSingleBLocks(
                                ['cyanosis', 'rash']),
                            {
                                type: "text",
                                data: 'in area of pain.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    {
                        type: "label",
                        data: "Head:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            ...components.RenderRadioSingleBLocks(['scalp swelling', 'scalp tenderness']),
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    components.Eyes(),
                    {
                        type: "label",
                        data: "ENMT:",
canDelete: true,
                        items: [

                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Pharynx clear:',
                                editable: true,
                                source: null
                            },
                            ...components.RenderRadioSingleBLocks(
                                ['erythema', 'swelling']),
                            {
                                type: "text",
                                data: '. Airway patent:',
                                editable: true,
                                source: null
                            },
                            ...components.RenderRadioSingleBLocks(
                                ['stridor', 'hoarseness']),
                            {
                                type: "text",
                                data: '. Mucous membranes moist.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    {
                        type: "label",
                        data: "Neck:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "radio-block",
                                data: [{checked: true, data: "minimal"},
                                    {checked: false, data: "mild"},
                                    {checked: false, data: "moderate"}
                                ]
                            },
                            {
                                type: "text",
                                data: 'tenderness of',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-block",
                                data: [{checked: true, data: "right"},
                                    {checked: false, data: "left anterior"},
                                    {checked: false, data: "lateral"}
                                ]
                            },
                            {
                                type: "text",
                                data: 'neck,',
                                editable: true,
                                source: null
                            },
                            ...components.RenderRadioSingleBLocks( ['lymphadenopathy', 'thyromegaly', 'mass']),
                            {
                                type: "text",
                                data: '. Pain aggravated by lateral flexion to',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-block",
                                data: [{checked: true, data: "ipsi"},
                                    {checked: false, data: "contra"}]
                            },
                            {
                                type: "text",
                                data: 'lateral side.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    components.ChestRespiratory(),
                    {
                        type: "label",
                        data: "Extremities:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Shoulders and arms nontender. Distal pulses normal.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    {
                        type: "label",
                        data: "Neuro/Psych:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Strength of deltoid, biceps, triceps normal. DTRs symmetric. Sensation normal.',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                ]
            }),
            components.TreatmentPerformed({items: [{
                    type: "text",
                    data: 'Toradol 60mg IM',
                    editable: true,
                    source: null
                }]}),
            components.AssessmentPlan([ {
                type: 'blockCheckboxCondition',
                extraType: 'assessmentContent',
                data: {
                    checkbox: [{checked: true, data: '', isHide: true}],
                    hiddenBlock: [
                        {
                            type: "text",
                            data: 'Follow-up consultation to be arranged with',
                            editable: true,
                            source: null
                        },
                        {
                            type: "radio-block",
                            data: [{checked: true, data: "PCP"},
                                {checked: false, data: "orthopedic surgeon"}]
                        },
                        {
                            type: "text",
                            data: 'for further evaluation. Go to the nearest ED in case of any weakness, numbness, incontinence, or increased pain.',
                            editable: true,
                            source: null
                        },
                    ]
                }
            }]),
        ]
    }
}
