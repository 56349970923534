import React, { useEffect, useState } from "react";
import { Typography } from "../../Common/Typography/Typography";
import Timer from "react-compound-timer";
import cn from "classnames";
import { ASSIGNED } from "../../../constants";
import moment from "moment";
import { durationTimeLaterRequest } from "../../../utils/date";
import {formatValueTime} from "../../../utils/common";

export const TimerPatient = ({
  setNewTime,
  initialTime,
  showTimer,
  showTimeVisit,
  startTimer,
  data
}) => {
  const timeLaterRequest =
    data &&
    durationTimeLaterRequest(
      data.application_can_start_at,
      data.application_time
    );

  const dataLaterRequest =
    data && moment(data.application_can_start_at).format("MMM D");

  return (
    <>
      {showTimer && (
        <div className={cn("approximate-eta")}>
          <Typography text="Approximate ETA" variant="span" />
          <Timer
            initialTime={initialTime}
            direction="backward"
            startImmediately={true}
            timeToUpdate={10}
          >
            {({ start, setTime }) => {
              setNewTime(setTime);
              startTimer(start);
              return (
                <div className="approximate-eta_container">
                  <span className="approximate-eta_time">
                    <Timer.Hours formatValue={formatValueTime}/>:<Timer.Minutes formatValue={formatValueTime}/>:<Timer.Seconds formatValue={formatValueTime}/>
                  </span>
                  <Typography
                    text="Left"
                    variant="p"
                    className="approximate-eta_left"
                  />
                </div>
              );
            }}
          </Timer>
        </div>
      )}
      {showTimeVisit && (
        <div className={cn("approximate-eta")}>
          <Typography text={dataLaterRequest} variant="span" />
          <div className="approximate-eta_container-extra">
            <span className="approximate-eta_time">
              {timeLaterRequest.timeOfStart}
            </span>
            <Typography text={timeLaterRequest.periodOfStart.toLowerCase()} variant="span" />
            <span className="approximate-eta_time approximate-eta_time-last">
              {`- ${timeLaterRequest.timeOfEnd}`}
            </span>
            <Typography text={timeLaterRequest.periodOfEnd.toLowerCase()} variant="span" />
          </div>
        </div>
      )}
    </>
  );
};
