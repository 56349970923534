import React, {useEffect, useState} from "react";
import {CustomTable} from "../../Table/Table";
import "./style.scss";
import {PopupContainer} from "../../Common/Popup/PopupContainer";
import {ADMIN} from "../../../constants";
import {dataHeader} from "../../../constants/archiveConstans";
import moment from "moment";

const doctorFilesByType = {
    'employee': [
        "CV",
        "Form I-9 Employment Eligibility Verification",
        "Copy of DEA",
        "Copy of State Medical License",
        "1099 Form",
        "Voided Check (For Payments)",
        "Copy of Social Security Card",
        "Copy of Government-Issued Photo ID",
        "W4 Form",
        "Employment Application",
    ],
    'contractor': [
        "CV",
        "Copy of DEA",
        "Copy of State Medical License",
        "1099 Form",
    ]
}

const Archive = ({
                     archived,
                     unArchiveFiles,
                     id,
                     getDataDoctor,
                     getArchivedDocuments,
                     doctorType
                 }) => {
    const [selectFile, setSelectFile] = useState("");
    const [showPopUp, setShowPopUp] = useState(false);

    useEffect(() => {
        getArchivedDocuments(id);
        return () => getDataDoctor(id, ADMIN);
    }, []);

    const archiveFile = (rowDataUuid, bool) => {
        setSelectFile(rowDataUuid);
        return unArchiveFiles(rowDataUuid, bool).then(resp => {
            if (resp && resp.status === 409) {
                setShowPopUp(true);
            } else {
                setShowPopUp(false);
            }
        });
    };

    const loadTableData = () => (
        {
            limit: 10,
            offset: archived.length
        },
            archived
    );

    const archivedArr = archived.map(i => ({
        ...i,
        expiry_date: i.expiry_date ? moment(i.expiry_date).format('MM/DD/YYYY') : '',
        uploaded_at: i.uploaded_at ? moment(i.uploaded_at).format('MM/DD/YYYY') : '',
        archived_at: i.archived_at ? moment(i.archived_at).format('MM/DD/YYYY') : ''
    }));

    return (
        <div className="archive">
            <CustomTable
                loadTableData={loadTableData}
                heightTable="calc(100vh - 190px)"
                columnsHeader={dataHeader}
                data={archivedArr}
                className="archive-underline"
                rowHeight={52}
                actions={{
                    archiveFile: archiveFile,
                    canUnArchived: (fileType) => doctorFilesByType[doctorType].includes(fileType)
                }}
            />
            <PopupContainer
                open={showPopUp}
                close={() => setShowPopUp(false)}
                title=""
                extraTitle=""
                textDefault="CANCEL"
                textPrimary="SWAP"
                actionPrimaryButton={() => archiveFile(selectFile, true)}
                actionDefaultButton={() => setShowPopUp(false)}
            >
                <div className="archive-popup">This File Already Exist.</div>
                <div className="archive-popup">Do you Want To Swap Them?</div>
            </PopupContainer>
        </div>
    );
};

export default Archive;
