import React, {useEffect, useRef, useState} from "react";
import "./style.scss";
import cn from 'classnames'
import {DisabledOverlay} from "../DisabledOverlay";

export const Input = ({ updateState, data, error, disabled, styles={}}) => {
    const [showPlaceholder, setShowPlaceholder] = useState(!data.data && !!data.placeholder)
    const [width, setWidth] = useState(100)
    const isError = error && !data.data

    let refInput = useRef(null)

    useEffect(()=>{
        setWidth(refInput.current.offsetWidth + 12)
    }, [])
    return (
        <span ref={refInput} style={{minWidth: width, ...styles}} className={cn("emr-notes-input", {
            'emr-notes-input_error': isError,
            'emr-notes-input_value': showPlaceholder,
            'emr-notes-input_textarea': data.isTextarea
        })}>
      <span className="emr-notes-input_container">
        <span
            onFocus={()=> setShowPlaceholder(false)}
          className="emr-notes-input_container_text"
          onBlur={e => {
              if(!!e.target.innerText) setShowPlaceholder(false)
              else setShowPlaceholder(true)
              let value = e.target.innerText
            updateState({
                ...data,
                data: value
            });
          }}
          contentEditable={true}
        >
          {showPlaceholder ? data.placeholder : data.data}
        </span>
      </span>
        <DisabledOverlay show={disabled}/>
    </span>
  );
};
