import React, {useEffect, useState} from 'react'
import {PopupContainer} from "../../../../../Common/Popup/PopupContainer";

import './style.scss'
import {DropDown} from "../../../../../Common/DropDown/DropDown";
import {HeaderNotes} from "../../../componentsPDF/HeaderNotes/HeaderNotes";
import {TablePatient} from "../../../componentsPDF/TablePatient/TablePatient";
import moment from "moment";
import {FooterNotes} from "../../../componentsPDF/FooterNotes/FooterNotes";
import {Input} from 'components/Common/Input/Input';
import {isntNullish} from '../../../../../../utils/common';

export const EditorReceipt = ({
                                  open,
                                  close,
                                  dataRequest,
                                  updateReceipt,
                                  cptCodes,
                                  receiptData,
                                  signature,
                                  targetRequest
                              }) => {
    const [receiptOptions, setReceiptOptions] = useState([])
    const [validationError, setValidationError] = useState(null)
    useEffect(() => {
        receiptOptionsLoaded();
    }, [receiptData])

    const receiptOptionsLoaded = () => {
        if (receiptData.status) {
            let arr = receiptData?.receipt_elements?.map(receiptOption => {
                return {
                    uuid: receiptOption.uuid,
                    cpt: receiptOption.cpt ? {label: receiptOption.cpt?.code, value: receiptOption.cpt?.uuid} : null,
                    serviceDescription: receiptOption.cpt?.name,
                    price: isntNullish(receiptOption.price) ? parseInt(receiptOption.price) + '' : '',
                    diagnosis: receiptOption?.diagnosis ? (`${receiptOption?.diagnosis?.code} ${receiptOption?.diagnosis?.name}`) : receiptOption.additional_service_request_assigment?.name,
                    is_free_type: receiptOption.cpt?.is_free_type,
                }
            }) || [];
            setReceiptOptions(arr)
        }
    }

    const updateCPTcodes = (field, value) => {
        setReceiptOptions((prev) =>
            prev.map((item) => {
                if (item.uuid === field) {
                    const updatedItem = {
                        ...item,
                        serviceDescription: value.name,
                        cpt: value,
                        price: value.is_free_type ? '' : value.price + '',
                        is_free_type: value.is_free_type,
                    }
                    return updatedItem;
                }
                return {
                    ...item, serviceDescription: null,
                    cpt: null,
                    price: ''
                };
            })
        );
    };

    const isReceiptValid = (receiptOptions) => {
        const cptCode = receiptOptions.find((option) => option.cpt);
        const hasPrice = isntNullish(cptCode.price);
        return hasPrice;
    };


    const saveReceipt = (status) => {
        if (status === 'draft' || (receiptOptions.length && isReceiptValid(receiptOptions))) {
            const cptFinal = receiptOptions.map((el) => ({
                ...el,
                price: el.price ? parseInt(el.price) : null,
            }));
            updateReceipt(cptFinal, status).then((resp) => {
                setReceiptOptions([]);
            });
            setValidationError(null);
        } else {
            setValidationError(true);
        }
    };

    const handleCancel = () => {
        setValidationError(null);
        receiptOptionsLoaded();
        close();
    };

    const sum = receiptOptions.filter(i => i.price).reduce((acc, elem) => acc + Number(elem.price ? elem.price : 0), 0);
    const date = moment(receiptData.updated_at || receiptData.created_at).format('MM/DD/YYYY hh:mm a');

    const inputValueProvider = (field, value) => {
        setValidationError(null);
        setReceiptOptions((prev) =>
            prev.map((item) => {
                if (item.uuid === field) {
                    return {...item, price: value};
                }
                return item;
            })
        );
    };

    return (
        <PopupContainer
            className='receipt-container-editor'
            open={open}
            close={handleCancel}
            title="Patient Receipt"
            actionPrimaryButton={saveReceipt}
            actionDefaultButton={handleCancel}
            uppercaseButton
            customSaveButton
        >
            <div className="receipt-container__wrapper">
                <div className="optional-templates-container_preview-block__receipt">
                    <div className="templates-header__receipt">
                        <HeaderNotes
                            contextComponent={{title: dataRequest?.service_type === 'covid' ? 'Receipt for COVID-19 Test Visit' : 'RECEIPT FOR THE VISIT'}}
                            dataRequest={dataRequest}
                            date={date}/>
                        <div className='line bold'/>
                        <div className="main-context">
                            <div className='receipt-context'>
                                <TablePatient dataRequest={dataRequest} targetRequest={targetRequest}
                                              dateService={moment(dataRequest.application_can_start_at).format('MM/DD/YYYY')}/>

                                <table style={{width: '100%', marginTop: 30}} className='table-service'>
                                    <tr>
                                        <th width='25%'>CPT Codes</th>
                                        <th width='15%'>Service Description</th>
                                        <th width='40%'>ICD 10 codes</th>
                                        <th width='20%'>Charges</th>
                                    </tr>

                                    {receiptOptions.map((receiptOption, ind, arr) => {
                                        return (
                                            <>
                                                <tr>
                                                    <td>
                                                        <DropDown
                                                            menuPlacement='top'
                                                            value={receiptOption.cpt}
                                                            label='CPT code'
                                                            field={receiptOption.uuid}
                                                            options={cptCodes}
                                                            handlerSelect={updateCPTcodes}
                                                            error={validationError && !receiptOption.cpt}
                                                        />
                                                    </td>
                                                    <td>{receiptOption?.serviceDescription}</td>
                                                    <td>{receiptOption?.diagnosis}</td>
                                                    <td>{isntNullish(receiptOption?.cpt) && (
                                                        <Input
                                                            disable={!receiptOption.is_free_type}
                                                            field={receiptOption.uuid}
                                                            type="number"
                                                            placeholder="Charges"
                                                            onChange={inputValueProvider}
                                                            value={receiptOption?.price}
                                                            error={validationError && !receiptOption.price}
                                                        />
                                                    )}
                                                    </td>
                                                </tr>
                                                {!!arr[ind]?.diagnosis && !arr[ind + 1]?.diagnosis && arr.length !== ind + 1 &&
                                                    <tr>
                                                        <td colSpan={4}>Additional services</td>
                                                    </tr>
                                                }
                                            </>
                                        )
                                    })}
                                    <tr>
                                        <td/>
                                        <td/>
                                        <td/>
                                        <td>{`$${sum}.00`}</td>
                                    </tr>
                                </table>
                                <div className='receipt-context__sum'>This invoice was paid in full by credit card in
                                    the amount
                                    of {`$${sum}.00`}</div>
                            </div>
                        </div>
                        <FooterNotes dataRequest={dataRequest} signature={signature}/>
                    </div>
                </div>
            </div>

        </PopupContainer>
    )
}
