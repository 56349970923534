import React, {useEffect, useMemo, useRef, useState} from "react";
import cn from "classnames";
import { openChatsFromRedirect } from "../../../utils/common";
import { colors } from "../../../assets/colors/colors";
import { Typography } from "../../Common/Typography/Typography";
import { CardDetails } from "./CardDetails";
import {timeDelta, addMilliseconds} from "../../../utils/date";
import {
  momentAbbreviation,
  routesCommon,
  BTN_LABELS
} from "../../../constants";
import { TimerPatient } from "../../SideBarRequest/ComponentRequestPatient/Timer";
import { DECLINED } from "../../../constants/constants";
import history from "../../../helpers/history";
import { routersPatient } from "../../../constants/Patient";
import { Route, Redirect, Switch } from "react-router-dom";
import { Services } from "./Services";
import { CardsForServices } from "./Cards";
import Analytics from "../../../utils/analytics/AnalyticsService";
import { EVENTS } from "../../../utils/analytics/Events";
import { Chat } from "../../Chats";
import { Map } from '../../../compoenentsAdditional/Map';
import { CountPatient } from "../../../compoenentsAdditional/CountPatients";
import { IconButton } from "../../Common/IconButton";
import { ArrowBack, Error} from '@material-ui/icons';
import { ButtonV2 } from "../../Common/ButtonV2";
import { COLORS_BTN, VARIATIONS_BTN } from "../../Common/ButtonV2/constants";
import { PersonalPatientCard } from "./PersonalPatientCard";
import { useDispatch, useSelector } from "react-redux";
import { requestDetailsSelector, requestFilesSelector } from "../../../store/request/selectors";
import { getDataRequest, getFilesRequest } from "../../../store/request/actions";
import { checkYouPatient } from "../../../utils/request";
import { PriceBlock } from "../../../compoenentsAdditional/PriceBlock";
import { groupPatientsByRelation } from "../../../helpers/helpers";
import { clearRequestDetails } from "../../../store/request/slice";
import {displayChatSelector} from "../../../store/stable/selectors";
import {setDisplayChat} from "../../../store/stable/slice";
import {Tooltip} from "../../Common/Tooltip";
import {patientHideInfoOtherPatient} from "../../Common/Tooltip/constants";

export const DetailsRequest = ({
  id,
  getCardsPatient,
  cards,
  updateData,
  addNewCard,
  changeDefaultCard,
  filesRequest,
  approveDeclineServices,
                                   location,
                                   user
}) => {
    const [initialTime, setInitialTime] = useState(0);
    const [showTimer, setShowTimer] = useState(false);
    const [showTimeVisit, setShowTimeVisit] = useState(false);
    const [selectedServices, setSelectedServices] = useState([])
    const [activePatientServices, setActivePatientServices] = useState(null)
    const [showListPatients, setListPatients] = useState(false)

    const chatVisible = useSelector(displayChatSelector)
    const {timestamp, data: details} = useSelector(requestDetailsSelector) || {}
    const dispatch = useDispatch()

    useEffect(()=>{
        Analytics.track(EVENTS.Request_Details_Page_Open);
        const header = document.querySelector('.container-custom-header')
        if(header) header.style.display = 'none'

        return ()=>{
            dispatch(setDisplayChat(false))
            dispatch(clearRequestDetails())
            if(header) header.style.display = 'flex'
        }
    },[])

    useEffect(()=>{
        if(location?.state?.openListPatients && details?.patients_number > 1){
            setListPatients(true)
        }
    },[JSON.stringify(location)])

    useEffect(()=>{
        if(activePatientServices?.uuid) history.push(baseUrl + routersPatient.services)
    },[activePatientServices?.uuid])

    useEffect(() => {
        dispatch(getDataRequest({uuid: id}))
        getCardsPatient()
        dispatch(getFilesRequest({id}))
    }, [id, updateData])

    const getDateInitialTime = data => {
        let time = timeDelta(
            data.doctor_5_mins_left_should_arrive_at ||
            data.doctor_should_arrive_at ||
            data.application_time,
            momentAbbreviation.milliseconds
        );
        if(!data.doctor_5_mins_left_should_arrive_at && !!data.doctor_should_arrive_at) {
          time = addMilliseconds(time, 300000);
        }
        return time;
    };
    const setEtaView = data => {
        if (details.urgency_type === "now") {
            const time = getDateInitialTime(data);
            setInitialTime(time);
            setNewTime(time);
            startTimer();
            setShowTimer(true);
            setShowTimeVisit(false);
        } else {
            if (data.doctor_should_arrive_at) {
                const time = getDateInitialTime(data);
                setInitialTime(time);
                setNewTime(time);
                startTimer();
                setShowTimer(true);
                setShowTimeVisit(false);
            } else {
                setShowTimer(false);
                setShowTimeVisit(true);
            }
        }
    };

    useEffect(()=>{
        if (
            details?.uuid &&
            !details.doctor_arrived_at &&
            details.status !== DECLINED
        ) {
            setEtaView(details);
        } else {
            setShowTimer(false);
            setShowTimeVisit(false);
        }
    },[details?.uuid])

    useEffect(()=>{
        if(window.localStorage.listPatients){
            setActivePatientServices(null)
            window.localStorage.removeItem('listPatients')
            setListPatients(true)
        }
    },[timestamp])



    const closeChat = () => {
        dispatch(setDisplayChat(false))
    }
    const openChat = () => {
        dispatch(setDisplayChat(true))
    }
    const closeSideBar = () => {
        history.push(`${routesCommon.patient}${routersPatient.schedule}`)
    }

  const baseUrl=`${routesCommon.patient}${routersPatient.details}/${id}`
    let setNewTime = () => {
    };
    let startTimer = () => {
    };


    const services = activePatientServices?.additional_services || [];

    if(!details) return <></>

    return (
        <>
            <Chat className={'details-request-patient'} dataRequest={details} isPatient open={chatVisible} hideArrowBack closeChat={closeChat} closeSideBar={closeSideBar}/>
            <div className={cn("details-request", {'details-request_chat-open': chatVisible})}>
            <Switch>
                <Route
                    path={baseUrl + routersPatient.info}
                    render={({location}) => <DetailsRequestContent details={details} baseUrl={baseUrl}
                                                                   initialTime={initialTime}
                                                                   timestampRequest={timestamp}
                                                                   showListPatients={showListPatients}
                                                                   setListPatients={setListPatients}
                                                                   showTimer={showTimer}
                                                                   showTimeVisit={showTimeVisit}
                                                                   openChat={openChat}
                                                                   user={user}
                                                                   setActivePatientServices={setActivePatientServices}
                                                                   filesRequest={filesRequest}
                                                                   setNewTime={func => {
                                                                       setNewTime = func;
                                                                   }}
                                                                   startTimer={func => {
                                                                       startTimer = func;
                                                                   }}
                    />}/>
                <Route
                    path={baseUrl + routersPatient.services}
                    render={({location}) => <Services services={services}

                                                      selectedServices={selectedServices}
                                                      baseUrl={baseUrl}
                                                      id={activePatientServices?.uuid}
                                                      setActivePatientServices={setActivePatientServices}
                                                      approveDeclineServices={approveDeclineServices}
                                                      setSelectedServices={setSelectedServices}/>}/>
                <Route
                    path={baseUrl + routersPatient.cards}
                    render={({location}) => <CardsForServices
                        addNewCard={addNewCard}
                        changeDefaultCard={changeDefaultCard}
                        selectedServices={selectedServices}
                        setSelectedServices={setSelectedServices}
                        id={activePatientServices?.uuid}
                        approveDeclineServices={approveDeclineServices}
                        cards={cards}
                        baseUrl={baseUrl}/>}/>

                <Redirect to={baseUrl + routersPatient.info}/>
            </Switch>
        </div>
            </>
    );
};


const DetailsRequestContent = ({details,
                                   timestampRequest,
                                   initialTime,
                                   showTimer,
                                   showTimeVisit,
                                   setNewTime,
                                   startTimer,
                                   openChat,
                                   showListPatients,
                                   setListPatients,
                                   setActivePatientServices,
                                   user
                               }) => {
    const files = useSelector(requestFilesSelector)
    const refContainer = useRef()

    useEffect(()=>{
        refContainer.current.scrollTo(0, 0)
    }, [showListPatients])
    const goToListAdditionalServices = (data) => {
        setActivePatientServices(data)
    }
    const onClose = () => history.push(`${routesCommon.patient}${routersPatient.schedule}`)

    const youPatient = checkYouPatient({request: details})
    let servicesPrice = 0;

    if(details?.additional_services && details?.additional_services?.length){
        details.additional_services.filter(i => i.payment_status === 'paid').forEach((i)=>{
            servicesPrice += Math.floor(+i.price)
        })
    }


    let isPromoCodes = details.promo_codes && details.promo_codes[0];

    const isCovid = details.service_type === 'covid';
    let price = 600
    if(isCovid) {
        price = 350;
    }
    let totalPrice = price;
    if(servicesPrice) totalPrice += servicesPrice
    if(isPromoCodes) {
        totalPrice -= Math.floor(+details.promo_codes[0].price)
        if(totalPrice < 0) totalPrice = 0
    }
    const titlePage = showListPatients ? 'List Of Patients' : 'Request Details'
    const isGroup = details.is_group
    const countPatients = details?.patients_number
    const notDeletedAmount = details?.patients.map(i => !i.is_deleted).length;
    const userIsClient = details?.client_user_info?.uuid === user.uuid
    let dataPatient = details?.patients[0]
    const isDeclined = details?.status?.toLowerCase() === DECLINED
    return <div className='details-request_info' ref={refContainer}>
        <div className='details-request_info_top-block'/>
        <div className="details-request_header">
            {showListPatients ? <IconButton onClick={()=> setListPatients(false)}><ArrowBack/></IconButton> : <span/>}
            <Typography color={colors.white} variant="h1" text={titlePage}/>
            <IconButton onClick={onClose}/>
        </div>
        {showListPatients && <ListPatients goToListAdditionalServices={goToListAdditionalServices} files={files} details={details}/>}
        {!showListPatients && <>
        {!isDeclined && <div className="details-request_eta">
                <TimerPatient
                    data={details}
                    initialTime={initialTime}
                    showTimer={showTimer}
                    showTimeVisit={showTimeVisit}
                    setNewTime={setNewTime}
                    startTimer={startTimer}
                />
            </div>}
            <div className="details-request_body">
                <CardDetails
                    isGroup={isGroup}
                    openChat={openChat}
                    details={details}
                />
                {isGroup && userIsClient && notDeletedAmount > 1 ?
                    <CountPatient number={countPatients} activeMode onClick={() => setListPatients(true)}/> : <>
                        <div className='details-request_body_title-patient'><span
                            className='details-request_body_you'>{dataPatient.client_patient_relationship || 'Requester'}</span>
                            {isGroup && notDeletedAmount > 1 &&
                                <div className='details-request_body_title-patient_content'>
                                    <span>{`+ ${countPatients - 1} more`}</span>
                                    <Tooltip title={patientHideInfoOtherPatient}>
                                        <Error/></Tooltip></div>}</div>
                        <PersonalPatientCard files={files?.data[0]?.documents}
                                             userData={dataPatient}
                                             payAdditionalServices={userIsClient ? () => goToListAdditionalServices(dataPatient) : null}/>
                    </>}
                <Map
                    isShowSuite
                    data={details}
                    className="details-request_body_container-address_map"
                    comment={details?.location.comment}
                />
                <PriceBlock userIsClient={userIsClient} timestampRequest={timestampRequest} request={details}/>
            </div>
        </>}
        {/*<div className="details-request_buttons">*/}
        {/*    <ButtonV2 uppercase onClick={onClose} variant={VARIATIONS_BTN.CONTAINED} color={COLORS_BTN.PRIMARY}>{BTN_LABELS.CLOSE}</ButtonV2>*/}
        {/*</div>*/}
    </div>
}


const ListPatients = ({details, files, goToListAdditionalServices}) => {
    const patients = details?.patients;
    return <div className='details-request_body'>
        {groupPatientsByRelation(patients, true)?.map((i, idx) => {
            const label = i.label === 'null' ? 'You' : i.label
            const Tag = idx === 0 ? 'white' : 'div'
            return <>
                <Tag className='details-request_body_label'>{label}</Tag>
                {i.items.map(j => {
                    const filesPatient = files?.data.find(k => k.uuid === j.uuid)
                    return <PersonalPatientCard payAdditionalServices={()=> goToListAdditionalServices(j)} files={filesPatient?.documents} userData={j}/>
                })}</>
        })}
    </div>
}
