import React from "react";
import { easeQuadInOut } from "d3-ease";
import {
  buildStyles,
  CircularProgressbarWithChildren
} from "react-circular-progressbar";

import "react-circular-progressbar/dist/styles.css";
// Animation
import AnimatedProgressProvider from "./AnimatedProgressProvider";
import { Typography } from "../Typography/Typography";
import cn from 'classnames'
import "./style.scss";
import {colors} from "../../../assets/colors/colors";

export const RoundProgressBar = ({ value, maxValue = 10, duration = 1.4, className, toFixedValue = 1}) => {
  return (
    <div className={cn("round-wrapper", className)}>
      <div className="round-background" />
      <AnimatedProgressProvider
        valueStart={0}
        valueEnd={value}
        duration={duration}
        easingFunction={easeQuadInOut}
      >
        {value => {
          const roundedValue = value.toFixed(toFixedValue);
          return (
            <CircularProgressbarWithChildren
              strokeWidth={5}
              maxValue={maxValue}
              value={value}
              /* This is important to include, because if you're fully managing the
                  animation yourself, you'll want to disable the CSS animation. */
              styles={buildStyles({
                pathColor: colors.navy,
                trailColor: "#DADCE0",
                pathTransition: "none"
              })}
            >
              <div>
                <Typography variant="h4" color={colors.navy} text={`${roundedValue} of `} />
                <Typography variant="h4" color="#757575" text={maxValue} />
              </div>
            </CircularProgressbarWithChildren>
          );
        }}
      </AnimatedProgressProvider>
    </div>
  );
};
