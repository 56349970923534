import * as components from "./components";
import {PatientData} from "./components/PatientData";

export const template = {
    name: 'Asthma (adult)',
    body: {
        body: [
            {
                type: "label",
                data: "PRESENTING PROBLEM:",
                items: [
                    {
                        type: "text",
                        data: 'Shortness of breath (asthma adult)',
                        editable: true,
                        source: null
                    },
                ]
            },
            {
                type: "label",
                data: "HISTORY OF THE PRESENT ILLNESS:",
                required: true,
                items: [
                    ...PatientData(),
                    {
                        type: "text",
                        data: 'who reports',
                        editable: true,
                        source: null
                    },
                    {
                        type: "input",
                        placeholder: "duration",
                        data: "",
                    },
                    {
                        type: "text",
                        data: 'history of',
                        editable: true,
                        source: null
                    },
                    {
                        type: "radio-block",
                        data: [{checked: false, data: "mild"},
                            {checked: false, data: "moderate"},
                            {checked: false, data: "severe"}]
                    },
                    {
                        type: "text",
                        data: 'dyspnea and wheezing despite treatment with',
                        editable: true,
                        source: null
                    },
                    {
                        type: "input",
                        placeholder: "medication name",
                        data: "",
                    },
                    {
                        type: "text",
                        data: '. Episode apparently precipitated by',
                        editable: true,
                        source: null
                    },
                    {
                        type: "input",
                        placeholder: "URI/allergy/stress",
                        data: "",
                    },
                    {
                        type: "text",
                        data: '.',
                        editable: true,
                        source: null
                    }
                ]
            },
            {
                type: "label",
                data: "REVIEW OF SYSTEMS:",
                items: [
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'General:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "fever"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'HENT:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "prior congestion"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'Respiratory:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "prior cough"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'Cardiovascular:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "chest pain"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'GI:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "abdominal pain"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'GU:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "urinary complaints"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'Musculoskeletal:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "other aches or pains"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'Endocrine:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "generalized weakness"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'Neurological:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "localized weakness"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: '. Psychiatric:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "emotional stress"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                ]
            },
            components.PMH(),
            components.GYM(),
            components.FamSocHistory(),
            components.Medications(),
            components.Allergies(),
            components.PhysicianExamination({
                items: [
                    components.GeneralizedAppearance(),
                    components.VitalSigns(),
                    {
                        type: "label",
                        data: "Skin:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Warm, dry;',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "cyanosis"}]
                            },
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    components.Eyes(),
                    {
                        type: "label",
                        data: "ENMT:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Mucous membranes moist. Airway patent:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "stridor"}]
                            },
                            {
                                type: "text",
                                data: '. Pharynx:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "swelling"}]
                            },
                            {
                                type: "text",
                                data: ',',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "erythema"}]
                            },
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    components.Neck(),
                    {
                        type: "label",
                        data: "Chest/Respiratory:",
canDelete: true,
                        required: true,
                        items: [{
                            type: "input",
                            placeholder: "Location (arm / leg)",
                            data: "",
                        },{
                            type: "radio-single",
                            data: [{checked: true, data: "bilateral expiratory wheezing"}]
                        },
                            {
                                type: "text",
                                data: ';',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "rales"}]
                            },
                            {
                                type: "text",
                                data: ',',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "rhonchi"}]
                            },
                            {
                                type: "text",
                                data: ',',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "rub"}]
                            },
                            {
                                type: "text",
                                data: '; breath sounds equal bilaterally.',
                                editable: true,
                                source: null
                            },]
                    },
                    components.HeartCardiovascular(),
                    {
                        type: "label",
                        data: "Abdomen/GI:", canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Soft;',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "tenderness"}]
                            },
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    components.Extremities(),
                    {
                        type: "label",
                        data: "Neuro/Psych:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Mental status as above;',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "focal findings"}]
                            },
                            {
                                type: "text",
                                data: '.', editable: true,
                                source: null
                            },
                        ]
                    },
                ]
            }),
            components.TestPerformed({items: [
                    {
                        type: 'blockCheckboxCondition',
                        data: {
                            checkbox: [{checked: false, data: 'Covid 19 test -'}],
                            hiddenBlock: [
                                {
                                    type: "radio-block",
                                    data: [{checked: true, data: "Negative"},
                                        {checked: false, data: "Positive"}]
                                },
                            ]
                        }
                    },
                    {
                        type: 'blockCheckboxCondition',
                        data: {
                            checkbox: [{checked: false, data: 'Flu test'}],
                            hiddenBlock: [
                                {
                                    type: "radio-block",
                                    data: [{checked: true, data: "Negative"},
                                        {checked: false, data: "Positive"}]
                                },
                            ]
                        }
                    },
                ]}),
            components.TreatmentPerformed({items: [{
                    type: "text",
                    data: 'Nebulizer treatment, Solumedrol 125mg IM.',
                    editable: true,
                    source: null
                }]}),
            components.AssessmentPlan([ {
                type: 'blockCheckboxCondition',
                extraType: 'assessmentContent',
                data: {
                    checkbox: [{checked: true, data: '', isHide: true}],
                    hiddenBlock: [
                        {
                            type: "text",
                            data: 'Follow up consultation to be arranged by',
                            editable: true,
                            source: null
                        },
                        {
                            type: "radio-block",
                            data: [{checked: true, data: "patient"},
                                {checked: false, data: "caretaker"},
                            ]
                        },
                        {
                            type: "text",
                            data: 'with',
                            editable: true,
                            source: null
                        },
                        {
                            type: "radio-block",
                            data: [{checked: true, data: "PCP"},
                                {checked: false, data: "Allergist"},
                                {checked: false, data: "Pulmonologist"},]
                        },
                        {
                            type: "text",
                            data: 'in',
                            editable: true,
                            source: null
                        },
                        {
                            type: 'input-static-text',
                            label: '',
                            data: [{data: '', mask: '999', min: 0, max: 365}],
                            deviders: []
                        },
                        {
                            type: "text",
                            data: 'days for further evaluation. Go to the nearest ED for increasing difficulty breathing or wheezing.',
                            editable: true,
                            source: null
                        }
                    ]
                }
            }]),
        ]
    }
}
