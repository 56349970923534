import React from "react";
import Select, { components } from "react-select";
import classNames from "classnames";
import "./style.scss";
import { Checkbox } from "../Checkbox/Checkbox";

export const CustomSelect = props => {
  const allProps = {
    value: props.value,
    className: classNames(
      "select-container",
      props.className,
      props.classNameOpenTop,
      { error: props.error }
    ),
    classNamePrefix: "select",
    onChange: (value, action) => {
      if (!!props.multi && !value) {
        value = [];
      }
      props.handlerSelect(value, action);
    },
    // menuIsOpen: true,
    options: props.options,
    // isDisabled: props.disabled,
    isDisabled: true,
    placeholder: props.placeholder || "Please select",
    isMulti: !!props.multi,
    hideSelectedOptions: false,
    closeMenuOnSelect: !props.multi,
    menuPlacement: props.menuPlacement || "bottom",
    components: {
      DropdownIndicator: ({ isFocused }) => {
        // return <div className="indicator" />;
        return <div/>;
      },
      IndicatorSeparator: () => null,
      ClearIndicator: () => null,

      Option: props => {
        if (props.isMulti) {
          return (
            <components.Option {...props}>
              <Checkbox
                label={props.data.label}
                isSelected={props.isSelected}
              />
            </components.Option>
          );
        } else {
          return <components.Option {...props} />;
        }
      },

      ...props.components
    }
  };

  return (
    <div className={classNames("select-wrapper", props.className)}>
      {props.label !== undefined && (
        <span explanation={props.explanation}>qwe</span>
      )}
      <Select {...allProps} />
    </div>
  );
};
