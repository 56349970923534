import React from 'react';
import {
  Separator,
  RequestedBy,
  Map,
  RequestInfo
} from '../../SideBarRequest/components';
import { PatientSidebar } from "../../Common/PatientSidebar";
import { GroupCard } from "../../Common/GroupCard/GroupCard";

export const TriggerOptionalServices = props => {
  return(
    <>
      <RequestInfo {...props} />
      <Separator />
      <RequestedBy {...props} />
      {props.data?.is_group ? (
        <GroupCard
          onClick={props.openPatientList}
          data={props.data}
        />
      ) : (
        <PatientSidebar
          {...props}
          detailsRequest={props.data}
          data={props.data?.patients[0]}
          hideSignature={props.data?.patients[0].status === 'main'}
          hideExamination
        />
      )}
      <Map {...props} comment={props.data?.location.comment} isShowSuite />
    </>
  );
};
