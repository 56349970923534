import React from "react";
import IconButton from '@material-ui/core/IconButton';
import {Close} from "@material-ui/icons";
import './style.scss'
import cn from 'classnames'
export const IconClose = ({className, onClick, children}) => {
    return <IconButton onClick={onClick} aria-label="delete" className={cn('icon-close', className)}>
        {children || <Close/>}
    </IconButton>
}
