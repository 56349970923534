import React, {useState} from 'react'
import {PatientCard} from "../../../Common/PatientCard";
import {ServicesGroups} from "../ServicesGroups";
import './style.scss'
import {ArrowRight} from "../../../Common/Icons";
import {colors} from "../../../../assets/colors/colors";
import cn from "classnames";
import {FileBlock} from "../../../Common/FileBlock/FileBlock";
import {IconButton} from "../../../Common/IconButton";
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import {ButtonV2} from "../../../Common/ButtonV2";
import {COLORS_BTN} from "../../../Common/ButtonV2/constants";

export const PersonalPatientCard = ({payAdditionalServices, userData, files}) => {
    const [filesShow, setFilesShow] = useState(false);

    return <div className='personal-patient-card'>
        <PatientCard user={userData} symptoms={userData.symptoms} hideArrow otherSymptoms={userData.comment} />
        {!!userData.additional_services?.length && <>
            <div className='separator'/>
            <ServicesGroups patientServices={userData.additional_services}
                            payAdditionalServices={payAdditionalServices}/>
        </>}
        {!!files?.length && <>
            <div className='separator'/>
            <ContainerDocuments filesRequest={files} filesShow={filesShow} setFilesShow={setFilesShow}/>
            </>}
    </div>
}

const ContainerDocuments = ({setFilesShow, filesShow, filesRequest, onlyActive = true}) => {
    return <div className={cn('details-request_body_files', {'details-request_body_files_only-active': onlyActive})}>
        <div className='details-request_body_files_header'>
            <span className='details-request_body_files_header_title'>Documents</span>
            {/*<IconButton onClick={() => setFilesShow(!filesShow)}>*/}
            {/*    {filesShow ? <ExpandLess/> : <ExpandMore/>}*/}
            {/*</IconButton>*/}
        </div>
        {(filesShow || true) && filesRequest?.map(i => <div className='details-request_body_files_item'>
            <FileBlock
                preview={i.file}
                size={i.size}
                file={{name: i.name, format: 'pdf'}}
            />
            <ButtonV2 color={COLORS_BTN.PRIMARY}><a className='details-request_body_files_item_download' href={i.download_link}>Download</a></ButtonV2>
        </div>)}
    </div>
}
