import {combineReducers} from "redux";
import error from "./error/error.reducer.js";
import request from "./createRequest/reducer.js";
import emr from "./emr/reducer";
import admin from "./admin/reducer";
import doctor from "./doctor/reducer";
import common from "./common/reducer";
import patient from "./patient/reducer";
import {constantsReducer as constants} from './constants/slice'
import {requestDetailsReducer} from './request/slice'
import {requestsReducer as requests} from "./requests/slice";
import {shiftsReducer as shifts} from "./shifts/slice";
import {doctorsReducer as doctors} from "./doctors/slice";
import {createRequestAdminDoctorsReducer as createRequestAdminDoctors} from "./createRequestAdminDoctors/slice";
import {commonReducer as stable} from "./stable/slice";
import {requestPatientsReducer as requestPatients} from "./requestPatients/slice";

const rootReducer = combineReducers({
    error,
    request,
    emr,
    admin,
    doctor,
    common,
    patient,

    requestDetailsReducer,
    constants,
    requestPatients,
    requests,
    shifts,
    doctors,
    stable,
    createRequestAdminDoctors
});

export default rootReducer;
