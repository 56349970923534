import React from "react";
import { Typography } from "../Typography/Typography";
import { UserData } from "../../../compoenentsAdditional/UserData";
import './style.scss';

export const RequestedBy = ({ data }) => {
  return (
    <>
      {data.client_user_info && (
        <div className="requested-by">
          <Typography text="Organizer:" className="request-by" variant="p" />
          <UserData
            user={data.client_user_info}
            showEmail
            isRequester
          />
        </div>
      )}
    </>
  );
};
