import {fetchV2} from "../../helpers/helpers";
import {setPatientsData} from "./slice";
import {converterDataField} from "../../utils/common";

export const getPatients = ({
                                         filters = {},
                                         data = {results: []},
                                     }) => dispatch => dispatch(fetchV2({
    url: `patients/list/`, body: {...filters, limit: 30}
})).then(resp => {
    let convertedResult = resp.data?.results?.map(item => converterDataField(item, true)) || [];
    const processData = {timestamp: resp.timestamp, ...resp.data, results: data.results.concat(convertedResult)}
    delete processData.data
    dispatch(setPatientsData(processData))
});
