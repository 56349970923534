import React from "react";
import {ReactComponent as Search} from "../../../dist/icons/search.svg";
import "./style.scss";

import { DebounceInput } from "react-debounce-input";
export const SearchInput = ({
  onChange,
  value,
  minLength,
  debounceTimeout,
  field
}) => {
  const handlerOnChange = e => {
    onChange(field, e.target.value);
  };
  return (
    <div className="container-search-input">
      <DebounceInput
        className="container-search-input_input"
        onChange={handlerOnChange}
        value={value}
        placeholder="Search"
        minLength={minLength || 3}
        debounceTimeout={debounceTimeout || 300}
      />
        <div className="container-search-input_img">
            <Search/>
        </div>
    </div>
  );
};
