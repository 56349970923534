import {colors} from "../assets/colors/colors";
import React from "react";
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
//if you change this const, please check flow with create request and redirect after creation to details request on patient flow
export const BASE_PART_URL = ''


export const mainHost = 'https://uplin.com'
export const homePageUrl = mainHost
export const termsAndConditionsUrl = mainHost + '/terms-of-use'
export const contactUsUrl = mainHost + '/contact-us'
export const uplinPrivacyNoticeUrl = mainHost + '/privacy-policy'
export const providerPrivacyPolicyUrl = mainHost + '/the-provider-privacy-notice'

export const routesCommon = {
  landing: homePageUrl,
  create_request: BASE_PART_URL + "/create-request",
  doctor: BASE_PART_URL + "/doctor",
  emr: BASE_PART_URL + "/emr",
  admin: BASE_PART_URL + "/admin",
  patient: BASE_PART_URL + "/patient",
  login_form: BASE_PART_URL + '/login'
};

export const rolesApp = {
  admin: "admin",
  doctor: "doctor",
  patient: "patient"
};
export const errorIsEmpty = "Please fill in all the required fields";

export const nameOfFields = {
  firstName: "First Name",
  lastName: "Last Name"
};
export const regexsWithMessage = {
  email: {
    regex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: "Incorrect email"
  },
  phone: {
    regex: /^[\+]?[0-9][(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,8}$/im,
    message: "Incorrect Phone Number"
  },
  birthday: {
    regex: /\d{2}\/\d{2}\/\d{4}/,
    message: "Incorrect Date of Birth"
  },
  zipcode: { regex: /\d{5}/, message: "Incorrect Zipcode" }
};

export const validationMessagesAge = {
  birthday18Year: "You must be at least 18 years old",
  birthday6Month: "Please fill in correct Date of Birth"
};

export const PERCENT_OF_LOADING = {
  state0: 0,
  state50: 50,
  state95: 95,
  state100: 100
};

export const configNotification = {
  containerId: "notification",
  autoClose: false,
  hideProgressBar: true,
  className: "custom-toast-notification"
};
export const configPusher = {
  containerId: "push",
  autoClose: false,
  hideProgressBar: true,
  className: "custom-toast-notification-pusher"
};

export const ROUTERS_DOCTORS = {
  table: "/table",
  schedule: "/schedule",
  personal_info: "/personal-info",
  dashboard: "/dashboard"
};
export const TEN_MB_CONVER_TO_BYTES = 10000000;

export const statesFiles = {
  pending: "pending",
  declined: "declined",
  accepted: "accepted"
};

export const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export const buttonsInnerText = {
  cancel_request: 'cancel request',
  activate_request: 'activate request',
  reactivate_request: 'reactivate request',
  back_to_calendar: 'back to calendar',
  send: "send",
  close: "close",
  details: "details",
  decline: "decline",
  add_shift: "add shift",
  cancel: "cancel",
  save: "save",
  assign: "Assign",
  reassign: "Re-assign",
  make_request: "Make a Request",
  confirm: "confirm",
  delete: "delete",
  edit: "edit",
  request_now: "Request Now",
  add_credit_card: "Add Credit Card",
  add_card: "Add Card",
  create_emr_notes: "Create EMR Notes",
  complete_request: 'Complete Request',
  recommended_services: 'Recommended Services',
  decline_all: 'Decline All',
  pay: 'Pay',
  covid_test: 'Covid-19 Test',
  upload_visit_file: 'Upload Group file',
  create: 'Create',
  send_to_pcp: 'Send to PCP',
  send_to_patient: 'Send to Patient'
};

export const placeholders = {
  location: "Location",
  speciality: "Specialty",
  physician: "Physician",
  patient: "Patient",
  doctor: "Doctor"
};

export const typesCard = [
  { name: "amex", placeholder: "0000 000000 00000", length: 15 },
  { name: "visa", placeholder: "0000 0000 0000 0000", length: 16 },
  { name: "mastercard", placeholder: "0000 0000 0000 0000", length: 16 }
];

export const statusAppointments = {
  assigned: "assigned",
  accepted: "accepted",
  rejected: "rejected"
};

export const visitNoteStatuses = {
  pending: {
    statusView: 'Pending',
    name: 'Incomplete',
    background: colors.apricot,
    color: colors.peach,
    availableChat: false,
    classNameRequest: 'pending-event',
    canCancelActivate: true,
    canDeletePatients: true,
  },
  assigned: {
    statusView: 'Assigned',
    name: 'Incomplete',
    background: colors.apricot,
    color: colors.peach,
    availableChat: true,
    classNameRequest: 'assigned-event',
    canCancelActivate: true,
    canDeletePatients: true,
  },
  "in progress": {
    statusView: 'In Progress',
    name: 'Incomplete',
    background: colors.apricot,
    color: colors.peach,
    availableChat: true,
    classNameRequest: 'in-progress-event',
    canCancelActivate: true,
    canDeletePatients: true,
  },
  active: {
    statusView: 'Active',
    name: 'Incomplete',
    background: colors.apricot,
    color: colors.peach,
    availableChat: true,
    classNameRequest: 'active-event',
    canCancelActivate: true,
    canDeletePatients: true,
  },
  "visit completed": {
    statusView: 'Visit Completed',
    name: 'Incomplete',
    background: colors.apricot,
    color: colors.peach,
    availableChat: true,
    classNameRequest: 'visit-completed-event',
    canCancelActivate: false,
    canDeletePatients: false,
  },
  "request completed": {
    statusView: 'Request Completed',
    name: 'Complete',
    background: colors.offGreen,
    color: colors.elfName,
    availableChat: true,
    classNameRequest: 'request-completed-event',
    canCancelActivate: false,
    canDeletePatients: false,
  },
  declined: {
    statusView: 'Canceled',
    name: 'Declined',
    background: colors.lightRed,
    color: colors.redAlarm,
    availableChat: false,
    classNameRequest: 'declined-event',
    canCancelActivate: true,
    canDeletePatients: false,
  },
  draft: {
    statusView: 'Draft',
    name: 'Unassigned',
    background: colors.lightRed,
    color: colors.redAlarm,
    availableChat: false,
    classNameRequest: 'draft-event',
    canCancelActivate: true,
    canDeletePatients: false,
  },
};

export const statusesRequest = ['pending', 'assigned', 'in progress', 'active', 'visit completed', 'request completed', 'declined' ,'draft'];


export const BTN_LABELS = {
  YES_MAKE_A_REQUEST: 'yes, make a request',
  SAVE_AS_DRAFT:'save as draft',
  LOG_OUT: 'log out',
  VIEW_PROFILE:'view profile',
  SAVE_AS_FINAL:'save as final',
  PREVIEW_DOCUMENT: 'preview document',
  RESEND: 'resend',
  MARK_ALL_AS_READ: 'mark all as read',
  VIEW_MESSAGE: 'view message',
  ADD_NEW: 'add new',
  VIEW_MORE: 'view more',
  CONFIRM: 'Confirm',
  ADD_PATIENT: 'Add Patient',
  SHOW_ALL: 'show all',
  CANCEL: 'cancel',
  APPLY: 'apply',
  DELETE: 'Delete',
  SAVE_CHANGES: 'save changes',
  covid_create: 'YES: The Rapid COVID Test Visit',
  pcr_covid_create: 'YES: The PCR Covid Test Visit',
  common_create: 'YES: The Comprehensive Visit',
  SHOW_FULL: 'show full request',
  SHOW_ALL_PATIENTS: 'Show all patients',
  CLOSE: 'close',
  SHOW_FULL_REQUEST: 'show full request',
  OPEN_EMR: 'open emr',
  CANCEL_REQUEST: 'Cancel request',
  REASSIGN: 'Reassign',
  START_EXAMINATION: 'Start examination',
  PROCEED_EXAMINATION: 'Proceed examination',
  EDIT: 'Edit',
  COMPLETE_BOOKING: 'Complete Booking',
  ACTIVATE: 'Activate',
  REACTIVATE: 'Reactivate',
  DELETE_SHIFT: 'Delete Shift',
  SAVE: 'save',
  OK: 'Ok',
  SEND: 'send',
  CLICK_HERE_FOR_UPDATE:'Click Here For Update'
}


export const gender = [{value: "male", label: 'Male'}, {value: "female", label: 'Female'}];
export const membership = [{value: true, label: 'Member'}, {value: false, label: 'Non-member'}];
export const emloyeeOptions = [{value: "employee", label: 'Employee'}, {value: "contractor", label: 'Contractor'}];


export const CALENDAR_VIEW_MODE = {
  LIST: 'list',
  CALENDAR: 'calendar'
}
export const CALENDAR_TIME_MODES_OPTIONS = [{label: 'Week View', value: 'week'}, {label: 'Day View', value: 'day'}]
export const CALENDAR_VIEW_MODE_OPTIONS = [{
  icon: FormatListBulletedIcon,
  value: CALENDAR_VIEW_MODE.LIST
}, {icon: CalendarTodayIcon, value: CALENDAR_VIEW_MODE.CALENDAR}]
