import React, {useEffect} from "react";
import {connect, useSelector, useDispatch} from "react-redux";
import "../styles/ProtectedApp.scss";
import {Route, Switch} from "react-router-dom";
import AdminPage from "./AdminPage";
import {getDoctorData, updateDoctor} from "../store/doctor/actions";
import {routesCommon} from "../constants";
import DoctorFlow from "./DoctorFlow";
import PatientFlow from "./PatientFlow";
import {getRequestTypes, getTokenTwilio} from "../store/common/actions";
import {requestsTypesSelector} from "../store/constants/selectors";
import {getRelations} from "../store/constants/actions";

const ProtectedApp = ({getTokenTwilio, dataNotification, path}) => {
    const dispatch = useDispatch();
    const typesRequests = useSelector(requestsTypesSelector);
    useEffect(() => {
        getTokenTwilio();
        dispatch(getRequestTypes(path.includes(routesCommon.admin)))
        dispatch(getRelations());
    }, []);

    if (!typesRequests.length) return <></>;

    return (
        <Switch>
            <div className="main-container-app">
                <Route
                    path={routesCommon.admin}
                    render={({location}) => {
                        return <AdminPage location={location}/>;
                    }}
                />
                <Route
                    path={routesCommon.patient}
                    render={({location}) => {
                        return (
                            <PatientFlow
                                location={location}
                                dataNotification={dataNotification}
                            />
                        );
                    }}
                />
                <Route
                    path={routesCommon.doctor}
                    render={({location}) => {
                        return (
                            <DoctorFlow
                                location={location}
                                dataNotification={dataNotification}
                            />
                        );
                    }}
                />
            </div>
        </Switch>
    );
};

const mapStateToProps = store => ({
    dataNotification: store.doctor.dataNotification,
    doctor: store.doctor.doctorData
});

export default connect(mapStateToProps, {
    getDoctorData,
    updateDoctor,
    getTokenTwilio
})(ProtectedApp);
