import * as components from "./components";
import {PatientData} from "./components/PatientData";
import {GroupBlockReviewOfSystem} from "./components/GroupBlockReviewOfSystems";

export const template = {
    name: 'Rash (allergic reaction)',
    body: {
        body: [
            {
                type: "label",
                data: "PRESENTING PROBLEM:",
                items: [
                    {
                        type: "text",
                        data: 'Allergic reaction',
                        editable: true,
                        source: null
                    },
                ]
            },
            {
                type: "label",
                data: "HISTORY OF THE PRESENT ILLNESS:",
                required: true,
                items: [
                    ...PatientData(),
                    {
                        type: "text",
                        data: 'who reports',
                        editable: true,
                        source: null
                    },
                    {
                        type: "input",
                        placeholder: "duration",
                        data: "",
                    },
                    {
                        type: "text",
                        data: 'swelling with',
                        editable: true,
                        source: null
                    },
                    {
                        type: "radio-block",
                        data: [{checked: false, data: "no"},
                            {checked: false, data: "mild"},]
                    },
                    {
                        type: "text",
                        data: 'pruritis of',
                        editable: true,
                        source: null
                    },
                    {
                        type: "input",
                        placeholder: "location",
                        data: "",
                    },
                    {
                        type: "text",
                        data: '. Reports exposure to',
                        editable: true,
                        source: null
                    },
                    {
                        type: "radio-block",
                        data: [{checked: false, data: "medication"},
                            {checked: false, data: "food"},
                            {checked: false, data: "allergen"}]
                    },
                    {
                        type: "text",
                        data: 'as potential source of allergic reaction. Otherwise:',
                        editable: true,
                        source: null
                    },
                    {
                        type: "checkbox",
                        data: [
                            {checked: false, data: "dyspnea"},
                            {checked: false, data: "throat swelling"},
                            {checked: false, data: "wheezing"}
                        ]
                    },
                    {
                        type: "text",
                        data: '. Has',
                        editable: true,
                        source: null
                    },
                    {
                        type: "radio-block",
                        data: [{checked: false, data: "no"},
                            {checked: false, data: "frequent"}]
                    },
                    {
                        type: "text",
                        data: 'history of prior allergic reactions.',
                        editable: true,
                        source: null
                    },
                ]
            },
            {
                type: "label",
                data: "REVIEW OF SYSTEMS:",
                items: [
                    GroupBlockReviewOfSystem({title: 'General:', radio: 'fever'}),
                    GroupBlockReviewOfSystem({title: 'HENT:', radio: 'congestion'}),
                    GroupBlockReviewOfSystem({title: 'Respiratory:', radio: 'cough'}),
                    GroupBlockReviewOfSystem({title: 'Cardiovascular:', radio: 'chest pain'}),
                    GroupBlockReviewOfSystem({title: 'GI:', radio: 'prior abdominal pain'}),
                    GroupBlockReviewOfSystem({title: 'GU:', radio: 'urinary complaints'}),
                    GroupBlockReviewOfSystem({title: 'Musculoskeletal:', radio: 'other aches or pains'}),
                    GroupBlockReviewOfSystem({title: 'Endocrine:', radio: 'generalized weakness'}),
                    GroupBlockReviewOfSystem({title: 'Neurological:', radio: 'localized weakness'}),
                    GroupBlockReviewOfSystem({title: 'Psychiatric:', radio: 'emotional stress'}),
                ]
            },
            components.PMH(),
            components.GYM(),
            components.FamSocHistory(),
            components.Medications(),
            components.Allergies(),
            components.PhysicianExamination({
                items: [
                    {
                        type: "label",
                        data: "Generalized Appearance:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Alert, cooperative, in',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-block",
                                data: [{checked: false, data: "no"},
                                    {checked: false, data: "minimal"},
                                    {checked: false, data: "moderate"},
                                    {checked: false, data: "severe"}
                                ]
                            },
                            {
                                type: "text",
                                data: 'distress.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    components.VitalSigns(),
                    {
                        type: "label",
                        data: "Skin:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Swelling with',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-block",
                                data: [{checked: false, data: "no"},
                                    {checked: false, data: "mild"}]
                            },
                            {
                                type: "text",
                                data: 'erythema of',
                                editable: true,
                                source: null
                            },
                            {
                                type: "input",
                                placeholder: "location",
                                data: "",
                            },
                            {
                                type: "text",
                                data: ',',
                                editable: true,
                                source: null
                            },

                            {
                                type: "radio-single",
                                data: [{checked: false, data: "suppuration"}]
                            },
                            {
                                type: "text",
                                data: ',',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "lymphangitis"}]
                            },
                            {
                                type: "text",
                                data: '. No \"target\" lesions. No other rash noted.',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    components.Eyes( ),
                    {
                        type: "label",
                        data: "ENMT:",
canDelete: true,
                        items: [

                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            ...components.RenderRadioSingleBLocks(
                                ['oral mucous membrane involvement',
                                    'edema of uvula'
                                ]),
                            {
                                type: "text",
                                data: '. Mucous membranes moist. Airway patent:',
                                editable: true,
                                source: null
                            },
                            ...components.RenderRadioSingleBLocks(
                                ['stridor', 'hoarseness']),
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "label",
                        data: "Neck:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            ...components.RenderRadioSingleBLocks( ['tenderness', 'stiffness', 'lymphadenopathy']),
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    components.ChestRespiratory(),
                ]
            }),
            components.AssessmentPlan([ {
                type: 'blockCheckboxCondition',
                extraType: 'assessmentContent',
                data: {
                    checkbox: [{checked: true, data: '', isHide: true}],
                    hiddenBlock: [
                        {
                            type: "text",
                            data: 'Follow up consultation with',
                            editable: true,
                            source: null
                        },
                        {
                            type: "radio-block",
                            data: [{checked: true, data: "PCP"},
                                {checked: false, data: "dermatologist"},
                            ]
                        },
                        {
                            type: "text",
                            data: 'as directed. Go to the nearest ED for any difficulty breathing or wheezing.',
                            editable: true,
                            source: null
                        },
                    ]
                }
            }]),
        ]
    }
}
