import React, {Component, useState} from 'react'
import _ from "lodash";
import './style.scss'
import {Element} from "../../chooserElements";
import {modesTemplate} from "../../../../../constants/Doctors";

export class BlockCheckboxCondition extends Component {

    constructor() {
        super()
        this.updateConfig = this.updateConfig.bind(this)
        this.updateCheckbox = this.updateCheckbox.bind(this)
    }

    updateConfig (value, index) {
        const isVitalSigns = this.props.data.extraType === 'vitalSigns'
        const newData = this.props.data.data.hiddenBlock.map((i, idx) => idx === index ? value : i)
        const allFill = isVitalSigns && newData.filter(i => !i.data.find(g => !g.data)).length === 7
        this.props.updateState({
            ...this.props.data,
            data: {
                ...this.props.data.data,
                hiddenBlock: this.props.data.data.hiddenBlock.map((i, idx) => idx === index ? value : i),
                checkbox: allFill ? [{data: "Refused by Patient", checked: false}] : this.props.data.data.checkbox
            }
        })
    }

    updateCheckbox (value) {
        this.props.updateState({...this.props.data, data: {...this.props.data.data, checkbox: value.data}})
    }

    render() {
        const dataBlock = this.props.data.data.hiddenBlock
        const isVitalSigns = this.props.data.extraType === 'vitalSigns'
        const isDisabled = !this.props.data.data.checkbox[0].checked && !this.props.data.data.notUseDisable
        const isCheckedCheckbox = this.props.data.data.checkbox[0].checked

        let error = this.props.error && (isVitalSigns ? !isCheckedCheckbox : isCheckedCheckbox)
        const disabledCheckbox = isVitalSigns && dataBlock.filter(i => !i.data.find(g => !g.data)).length === 7
        const getCheckbox = () => {
            return !this.props.data.data.checkbox[0].isHide ? <Elements type='checkbox'
                                                                        disabled={disabledCheckbox}
                                                                        mode={modesTemplate.render}
                                                                        data={{data: this.props.data.data.checkbox}}
                                                                        DbData={this.props.DbData}
                                                                        updateState={this.updateCheckbox}
            /> : <></>
        }
        return <div className='container-block-checkbox-condition'>
            {!isVitalSigns && getCheckbox()}
            {this.props.data.data.hiddenBlock.map((i, index) => <Elements type={i.type}
                                                                          data={i}
                                                                          mode={modesTemplate.render}
                                                                          disabled={isDisabled}
                                                                          DbData={this.props.DbData}
                                                                          updateState={(value) => this.updateConfig(value, index)}
                                                                          error={error}/>
            )}
            {isVitalSigns && getCheckbox()}
        </div>
    }
}

const Elements = React.memo(({...props}) => <Element {...props}/>,
    (prev, next) => _.isEqual(prev.data, next.data) && prev.error === next.error && prev.disabled === next.disabled)
