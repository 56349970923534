import React, { Component } from "react";
import "./style.scss";
import cn from "classnames";
import { Typography } from "../Typography/Typography";

export const Checkbox = props => {
  const action = e => {
    e.stopPropagation();
    if (e.target.type === "checkbox")
      props.onClick(props.field, !props.checked);
  };
  return (
    <label
      className={cn("container", props.className, {
        container_error: props.error,
        container_disabled: props.disabled
      })}
      onClick={action}
    >
      {props.label && <span className="container_label">{props.label}</span>}
      <input disabled={props.disabled} type="checkbox" checked={props.checked} />
      <Typography variant="p" className="checkmark" />
    </label>
  );
};
