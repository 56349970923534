import React from "react";

export const Eyes = (extra = []) => {
    return {
        type: "label",
        data: "Eyes:",
        canDelete: true,
        items: [
            {
                type: "input",
                placeholder: "",
                data: "",
            },
            {
                type: "radio-single",
                data: [{checked: false, data: "conjunctival pallor"}]
            },
            ...extra,
            {
                type: "text",
                data: '.',
                editable: true,
                source: null
            },
        ]
    }
}