import React, {useEffect, useState} from 'react'
import {PopupContainer} from "../../../../../Common/Popup/PopupContainer";
import './style.scss'
import {Typography} from "../../../../../Common/Typography/Typography";
import {Radiobutton} from "../../../../../Common/RadioButton/Radiobutton";
import {colors} from "../../../../../../assets/colors/colors";


export const EditorCovid = ({open, close, updateCovid, defaultValue}) => {
const [stateCovid, setStateCovid] = useState(null)
const [errorCovid, setErrorCovid] = useState(null)
    const closeCovid = () => {
        close()
    }

    useEffect(()=>{
        if(open && defaultValue){
            setStateCovid(defaultValue?.result)
        }else {
            setErrorCovid(null)
            setStateCovid(null)
        }
    },[open])

    const saveCovid = () => {
    if(stateCovid === null){
        setErrorCovid('Please fill in all the required fields')
        return
    }
        close()
        updateCovid({
            result: stateCovid
        })
    }

    return <PopupContainer
        className='covid-container-editor'
        textDefault="CANCEL"
        textPrimary="SAVE"
        open={open}
        close={closeCovid}
        actionDefaultButton={closeCovid}
        title="COVID-19 Test Results"
        actionPrimaryButton={saveCovid}
    >
        <Typography color={colors.black} className='covid_txt' text='Identify the test result as either positive or negative'
                    variant='label'/>

        <Radiobutton
            label='Positive'
            onClick={() => {
                setErrorCovid('')
                setStateCovid('Positive')
            }}
            className="covid_positive"
            checked={stateCovid === 'Positive'}
        />
        <Radiobutton
            label='Negative'
            error={errorCovid}
            onClick={() => {
                setErrorCovid('')
                setStateCovid('Negative')
            }}
            className="covid_negative"
            checked={stateCovid === 'Negative'}
        />
    </PopupContainer>
}
