import * as components from "./components";
import {PatientData} from "./components/PatientData";
import {GroupBlockReviewOfSystem} from "./components/GroupBlockReviewOfSystems";

export const template = {
    name: 'Facial trauma',
    body: {
        body: [
            {
                type: "label",
                data: "PRESENTING PROBLEM:",
                items: [
                    {
                        type: "text",
                        data: 'Facial trauma',
                        editable: true,
                        source: null
                    },
                ]
            },
            {
                type: "label",
                data: "HISTORY OF THE PRESENT ILLNESS:",
                required: true,
                items: [
                    ...PatientData(),
                    {
                        type: "text",
                        data: 'who reports injury to',
                        editable: true,
                        source: null
                    },
                    {
                        type: "input",
                        placeholder: "location",
                        data: "",
                    },
                    {
                        type: "text",
                        data: 'when',
                        editable: true,
                        source: null
                    },
                    {
                        type: "input",
                        placeholder: "mechanism/time",
                        data: "",
                    },
                    {
                        type: "text",
                        data: '. Otherwise:',
                        editable: true,
                        source: null
                    },
                    {
                        type: "checkbox",
                        data: [
                            {checked: false, data: "LOC"},
                            {checked: false, data: "headache"},
                            {checked: false, data: "diplopia"},
                            {checked: false, data: "vomiting"}
                        ]
                    },
                    {
                        type: "text",
                        data: '.',
                        editable: true,
                        source: null
                    },
                ]
            },
            {
                type: "label",
                data: "REVIEW OF SYSTEMS:",
                items: [
                    GroupBlockReviewOfSystem({title: 'General:', radio: 'fever'}),
                    GroupBlockReviewOfSystem({title: 'HENT:', radio: 'congestion'}),
                    GroupBlockReviewOfSystem({title: 'Respiratory:', radio: 'cough'}),
                    GroupBlockReviewOfSystem({title: 'Cardiovascular:', radio: 'chest pain'}),
                    GroupBlockReviewOfSystem({title: 'GI:', radio: 'abdominal pain'}),
                    GroupBlockReviewOfSystem({title: 'GU:', radio: 'urinary complaints'}),
                    GroupBlockReviewOfSystem({title: 'Musculoskeletal:', radio: 'other aches or pains'}),
                    GroupBlockReviewOfSystem({title: 'Endocrine:', radio: 'generalized weakness'}),
                    GroupBlockReviewOfSystem({title: 'Neurological:', radio: 'localized weakness'}),
                    GroupBlockReviewOfSystem({title: 'Psychiatric:', radio: 'emotional stress'}),
                ]
            },
            components.PMH(),
            components.GYM(),
            components.FamSocHistory(),
            components.Medications(),
            components.Allergies(),
            components.PhysicianExamination({
                items: [
                    components.GeneralizedAppearance(),
                    components.VitalSigns(),
                    {
                        type: "label",
                        data: "Skin:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Warm, dry;',
                                editable: true,
                                source: null
                            },
                            ...components.RenderRadioSingleBLocks(['cyanosis']),
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    {
                        type: "label",
                        data: "Head:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            ...components.RenderRadioSingleBLocks(['scalp swelling', 'scalp tenderness']),
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    {
                        type: "label",
                        data: "ENMT:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Mild swelling, tenderness and ecchymosis of',
                                editable: true,
                                source: null
                            },
                            {
                                type: "input",
                                placeholder: "location",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'with no palpable bony defect. PERRL. EOM\'s intact, including upward gaze;',
                                editable: true,
                                source: null
                            },
                            ...components.RenderRadioSingleBLocks(['hyphema', 'infra-orbital hypesthesia']),
                            {
                                type: "text",
                                data: '. Nasal bridge:',
                                editable: true,
                                source: null
                            },
                            ...components.RenderRadioSingleBLocks(['tenderness', 'epistaxis']),
                            {
                                type: "text",
                                data: '. Dentition intact. Airway patent:',
                                editable: true,
                                source: null
                            },
                            ...components.RenderRadioSingleBLocks(['stridor']),
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },

                        ]
                    },
                    {
                        type: "label",
                        data: "Neck:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            ...components.RenderRadioSingleBLocks(['tenderness', 'stiffness', 'crepitus']),
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    {
                        type: "label",
                        data: "Chest/Respiratory:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            ...components.RenderRadioSingleBLocks(['chest wall tenderness'
                            ]),
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    components.HeartCardiovascular(),
                    {
                        type: "label",
                        data: "Abdomen/GI:", canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Soft;',
                                editable: true,
                                source: null
                            },
                            ...components.RenderRadioSingleBLocks(
                                ['tenderness', 'guarding',
                                    'rebound', 'palpable masses', 'CVA tenderness']),
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    components.Extremities(),
                    {
                        type: "label",
                        data: "Neuro/Psych:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Mental status as above; CNs intact; strength and gait WNL.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                ]
            }),
            components.TestPerformed({items: [
                    {
                        type: "text",
                        data: 'X-ray:',
                        editable: true,
                        source: null
                    },
                    {
                        type: "checkbox",
                        data: [
                            {checked: false, data: "fracture"},
                            {checked: false, data: "dislocation"},
                        ]
                    },
                    {
                        type: "text",
                        data: '.',
                        editable: true,
                        source: null
                    },
                ]}),
            components.AssessmentPlan([ {
                type: 'blockCheckboxCondition',
                extraType: 'assessmentContent',
                data: {
                    checkbox: [{checked: true, data: '', isHide: true}],
                    hiddenBlock: [
                        {
                            type: "text",
                            data: 'Follow up consultation to be arranged by',
                            editable: true,
                            source: null
                        },
                        {
                            type: "radio-block",
                            data: [{checked: true, data: "patient"},
                                {checked: false, data: "caretaker"},
                                {checked: false, data: "me"}]
                        },
                        {
                            type: "text",
                            data: 'with',
                            editable: true,
                            source: null
                        },
                        {
                            type: "radio-block",
                            data: [{checked: true, data: "PCP"},
                                {checked: false, data: "facial plastic surgeon"}]
                        },
                        {
                            type: "text",
                            data: 'in',
                            editable: true,
                            source: null
                        },
                        {
                            type: 'input-static-text',
                            label: '',
                            data: [{data: '', mask: '999', min: 0, max: 365}],
                            deviders: []
                        },
                        {
                            type: "text",
                            data: 'days for further evaluation. Instructed to contact PCP or go to the nearest ED at any time for severe headache, vomiting or visual symptoms.',
                            editable: true,
                            source: null
                        },
                    ]
                }
            }]),
        ]
    }
}
