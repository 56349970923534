"use strict";
import React, { useEffect } from "react";
import { render } from "react-dom";
import App from "./containers/App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { Router, Route } from "react-router-dom";
import history from "./helpers/history";
import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./store/rootReducer";
import { toast } from "react-toastify";
import Analytics from "./utils/analytics/AnalyticsService";
import { EVENTS } from "./utils/analytics/Events";
import moment from "moment";
import _ from "lodash";

toast.configure({
  autoClose: false,
  hideProgressBar: true,
  className: "custom-toast-error",
  toastClassName: "custom-toast"
});

const store = createStore(rootReducer, applyMiddleware(thunk));

const RootComponent = ({ location }) => {
  useEffect(() => {
    window.moment = moment;
    window.lodash = _;
  }, []);
  useEffect(() => {
    Analytics.track(EVENTS.Page_Open);
  }, [location]);

  return <App location={location} />;
};

render(
  <Provider store={store}>
    <Router history={history}>
      <Route render={({ location }) => <RootComponent location={location} />} />
    </Router>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
