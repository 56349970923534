import React from "react";

export const TreatmentPerformed = ({items}) => {
    return {
        type: "label",
        data: "TREATMENT PERFORMED:",
        required: true,
        isDelete: false,
        canDeleteBlock: true,
        items: items
    }
}
