import React, {useState} from "react";
import "./style.scss";
import {SearchInput} from "../../Common/SearchInput/SearchInput";
import cn from "classnames";
import {FiltersPopup} from "../FiltersComponent/FiltersPopup";
import history from "../../../helpers/history";
import {Plus} from "../../Common/Icons";
import {RangeDatePicker} from "../../Common/RangeDatePicker/RangeDatePicker";
import {BTN_LABELS, routesCommon} from "../../../constants";
import {routersAdmin} from "../../../constants/Admin";
import {InfoCloud} from "../../Common/InfoCloud/InfoCloud";
import {ButtonV2} from "../../Common/ButtonV2";
import {COLORS_BTN, VARIATIONS_BTN} from "../../Common/ButtonV2/constants";

export const PanelFilters = ({
                                 onChangeDate,
                                 dateTo,
                                 dateFrom,
                                 showDatePickerRange,
                                 showButtonAdd,
                                 className,
                                 children,
                                 value,
                                 onChange,
                                 applyFilterAction,
                                 deleteAppliedFilters,
                                 filtersValue,
                                 clearDoctorData,
                                 noSearch,
                                 infoCloud,
                                 isStatic,
                                 customButtonAdd
                             }) => {
    const goToAddNew = () => {
        clearDoctorData();
        history.push(`${routesCommon.admin}${routersAdmin.add}`);
    };

    return (
        <div className={cn("container-panel-filter", className)}>
            <div className="container-panel-filter_left-part">
                {!noSearch && (
                    <div className="container-panel-filter_search">
                        <InfoCloud dangerouslySetInnerHTML={infoCloud}>
                            <SearchInput
                                minLength="1"
                                value={value}
                                onChange={onChange}
                                field="searchValue"
                            />
                        </InfoCloud>
                    </div>
                )}
                {children && <FiltersPopup
                    actionPrimaryButton={applyFilterAction}
                    deleteAppliedFilters={deleteAppliedFilters}
                    filtersValue={filtersValue}
                    isStatic={isStatic}
                >
                    {children}
                </FiltersPopup>}
            </div>
            {customButtonAdd}
            {showButtonAdd && (
                <ButtonV2 onClick={goToAddNew} uppercase variant={VARIATIONS_BTN.TEXT} color={COLORS_BTN.PRIMARY}>
                    <Plus className="container-panel-filter_button-add_icon"/>
                    {BTN_LABELS.ADD_NEW}
                </ButtonV2>
            )}

            {showDatePickerRange && (
                <div className="container-panel-filter__datepicker">
                    <RangeDatePicker
                        onChange={onChangeDate}
                        dateTo={dateTo}
                        dateFrom={dateFrom}
                    />
                </div>
            )}
        </div>
    );
};
