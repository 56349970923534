export const initialDatesRequests = {
    event_date_after: "1971-02-24T00:00",
    event_date_before: "2200-02-24T00:00",
    grouping: true
}

export const MODES_ADDITIONAL_LOADING = {
    TOP:'top',
    BOTTOM: 'bottom'
}
