import * as components from "./components";
import {PatientData} from "./components/PatientData";

export const template = {
    name: 'Blank Template',
    body: {
        body: [
            {
                type: "label",
                data: "PRESENTING PROBLEM:",
                items: [
                    {
                        type: "input",
                        placeholder: "presenting problem",
                        data: "",
                    }
                ]
            },
            {
                type: "label",
                data: "HISTORY OF THE PRESENT ILLNESS:",
                required: false,
                items: [
                    ...PatientData(),
                    {
                        type: "input",
                        placeholder: "Some text",
                        data: "",
                        isTextarea: true
                    }
                ]
            },
            {
                type: "label",
                data: "REVIEW OF SYSTEMS:",
                items: [
                    {
                        type: "input",
                        placeholder: "Some text",
                        data: "",
                        isTextarea: true
                    }
                ]
            },
            components.PMH(),
            components.FamSocHistory(),
            components.Medications(),
            components.Allergies(),
            components.PhysicianExamination({
                items: [
                    {
                        type: "label",
                        data: "",
                        items: [
                            {
                                type: "input",
                                placeholder: "Some text",
                                data: "",
                                isTextarea: true
                            }
                        ]}
                ]
            }),
            components.AssessmentPlan([ {
                type: 'blockCheckboxCondition',
                extraType: 'assessmentContent',
                data: {
                    checkbox: [{checked: true, data: '', isHide: true}],
                    hiddenBlock: [
                        {
                            type: "text",
                            data: 'Follow up consultation to be arranged by',
                            editable: true,
                            source: null
                        },
                        {
                            type: "radio-block",
                            data: [{checked: true, data: "patient"},
                                {checked: false, data: "caretaker"},
                                {checked: false, data: "me"}]
                        },
                        {
                            type: "text",
                            data: 'with',
                            editable: true,
                            source: null
                        },
                        {
                            type: "radio-block",
                            data: [{checked: true, data: "PCP"},
                                {checked: false, data: "specialist"}]
                        },
                        {
                            type: "text",
                            data: 'in',
                            editable: true,
                            source: null
                        },
                        {
                            type: 'input-static-text',
                            label: '',
                            data: [{data: '', mask: '999', min: 0, max: 365}],
                            deviders: []
                        },
                        {
                            type: "text",
                            data: 'days for further evaluation.',
                            editable: true,
                            source: null
                        },
                    ]
                }
            }]),
        ]
    }
}
