import React, {useState, createContext, useContext, useMemo, useEffect} from "react";
import './style.scss'
import {ProgressStepper} from "../../../components/ProgressStepper";
import {Typography} from "../../../components/Common/Typography/Typography";
import {ArrowLeft, Cross} from "../../../components/Common/Icons";
import IconButton from "@material-ui/core/IconButton";
import {COLORS_BTN, VARIATIONS_BTN} from "../../../components/Common/ButtonV2/constants";
import {ButtonV2} from "../../../components/Common/ButtonV2";
import {PatientForm} from "../../../components/AdminPage/CreateAppointment/PatientForm";
import {
    PatientsList,
    PatientsStepContext,
    usePatientsStepState
} from "../../../components/AdminPage/CreateAppointment/PatientList";
import {
    DoctorsList,
    DoctorsStepContext,
    useDoctorsStepState
} from "../../../components/AdminPage/CreateAppointment/DoctorsList";
import {
    RequestDetailsContext,
    RequestDetailsStep,
    useRequestDetailsStepState
} from "../../../components/AdminPage/CreateAppointment/RequestDetailsStep";
import {
    PaymentStep,
    PaymentStepContext,
    usePaymentStepState
} from "../../../components/AdminPage/CreateAppointment/PaymentStep";
import {SummaryStep} from "../../../components/AdminPage/CreateAppointment/SummaryStep";
import {useDispatch} from "react-redux";
import {clearPatientsData} from "../../../store/requestPatients/slice";
import {clearCreateRequestAdminDoctorsData} from "../../../store/createRequestAdminDoctors/slice";

const configuration = {
    patients: {
        id: 'patients', stepNumber: 1, nextStep: 'edit_patient',
        header: 'Select A Patient', title: 'Choose Who The Visit Is For', subtitle: null
    },

    create_patient: {
        id: 'create_patient', nextStep: 'doctors', header: 'Create A New Patient',
    }, edit_patient: {
        id: 'edit_patient',
        stepNumber: 2,
        nextStep: 'doctors',

        header: 'Edit Patient',
        title: 'Edit Patient Data If Needed',
        subtitle: 'Please, Check Patient Data And Update If Needed'
    },

    doctors: {
        id: 'doctors', stepNumber: 3, nextStep: 'date_time',

        header: 'Doctor', title: 'Please, Select A Doctor',
    }, date_time: {
        id: 'date_time', stepNumber: 4, nextStep: 'payment',

        header: 'Service Requested', title: 'Please Select A Date And Time To Continue',
    }, payment: {
        id: 'payment', stepNumber: 5, nextStep: 'summary',

        header: 'Payment details', title: 'Visit Details information',
    }, summary: {
        id: 'summary', stepNumber: 6,
        header: 'Requesting The Doctor', title: 'Review Visit Information Before Proceeding',
    },
}

const CreateRequestContext = createContext(null);


export const useCreateRequestContext = () => useContext(CreateRequestContext);
export const CreateAppointment = ({
                                      onClose
                                  }) => {
    const [stepperHistory, setStepperHistory] = useState([configuration.patients]);

    const dispatch = useDispatch();

    const activeStep = stepperHistory[stepperHistory.length - 1];
    useEffect(() =>{
        return () => {
            dispatch(clearPatientsData());
            dispatch(clearCreateRequestAdminDoctorsData());
        }
    },[])
    const onBack = () => {
        let copy = [...stepperHistory];
        copy.pop();
        setStepperHistory(copy);
    }
    const onNext = () => setStepperHistory([...stepperHistory, configuration[activeStep.nextStep]])
    const onEditPatientStepPush = () => setStepperHistory((prev) => [...prev, configuration.edit_patient]);
    const onCreatePatientStepPush = () => setStepperHistory((prev) => [...prev, configuration.create_patient]);

    const createRequestValues = useMemo(() => ({
        activeStep: activeStep,
        stepperHistory: stepperHistory,
        onBack: onBack,
        onNext: onNext,
        setStepperHistory: setStepperHistory,
        configuration: configuration,
        onEditPatientStepPush: onEditPatientStepPush,
        onCreatePatientStepPush: onCreatePatientStepPush,
        onClose: onClose,
    }), [stepperHistory.length])

    const patientsStepState = usePatientsStepState();
    const paymentStepState = usePaymentStepState();
    const requestDetailsStepState = useRequestDetailsStepState(patientsStepState);
    const doctorsStepState = useDoctorsStepState();

    return <CreateRequestContext.Provider value={createRequestValues}>
        <RequestDetailsContext.Provider value={requestDetailsStepState}>
            <PaymentStepContext.Provider value={paymentStepState}>
                <DoctorsStepContext.Provider value={doctorsStepState}>
                    <PatientsStepContext.Provider value={patientsStepState}>
                        <div className='create-appointment'>
                            <Header title={activeStep.header} onClose={onClose}/>
                            {activeStep.stepNumber &&
                                <ProgressStepper className='create-appointment_progress_stepper'
                                                 activeStep={activeStep.stepNumber}
                                                 stepCount={6}
                                                 title={activeStep.title}
                                                 subtitle={activeStep.subtitle}
                                />}
                            {activeStep.id === configuration.patients.id && <PatientsList/>}
                            {activeStep.id === configuration.doctors.id && <DoctorsList/>}
                            {[configuration.create_patient.id, configuration.edit_patient.id].includes(activeStep.id) &&
                                <PatientForm/>}
                            {activeStep.id === configuration.date_time.id && <RequestDetailsStep/>}
                            {activeStep.id === configuration.payment.id && <PaymentStep/>}
                            {activeStep.id === configuration.summary.id && <SummaryStep/>}
                        </div>
                    </PatientsStepContext.Provider>
                </DoctorsStepContext.Provider>
            </PaymentStepContext.Provider>
        </RequestDetailsContext.Provider>
    </CreateRequestContext.Provider>
}


const Header = ({
                    title, onClose
                }) => {
    const {onBack, stepperHistory} = useCreateRequestContext();
    const isBackButton = stepperHistory.length > 1;
    return <div className='create-appointment_header'>
        {isBackButton ? <IconButton onClick={() => {
        }} className={''}>
            <ArrowLeft
                className=''
                onClick={onBack}
            />
        </IconButton> : <div/>}
        <Typography
            text={title}
            variant="h1"
        />
        <IconButton onClick={onClose} className={''}>
            <Cross/>
        </IconButton>
    </div>
}

export const CreateRequestFooter = ({
                                        onNext, disabledNextButton
                                    }) => {
    const {activeStep, onBack, stepperHistory} = useCreateRequestContext();
    let isBackButton = stepperHistory.length > 1;
    let isNextButton = activeStep.nextStep;
    return <div className='create-appointment_footer'>
        {isBackButton && <ButtonV2
            uppercase
            onClick={onBack}
            variant={VARIATIONS_BTN.CONTAINED}
            color={COLORS_BTN.DEFAULT}
        >Back</ButtonV2>}
        <ButtonV2
            className='create-appointment_footer_next-button'
            uppercase
            onClick={onNext}
            disabled={disabledNextButton}
            variant={VARIATIONS_BTN.CONTAINED}
            color={COLORS_BTN.PRIMARY}
        >{isNextButton ? 'Next' : 'Complete Booking'}</ButtonV2>
    </div>
}

