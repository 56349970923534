import React from "react";
import { ListSymptoms } from "./StepSymptoms/ListSymptoms";
import { TimeDefinig } from "./StepTimeDefining/TimeDefining";
import { MobileCode } from "./StepMobileCode/MobileCode";
import InfoRewiew from "./StepInfoRewiew/InfoRewiew";
import { steps } from "../../constants/constants";
import { Route } from "react-router-dom";
import { routesRequest } from "../../constants";
import { ListPatients } from "./StepRequestDataInfo/ListPatients";
import { PatientsList } from "./StepSymptoms/PatientsList";
import { Buttons } from "./Buttons";
import { Address } from "./StepRequestDataInfo/Address";
import { UserInfo } from "./StepRequestDataInfo/UserInfo";
import './StepRequestDataInfo/style.scss';

const initialStateUser = {
  lastName: "",
  firstName: "",
  birthday: "",
  phone: "",
  email: "",
  sex: ""
};

const initialStatePatient = {
  lastName: "",
  firstName: "",
  birthday: "",
  phone: "",
  email: "",
  sex: "",
  relationship: "",
  agreement: false
};

export const RightPanel = props => {
  const patientWithOthers = props.selectedPatients.filter(i => !!i.selected?.find(j => !j.uuid));
  let disabledPrimary = props.selectedPatients.filter(item => item.selected?.length).length !== props.selectedPatients.length;
  for(let patient of patientWithOthers) {
    if(!patient.otherSymptoms) {
      disabledPrimary = true;
    }
  }

  return (
    <div className="app-container-request_wrapper_right-panel">
      <div className="app-container-request_wrapper_right-panel_main-actions">
        {props.activeStep === steps.PickingPatients && <ListPatients {...props} />}
        {props.activeStep === steps.Symptoms && (
          <>
            {!props.needRebuild && <ListSymptoms {...props} />}
            {props.needRebuild && <PatientsList {...props} />}
            <Buttons
              DefaultClick={props.clickSymptomsDefault}
              PrimaryClick={props.actionNextStep}
              DefaultDisabled={false}
              PrimaryDisabled={disabledPrimary}
            />
          </>
        )}
        {props.activeStep === steps.Time && <TimeDefinig {...props} />}
        {props.activeStep === steps.FillInfo && (
          <UserInfo
            isUser
            initialState={initialStateUser}
            {...props}
          />
        )}
        {props.activeStep === steps.AddNew && (
          <UserInfo
            isPatient
            initialState={initialStatePatient}
            {...props}
          />
        )}
        {props.activeStep === steps.Address && <Address {...props} />}
        {(props.activeStep === steps.Review || props.activeStep === steps.Cards) && <InfoRewiew {...props} />}
        <Route
          path={`${props.path}${routesRequest.login}`}
          render={() => <MobileCode {...props} />}
        />
      </div>
    </div>
  );
};
