import {createSlice} from '@reduxjs/toolkit'
import {requestsTypesFields} from "./constants";

const initialState = {
    requestsTypes: []
}

const constantsSlice = createSlice({
    name: 'constantsSlice',
    initialState,
    reducers: {
        setRequestsTypes(state, {payload: {data, isAdmin}}) {
            state.requestsTypes = data.map(i => {
                let item = ({...i, ...requestsTypesFields[i.name], name: i.name.replace(/_/g, '-')});
                return {
                    ...item,
                    viewLabel: item.viewLabel(isAdmin)
                }
            })
        },
        setRelations: (state, {payload}) => {
            state.relations = payload;
        },
    }
})

export const {setRequestsTypes, setRelations} = constantsSlice.actions
export const constantsReducer = constantsSlice.reducer
