import * as components from "./components";
import { PatientData} from "./components/PatientData";

export const template = {
    name: 'Herpes Zoster',
    body: {
        body: [
            {
                type: "label",
                data: "PRESENTING PROBLEM:",
                items: [
                    {
                        type: "text",
                        data: 'Pain and rash',
                        editable: true,
                        source: null
                    },
                ]
            },
            {
                type: "label",
                data: "HISTORY OF THE PRESENT ILLNESS:",
                required: true,
                items: [
                    ...PatientData(),
                    {
                        type: "text",
                        data: 'who reports',
                        editable: true,
                        source: null
                    },
                    {
                        type: "input",
                        placeholder: "duration",
                        data: "",
                    },
                    {
                        type: "text",
                        data: 'history of local pain, now accompanied by rash in area of discomfort. Otherwise:',
                        editable: true,
                        source: null
                    },
                    {
                        type: "checkbox",
                        data: [
                            {checked: false, data: "dyspnea"},
                            {checked: false, data: "headache"},
                            {checked: false, data: "fever"},
                        ]
                    },
                    {
                        type: "text",
                        data: '.',
                        editable: true,
                        source: null
                    },
                ]
            },
            components.ReviewOfSystem(),
            components.PMH(),
            components.GYM(),
            components.FamSocHistory(),
            components.Medications(),
            components.Allergies(),
            components.PhysicianExamination({
                items: [
                    components.GeneralizedAppearance(),
                    components.VitalSigns(),
                    {
                        type: "label",
                        data: "Skin:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            ...components.RenderRadioSingleBLocks(
                                ['herpetiform rash',
                                    'vesicle formation with surrounding erythema',
                                    'dermatomal distribution']),
                            {
                                type: "text",
                                data: 'on the',
                                editable: true,
                                source: null
                            },
                            {
                                type: "input",
                                placeholder: "location",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'area.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    {
                        type: "label",
                        data: "Head:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            ...components.RenderRadioSingleBLocks(['scalp swelling', 'tenderness']),
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    components.Eyes(components.RenderRadioSingleBLocks(['scleral icterus'], true)),
                    {
                        type: "label",
                        data: "ENMT:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Pharynx:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "erythema"}]
                            },
                            {
                                type: "text",
                                data: '; airway patent:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "stridor"}]
                            },
                            {
                                type: "text",
                                data: '; mucous membranes moist.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    {
                        type: "label",
                        data: "Neck:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            ...components.RenderRadioSingleBLocks(['tenderness', 'stiffness', 'lymphadenopathy', 'thyromegaly']),
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    components.ChestRespiratory(),
                    components.HeartCardiovascular(),
                    {
                        type: "label",
                        data: "Abdomen/GI:", canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Soft;',
                                editable: true,
                                source: null
                            },
                            ...components.RenderRadioSingleBLocks(
                                ['tenderness', 'guarding',
                                    'rebound', 'palpable masses', 'CVA tenderness']),
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    components.Extremities(),
                    {
                        type: "label",
                        data: "Neuro/Psych:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Mental status as above. Cranial nerves grossly intact; strength symmetric.',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                ]
            }),
            components.TreatmentPerformed({items: [{
                    type: "text",
                    data: 'Toradol 60mg IM',
                    editable: true,
                    source: null
                }]}),
            components.AssessmentPlan([ {
                type: 'blockCheckboxCondition',
                extraType: 'assessmentContent',
                data: {
                    checkbox: [{checked: true, data: '', isHide: true}],
                    hiddenBlock: [
                        {
                            type: "text",
                            data: 'Follow up consultation with',
                            editable: true,
                            source: null
                        },
                        {
                            type: "radio-block",
                            data: [{checked: true, data: "PCP"},
                                {checked: false, data: "dermatologist"}]
                        },
                        {
                            type: "text",
                            data: 'in 1-2 days. Go to the nearest ED if patient has difficulty breathing or new symptoms.',
                            editable: true,
                            source: null
                        },
                    ]
                }
            }]),
        ]
    }
}
