import React from "react";
import './style.scss';
import { Typography } from "../Typography/Typography";
import { colors } from "../../../assets/colors/colors";
import { ButtonV2 } from "../ButtonV2";
import { COLORS_BTN } from "../ButtonV2/constants";
import { ArrowForward } from "@material-ui/icons";
import { BTN_LABELS, IN_PROGRESS, VISIT_COMPLETED, REQUEST_COMPLETED } from "../../../constants";
import { COMPLETED } from "../../../store/request/constants";

export const GroupCard = ({
  onClick,
  data
}) => {
  const amount = data.patients_number;
  const showExaminedAmount = data.status?.toLowerCase() === IN_PROGRESS ||
    data.status?.toLowerCase() === VISIT_COMPLETED ||
    data.status?.toLowerCase() === REQUEST_COMPLETED;
  const examined = data.patients?.filter(i => i.examination_status === COMPLETED).length;
  const text = showExaminedAmount ? `${examined} / ${amount} examined` : `${amount} patients`;
  const areAllExamined = examined === amount;

  return(
    <div
      className="group-card"
      onClick={onClick}
    >
      <Typography
        color={colors.greyMiddle}
        text="Patients"
        className="group-card__title"
      />
      <Typography
        color={areAllExamined ? colors.elfName : colors.black}
        text={text}
        className="group-card__patients-amount"
      />
      <ButtonV2
        className='group-card__button'
        color={COLORS_BTN.PRIMARY}
        endIcon={<ArrowForward />}
        onClick={onClick}
        uppercase
      >
        {BTN_LABELS.SHOW_ALL_PATIENTS}
      </ButtonV2>
    </div>
  );
};
