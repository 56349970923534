import {template as Template1} from './Abdominal pain AP F'
import {template as Template2} from './Abdominal pain F older'
import {template as Template3} from './Abdominal pain F young'
import {template as Template4} from './Abdominal pain GB'
import {template as Template5} from './Abscess (I and D)'
import {template as Template6} from './Allergic reaction'
import {template as Template7} from './Asthma (adult)'
import {template as Template8} from './Asthma (child)'
import {template as Template9} from './Back pain (strain)'
import {template as Template10} from './Bee Sting'
import {template as Template11} from './Bronchitis'
import {template as Template12} from './Burn mild'
import {template as Template13} from './Cellulitis'
import {template as Template14} from './Clogged ear (cerumen impaction)'
import {template as Template15} from './Conjunctivitis'
import {template as Template16} from './Corneal abrasion'
import {template as Template17} from './CP admit R-O MI'
import {template as Template18} from './CP injury to chest'
import {template as Template19} from './Dog bite'
import {template as Template20} from './DVT'
import {template as Template21} from './Epistaxis without packing'
import {template as Template22} from './Facial trauma'
import {template as Template23} from './Finger injury'
import {template as Template24} from './Foot injury'
import {template as Template25} from './Gastroenteritis (adult)'
import {template as Template26} from './Gastroenteritis (child)'
import {template as Template27} from './General'
import {template as Template28} from './Hand injury'
import {template as Template29} from './Headache (Migraine)'
import {template as Template30} from './Hemorrhoids'
import {template as Template31} from './Herpes Zoster'
import {template as Template32} from './Impetigo'
import {template as Template33} from './Influenza (Flu)'
import {template as Template34} from './Insect sting (bee sting)'
import {template as Template35} from './Kidney stones'
import {template as Template36} from './Knee injury'
import {template as Template37} from './Neck pain (cervical pain)'
import {template as Template38} from './Otitis externa'
import {template as Template39} from './Otitis Media'
import {template as Template40} from './Pharyngitis (adult)'
import {template as Template41} from './Pharyngitis (child)'
import {template as Template42} from './Physical, General'
import {template as Template43} from './Rash (allergic reaction)'
import {template as Template44} from './Rectal bleeding'
import {template as Template45} from './Sciatica'
import {template as Template46} from './Seasonal allergies'
import {template as Template47} from './Shoulder pain (bursitis)'
import {template as Template48} from './Sinusitis'
import {template as Template49} from './SOB (adult)'
import {template as Template50} from './Sty'
import {template as Template51} from './Sunburn'
import {template as Template52} from './Tick bite'
import {template as Template53} from './Tonsilitis (adult)'
import {template as Template54} from './URI (adult)'
import {template as Template55} from './URI (child)'
import {template as Template56} from './UTI (female)'
import {template as Template57} from './Covid'
import {template as Template58} from './Blank Template'
export const templates = [
    Template1,
    Template2,
    Template3,
    Template4,
    Template5,
    Template6,
    Template7,
    Template8,
    Template9,
    Template10,
    Template11,
    Template12,
    Template13,
    Template14,
    Template15,
    Template16,
    Template17,
    Template18,
    Template19,
    Template20,
    Template21,
    Template22,
    Template23,
    Template24,
    Template25,
    Template26,
    Template27,
    Template28,
    Template29,
    Template30,
    Template31,
    Template32,
    Template33,
    Template34,
    Template35,
    Template36,
    Template37,
    Template38,
    Template39,
    Template40,
    Template41,
    Template42,
    Template43,
    Template44,
    Template45,
    Template46,
    Template47,
    Template48,
    Template49,
    Template50,
    Template51,
    Template52,
    Template53,
    Template54,
    Template55,
    Template56,
    Template57,
    Template58
]
