import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { configPusher, rolesApp, routesCommon } from "../../constants";
import { connect } from "react-redux";
import "../../styles/PatientPage.scss";
import {
  getPatientData,
  getCardsPatient,
  addNewCard,
  removeCard,
  approveDeclineServices,
  changeDefaultCard,
  clearDataPatient,
} from "../../store/patient/actions";
import {PatientListRequests} from "../../components/PatientFlow/Schedule";
import CustomHeader from "../../components/AdminEmr/Header/CustomHeader";
import { routersPatient } from "../../constants/Patient";
import PersonalInfoPatient from "../../components/PatientFlow/PersonalInfo";
import history from "../../helpers/history";
import { subscribeToEvents, unsubscribePusher } from "../../pushers";
import { toast } from "react-toastify";
import { PusherNotification } from "../../components/Pusher";
import { getNotifications } from "../../store/common/actions";
import {validationFieldLength, validationIsEmpty} from "../../utils/validation";

const optionsTabs = [
  {
    value: "schedule ",
    path: `${routesCommon.patient}${routersPatient.schedule}`,
    nestedUrls: [`${routesCommon.patient}${routersPatient.personal_info}`]
  }
];

class PatientFlow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectToProfile: false
    };
  }

  componentDidMount() {
    const layout = document.querySelector('.main-container-app')
    if(layout) layout.style.minWidth = 'initial'
    this.props.getPatientData().then(resp => {
      if(resp.uuid){
        this.props.getNotifications()
        this.props.subscribeToEvents(rolesApp.patient, resp.uuid);
        window.localStorage.getItem('hide_note_patient') !== resp.uuid && toast(
            <PusherNotification
                classNameTitle='patient-flow-container_note'
                body={`We Hope you’re well.`}
                title={resp.first_name ? `Welcome ${resp.first_name}.` : ''}
            />,
            configPusher
        );
        window.localStorage.setItem('hide_note_patient', resp.uuid)
      }
    });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const refreshUrl = nextProps.location?.state?.refreshUrl;
    if (refreshUrl) {
      this.props.getPatientData().then(resp => {
        resp.uuid && unsubscribePusher(resp.uuid);
        resp.uuid && this.props.subscribeToEvents(rolesApp.patient, resp.uuid);
      });
      history.push(`${routesCommon.patient}${refreshUrl}`);
    }
    if(nextProps.patient?.uuid) {
      const error = validationFieldLength(this.props.patient);
      if(!!error) {
        this.setState({ redirectToProfile: true });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.redirectToProfile && !prevState.redirectToProfile) {
      history.push(`${routesCommon.patient}${routersPatient.personal_info}`);
    }
  }

  componentWillUnmount() {
    const layout = document.querySelector('.main-container-app')
    if(layout) layout.style.minWidth = '1200px'
    unsubscribePusher(this.props.patient?.uuid);
    this.props.clearDataPatient()
  }

  render() {
    const user = { user: this.props.patient };
    if(!this.props.patient?.uuid) {
      return <></>;
    }
    const isRefresh = this.props.location?.state?.refreshUrl;
    return (
      <div className="patient-flow-container">
        <CustomHeader
            location={this.props.location}
          mainPath={routesCommon.patient}
          tabs={optionsTabs}
          className="main-container-app_header"
          user={user}
          isPatient='patient'
        />
        <Switch>
          <Route
            path={[`${routesCommon.patient}${routersPatient.schedule}`, `${routesCommon.patient}${routersPatient.details}/:id`]}
            render={({ location }) => (
              <PatientListRequests
                  filesRequest={this.props.filesRequest}
                location={location}
                  notifications={this.props.notifications}
                changeDefaultCard={this.props.changeDefaultCard}
                user={this.props.patient}
                data={this.props.dataNotification}
                patient={this.props.patient}
                time={this.props.time}
                cards={this.props.cards}
                addNewCard={this.props.addNewCard}
                getCardsPatient={this.props.getCardsPatient}
                approveDeclineServices={this.props.approveDeclineServices}
              />
            )}
          />
          <Route
            path={`${routesCommon.patient}${routersPatient.personal_info}`}
            render={() => (
              <PersonalInfoPatient
                removeCard={this.props.removeCard}
                cards={this.props.cards}
                getCardsPatient={this.props.getCardsPatient}
                addNewCard={this.props.addNewCard}
                patient={this.props.patient}
                updateDataUser={this.props.getPatientData}
                changeDefaultCard={this.props.changeDefaultCard}
              />
            )}
          />
          {!isRefresh && (
            <Redirect
              to={`${routesCommon.patient}${routersPatient.schedule}`}
            />
          )}
        </Switch>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    notifications: store.common.notifications,
    cards: store.patient.cards,
    patient: store.patient.patient,
    time: store.doctor.initialTime,
    filesRequest: store.patient.filesRequest
  };
}

export default connect(mapStateToProps, {
  getPatientData,
  getCardsPatient,
  addNewCard,
  subscribeToEvents,
  removeCard,
  approveDeclineServices,
  changeDefaultCard,
  clearDataPatient,
  getNotifications
})(PatientFlow);
