import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import style from "./GoogleMapStyles";
import cn from "classnames";
import { GoogleMarker } from "../../Common/GoogleMarker/GoogleMarker";
import { colors } from "../../../assets/colors/colors";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";

export const PatientGoogleMap = ({ data, className }) => {
  const [address, setAddress] = useState({ address: "", latLng: {} });

  useEffect(() => {
    geocodeByAddress(data.location.address_line)
      .then(results => getLatLng(results[0]))
      .then(latLng => setAddress({ address: data, latLng: latLng }));
  }, [data.uuid]);

  const defaultMapOptions = {
    fullscreenControl: false,
    styles: style
  };

  const zoom = 13;
  return (
    <div
      className={cn("map", className)}
      style={{ height: "173px", width: "376px" }}
    >
      {address.latLng.lat && (
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyDdm7tYTjT39nEbzBKakm7PZGy8xYGtN_s"
          }}
          center={address.latLng}
          defaultZoom={zoom}
          yesIWantToUseGoogleMapApiInternals
          onCenterChanged={address.latLng}
          options={defaultMapOptions}
        >
          <GoogleMarker
            lat={address.latLng.lat}
            lng={address.latLng.lng}
            name="My Marker"
            color={colors.navy}
          />
        </GoogleMapReact>
      )}
    </div>
  );
};
