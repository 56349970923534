import React, { Component } from "react";
import { connect } from "react-redux";

import { Typography } from "../../Common/Typography/Typography";
import { Button } from "../../Common/Button/Button";
import ButtonGroup from "../../Common/ButtonGroup/ButtonGroup";
import { ADMIN } from "../../../constants/constants";
import { Archive } from "../Archive";

import EmploymentApplication from "../../DoctorFlow/EmploymentApplication/EmploymentApplication";
import PersonalInfoForm from "../../DoctorFlow/PersonalInfoForm";
import {
  getLocations,
  getSpeciality,
  getArchivedDocuments,
  unArchiveDocument
} from "../../../store/actions";

import "./style.scss";
import {
  BUTTONSADDNEWDOCTOR,
  BUTTONSEDITDOCTOR, routersAdmin
} from "../../../constants/Admin";
import { lengthObj } from "../../../utils/common";
import { clearDoctorData } from "../../../store/admin/actions";
import history from "../../../helpers/history";
import {routesCommon} from "../../../constants";
import {routersDoctor} from "../../../constants/Doctors";

class AddEditDoctor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      statePage: BUTTONSEDITDOCTOR[0],
      data: {}
    };

    this.funcSave = () => {};
    this.func = () => {};
    this.funcCancel = () => {};
  }

  componentDidMount = () => {
    if (this.props.getDataDoctor) {
      this.props.getDataDoctor(this.props.id, ADMIN)
      this.props.saveSignature(null, this.props.id)
    }
    this.props.getSpeciality();
    this.props.getLocations();
  };

  componentWillUnmount() {
    this.props.clearDoctorData();
  }

  changePage = title => {
    if (title !== this.state.statePage) {
      let switchTab = true;
      if (title === BUTTONSEDITDOCTOR[1] || title === BUTTONSEDITDOCTOR[2])
        switchTab = this.funcSave(true);
      if (switchTab)
        this.setState({
          statePage: title
        });
    }
  };

  unArchiveFiles = (uuidFile, bool) =>
    this.props.unArchiveDocument(this.props.id, uuidFile, { force: bool });

  saveElements = () => this.func();
  actionCancel = () => this.funcCancel();
  setPersonalInfo = data => this.setState({ data: data });
  updateStatusPersonalInfo = status =>
    this.setState({ data: { ...this.state.data, status: status } });
  render() {
    const buttonsTab = [
      ...(!!lengthObj(this.props.doctorData)
        ? BUTTONSEDITDOCTOR
        : BUTTONSADDNEWDOCTOR)
    ];
    const doctorData = lengthObj(this.state.data)
      ? this.state.data
      : this.props.doctorData;

    return (
      <>
        <div className="container-add-new-doctor">
          <ButtonGroup
            page={this.state.statePage}
            buttonsTab={buttonsTab}
            changePage={this.changePage}
            className="container-add-new-doctor__btn"
          />
          <Typography
            capitalize
            text={`${this.props.id ? "Edit" : "Add A New"} Doctor`}
            variant="h3"
            className="doctor-page-personal-info_title"
          />

          {this.state.statePage === BUTTONSEDITDOCTOR[0] && (
            <PersonalInfoForm
              setPersonalInfo={this.setPersonalInfo}
              updatePersonalInfo={this.props.updatePersonalInfo}
              flag={ADMIN}
              id={this.props.id}
              updateDoctor={this.props.updateDoctor}
              doctorData={doctorData}
              locations={this.props.locations}
              speciality={this.props.speciality}
              saveElements={(func, funcCancel) => {
                this.func = func;
                this.funcSave = func;
                this.funcCancel = funcCancel;
              }}
            />
          )}

          {this.state.statePage === BUTTONSEDITDOCTOR[1] && (
            <EmploymentApplication
              listOfDocumentsFor={doctorData.employeeStatus}
              flag={ADMIN}
              saveSignature={this.props.saveSignature}
              signature={this.props.signature}
              updateStatusPersonalInfo={this.updateStatusPersonalInfo}
              saveDataDoctor={this.funcSave}
              doctorData={this.props.doctorData}
              uploadApplications={this.props.uploadApplications}
              updateUploadedFiles={this.props.updateUploadedFiles}
              saveElements={(func, funcCancel) => {
                this.func = func;
                this.funcCancel = funcCancel;
              }}
            />
          )}

          {this.state.statePage === BUTTONSEDITDOCTOR[2] && (
            <Archive
                doctorType={doctorData.employeeStatus}
              archived={this.props.archivedFiles}
              unArchiveFiles={this.unArchiveFiles}
              getDataDoctor={this.props.getDataDoctor}
              getArchivedDocuments={(id) => {
                this.func = this.funcSave;
                this.funcCancel = () => {
                  history.push(`${routesCommon.admin}${routersAdmin.table}`);
                }
                this.props.getArchivedDocuments(id);
              }}
              id={this.props.id}
            />
          )}

          <div className="doctor-page-personal-info_title-btn">
            <Button
              text="Cancel"
              size="middle"
              variant="default"
              onClick={this.actionCancel}
            />
            <Button
              text="Save"
              size="middle"
              variant="primary"
              onClick={this.saveElements}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = store => {
  return {
    locations: store.doctor.addEditDoctor.locations,
    speciality: store.doctor.addEditDoctor.speciality,
    archivedFiles: store.admin.archivedFiles
  };
};

export default connect(mapStateToProps, {
  getLocations,
  getSpeciality,
  getArchivedDocuments,
  unArchiveDocument,
  clearDoctorData
})(AddEditDoctor);
