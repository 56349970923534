import React from "react";
import {assessmentsPlanKeys} from "../../constants";

export const PatientData = () => {
    return [
        {
            type: "text",
            data: null,
            editable: false,
            source: "patient.first_name"
        },
        {
            type: "text",
            data: null,
            editable: false,
            source: "patient.last_name"
        },
        {
            type: "text",
            data: 'is a',
            editable: true,
            source: null
        },
        {
            type: "text",
            data: null,
            editable: false,
            source: "patient.age"
        },
        {
            type: "text",
            data: 'y.o.',
            editable: true,
            source: null
        },
        {
            type: "text",
            data: null,
            editable: false,
            source: "patient.sex"
        }
    ]
}
