import React from "react";
import cn from "classnames";

export const UnArchiveFiles = props => (
    <div
        onClick={props.onClick}
        className={cn(props.className, "icon-component")}
        style={props.style}
    >
      <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
        <path
            className={cn(props.className)}
            d="M17.55 2.22L16.16 0.54C15.88 0.21 15.47 0 15 0H3C2.53 0 2.12 0.21 1.85 0.55L0.46 2.22C0.17 2.57 0 3.01 0 3.5V16C0 17.1 0.89 18 2 18H16C17.1 18 18 17.1 18 16V3.5C18 3.01 17.83 2.57 17.55 2.22ZM9 6.5L14.5 12H11V14H7V12H3.5L9 6.5ZM2.12 2L2.94 1H14.94L15.87 2H2.12Z"
            fill="#5F6368"
        />
      </svg>
    </div>
);
