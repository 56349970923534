import React from "react";

export const ChestRespiratory = (extra = []) => {
    return {
        type: "label",
        data: "Chest/Respiratory:",
        canDelete: true,
        items: [
            {
                type: "input",
                placeholder: "",
                data: "",
            },
            {
                type: "radio-single",
                data: [{checked: false, data: "rales"}]
            },
            {
                type: "text",
                data: ',',
                editable: true,
                source: null
            },
            {
                type: "radio-single",
                data: [{checked: false, data: "rhonchi"}]
            },
            {
                type: "text",
                data: ',',
                editable: true,
                source: null
            },
            {
                type: "radio-single",
                data: [{checked: false, data: "wheezes"}]
            },
            ...extra,
            {
                type: "text",
                data: ';',
                editable: true,
                source: null
            },
            {
                type: "text",
                data: 'breath sounds equal bilaterally.',
                editable: true,
                source: null
            },
        ]
    }
}