import * as components from "./components";
import {PatientData} from "./components/PatientData";

export const template = {
    name: 'Bronchitis',
    body: {
        body: [
            {
                type: "label",
                data: "PRESENTING PROBLEM:",
                items: [
                    {
                        type: "text",
                        data: 'Cough',
                        editable: true,
                        source: null
                    },
                ]
            },
            {
                type: "label",
                data: "HISTORY OF THE PRESENT ILLNESS:",
                required: true,
                items: [
                    ...PatientData(),
                    {
                        type: "text",
                        data: 'who complains of cough productive of',
                        editable: true,
                        source: null
                    },
                    {
                        type: "input",
                        placeholder: "type",
                        data: "",
                    },
                    {
                        type: "text",
                        data: 'sputum, beginning',
                        editable: true,
                        source: null
                    },
                    {
                        type: "input",
                        placeholder: "duration",
                        data: "",
                    },
                    {
                        type: "text",
                        data: '.',
                        editable: true,
                        source: null
                    },
                    {
                        type: "checkbox",
                        data: [
                            {checked: false, data: "fever"},
                            {checked: false, data: "chills"},
                            {checked: false, data: "night sweats"},
                        ]
                    },
                    {
                        type: "text",
                        data: '. Otherwise:',
                        editable: true,
                        source: null
                    },
                    {
                        type: "checkbox",
                        data: [
                            {checked: false, data: "chest pain"},
                            {checked: false, data: "dyspnea"},
                            {checked: false, data: "hemoptysis"},
                        ]
                    },{
                        type: "text",
                        data: '.',
                        editable: true,
                        source: null
                    },
                ]
            },
            components.ReviewOfSystem(),
            components.PMH(),
            components.GYM(),
            components.FamSocHistory(),
            components.Medications(),
            components.Allergies(),
            components.PhysicianExamination({
                items: [
                    {
                        type: "label",
                        data: "Generalized Appearance:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "checkbox",
                                data: [
                                    {checked: false, data: "Alert"},
                                    {checked: false, data: "cooperative"},
                                    {checked: false, data: "in no distress"},
                                ]
                            },
                        ]
                    },
                    components.VitalSigns(),
                    {
                        type: "label",
                        data: "Skin:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Warm, dry;',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "cyanosis"}]
                            },
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    components.Eyes(),
                    {
                        type: "label",
                        data: "ENMT:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Mucous membranes moist. Airway patent:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "stridor"}]
                            },
                            {
                                type: "text",
                                data: '. Pharynx:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "swelling"}]
                            },
                            {
                                type: "text",
                                data: ',',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "erythema"}]
                            },
                            {
                                type: "text",
                                data: ',',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "exudate"}]
                            },
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    components.Neck(),
                    {
                        type: "label",
                        data: "Chest/Respiratory:",
canDelete: true,
                        required: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "wheezes"}]
                            },
                            {
                                type: "text",
                                data: ',',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "rhonchi"}]
                            },
                            {
                                type: "text",
                                data: ',',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "rales"}]
                            },
                            {
                                type: "text",
                                data: ',',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "pleural rub"}]
                            },
                            {
                                type: "text",
                                data: '; breath sounds equal bilaterally.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    components.HeartCardiovascular(),
                    {
                        type: "label",
                        data: "Abdomen/GI:", canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Soft;',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "tenderness"}]
                            },
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    {
                        type: "label",
                        data: "Extremities:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "deformity"}]
                            },
                            {
                                type: "text",
                                data: ',', editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "edema"}]
                            },
                            {
                                type: "text",
                                data: '.', editable: true,
                                source: null
                            },
                        ]
                    },
                    {
                        type: "label",
                        data: "Neuro/Psych:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Mental status as above;',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "focal findings"}]
                            },
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                ]
            }),
            components.TestPerformed({items: [
                    {
                        type: 'blockCheckboxCondition',
                        data: {
                            checkbox: [{checked: false, data: 'None'}],
                            hiddenBlock: []
                        }
                    },
                    {
                        type: 'blockCheckboxCondition',
                        data: {
                            checkbox: [{checked: false, data: 'Strep test:'}],
                            hiddenBlock: [
                                {
                                    type: "radio-block",
                                    data: [{checked: true, data: "Negative"},
                                        {checked: false, data: "Positive"}]
                                },
                            ]
                        }
                    },
                    {
                        type: 'blockCheckboxCondition',
                        data: {
                            checkbox: [{checked: false, data: 'Covid 19 test -'}],
                            hiddenBlock: [
                                {
                                    type: "radio-block",
                                    data: [{checked: true, data: "Negative"},
                                        {checked: false, data: "Positive"}]
                                },
                            ]
                        }
                    },
                    {
                        type: 'blockCheckboxCondition',
                        data: {
                            checkbox: [{checked: false, data: 'Flu test'}],
                            hiddenBlock: [
                                {
                                    type: "radio-block",
                                    data: [{checked: true, data: "Negative"},
                                        {checked: false, data: "Positive"}]
                                },
                            ]
                        }
                    },
                    {
                        type: 'blockCheckboxCondition',
                        data: {
                            checkbox: [{checked: false, data: ':'}],
                            hiddenBlock: [
                                {
                                    type: "input",
                                    placeholder: "",
                                    data: "",
                                },
                            ]
                        }
                    },
                ]}),
            components.AssessmentPlan([ {
                type: 'blockCheckboxCondition',
                extraType: 'assessmentContent',
                data: {
                    checkbox: [{checked: true, data: '', isHide: true}],
                    hiddenBlock: [
                        {
                            type: "text",
                            data: 'Follow up with',
                            editable: true,
                            source: null
                        },
                        {
                            type: "radio-block",
                            data: [{checked: true, data: "PCP"},
                                {checked: false, data: "Pulmonologist"}]
                        },
                        {
                            type: "text",
                            data: 'in',
                            editable: true,
                            source: null
                        },
                        {
                            type: 'input-static-text',
                            label: '',
                            data: [{data: '', mask: '999', min: 0, max: 365}],
                            deviders: []
                        },
                        {
                            type: "text",
                            data: 'days for further evaluation.',
                            editable: true,
                            source: null
                        },

                    ]
                }
            }]),
        ]
    }
}
