import React from "react";
import cn from "classnames";
import { Typography } from "../../../Common/Typography/Typography";
import "./style.scss";
import { Delta } from "../../../Common/Delta/Delta";

export const TimeButton = ({ label, time, delta, ...rest }) => (
  <div className={"admin-dashboard-time"}>
    {!!label && (
      <Typography variant="p" text={label} className="dashboard-title" />
    )}
    <div className="time-wrapper">
      <Typography variant="h2_5" text={`${time || 0}`} />
      <Delta delta={delta} />
    </div>
  </div>
);
