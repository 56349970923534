import React, { Component } from "react";
import cn from "classnames";

export const BirdSelect = props => (
  <div
    onClick={props.onClick}
    className={cn(props.className, "icon-component")}
    style={props.style}
  >
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.81818 15.1194L6.95455 12.2985L6 13.2388L9.81818 17L18 8.9403L17.0455 8L9.81818 15.1194Z" fill="white"/>
    </svg>

  </div>
);
