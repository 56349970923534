import {fetchV2} from "../../helpers/helpers";
import {setDoctorsData} from "./slice";

export const getDoctorsData = (filters = {}, data = { results: [] }) => dispatch =>
    dispatch(fetchV2({
        url: "admin/doctors/",
        body: filters
    })).then(resp => {
        const processData = { timestamp: resp.timestamp, ...resp.data, results: data.results.concat(resp.data.results) }
        delete processData.data
        dispatch(setDoctorsData(processData))
        return resp.results;
    });