export const VARIATIONS_BTN = {
    CONTAINED: 'contained',
    OUTLINED: 'outlined',
    TEXT: 'text'
}

export const SIZES_ICON_BUTTON = {
    SMALL: 0,
    MEDIUM: 16,
    BIG: 0
}

export const COLORS_ICON_BTN = {
    DEFAULT: 'default',
    PRIMARY: 'primary',
    SECONDARY: 'secondary'
}

