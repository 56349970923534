import React from 'react';
import { PatientSidebar } from "../../Common/PatientSidebar";

export const Examination = props => {
  const currentPatient = props.data?.patients.find(i => i?.uuid === props?.patientForExamination);
  return(
    <div className="examination">
      <PatientSidebar
        {...props}
        detailsRequest={props.data}
        data={currentPatient}
        hideExamination
        hideSignature={currentPatient?.status === 'main'}
      />
    </div>
  );
};
