import React, {useEffect, useState} from "react";
import { ArrowLeft } from "../../Common/Icons";
import { addNewCard, patientProfileTabs } from "../../../constants/Patient";
import { colors } from "../../../assets/colors/colors";
import { Typography } from "../../Common/Typography/Typography";
import Cards from "react-credit-cards";
import { Input } from "../../Common/Input/Input";
import { Button } from "../../Common/Button/Button";
import { PopupContainer } from "../../Common/Popup/PopupContainer";
import moment from "moment";
import {
  buttonsInnerText,
  PLACEHOLDERS,
  placeholdersAddress,
  typesCard
} from "../../../constants";
import { InputAddress } from "../../Common/Input/InputAddress";
import { validationZipCodeRegex } from "../../../utils/validation";
import {Checkbox} from "../../Common/Checkbox/Checkbox";
import Analytics from "../../../utils/analytics/AnalyticsService";
import {EVENTS} from "../../../utils/analytics/Events";

const placeholdersCard = {
  name: "YOUR NAME"
};

export const AddCard = ({controlAddNewCard, ...props}) => {
  const [cardData, setCardData] = useState({
    cvc: "",
    expiry: "",
    // name: "",
    number: "",
    address: props.defaultAddress || null,
    zipcode: "",
    isDefault: false
  });
  const [focus, setFocus] = useState("");
  const [typeCard, setTypeCard] = useState(typesCard[1]);
  const [errors, setErrors] = useState({});

  useEffect(()=>{
    Analytics.track(EVENTS.Comprehensive_Add_Card_Page_Open);
  },[])

  const callbackCard = data => {
    const card = typesCard.find(i => data.issuer === i.name);
    if (card) setTypeCard(card);
  };
  const simpleUpdate = (field, value) => {
    let errorsAll = { ...errors };
    delete errorsAll[field];
    setErrors(errorsAll);
    setCardData({ ...cardData, [field]: value });
  };

  const handlerSetFocus = e => setFocus(e.target.name);

  const handleSubmit = async ev => {
    const expiryDate = moment(cardData.expiry, "MM-YY")
      .endOf("month")
      .valueOf();
    let errors = {};
    if (cardData.number.length !== typeCard.length)
      errors.number = `Card number length is not ${typeCard.length}`;
    // if (!cardData.name.length) errors.name = "This field is required";
    if (cardData.cvc.length < 3) errors.cvc = "CVC min length is 3";
    if (expiryDate < moment().valueOf())
      errors.expiry = "Expiry date should be in future";
    if (isNaN(expiryDate) && cardData.expiry.length === 5)
      errors.expiry = "Is not valid date";
    if (!cardData.expiry.length) errors.expiry = "This field is required";
    if (!cardData.address) errors.address = "This field is required";
    if (!cardData.zipcode) errors.zipcode = "This field is required";
    if (!errors.zipcode) {
      const errorZipcode = validationZipCodeRegex(cardData.zipcode);
      if (errorZipcode) errors.zipcode = errorZipcode;
      // else {
      //   const isValid = await validationZipCode(cardData.zipcode);
      //   if (!isValid) errors.zipcode = "We Don’t Serve This Zip Code";
      // }
    }
    if (Object.keys(errors).length) setErrors(errors);
    else {
      const processedData = {
        address_line: cardData.address.address,
        zip_code: cardData.zipcode,
        card_number: cardData.number,
        expiry_date:
            "20" +
            cardData.expiry
                .split("/")
                .reverse()
                .join("-"),
        card_code: cardData.cvc,
        is_default: cardData.isDefault
      }

      if(props.checkWishSaveForLater && !cardData.isDefault && props.actionAddCard) props.actionAddCard(processedData)
      else {
        props
            .addNewCard(processedData)
            .then(resp => {
              if (resp.card_number) {
                controlAddNewCard(false)
                props.actionAddCard && props.actionAddCard(resp.payment_profile_id)
              }
            });
      }
    }
  };
  const back = () => controlAddNewCard(false);


  return (
    <div className="personal-info-patient_add-card">
      {!props.hideHeaderAddCard && <div className="personal-info-patient_add-card_header">
        <ArrowLeft onClick={back} />
        <Typography
          color={colors.blackMuted}
          variant="h2"
          className=""
          text="Add New Credit Card"
        />
      </div>}
      <div className="personal-info-patient_add-card_info">
        <Typography
          color={colors.grey}
          variant="label"
          className="personal-info-patient_add-card_info_label"
          text="Card Information"
        />
        <Cards
          placeholders={placeholdersCard}
          preview
          acceptedCards={["visa", "mastercard", "amex"]}
          cvc={cardData.cvc}
          expiry={cardData.expiry}
          focused={focus}
          name={cardData.name}
          number={cardData.number}
          callback={callbackCard}
        />
        <div className="personal-info-patient_add-card_info_form">
          <Input
            autocomplete="off"
            maxLength={typeCard.length}
            type="number"
            placeholder={typeCard.placeholder}
            value={cardData.number}
            error={errors.number}
            field="number"
            name="number"
            pattern="[\d| ]{16,22}"
            onFocus={handlerSetFocus}
            onChange={simpleUpdate}
          />
          <div className="personal-info-patient_add-card_info_form_container">
            <Input
              autocomplete="off"
              placeholder="MM/YY"
              value={cardData.expiry}
              error={errors.expiry}
              field="expiry"
              name="expiry"
              mask="99/99"
              maskChar=""
              onFocus={handlerSetFocus}
              onChange={simpleUpdate}
            />
            <Input
              autocomplete="off"
              placeholder="CVC"
              value={cardData.cvc}
              error={errors.cvc}
              field="cvc"
              name="cvc"
              mask="9999"
              maskChar=""
              onFocus={handlerSetFocus}
              onChange={simpleUpdate}
            />
          </div>
          {/*<Input*/}
          {/*  autocomplete="off"*/}
          {/*  type="text"*/}
          {/*  placeholder="Card Holder Name"*/}
          {/*  value={cardData.name}*/}
          {/*  error={errors.name}*/}
          {/*  field="name"*/}
          {/*  name="name"*/}
          {/*  onFocus={handlerSetFocus}*/}
          {/*  onChange={simpleUpdate}*/}
          {/*/>*/}
          <div className="personal-info-patient_add-card_info_form_container-full">
            <Input
              type="number"
              placeholder="Billing Zip Code"
              className=""
              value={cardData.zipcode}
              error={errors["zipcode"]}
              field="zipcode"
              maxLength="5"
              onFocus={handlerSetFocus}
              onChange={simpleUpdate}
            />
            <InputAddress
              placeholder="Billing Address"
              value={cardData.address}
              error={errors["address"]}
              field="address"
              maxLength="200"
              onFocus={handlerSetFocus}
              onChange={simpleUpdate}
            />
          </div>
          {props.checkWishSaveForLater && <div className="personal-info-patient_add-card_info_form_container-full">
            <Checkbox
                checked={cardData.isDefault}
                field='isDefault'
                onClick={simpleUpdate}
                label='Save Card For Later Payments'
            />
          </div>}

          <div className="personal-info-patient_add-card_info_form_button">
            <Button
              onClick={handleSubmit}
              text={props.buttonNameAddCard || buttonsInnerText.add_card}
              capitalize
              size="large"
              variant="primary"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
