import React from "react";
import cn from "classnames";

export const Trash = props => {
  return (
    <div
      onClick={props.onClick}
      className={cn(props.className, "icon-component")}
      style={props.style}
    >
      <svg
        width="14"
        height="18"
        viewBox="0 0 14 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={cn(props.className)}
          d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM3.46 8.88L4.87 7.47L7 9.59L9.12 7.47L10.53 8.88L8.41 11L10.53 13.12L9.12 14.53L7 12.41L4.88 14.53L3.47 13.12L5.59 11L3.46 8.88ZM10.5 1L9.5 0H4.5L3.5 1H0V3H14V1H10.5Z"
          fill="#5F6368"
        />
      </svg>
    </div>
  );
};
