import { Button } from "../Common/Button/Button";
import { buttonsText, steps } from "../../constants";
import React from "react";

export const Buttons = ({
  DefaultText = buttonsText.cancel,
  PrimaryText = buttonsText.next,
  DefaultClick,
  PrimaryClick,
  DefaultDisabled,
  PrimaryDisabled,
  hideDefaultButton
}) => (
  <div className="app-container-request_wrapper_right-panel_navigation-buttons">
    {!hideDefaultButton && (
      <Button
        text={DefaultText}
        size="middle"
        variant="default"
        disabled={DefaultDisabled}
        onClick={DefaultClick}
      />
    )}
    <Button
      text={PrimaryText}
      size="middle"
      variant="primary"
      disabled={PrimaryDisabled}
      onClick={PrimaryClick}
    />
  </div>
);
