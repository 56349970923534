import React from 'react';
import {
  RequestInfo,
  Separator,
  RequestedBy,
  Map
} from '../../SideBarRequest/components';
import { PatientSidebar } from "../../Common/PatientSidebar";
import { GroupCard } from "../../Common/GroupCard/GroupCard";

export const Confirm = props => {
  return(
    <>
      <RequestInfo {...props} />
      <Separator />
      <RequestedBy {...props} />
      {props.data?.is_group ? (
        <GroupCard
          onClick={props.openPatientList}
          data={props.data}
        />
      ) : (
          <>
            <span className='details-request_body_you'>{props.data?.patients[0].client_patient_relationship || 'Requester'}</span>
              <PatientSidebar
                  {...props}
                  detailsRequest={props.data}
                  data={props.data?.patients[0]}
                  hideNote
                  hideSignature
                  hideExamination
                  hideAddButton
              />
          </>
      )}
      <Map {...props} comment={props.data?.location.comment} isShowSuite />
    </>
  );
};
