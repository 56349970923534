import React, { useEffect, useState } from "react";
import "./style.scss";
import { Card } from "./Card";
import { Typography } from "../../Common/Typography/Typography";
import { colors } from "../../../assets/colors/colors";
import { PopupContainer } from "../../Common/Popup/PopupContainer";
import Cards from "react-credit-cards";
import "react-credit-cards/lib/styles.scss";
import { Input } from "../../Common/Input/Input";
import { Button } from "../../Common/Button/Button";
import moment from "moment";
import {
  BTN_LABELS,
  buttonsInnerText, providerPrivacyPolicyUrl,
  routesCommon,
  statesButtonsTime,
  steps, termsAndConditionsUrl,
  uplinPrivacyNoticeUrl,
} from "../../../constants";
import { Buttons } from "../Buttons";
import history from "../../../helpers/history";
import { v1 as uuidv1 } from "uuid";
import {
  checkGiftCardBe,
  getCardsUser
} from "../../../store/createRequest/actions";
import { routersPatient } from "../../../constants/Patient";
import { CardsForServices } from "../../PatientFlow/Schedule/Cards";
import { Trash } from "../../Common/Icons/trash";
import Analytics from "../../../utils/analytics/AnalyticsService";
import { EVENTS } from "../../../utils/analytics/Events";
import { dateToDisplay } from "../../../helpers/helpers";
import { Map } from '../../../compoenentsAdditional/Map';
import { Price } from "../StepRequestDataInfo/Price";
import { PatientsList } from "../StepSymptoms/PatientsList";
import { CheckboxV2 } from "../../Common/CheckboxV2";
import {COLORS_CHECKBOX} from "../../Common/CheckboxV2/constants";

const InfoRewiew = props => {
  const [checked, setChecked] = useState(false);
  const [giftCard, setGiftCard] = useState({check: false, value: '', price: ''});
  const [errorGiftCard, setErrorGiftCard] = useState(false)

  const [listCards, setListCards] = useState([]);

  useEffect(()=>{
      Analytics.track(EVENTS.Comprehensive_Review_Page_Open);
  },[])

  useEffect(() => {
    getCardsUser().then(resp => {
      if (Array.isArray(resp) && !!resp.length) setListCards(resp);
    });
  }, []);
  useEffect(() => {
    props.simpleUpdateState("activeStep", steps.Review);
  }, []);
  useEffect(()=>{
    if(props.cards?.length) setListCards(props.cards)
  }, [props.cards.length])

  const goBack = () => props.simpleUpdateState("openConfirmation", true);
  const sendRequest = (dataCard) => {
    const { address, timeVisiting, dateVisiting } = props;
    let date = {};
    if (props.activeButtonTime === statesButtonsTime[1]) {
      let time = moment(
        `${moment(dateVisiting).format("YYYY-MM-DD")}-${timeVisiting.value}-00`,
        "YYYY-MM-DD-HH:mm-ss"
      );
      date = {
        application_can_start_at: moment(time.valueOf())
          .format("YYYY-MM-DDTHH:mm:ssZ"),
        application_time: time.add(timeVisiting.duration, 'seconds').format("YYYY-MM-DDTHH:mm:ssZ")
      };
    }
    let promoCode = giftCard.value ? {promo_code_title: giftCard.value} : {}
    let data = {
      ...promoCode,
      idempotency_key: uuidv1(),
      ...date,
      urgency_type: props.activeButtonTime,
      service_type: props.requestType.replace(/-/g,'_'),
      location: {
        zip_code: address.zipcode,
        address_line: address.address.address,
        apartment: address.apartment || null,
        comment: address.comment,
        address: address.address.street,
        state: address.address.state,
        city: address.address.city
        // floor: address.floor
      },
      single_service_requests: props.selectedPatients.map(i => {
        const requestInfo = {
          service_type: props.requestType.replace(/-/g,'_'),
          symptoms: i.selected.filter(i => i.uuid).map(i => i.uuid),
          patient: i.uuid,
          comment: i.otherSymptoms
        };
        if(!requestInfo.symptoms) {
          delete requestInfo.symptoms;
        }
        if(!i.otherSymptoms) {
          delete requestInfo.comment;
        }
        return requestInfo;
      })
    };
    if(typeof dataCard === 'string') data.payment_profile_id = dataCard
    if(typeof dataCard === 'object') data.card_info = dataCard
    props.createRequest(data).then(resp => {
      if (resp.idempotency_key) {
          Analytics.track(EVENTS.Request_Details_Page_Open_From_Comprehensive_Flow);
        history.push({
          pathname: `${routesCommon.patient}${routersPatient.details}/${resp.uuid}`,
        });
      }
      else{
        if(resp?.non_field_errors && resp.non_field_errors[0]){
          setGiftCard({...giftCard, check: false, price: ''})
          setErrorGiftCard('The Code You Entered is Not Valid. Please Try Again.')
          props.moveToTheStep(steps.Review);
        }
      }
    });
  };

  const handleChangeGift = (field, value)=>{
    setErrorGiftCard(false)
    setGiftCard({
      ...giftCard,
      value: value,
    })}

    const applyGiftCard = () => {
    checkGiftCardBe(giftCard.value).then((resp)=>{
      if(resp.price) setGiftCard({...giftCard, check: true, price: resp.price})
      else setErrorGiftCard('The Code You Entered is Not Valid. Please Try Again.')
    })
    }

    const clearGiftCard = () => {
      setGiftCard({value: '', check: false, price: ''})
    }

  const additionalComment = props.address?.comment

  let price = props.currentRequestData?.price;
  let totalPrice = price * props.patientsAmount;
  if(giftCard.price) {
    totalPrice -= Math.floor(+giftCard.price);
    if(totalPrice < 0) totalPrice = 0;
  }
  const urgencyType = props.activeButtonTime === 'now' ? 'Now' : 'Later';
  const requestType = props.currentRequestData?.viewLabel;
  const timeVisiting = props.activeButtonTime === 'now' ? 'In 60 Minutes' : props.timeVisiting.label;

  const addressData = {
    location: {
      ...props.address?.address,
      address_line: props.address?.address?.address,
      apartment: props.address?.apartment
    }
  };

  return (
    <div className="container-info-rewiew">
      {props.activeStep === steps.Review && (
        <>
          <Card
            user={props.user}
            applicationDate={props.activeButtonTime === 'now' ? 'Today' : dateToDisplay(props.dateVisiting)}
            timeVisiting={timeVisiting}
            urgencyType={urgencyType}
          />
          <div className="container-info-rewiew__patients">
            <PatientsList
              selectedPatients={props.selectedPatients}
              showGroup
              user={props.user}
            />
          </div>
          <Map data={addressData} isShowSuite comment={additionalComment} />
          <div className='container-info-rewiew_gift-card'>
            {giftCard.check ? <div className='container-info-rewiew_gift-card_previe'>
              <Typography
                  color={colors.blackMuted}
                  variant="title"
                  text={giftCard.value}
              />
              <Typography
                  color={colors.navy}
                  variant="title"
                  text={`-$${Math.floor(giftCard.price)}`}
              />

              {!giftCard.block &&
               <Trash onClick={clearGiftCard}/>
              }
            </div> : <div style={{width: '100%'}}>
              <Typography
                  color={colors.grey}
                  className='container-info-rewiew_gift-card_title'
                  variant="p"
                  text={`Do you have a Group Code?`}
              />
              <div className='container-info-rewiew_gift-card_wrapper'><Input
                placeholder='CODE'
                value={giftCard.value}
                maxLength="24"
                error={errorGiftCard}
                onChange={handleChangeGift}
            />
            <Button text='Apply' variant='default' size='middle' onClick={applyGiftCard} disabled={!giftCard.value}/>
            </div></div>}
          </div>
          <div className='container-info-rewiew_container'>
            <Price
              price={price}
              totalPrice={totalPrice}
              patientsAmount={props.patientsAmount}
              requestType={requestType}
              giftCard={giftCard}
              className='container-info-rewiew_container_price'
            />
          </div>
          <div className="container-info-rewiew__agreements">
            <CheckboxV2
              label={(
                <Typography
                  className="container-info-rewiew__links"
                  variant="p"
                  dangerouslySetInnerHTML={`By clicking “Send Doctor” you agree to the <a href=${providerPrivacyPolicyUrl} target="_blank">Provider Privacy Notice</a>, <a href=${termsAndConditionsUrl} target="_blank">Terms of Use</a> and <a target="_blank" href=${uplinPrivacyNoticeUrl}>Uplin Privacy Policy</a>.`}
                />
              )}
              color={COLORS_CHECKBOX.PRIMARY}
              checked={checked}
              onChange={() => setChecked(prev => !prev)}
            />
          </div>
          <div className="container-info-rewiew_container-buttons">
            <Buttons
                PrimaryText={BTN_LABELS.COMPLETE_BOOKING}
                DefaultClick={goBack}
                PrimaryClick={() => {
                  if(!totalPrice) sendRequest()
                    else props.moveToTheStep(steps.Cards);
                }}
                PrimaryDisabled={!checked}
            />
          </div>
        </>
      )}
      {props.activeStep === steps.Cards && (
        <div className='container-info-rewiew_card-container'>
          <CardsForServices
            createRequestMode
            defaultAddress={props.address.address}
            simpleUpdateState={props.simpleUpdateState}
            payAction={sendRequest}
            goBackAction={()=>{
              props.simpleUpdateState("hideStepsHeader", false);
              props.moveToTheStep(steps.Review)
            }}
            addNewCard={props.addNewCard}
            cards={listCards}
          />
        </div>
      )}
    </div>
  );
};
export default InfoRewiew;
