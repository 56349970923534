import React, { useEffect, useState } from "react";
import { Input } from "../../Common/Input/Input";
import { placeholdersAddress, steps } from "../../../constants";
import { InputAddress } from "../../Common/Input/InputAddress";
import { Buttons } from "../Buttons";
import {
  validationIsEmpty,
  validationZipCodeRegex
} from "../../../utils/validation";
import { validationZipCode } from "../../../store/createRequest/actions";
import { filterObj, lengthObj } from "../../../utils/common";
import { PopupZipcode } from "./PopupZipcode";
import { InfoCloud } from "../../Common/InfoCloud/InfoCloud";
import Analytics from "../../../utils/analytics/AnalyticsService";
import { EVENTS } from "../../../utils/analytics/Events";

const initialState = {
  zipcode: "",
  address: null,
  apartment: "",
  comment: ''
  // floor: ""
};

export const Address = ({ actionNextStep, simpleUpdateState, address }) => {
  const [state, setState] = useState({ ...initialState, ...address });
  const [errors, setErrors] = useState({});
  const [showPopup, setShowPopup] = useState(false);

  const updateState = (field, value, zipCode) => {
    let newState = { ...state, [field]: value }
    const errorsState = {...errors}
    if(zipCode !== undefined){
      newState.zipcode = zipCode
    }
    if(field === 'address') errorsState.zipcode = false
    setState(newState);
    setErrors({ ...errorsState, [field]: false });
  };

  useEffect(()=>{
    Analytics.track(EVENTS.Comprehensive_Address_Page_Open);
  },[])


  useEffect(() => {
    simpleUpdateState("activeStep", steps.Address);
  }, []);

  const validation = async () => {
    let errors = validationIsEmpty(state);
    delete errors.apartment
    delete errors.comment
    errors.zipcode = validationZipCodeRegex(state.zipcode);
    if (!errors.zipcode) {
      const isValid = await validationZipCode(state.zipcode);
      if (!isValid) {
        setShowPopup(true);
        errors.zipcode = " ";
      }
    }
    return filterObj(errors);
  };

  const closePopup = () => setShowPopup(false);
  const saveAddress = async () => {
    const errors = await validation();

    if (!lengthObj(errors)) {
      simpleUpdateState("address", state);
      actionNextStep();
    } else setErrors(errors);
  };

  const goToPreviousScreen = () => simpleUpdateState("openConfirmation", true);
  return (
    <div className="patient-info-container">
      <div className="patient-info-container_block-row">
        <InputAddress
            checkZipcode
            placeholder={placeholdersAddress.address}
            error={errors["address"]}
            field="address"
            value={state.address}
            onChange={updateState}
            className=""
        />
        <InfoCloud dangerouslySetInnerHTML={`Zip Code is added based on address`}>
          <Input
              disable
              type="number"
              placeholder={placeholdersAddress.zipcode}
              className=""
              value={state.zipcode}
              error={errors["zipcode"]}
              field="zipcode"
              maxLength="5"
              onChange={updateState}
          />
        </InfoCloud>
        {/*<Input*/}
        {/*  type="number"*/}
        {/*  placeholder={placeholdersAddress.floor}*/}
        {/*  value={state.floor}*/}
        {/*  error={errors["floor"]}*/}
        {/*  field="floor"*/}
        {/*  maxLength="12"*/}
        {/*  onChange={updateState}*/}
        {/*/>*/}
      </div>
      <div className="patient-info-container_block-row">
        <Input
            type="noSign"
            placeholder={placeholdersAddress.apartment}
            className=""
            value={state.apartment}
            error={errors["apartment"]}
            field="apartment"
            maxLength="12"
            onChange={updateState}
        />
        <Input
            placeholder={placeholdersAddress.comment}
            className=""
            value={state.comment}
            error={errors["comment"]}
            field="comment"
            maxLength="256"
            onChange={updateState}
        />
      </div>
      <PopupZipcode open={showPopup} close={closePopup} emailUser={""} zip={state.zipcode} />
      <Buttons DefaultClick={goToPreviousScreen} PrimaryClick={saveAddress} />
    </div>
  );
};
