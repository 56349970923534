import React from "react";
import PropTypes from "prop-types";
import { Bar as BarChart } from "react-chartjs-2";
import "./RoundedBars.js";
import {colors} from "../../../assets/colors/colors";

export const Chart = ({ datasets, admin }) => {
  return (
    <BarChart
      data={datasets}
      width={360}
      height={171}
      options={{
        maintainAspectRatio: false,
        cornerRadius: 8,
        legend: {
          display: true,
          position: "right",
          labels: {
            boxWidth: 10,
            fontColor: admin ? "#000000" : "#717579",
            fontSize: admin ? 8 : 16,
            padding: 15
          }
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                fontColor: colors.navy,
                fontSize: 14,
                min: 0,
                stepSize: 25
              }
            }
          ],
          xAxes: [
            {
              ticks: {
                fontColor: colors.navy,
                fontSize: 14,
                min: 0
              }
            }
          ]
        }
      }}
    />
  );
};

Chart.propTypes = {
  datasets: PropTypes.object
};

Chart.defaultProps = {
  datasets: {
    labels: ["January"],
    datasets: [
      {
        label: "Completed on shift",
        data: [12],
        backgroundColor: "#219653",
        borderColor: "#219653",
        borderWidth: 0
      },
      {
        label: "Completed < 24 hours",
        data: [7],
        backgroundColor: colors.navy,
        borderColor: colors.navy,
        borderWidth: 0
      },
      {
        label: "Completed > 24 hours",
        data: [9],
        backgroundColor: "#F2BD42",
        borderColor: "#F2BD42",
        borderWidth: 0
      },
      {
        label: "Not Completed",
        data: [8],
        backgroundColor: "#FF6666",
        borderColor: "#FF6666",
        borderWidth: 0
      }
    ]
  }
};
