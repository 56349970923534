import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Card, ProgressButton, TimeButton, Summary } from "./components";
import "./style.scss";

import { Typography } from "../../Common/Typography/Typography";
import { Chart } from "../../Common/Chart/Chart";
import { RangeDatePicker } from "../../Common/RangeDatePicker/RangeDatePicker";
import moment from "moment";
import {
  convertToISO,
  covertTime,
  formatAppointmentDate
} from "../../../utils/date";
import { CustomTable } from "../../Table/Table";
import { colors } from "../../../assets/colors/colors";
import { DropDown } from "../../Common/DropDown/DropDown";
import { PanelFilters } from "../PanelFilters/PanelFilters";
import { placeholders } from "../../../constants";
import { lengthObj, showFiltersValue } from "../../../utils/common";
import { init } from "@sentry/browser";
import { InfoWithMessage } from "../../Common/infoWithMessage/infoWithMessage";
import {fetchTotalPatientCount} from "../../../store/admin/actions";

const defaultStyles = {
  fontSize: 14,
  letterSpacing: "0.25px",
  textTransform: "capitalize",
  color: colors.tuna,
  fontWeight: 500,
  marginRight: 0,
  height: 29
};

const aligmentRight = {
  textAlign: 'right'
}
const aligmentLeft = {
  textAlign: 'left'
}

const columnsAdmin = [
  {
    headerClassName: "table-header-cell",
    label: "Doctor's Name",
    dataKey: "doctor_name",
    headerStyle: { width: "7.7%", ...defaultStyles,...aligmentLeft },
    style: { width: "7.7%",...aligmentLeft }
  },
  {
    headerClassName: "table-header-cell",
    label: "Specialty",
    dataKey: "specialty",
    headerStyle: { width: "7.7%", ...defaultStyles,...aligmentLeft },
    style: { width: "7.7%",...aligmentLeft }
  },
  {
    headerClassName: "table-header-cell",
    label: "Location",
    dataKey: "region",
    headerStyle: { width: "7.7%", ...defaultStyles,...aligmentLeft },
    style: { width: "7.7%",...aligmentLeft }
  },
  {
    headerClassName: "table-header-cell",
    label: "Total Visits",
    dataKey: "visits",
    headerStyle: { width: "7.7%", ...defaultStyles,...aligmentRight },
    style: { width: "7.7%",...aligmentRight }
  },
  {
    headerClassName: "table-header-cell",
    label: "New Patients",
    dataKey: "new_patients",
    headerStyle: { width: "7.7%", ...defaultStyles,...aligmentRight },
    style: { width: "7.7%",...aligmentRight }
  },
  {
    headerClassName: "table-header-cell",
    label: "Established Patients",
    dataKey: "established_patients",
    headerStyle: { width: "7.7%", ...defaultStyles,...aligmentRight },
    style: { width: "7.7%",...aligmentRight }
  },

  {
    headerClassName: "table-header-cell",
    label: "Order Response Time",
    dataKey: "order_response_time",
    headerStyle: { width: "7.7%", ...defaultStyles,...aligmentRight },
    style: { width: "7.7%",...aligmentRight }
  },
  {
    headerClassName: "table-header-cell",
    label: "Request To Door Time",
    dataKey: "road_time",
    headerStyle: { width: "7.7%", ...defaultStyles,...aligmentRight },
    style: { width: "7.7%",...aligmentRight }
  },
  {
    headerClassName: "table-header-cell",
    label: "Visit Duration",
    dataKey: "visit_time",
    headerStyle: { width: "7.7%", ...defaultStyles,...aligmentRight },
    style: { width: "7.7%",...aligmentRight }
  },
  {
    headerClassName: "table-header-cell",
    label: "Notes Completed on shift",
    dataKey: "completed_note_on_shift",
    headerStyle: { width: "7.7%", ...defaultStyles,...aligmentRight },
    style: { width: "7.7%",...aligmentRight }
  },
  {
    headerClassName: "table-header-cell",
    label: "Notes Completed < 24 hours",
    dataKey: "completed_note_in_24_hours",
    headerStyle: { width: "7.7%", ...defaultStyles,...aligmentRight },
    style: { width: "7.7%",...aligmentRight }
  },
  {
    headerClassName: "table-header-cell",
    label: "Notes Completed > 24 hours",
    dataKey: "completed_note_more_than_24_hours",
    headerStyle: { width: "7.7%", ...defaultStyles,...aligmentRight },
    style: { width: "7.7%",...aligmentRight }
  },
  {
    headerClassName: "table-header-cell",
    label: "Notes Not Completed",
    dataKey: "not_completed_note",
    headerStyle: { width: "7.7%", ...defaultStyles,...aligmentRight },
    style: { width: "7.7%",...aligmentRight }
  },
  {
    headerClassName: "table-header-cell",
    label: "All Uplin",
    dataKey: "patient_satisfaction_by_uplin",
    headerStyle: { width: "7.7%", ...defaultStyles,...aligmentRight },
    style: { width: "7.7%",...aligmentRight }
  }
];

const initialState = {
  location: [],
  speciality: [],
  physician: []
};

export default props => {
  const [date, setDate] = useState({
    dateFrom: moment()
      .startOf("month")
      .toDate(),
    dateTo: moment().toDate()
  });

  const [tableData, setTableData] = useState([]);
  const [tableDataFilters, setTableDataFilters] = useState([]);
  const [choosedFilters, setChoosedFilters] = useState({ ...initialState });
  const [applyFilters, setApplyFilters] = useState({ ...initialState });
  const [searchValue, setSearchValue] = useState("");
  const [totalPatientCount, setTotalPatientCount] = useState(0);

  const onChangeDate = (field, value) => {
    const valueDate = { ...date, [field]: value };
    setDate(valueDate);
    getData(valueDate).then(resp => {
      setTableData(resp);
      filterTableData(applyFilters, resp);
    });
  };

  const getData = dates => {
    return props.getDashboardDataAdmin({
      date_from: formatAppointmentDate(dates.dateFrom),
      date_to: formatAppointmentDate(dates.dateTo)
    });
  };
  const selectFilters = (field, value) => {
    setChoosedFilters({
      ...choosedFilters,
      [field]: value
    });
  };

  useEffect(() => {
    filterTableData();
  }, [searchValue]);

  const applyFilterAction = () => {
    setApplyFilters(choosedFilters);
    filterTableData(choosedFilters);
  };
  const deleteAppliedFilters = () => {
    setApplyFilters({ ...initialState });
    setChoosedFilters({ ...initialState });
    filterTableData({ ...initialState });
  };

  const filterTableData = (filters = applyFilters, data = tableData) => {
    setTableDataFilters(
      data.filter(doctor => {
        if ( !!filters.location.length &&
            !filters.location.some(location => location.value === doctor.region_uuid)) {
            return false;
        }

        if ( !!filters.speciality.length &&
          !filters.speciality.some(speciality => speciality.value === doctor.specialty_uuid)) {
            return false;
        }

        if (searchValue && doctor.doctor_name.toLowerCase().indexOf(searchValue.toLowerCase()) === -1){
            return false;
        }

        return true;
      })
    );
  };

  const handleChangeSearchValue = (field, value) => {
    setSearchValue(value);
  };

  useEffect(() => {
    props.getLocations();
    props.getSpeciality();
    fetchTotalPatientCount().then((resp)=>{
      setTotalPatientCount(resp?.total_count || 0);
    });
    getData(date).then(data => {
      setTableData(data);
      setTableDataFilters(data);
    });
  }, []);

  const responseTime = props.dashboardData.orderResponceTime;
  const dataChar = props.dashboardData.notesChar;
  const dataAllSatisfaction = props.dashboardData.satisfaction;
  let filtersValue = showFiltersValue(applyFilters);

  return (
    <div className="admin-page-dashboard">
      <div className="admin-page-dashboard_filterTime">
        <RangeDatePicker
          maxDateTo={moment().toDate()}
          maxDateFrom={moment().toDate()}
          onChange={onChangeDate}
          dateTo={date.dateTo}
          dateFrom={date.dateFrom}
        />
      </div>
      <div className="admin-page-dashboard_containerCard">
        <Card none className="one">
          <Summary {...props.dashboardData.summary} totalPatientCount={totalPatientCount} />
        </Card>
        <Card className="time-components-wrapper two">
          <TimeButton
            label="Order Response Time"
            time={responseTime.order_response_time}
            delta={responseTime.order_response_time_difference}
          />
          <TimeButton
            label="Request to Door Time"
            time={responseTime.request_to_door_time}
            delta={responseTime.request_to_door_time_difference}
          />
          <TimeButton
            label="Visit Duration"
            time={responseTime.visit_duration}
            delta={responseTime.visit_duration_difference}
          />
        </Card>
        <Card className="three">
          <div className="admin-page_-dashboard-statistics_container">
            <Typography variant="p" text="Notes Completion Statistics" />
            <InfoWithMessage label="How timely is your notes completion" />
          </div>

          {lengthObj(dataChar) && (
            <div className="chart-container">
              <Chart datasets={dataChar} admin />
            </div>
          )}
        </Card>
        <Card className="four">
          <ProgressButton
            label="All Uplin"
            value={dataAllSatisfaction.satisfaction}
            delta={dataAllSatisfaction.satisfaction_difference}
          />
        </Card>
      </div>
      <PanelFilters
        value={searchValue}
        onChange={handleChangeSearchValue}
        className=""
        filtersValue={filtersValue}
        applyFilterAction={applyFilterAction}
        applyFilters={applyFilters}
        deleteAppliedFilters={deleteAppliedFilters}
      >
        <DropDown
          multi
          value={choosedFilters.location}
          field="location"
          handlerSelect={selectFilters}
          label={placeholders.location}
          options={props.locations}
        />
        <DropDown
          multi
          value={choosedFilters.speciality}
          field="speciality"
          handlerSelect={selectFilters}
          label={placeholders.speciality}
          options={props.speciality}
        />
      </PanelFilters>
      <CustomTable
        heightTable="calc(100vh - 600px)"
        columnsHeader={columnsAdmin}
        data={tableDataFilters}
        rowClassName="emr-page-container_table_row"
        className="emr-page-container_table dashboard-table-admin"
      />
    </div>
  );
};
