import React, { Component } from "react";
import "./style.scss";
import cn from "classnames";
import { colors } from "../../../assets/colors/colors";
import { Typography } from "../Typography/Typography";

export const Textarea = props => {
  const classNames = cn(
    "textarea-common-component_placeholder",
    props.value && "textarea-common-component_placeholder_isValue",
      props.disabled && 'textarea-common-component_placeholder_disabled'
  );
  return (
    <div
      className={cn("textarea-common-component", {
        "textarea-common-component_error": props.error
      })}
      tabIndex="-1"
    >
      <textarea
        className={cn("textarea-common-component_textarea", props.className)}
        value={props.value}
        row={5}
        onKeyPress={props.onKeyPress}
        disabled={props.disabled}
        maxLength={props.maxLength || 255}
        onChange={props.onChange}
        onBlur={props.onBlur}
      />
      <span className={classNames}>{props.placeholder}</span>
      {props.textError && (
        <Typography
          variant="h5"
          color={colors.redError}
          text={props.textError}
          className="app-container-request_header-panel_wrapper-titles_title"
        />
      )}
    </div>
  );
};
