import React from "react";
import { Typography } from "../../Common/Typography/Typography";
import { colors } from "../../../assets/colors/colors";
import cn from "classnames";
import { backToAppointment, backToShift } from "../../../constants/Admin";
export const DoctorInfo = ({
  isAppointment,
  data = {},
  doctorLocation,
  filtersShifts,
  viewDetailsAppointmentOrShift
}) => {
  let fullName = `${data.first_name} ${data.last_name}, ${data['title'] || 'M.D.'}`;
  let location = data.region;
  let speciality = data.speciality;

  return (
    <div
      className={cn("doctor-info-event", {
        "doctor-info-event_extra": isAppointment
      })}
    >
      <Typography
        capitalize
        variant="h4"
        text="Assigned to:"
        color={colors.steel}
      />
      <Typography capitalize variant="p" text={fullName} color={colors.black} />
      <div className="doctor-info-event_container-data">
        <div>
          <Typography
            capitalize
            variant="h4"
            text={speciality}
            color={colors.steel}
          />
          <Typography
            capitalize
            variant="h4"
            text={`, ${location || doctorLocation}`}
            color={colors.steel}
          />
        </div>
        {/*{isAppointment && (*/}
        {/*  <button*/}
        {/*    className="no-default-style-button"*/}
        {/*    onClick={() => {*/}
        {/*      viewDetailsAppointmentOrShift(data, backToAppointment);*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <Typography*/}
        {/*      variant="h4"*/}
        {/*      text="view more"*/}
        {/*      uppercase*/}
        {/*      color={colors.navy}*/}
        {/*    />*/}
        {/*  </button>*/}
        {/*)}*/}
      </div>
    </div>
  );
};
