import createReducer from "../createReducer.js";
import { SET_TABLE_DATA } from "./actionTypes";

const initialState = {
  tableData: {
    count: 0,
    next: null,
    previous: null,
    results: []
  }
};

function setTableData(state, { payload }) {
  return { ...state, tableData: payload };
}

const emrReducer = createReducer(initialState, {
  [SET_TABLE_DATA]: setTableData
});

export default emrReducer;
