import { Typography } from "../../components/Common/Typography/Typography";
import { colors } from "../../assets/colors/colors";
import { colorStatus } from "../../constants";
import React from "react";
import "./style.scss";

export const AdditionalServices = ({ services = [], noLabel }) => {
  return (
    <div className="additional-services">
      {!noLabel && (
        <Typography
          color={colors.greyMiddle}
          variant="p"
          className="additional-services_title"
          text="Additional Services"
        />
      )}
      {services.map((i, index) => (
        <div key={index} className="additional-services_item">
          <div className="name" title={i.name}>{i.name}</div>
          <div className="container-info">
            <Typography
              color={colors.navy}
              variant="p"
              text={`$${Math.floor(i.price)}`}
              className="container-info_cost"
            />
            <Typography
              color={colorStatus[i.payment_status].toLowerCase()}
              variant="p"
              capitalize
              text={i.payment_status}
              className="container-info_status"
            />
          </div>
        </div>
      ))}
    </div>
  );
};
