import React, { Component } from "react";
import "./style.scss";
import cn from "classnames";

import { createUseStyles } from "react-jss";
import { colors } from "../../../assets/colors/colors";

const styles = createUseStyles({
  h0: {
    color: colors.navy
  },
  h1: {
    color: colors.blackMuted
  },
  h1_75: {
    color: colors.blackMuted
  },
  h2: {
    color: colors.blackRussian
  },
  h2_5: {
    color: colors.blackMuted
  },
  label: {
    color: colors.grey
  },
  p: {
    color: colors.blackMuted
  },
  h3: {
    color: colors.black
  },
  h4: {
    color: colors.blackMuted
  },
  h5: {
    color: colors.grey
  },
  h6: {
    color: colors.steel
  },
  title: {
    color: colors.black
  },
  underline: {
    textDecorationLine: "underline"
  },
  link: {
    color: colors.orangeMuted
  },
  uppercase: {
    textTransform: "uppercase"
  },
  capitalize: {
    textTransform: "capitalize"
  }
});

export const Typography = ({
  text,
  variant,
  className,
  color,
  link,
  styleCustom,
  uppercase,
  dangerouslySetInnerHTML,
  capitalize,
  background,
  paddingSide
}) => {
  let style = color ? { color: color } : {};
  if (styleCustom) style = { ...style, ...styleCustom };
  if (paddingSide)
    style = {
      ...style,
      padding: `7px ${paddingSide}px`,
      background: background,
      borderRadius: 20
    };
  let config = {
    className: cn(
      "typography",
      className,
      styles()[variant],
      `typography-${variant}`,
      uppercase && styles().uppercase,
      capitalize && styles().capitalize
    ),
    style: style
  };
  return dangerouslySetInnerHTML ? (
    <span
      dangerouslySetInnerHTML={{ __html: dangerouslySetInnerHTML }}
      {...config}
    />
  ) : (
    <span {...config}>{variant === "link" ? <a>{text}</a> : text}</span>
  );
};
