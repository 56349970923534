import React from "react";

export const Neck = (extra= [], withOutLymphadenopathy) => {
    return {
        type: "label",
        data: "Neck:",
        canDelete: true,
        items: [
            {
                type: "input",
                placeholder: "",
                data: "",
            },
            {
                type: "radio-single",
                data: [{checked: false, data: "tenderness"}]
            },
            {
                type: "text",
                data: ',',
                editable: true,
                source: null
            },
            {
                type: "radio-single",
                data: [{checked: false, data: "stiffness"}]
            },
            {
                type: "text",
                data: ',',
                editable: true,
                source: null
            },
            {
                type: "radio-single",
                data: [{checked: false, data: "lymphadenopathy"}]
            },
            ...extra,
            {
                type: "text",
                data: '.',
                editable: true,
                source: null
            },
        ]
    }
}