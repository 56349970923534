import React, { useEffect, useState } from "react";
import { getSetSignatureOfPatient } from "../../../store/doctor/actions";
import { SignatureBlock } from "../../SignatureBlock";
import { changeRequestDetails } from "../../../store/request/actions";
import { useDispatch } from "react-redux";
import { DECLINED, REQUEST_COMPLETED, VISIT_COMPLETED } from "../../../constants";

export const SignatureContainer = ({
    setNeedRefresh,
    needRefresh,
    detailsRequest,
    data,
    canAdd
}) => {
    const [signature, setSignature] = useState(null);
    const dispatch = useDispatch();

    const resetSignature = () => {
        setSignature(null);
        setNeedRefresh && setNeedRefresh(false);
    };

    const defineSignature = path => {
        setSignature(path);
        setNeedRefresh && setNeedRefresh(true);
    };

    useEffect(() => {
        if(data && data.uuid || needRefresh) {
            if(!!data?.patient_signature_path) {
                setSignature(data?.patient_signature_path);
            } else {
                resetSignature();
            }
        }
    }, [data?.uuid, needRefresh]);

    const action = request => {
        return getSetSignatureOfPatient(data?.uuid, request).then(resp => {
            if(!!resp.signature_path) {
                defineSignature(resp.signature_path);
                dispatch(changeRequestDetails('patient_signature_path', data.uuid, resp.signature_path));
            } else {
                resetSignature();
                throw 'error';
            }
        });
    };

    const titlePopup = `${data?.first_name || ''} ${data?.last_name} Agrees that requested person can receive documents`;
    const isCompleted = [REQUEST_COMPLETED, VISIT_COMPLETED, DECLINED].find(i => i === detailsRequest?.status.toLowerCase());
    let showSignature = data?.status !== 'main';
    if(detailsRequest?.status && isCompleted && !signature) {
        showSignature = false;
    }

    return(
        showSignature ? (
            <>
                <SignatureBlock
                    canAdd={canAdd}
                    isEmr
                    titlePopup={titlePopup}
                    signature={signature}
                    action={action}
                />
            </>
        ) : (<></>)
    );
};
