import React, { useState } from "react";
import './style.scss'
import { GiftCards } from "../Gift";
import { DailyReport } from '../DailyReport';
import { PatientsReport } from "../PatientsReport";
import { REPORTS_TABS } from "../../../constants/Admin";
import ButtonGroup from '../../Common/ButtonGroup/ButtonGroup';

export const Reports = ({ locations, getLocations }) => {
  const [activePage, setActivePage] = useState(
      REPORTS_TABS[0]
  );

  return (
    <div className="reports">
      <div className="reports__control">
        <ButtonGroup
          page={activePage}
          buttonsTab={REPORTS_TABS}
          changePage={setActivePage}
        />
      </div>
      <div className="reports__filters">
        {activePage === REPORTS_TABS[0] && (
          <DailyReport
            locations={locations}
            getLocations={getLocations}
          />
        )}
        {activePage === REPORTS_TABS[1] && <GiftCards />}
        {activePage === REPORTS_TABS[2] && <PatientsReport />}
      </div>
    </div>
  );
};
