const SET_TABLE_DATA_ADMIN = "SET_TABLE_DATA_ADMIN";
const SET_DOCTOR_DATA = "SET_DOCTOR_DATA";
const SET_UPLOAD_APPLICATIONS_DOCTORS_ADMIN =
  "SET_UPLOAD_APPLICATIONS_DOCTORS_ADMIN";
const SET_PERSONAL_INFO_ADMIN = "SET_PERSONAL_INFO_ADMIN";
const CLEAR_DOCTOR_DATA = "CLEAR_DOCTOR_DATA";
const CLEAR_PHYSICIAN = "CLEAR_PHYSICIAN";
const SET_APPOINTMENTS_SELECTED_DOCTORS = "SET_APPOINTMENTS_SELECTED_DOCTORS";
const SET_FILTERS_SHIFTS = "SET_FILTERS_SHIFTS";
const SET_ARCHIVED_FILES = "SET_ARCHIVED_FILES";
const SET_ACTIVE_PHYSICIAN = "SET_ACTIVE_PHYSICIAN";
const SET_DASHBOARD_DATA_ADMIN = "SET_DASHBOARD_DATA_ADMIN";
const SET_ADMIN_DATA = "SET_ADMIN_DATA";
const SET_DOCTOR_ASSIGN = "SET_DOCTOR_ASSIGN";
const UPDATE_STATUS_INVITE_SENT = "UPDATE_STATUS_INVITE_SENT";
export {
  SET_ACTIVE_PHYSICIAN,
  SET_TABLE_DATA_ADMIN,
  SET_DOCTOR_DATA,
  SET_UPLOAD_APPLICATIONS_DOCTORS_ADMIN,
  SET_PERSONAL_INFO_ADMIN,
  CLEAR_DOCTOR_DATA,
  CLEAR_PHYSICIAN,
  SET_APPOINTMENTS_SELECTED_DOCTORS,
  SET_FILTERS_SHIFTS,
  SET_ARCHIVED_FILES,
  SET_DASHBOARD_DATA_ADMIN,
  SET_ADMIN_DATA,
  SET_DOCTOR_ASSIGN,
  UPDATE_STATUS_INVITE_SENT
};
